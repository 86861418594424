'use client'

import Link from 'components/Link';
import { AIIcon, ArrowRight, DownIcon, GuideIcon } from './Icon'

import styles from './index.module.scss'
import { pushToResume } from 'helpers/push';
import { SectionSubtitle, SectionTitle } from '../SectionTitle';
import { formatTemplateString } from 'helpers/formatter';
import { languageContext } from 'app/components/providers/languageProvider';
import { useContext } from 'react';

export const AdditionalServices = ({ countryKey, dictionary }) => {
  const lang = dictionary; // useContext(languageContext) as any
  const { remotePage, comm } = lang || {};
  const isJp = countryKey === 'jp'


  const data = [
    {
      title: remotePage?.superchargeSearch, //'Supercharge Your Search with Our AI Career Coach',
      content: remotePage?.craftResume, //'Craft A Winning Resume In 4 Easy Steps',
      btn: remotePage?.craftResumeBtn,//'Create My Resume',
      Icon: AIIcon,
      color: '#2378E5',
      action: pushToResume
    },
    {
      title: remotePage?.elevateJobSearch,// `Elevate Your Remote Job Search with Our App`,
      content: remotePage?.discoverJobs,//'Discover Exclusive Remote Jobs and Apply Anywhere, Anytime',
      btn: remotePage?.downloadApp,//'Download App',
      Icon: DownIcon,
      color: '#18D4BE',
      href: '/talents',
    },
    {
      title: remotePage?.remoteJobGuide,// `Remote Job Guide`,
      content: remotePage?.remoteJobGuideContent,//`Provide professional advice and guidance to individuals on remote job choices.`,
      btn: remotePage?.remoteJobGuideBtn,//`Visit Our Blog`,
      Icon: GuideIcon,
      color: '#FF9500',
      href: `https://blog.bossjob.${isJp ? 'jp' : 'ph'}/`
    }
  ];

  return <div className={styles.additionalServices}>
    <div style={{ position: 'absolute', right: '0px', top: '-185px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="310" height="977" viewBox="0 0 310 977" fill="none">
        <path d="M4.2706e-05 488.5C1.91201e-05 758.291 218.709 977 488.5 977L1166 977L1166 -2.01354e-05L488.5 -7.93643e-05C218.709 -0.00010295 6.62919e-05 218.709 4.2706e-05 488.5Z" fill="url(#paint0_linear_8934_9408)" />
        <defs>
          <linearGradient id="paint0_linear_8934_9408" x1="221.5" y1="518" x2="-174.916" y2="609.756" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7BA7FF" stopOpacity="0.05" />
            <stop offset="1" stopColor="#377CFE" stopOpacity="0.25" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div>
      {/* class='coloredText' */}
      <SectionTitle innerHTML={`
        ${formatTemplateString(remotePage?.additionalServices, { Services: `<span >${comm?.Services}</span>` })}
        `} />
      <SectionSubtitle style={{ marginTop: 12 }}>
        {/* Maximize Your Career Potential with Our Extra Tools and Services */}
        {remotePage?.additionalServicesSubtitle}
      </SectionSubtitle>
    </div>

    <div className={styles.servicesWrapper}>
      {data.map((v, i) => {
        const { Icon, btn, color, href, action } = v
        return <div className={styles.servicesCard} key={i}>
          <span className={styles.IconWrapper}>
            <Icon />
          </span>
          <div className={styles.title}>{v.title}</div>
          <div className={styles.content}>{v.content}</div>
          <div style={{ marginTop: 'auto' }}>
            <Link href={href || ''} onClick={action ? (e) => {
              e.preventDefault();
              action()
            } : null}
              className={styles.actionBtn}
              style={{ color }}
            >
              {btn}
              <ArrowRight />
            </Link>
          </div>
        </div>
      })}
    </div>

  </div>
}