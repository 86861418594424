import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import style from './index.module.scss'
import { Country, JobClasses, JobData, fetchJobsListReq, getIDFromKeyword } from '../../service'
import Autocomplete from '@mui/material/Autocomplete'
import { flat } from 'helpers/formatter'
import { useCompanyDetail } from '../../DataProvider'
import Link from 'components/Link'
import { useParams } from 'next/navigation'
import Image from 'next/image'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext, useLanguage } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
import { getCookie } from 'helpers/cookies'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import { uniq } from 'lodash-es'
import { useFirstRender } from 'helpers/useFirstRender'
import MaterialButton from 'components/MaterialButton'
import MaterialBasicSelect from '../Select/MaterialBasicSelect'
import ShowSalary from 'app/components/ShowSalary'
import { CircularProgress } from 'app/components/MUIs'
import SalarySelect from 'app/components/commons/salarySelect'
import { cloneDeep } from 'lodash-es'
import { getSalaryRange } from 'helpers/salary'
import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
interface Props {
  CountryList: Country[]
  functions: JobClasses[]
  xpLvlsList: any
  salaryList: any
  setOpen: (Boolean) => void
  containerHight: string
  addJobsTableTouch: any
  salaryTypeLists: any
  dailySalaryRangeFilters: any
  hourlySalaryRangeFilters: any
  annuallySalaryRangeFilters: any
}

const SearchIcon = () => {
  return <span className={style.job_prefix}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
    >
      <path
        d='M8.75 15.511C12.2018 15.511 15 12.7128 15 9.26105C15 5.80928 12.2018 3.01105 8.75 3.01105C5.29824 3.01105 2.5 5.80928 2.5 9.26105C2.5 12.7128 5.29824 15.511 8.75 15.511Z'
        stroke='#B8B8B8'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M13.3333 13.8444L16.6666 17.1777'
        stroke='#B8B8B8'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </span>
}
const DownIcon = () => <svg
  xmlns='http://www.w3.org/2000/svg'
  width='11'
  height='7'
  viewBox='0 0 11 7'
  fill='none'
>
  <path
    d='M0.938965 0.907986L5.40409 5.42093L9.67375 0.601135'
    stroke='#414141'
    strokeLinecap='round'
  />
</svg>
const formatLocationConfig = (locationList) => {
  const locationConfig = locationList?.map((region) => region.locations)
  return locationConfig
}

const SearchPanelMobile = (props: Props) => {
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const { containerHight, addJobsTableTouch, salaryList, salaryTypeLists } = props

  const formattedLocationList = flat(formatLocationConfig(props.CountryList))
  const params = useParams()
  const { jobs } = useCompanyDetail()
  const [jobsData, setJobsData] = useState(jobs)
  useEffect(() => {
    return addJobsTableTouch('jobs-card-list')
  }, [])
  const jobFunctions = props.functions?.map((e) => ({
    label: e.value,
    id: e.id
  }))

  const xpLvlsList = props.xpLvlsList?.map((e) => ({
    label: e.value,
    id: e.id
  }))

  // const salaryList = props.salaryList?.map((e) => ({
  //   label: e.value,
  //   id: e['seo-value']
  // }))

  const [location, setLocation] = useState<Country | undefined>()
  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)
  const firstRender = useFirstRender()
  const pageRef = useRef(1)
  const currentLocation = useRef<Country>()
  const [searchParams, setSearchParams] = useState<any>({
    companyIds: id,
    size: 10,
    page: 1,
    query: inputText.current,
    location: location?.id,
    jobFunctions: '',
    //  salary: '',
    workExperience: ''
  })
  const [salaries, setSalaries] = useState({
    salary: [],
    salaryType: []
  })
  const scrollRef = useRef(null)
  const unshiftArr = (arr = []) => {
    arr?.unshift({
      label: overview.All,
      id: ''
    })
    return arr
  }
  useEffect(() => {
    if (!props.functions) return
    // filterTagView.current = [{}].concat(props.functions);
  }, [props.functions])

  useEffect(() => {
    if (jobs.jobs?.length > 0) {
      getRecruiterIsOnline(jobs)
    }
  }, [jobs])

  useEffect(() => {
    if (firstRender) return
    searchFunc()
  }, [searchParams])

  useEffect(() => {
    if (firstRender) return
    searchFunc()
  }, [salaries])

  const handleSalary = (salaryRanges = [], salaryList) => {
    const selected = salaryRanges.map((seo) => salaryList.find((item) => item['seo-value'] === seo)).filter(a => a)
    const from = selected.map((item) => item.from).join(',')
    const to = selected.map((item) => item.to).join(',')
    return [from, to]
  }
  const getRecruiterIsOnline = (oldData) => {
    const Ids = uniq(oldData.jobs.map((e) => e.recruiter_id))
    fetchQueryOnlineStatus({
      ids: Ids
    })
      .then((res) => {
        const data = res.data.data
        oldData.jobs.map((e) => {
          data.map((k) => {
            if (e.recruiter_id == k.user_id) {
              e.recruiter_is_online = k.is_online
            }
          })
        })
        setJobsData({ ...oldData })
      })
      .catch((e) => console.log(e))
  }

  const searchFunc = () => {
    setLoading(true)
    if (searchParams.job_function_ids === 'all') {
      delete searchParams.jobFunctions
    }
    const newParams = cloneDeep(searchParams)
    if (salaries?.salaryType?.length > 0) {
      const { salaryKey, salaryFrom, salaryTo, salaryType } = getSalaryRange({
        monthlyFilter: props.salaryList,
        dailyFilter: props.dailySalaryRangeFilters,
        hourlyFilter: props.hourlySalaryRangeFilters,
        annuallyFilter: props.annuallySalaryRangeFilters,
        salaryTypeLists,
        salaryType: salaries.salaryType?.join(),
        selectedSalaries: salaries.salary
      })

      if (salaryKey) {
        newParams[`${salaryKey}_from`] = salaryFrom
        newParams[`${salaryKey}_to`] = salaryTo
      }
    } else {
      newParams.salaryFrom = ''
      newParams.salaryTo = ''
    }

    fetchJobsListReq(newParams)
      .then((res) => {
        const data = res.data
        if (newParams.page > 1) {
          data.jobs = jobsData.jobs.concat(data.jobs)
        }
        setJobsData(data)
        getRecruiterIsOnline(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchParams({
        ...searchParams,
        query: inputText.current
      })
      event?.target?.blur?.()
      // searchFunc(null, location)
    }
  }

  const AutocompleteComponent = useMemo(() => {
    return (
      <Autocomplete
        id='location-autocomplete1'
        options={formattedLocationList}
        groupBy={(option: any) => option.region_display_name}
        getOptionLabel={(option: any) => {
          return option.value
        }}
        value={location}
        size='small'
        onChange={(e, value) => {
          currentLocation.current = value
          setLocation(value)
          // searchFunc(inputText.current, value, 1)
        }}
        key={location?.id}
        // disablePortal
        disableClearable={false}
        // className={className}
        // disableCloseOnSelect
        renderInput={(params) => {
          return (
            <label
              ref={params.InputProps.ref}
              htmlFor={'location-autocomplete'}
              className={style.location_input_wrapper}
            >
              <input
                {...params.inputProps}
                placeholder='Location'
                type='search'
                className={style.location_input}
              />
              <div className={style.location_arrow}></div>
              <div className={style.location_input_border} />
            </label>
          )
        }}
      // defaultValue={defaultValue}
      // {...rest}
      />
    )
  }, [location])
  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          <label htmlFor='input-search' className={style.job_search_container}>

            <SearchIcon />
            <input
              id={'input-search'}
              name={'input-search'}
              enterKeyHint='search'
              placeholder={overview.SearchPlaceholder}
              className={style.job_search_input}
              onChange={(e) => {
                inputText.current = e.target.value
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={handleKeyPress}
            />
          </label>
        </div>
      </div>
      <div className={style.select}>
        <MaterialBasicSelect
          label={overview.jobFunction}
          value
          options={unshiftArr(jobFunctions)}
          onSelect={(e) => {
            setSearchParams({
              ...searchParams,
              jobFunctions: e
            })
          }}
        ></MaterialBasicSelect>

        {/* <MaterialBasicSelect
          label={overview.salary}
          onSelect={(e) => {
            setSearchParams({
              ...searchParams,
              salary: e
            })
          }}
          options={unshiftArr(salaryList)}
        ></MaterialBasicSelect> */}
        <SalarySelect
          id='SalarySelect'
          key='SalarySelect'
          salaryTypeLists={props.salaryTypeLists}
          label={contextLang.search?.salary}
          value={salaries}
          lang={contextLang.search}
          className={style.filterItems}
          onChange={setSalaries}
          initHeight={'calc(80vh'}
          Icon={<DownIcon />}
        />
        <MaterialBasicSelect
          label={overview.WorkExperience}
          onSelect={(e) => {
            setSearchParams({
              ...searchParams,
              workExperience: e
            })
          }}
          options={unshiftArr(xpLvlsList)}
        ></MaterialBasicSelect>
      </div>
      <div className={style.lineBox}></div>
      <div
        className={style.search_content_wrapper}
        style={{ height: `calc(${containerHight} - 152px)` }}
        ref={scrollRef}
        id='jobs-card-list'
      >
        <div className={style.content_layout}>
          {!!jobsData?.jobs.length ? (
            jobsData?.jobs.map((item, index) => {
              return <JobsSearchCard {...item} key={item.job_title + item.id + index} />
            })
          ) : (
            <div className={style.noData}>
              <Empty lang={contextLang.search} description={null} />
            </div>
          )}
        </div>
        <div className={style.pagination}>
          {(jobsData?.total_pages > pageRef.current || loading) && (
            <MaterialButton
              variant='contained'
              sx={{
                height: '44px',
                borderRadius: '4px',
                width: '144px',
                textTransform: 'capitalize !important',
                background: '#ffffff',
                boxShadow: 'none',
                border: '1px solid #2378E5',
                color: '#2378E5',
                fontSize: '14px',
                fontWeight: 'normal',
                ':hover': {
                  backgroundColor: '#fff',
                  boxShadow: 'none'
                },
                '-loading': {
                  border: 'none'
                }
              }}
              isLoading={loading}
              onClick={() => {
                pageRef.current++
                setSearchParams({
                  ...searchParams,
                  page: pageRef.current
                })
              }}
            >
              {contextLang?.home?.seeMoreBtn}
            </MaterialButton>
          )}
        </div>
      </div>
    </div>
  )
}

enum RemoteWorldwideType {
  OnSite = 1,
  Hybrid = 2,
  Remote = 3
}

const translateCountries = (countries, config) => {
  const country_list = config?.country_lists || []
  const result = countries.map((item) => {
    return country_list.find((country) => country.id === item.id)?.value
  })
  return result
}

export const getLocation = (props, lang, translations) => {
  const {
    job_region_id,
    job_location_id,
    work_arrangement_id,
    remote_countries = [],
    is_remote_worldwide,
    is_all_location
  } = props

  const { config } = useCompanyDetail()

  // remote work and not worldwide
  if (work_arrangement_id === RemoteWorldwideType.Remote && !is_remote_worldwide) {
    return translateCountries(remote_countries, config).join(',')
  }
  // remote work and worldwide
  if (work_arrangement_id === RemoteWorldwideType.Remote && is_remote_worldwide) {
    return lang.global
  }
  // on site and hybrid
  const location_list = config?.location_lists || []
  const region = location_list.find((item) => item.id === job_region_id)?.locations || []
  const location = region.find((item) => item.id === job_location_id)?.value || ''
  return `${getSingaporeAddress(is_all_location, translations, location)}`// `${location}`
}

const JobsSearchCard = (props: JobData) => {
  const { lang, config, dictionary } = useCompanyDetail()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const contextLang = useContext(languageContext)
  const dic = useLanguage()
  const { overview } = contextLang.companyDetail
  const degree_list = config?.degrees || []
  const xp_lvl_list = config?.xp_lvls || []
  const job_type_list = config?.job_types || []
  const _tagsData = [...tagsData]
  _tagsData[0].name = xp_lvl_list.find((item) => item.id === props.xp_lvl_id)?.value || ''
  _tagsData[1].name = degree_list.find((item) => item.id === props.degree_id)?.value || ''
  _tagsData[2].name = job_type_list.find((item) => item.id === props.job_type_id)?.value || ''
  return (
    <div className={style.search_card}>
      <div className={style.search_title_layout}>
        <Link
          href={'/' + lang + props.job_url}
          target='_blank'
          title={props.job_title}
          className={style.title}
        >
          {props.is_urgent && <span className={style.urgentLabel}>{dictionary?.search?.urgent}</span>}
          <span style={{ verticalAlign: 'bottom' }}>{props.job_title}</span>
        </Link>
        {
          <div className={style.jobcard_salary_wrapper}>
            <div className={style.salary}>
              <ShowSalary dictionary={{
                salaryType: dictionary?.salaryType
              }} jobs={props} size={14} />
              {/* {props.local_salary_range_value} */}
            </div>
            <Link className={style.chat_now} href={'/' + lang + props.job_url} target='_blank'>
              {overview.jobs.card.chatNow}
            </Link>
          </div>
        }
      </div>
      {/* {isMobile && <div className={style.salary}>
            {props.local_salary_range_value}
        </div>} */}
      <div className={style.content}>
        {_tagsData
          .map((item, index) => {
            const value = props[item.field]
            if (!value) return null
            return (
              <span className={style.mobile_tag} key={index}>
                {item.name}
              </span>
            )
          })
          .slice(0, 3)}
        {!isMobile && <JobsTag {...props} />}
      </div>
      <div className={style.footer}>
        <div className={style.chat_footer}>
          <div className={style.avatar}>
            <Image fill src={props.recruiter_avatar} alt='img' />
            <div
              className={style.status}
              style={{ backgroundColor: props.recruiter_is_online ? '#0ebd5c' : '#E5E6EB' }}
            />
          </div>
          <Link className={style.name} href={'/' + lang + props.job_url} target='_blank'>
            <span title={props.recruiter_full_name}>{props.recruiter_full_name}</span>
            {props.recruiter_job_title && (
              <>
                &nbsp;<div style={{ position: 'relative', top: -2 }}>.</div>&nbsp;
                <span title={props.recruiter_job_title}>{props.recruiter_job_title}</span>
              </>
            )}
          </Link>
        </div>
        <div className={style.location} title={getLocation(props, overview, dic.home.allRegions)}>
          {getLocation(props, overview, dic.home.allRegions)}
        </div>
      </div>
    </div>
  )
}

interface TagProps extends JobData {
  count?: number
  classNames?: any
  type?: 'background'
  style?: React.CSSProperties
}

export const tagsData = [
  { name: '', field: 'xp_lvl_id' },
  { name: '', field: 'degree_id' },
  { name: '', field: 'job_type_id' }
]
export const JobsTag = (props: TagProps) => {
  const { config } = useCompanyDetail()
  const degree_list = config?.degrees || []
  const xp_lvl_list = config?.xp_lvls || []
  const job_type_list = config?.job_types || []
  const _tagsData = [...tagsData]
  _tagsData[0].name = xp_lvl_list.find((item) => item.id === props.xp_lvl_id)?.value || ''
  _tagsData[1].name = degree_list.find((item) => item.id === props.degree_id)?.value || ''
  _tagsData[2].name = job_type_list.find((item) => item.id === props.job_type_id)?.value || ''
  return (
    <div className={style.tags} style={props.style ? props.style : null}>
      {_tagsData
        .map((item, index) => {
          const value = props[item.field]
          if (!value || !item.name) return null
          return (
            <div className={style.tag_item + ' ' + ' tag_flag'} key={index}>
              {item.name}
            </div>
          )
        })
        .slice(0, props.count ?? 3)}
    </div>
  )
}

export default SearchPanelMobile
