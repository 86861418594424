import React from 'react';
import styled from '@emotion/styled';

const Step = ({ current, items, className = "" }) => {
  return (
    <StepContainer className={className}>
      {items.map((v, i) => {
        const isActive = i === current;
        const isCompleted = i < current;

        return (
          <React.Fragment key={i}>
            <div className={`custom-step ${isActive ? 'custom-step-active' : ''} ${isCompleted ? 'custom-step-completed' : ''}`}>
              {isCompleted ? <CheckIcon /> : <i className={`custom-step-index ${isActive ? 'custom-step-count' : ''}`}>{i + 1}</i>}
              <span className={`custom-step-title ${isActive ? 'custom-step-active' : ''}`}>{v.title}</span>
            </div>
            {i < items.length - 1 && <div className={`custom-step-line ${isCompleted ? 'custom-step-line-active' : ''}`} />}
          </React.Fragment>
        );
      })}
    </StepContainer>
  );
};

export default Step;

const CheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0052D9"
      d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Zm0 1C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12Z"
    />
    <path
      fill="#0052D9"
      d="m10.434 13.921-3.54-3.54-.92.92 4.46 4.459 7.142-7.142-.92-.92-6.222 6.223Z"
    />
  </svg>
);

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .custom-step {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .custom-step-title {
    color: #7d7d7d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .custom-step-index {
    display: inline-block;
    font-style: normal;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #e8e8ea;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    line-height: 24px;
    flex-shrink: 0;
  }

  .custom-step-line {
    display: block;
    height: 2px;
    background: #e8e8ea;
    width: 38px;
  }

  .custom-step-line-active {
    background: #e8e8ea;
  }

  .custom-step-count {
    background-color: #2378e5;
    color: #fff;
  }

  .custom-step-active {
    color: #121212;
    font-size: 16px;
    font-weight: 500;
  }

  .custom-step-completed .custom-step-title {
    color: #2378e5;
    font-weight: 500;
  }
`;