import React from 'react'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './hooks'
import useEmblaCarousel from 'embla-carousel-react'

import styles from './index.module.scss'

type PropType = {
  slides: any[]
  renderItem: (index: number) => any,
  isMobile: boolean
}
const OPTION: any = {
  align: 'start',
  loop: true,
  slidesToScroll: 'auto'
}
const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, renderItem, isMobile } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTION)

  const {
    // prevBtnDisabled,
    // nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
      <PrevButton className={styles.emblaBtn}
        onClick={onPrevButtonClick} style={{ left: '0px', transform: 'translate3d(-100%, -50%, 0px)', zIndex: 1 }} />

      <section className={styles["embla"]} style={{ width: `calc(100% - ${0}px)` }}>
        <div className={styles["embla__viewport"]} ref={emblaRef}>
          <div className={styles["embla__container"]}>
            {slides.map((_, index) => {
              // return renderItem(index)
              return <div className={styles["embla__slide"]} key={index} >
                {renderItem(index)}
              </div>
            })}
          </div>
        </div>
      </section >

      <NextButton
        className={styles.emblaBtn}
        onClick={onNextButtonClick}
        style={{ right: '0px', transform: 'translate3d(100%, -50%, 0px) rotate(180deg)' }} />
    </div>
  )
}

export default EmblaCarousel
