/* Vendor */
import { ThemeProvider } from '@emotion/react'

/* Components */
import { Tabs, Tab, createTheme } from '@mui/material'
import UserProfileOverview from 'components/UserProfileOverview'
import Text from 'components/Text'
import Image from 'next/image';
import { formatTemplateString } from 'helpers/formatter'
import { isMobile } from 'react-device-detect'
/* Styles */
import styles from './ProfileLayout.module.scss'

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '50px'
        },
        centered: {
          justifyContent: 'flex-start',
          ['@media (max-width: 780px)']: {
            // eslint-disable-line no-useless-computed-key
            justifyContent: 'center'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: 'capitalize'
        }
      }
    }
  }
})

type ProfileLayoutProps = {
  userDetail: any
  lang: any
  tabValue: string | string[]
  setTabValue: Function
  modalName: string
  handleModal: Function
  children: React.ReactNode
  dic: any
  unCompleted: any,
  advertisingLink: any,
  newGetStarted: any
}

const ProfileLayout = ({
  dic,
  lang,
  userDetail,
  tabValue,
  setTabValue,
  modalName,
  handleModal,
  children,
  advertisingLink,
  unCompleted,
  newGetStarted
}: ProfileLayoutProps) => {
  const {
    avatar,
    email,
    phone_num: contactNum,
    first_name: firstName,
    last_name: lastName,
    location: userLocation,
    description,
    xp_lvl: expLevel,
    birthdate,
    working_since,
    address,
    location_id,
    vip,
    referral_code,
    is_fresh_graduate
  } = userDetail
  const {
    profile
  } = lang
  const handleShowModal = () => {
    handleModal(modalName, true)
  }

  const handleTabChange = (e, target) => {
    setTabValue(target)
    /*
     * Set the tab with param: e.g: ?tab=profile.
     * Using window.history.replaceState instead of router event from useRouter as router event causes the page to rerender.
     * We only want the params to change without rerendering the page
     */
    const url = new URL(window.location as any)
    url.searchParams.set('tab', target)
    window.history.replaceState({}, '', url)
  }

  const handleEditClick = () => {
    handleShowModal()
  }
  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageBanner}>
        <div className={styles.bannerText}>
          <div className={styles.inner}>
            <div className={styles.innerText}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="none">
                <path d="M13.5446 2.94671L12.9246 2.93663C12.9095 2.93663 11.5216 2.90639 10.0868 2.32504C8.61493 1.72858 7.66731 1.04307 7.65891 1.03466L7.28423 0.764156L6.91291 1.03466C6.90451 1.04139 5.95521 1.7269 4.48505 2.32504C3.05186 2.90639 1.66235 2.93663 1.64891 2.93663L1.02724 2.94671L1.02724 9.25411C1.02724 12.4213 5.09832 15.7699 7.28591 15.7699C9.47519 15.7699 13.5463 12.4213 13.5463 9.25411C13.5463 8.62626 13.547 7.63873 13.5463 5.86624C13.5457 4.55432 13.5446 2.94671 13.5446 2.94671Z" fill="#6CABFF" />
                <path d="M4.52407 8.12661L6.73814 10.3645L10.6623 6.1995" stroke="white" strokeWidth="1.34711" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p dangerouslySetInnerHTML={{
                __html: formatTemplateString(profile.userGuideLinkTips, {
                  userGuideLink: `<a href="https://bossjob.crisp.help/en/article/bossjob-user-data-guide-19m0q4y/" target="_blank">${formatTemplateString(profile.userGuideLink, {})}</a>`
                })
              }} />
              {/* {formatTemplateString(profile.userGuideLinkTips, {
                userGuideLink: formatTemplateString(profile.userGuideLink, {})
              })} */}
              {/* <p>Learn about the use and protection of your data on Bossjob User Data Guide</p> */}
            </div>

          </div>
        </div>
        <Image src={`${process.env.S3_BUCKET_URL}/profile/banner2.png`} style={{ objectFit: 'cover' }} fill alt='banner'></Image>
      </div>

      <div className={styles.profileLayout}>
        {isMobile && <div className={styles.userGuide}>
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none">
            <path d="M12.8054 2.32241L12.1854 2.31233C12.1703 2.31233 10.7825 2.28209 9.3476 1.70075C7.87576 1.10428 6.92814 0.418768 6.91973 0.410368L6.54505 0.139858L6.17374 0.410368C6.16533 0.417088 5.21603 1.1026 3.74587 1.70075C2.31268 2.28209 0.923171 2.31233 0.909729 2.31233L0.288062 2.32241L0.288062 8.62981C0.288062 11.797 4.35914 15.1456 6.54673 15.1456C8.73601 15.1456 12.8071 11.797 12.8071 8.62981C12.8071 8.00197 12.8079 7.01443 12.8071 5.24194C12.8065 3.93002 12.8054 2.32241 12.8054 2.32241Z" fill="#6CABFF" />
            <path d="M3.78481 7.50234L5.99888 9.74023L9.92302 5.57524" stroke="white" strokeWidth="1.34711" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <p dangerouslySetInnerHTML={{
            __html: formatTemplateString(profile.userGuideLinkTips, {
              userGuideLink: `<a href="https://bossjob.crisp.help/en/article/bossjob-user-data-guide-19m0q4y/" target="_blank">${formatTemplateString(profile.userGuideLink, {})}</a>`
            })
          }} />
        </div>}
        <div className={styles.profileLayoutUserOverview}>
          <UserProfileOverview
            name={`${firstName} ${lastName}`}
            location={userLocation}
            location_id={location_id}
            email={email}
            avatarUrl={avatar}
            contactNumber={contactNum}
            description={description}
            expLevel={expLevel}
            birthdate={birthdate}
            working_since={working_since}
            address={address}
            vip={vip}
            referral_code={referral_code}
            handleEditClick={handleEditClick}
            lang={dic}
            advertisingLink={advertisingLink}
            newGetStarted={newGetStarted}
            isFreshGraduate={is_fresh_graduate}
          />
        </div>
        <div className={styles.profileLayoutSettings}>
          <div className={styles.settingTabs}>
            <ThemeProvider theme={theme}>
              <Tabs
                value={tabValue}
                variant='scrollable'
                scrollButtons='auto'
                onChange={handleTabChange}
              >
                <Tab
                  className={styles.settingTabsItem}
                  value='profile'
                  label={
                    <Text
                      bold
                      textStyle='xl'
                      textColor={tabValue === 'profile' ? 'primaryBlue' : 'black'}
                      className={unCompleted['profile'] ? styles.unCompleted : ''}
                    >
                      {dic.profile.tabTitle}
                    </Text>
                  }
                />
                <Tab
                  className={styles.settingTabsItem}
                  value='job-preferences'
                  label={
                    <Text
                      bold
                      textStyle='xl'
                      textColor={tabValue === 'job-preferences' ? 'primaryBlue' : 'black'}
                      className={unCompleted['job-preferences'] ? styles.unCompleted : ''}
                    >
                      {dic.preference.tabTitle}
                    </Text>
                  }
                />
                <Tab
                  className={styles.settingTabsItem}
                  value='resume'
                  label={
                    <Text
                      bold
                      textStyle='xl'
                      textColor={tabValue === 'resume' ? 'primaryBlue' : 'black'}
                      className={unCompleted['resume'] ? styles.unCompleted : ''}
                    >
                      {dic.resume.tabTitle}
                    </Text>
                  }
                />
              </Tabs>
            </ThemeProvider>
          </div>
          {children}
        </div>
      </div>
    </div>

  )
}

export default ProfileLayout
