'use client'
import { useRouter } from 'next/navigation'
import { toPairs } from 'ramda'

import { encode } from 'app/(jobs-hiring)/[lang]/jobs-hiring/interpreters/encoder'

import styles from '../../../../page.module.scss'

type propsType = {
  data: any
  isMobile?: boolean
}

const SeeMore = ({ data, isMobile }: propsType) => {
  const router = useRouter()
  const text = data?.text
  const handleToHomePage = () => {
    const searchQuery: any = {
      location: [data?.location],
      mainFunctions: [data?.main_function],
      jobFunctions: [data?.sub_function],
      workExperience: [data?.xp_lvl_seo_value]
    }

    const result = encode(searchQuery)
    const url = new URLSearchParams(toPairs(result.params)).toString()
    router.push('/jobs-hiring/' + result.searchQuery + '?' + url, {
      scroll: true
    })
  }

  if (isMobile) {
    return (
      <div
        className={styles.similarJobs_seeMore_h5}
        onClick={handleToHomePage}
      >
        <span>{text || 'See more'}</span>
      </div>
    )
  }

  return (
    <div
      className={styles.similarJobs_seeMore}
      onClick={handleToHomePage}
    >
      <span>{text || 'See more'}</span>
    </div>
  )
}

export default SeeMore
