import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import { Country, JobClasses, JobData, getIDFromKeyword, fetchRemoteJobs } from '../../service'
import Pagination from '@mui/material/Pagination'
import { useCompanyDetail } from '../../DataProvider'
import Link from 'components/Link'
import { useParams } from 'next/navigation'
import Image from 'next/image'
import Loading from 'app/components/loading'
import className from 'classnames'
import { useFirstRender } from 'helpers/useFirstRender'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
import { getCookie } from 'helpers/cookies'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { debounce } from 'lodash-es'
import MaterialButton from 'components/MaterialButton'
import { uniq } from 'lodash-es'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import ShowSalary from 'app/components/ShowSalary'
import searchUrl from './search.svg'
interface Props {
  CountryList: Country[]
  functions: JobClasses[]
  locationRemote?: any
  languageRemote?: any
  remoteJobs?: any
}

const SearchPanel = (props: Props) => {
  const params = useParams()
  const { remoteJobs, isRemoteWorldwide, dictionary } = useCompanyDetail()
  const [jobsData, setJobsData] = useState(remoteJobs)
  console.log('remoteJobs', remoteJobs)
  // const [location, setLocation] = useState<Country | undefined>()
  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const pageRef = useRef(1)
  const [isMobileLoading, setIsMobileLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)

  // const filterTagView = useRef<JobVisible[]>([{}].concat(props.functions));
  const firstRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useMediaQuery('(max-width:768px)')
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const firstRender = useFirstRender()
  const [searchParams, setSearchParams] = useState({
    language_ids: '',
    job_function_ids: '',
    remote_country_ids: ''
    // is_remote_worldwide: 1
  })

  const debouncedFunction = useCallback(
    debounce((params) => {
      // 在这里执行你想要防抖的操作
      searchFunc(null, 1, params)
    }, 300),
    []
  )
  useEffect(() => {
    if (firstRender) return
    debouncedFunction(searchParams)
  }, [searchParams])

  useEffect(() => {
    if (remoteJobs.jobs?.length > 0) {
      getRecruiterIsOnline(remoteJobs)
    }
  }, [remoteJobs])

  const getRecruiterIsOnline = (oldData) => {
    const Ids = uniq(oldData.jobs.map((e) => e.recruiter_id))
    fetchQueryOnlineStatus({
      ids: Ids
    })
      .then((res) => {
        const data = res.data.data
        oldData.jobs.map((e) => {
          data.map((k) => {
            if (e.recruiter_id == k.user_id) {
              e.recruiter_is_online = k.is_online
            }
          })
        })
        setJobsData({ ...oldData })
      })
      .catch((e) => console.log(e))
  }

  const searchFunc = (jobTitle?: string, page = 1, params?: any) => {
    if (isMobile) {
      setIsMobileLoading(true)
    } else {
      setLoading(true)
    }

    const reqData = {
      company_ids: id,
      size: 10,
      page,
      query: jobTitle || inputText.current,
      ...params
    }

    if (isRemoteWorldwide && params.remote_country_ids === '-1') {
      reqData.is_remote_worldwide = 1
      delete reqData.remote_country_ids
    }
    fetchRemoteJobs(reqData)
      .then((res) => {
        const data = res.data
        if (isMobile && reqData.page > 1) {
          data.jobs = jobsData.jobs.concat(data.jobs)
        }
        setJobsData(data)
        getRecruiterIsOnline(data)
      })
      .finally(() => {
        setLoading(false)
        setIsMobileLoading(false)
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchFunc(null, 1, searchParams)
    }
  }

  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          {/* <div className={style.location_selector_wapper}>{AutocompleteComponent}</div> */}
          <label htmlFor='input-search' className={style.job_search_container}>
            <Image
              width={16}
              height={16}
              className={style.job_prefix}
              src={searchUrl}
              alt='_'
            />
            <input
              id={'input-search'}
              name={'input-search'}
              placeholder={overview.SearchPlaceholder}
              className={style.job_search_input}
              onChange={(e) => {
                inputText.current = e.target.value
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={handleKeyPress}
            />
          </label>
        </div>
        <div
          className={className({
            [style.search_button]: true
            //  [style.button_loading]: loading
          })}
          onClick={() => {
            if (loading) return
            pageRef.current = 1
            searchFunc(null, 1, searchParams)
          }}
        >
          <span>{isMobile ? overview.Find : overview['FindNow']}</span>
        </div>
      </div>
      <div className={style.filter_container}>
        {props.functions?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'job_function_ids'}
              value={searchParams.job_function_ids}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  job_function_ids: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {props.functions?.map((item, index) => (
                <Tab label={item.value} value={item.id} key={item.id} />
              ))}
            </Tabs>
          </div>
        )}
        {props.locationRemote?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'remote_country_ids'}
              value={searchParams.remote_country_ids}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  remote_country_ids: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {isRemoteWorldwide && <Tab label={overview.worldwide} value={'-1'} key={'-1'} />}
              {props.locationRemote?.map((item, index) => (
                <Tab label={item.value} value={item.id} key={item.id} />
              ))}
            </Tabs>
          </div>
        )}
        {props.languageRemote?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'language_ids'}
              value={searchParams.language_ids}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  language_ids: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {props.languageRemote?.map((item, index) => (
                <Tab label={item.name} value={item.id} key={item.id} />
              ))}
            </Tabs>
          </div>
        )}
      </div>
      <div className={style.search_content_wrapper}>
        <div className={style.filter_split} ref={firstRef}></div>
        <div className={style.content_layout}>
          {loading ? (
            loading && (
              <div className={style.loading_wrapper}>
                {/* <div className={style.loading_wrapper}/> */}
                <Loading />
              </div>
            )
          ) : !!jobsData.jobs.length ? (
            !loading &&
            jobsData.jobs.map((item, index) => {
              return <JobsSearchCard {...item} key={item.job_title + item.id + index} />
            })
          ) : (
            <div className={style.noData}>
              <Empty lang={contextLang.search} description={null} />
            </div>
          )}
        </div>
        <div className={style.pagination}>
          {isMobile
            ? jobsData.total_pages > pageRef.current && (
              <MaterialButton
                variant='contained'
                sx={{
                  height: '44px',
                  borderRadius: '4px',
                  width: '144px',
                  textTransform: 'capitalize !important',
                  background: '#ffffff',
                  boxShadow: 'none',
                  border: '1px solid #2378E5',
                  color: '#2378E5',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  ':hover': {
                    backgroundColor: '#fff',
                    boxShadow: 'none'
                  },
                  '-loading': {
                    border: 'none'
                  }
                }}
                isLoading={isMobileLoading}
                onClick={() => {
                  pageRef.current++
                  searchFunc('', pageRef.current, searchParams)
                }}
              >
                {contextLang?.home?.seeMoreBtn}
              </MaterialButton>
            )
            : !!jobsData.total_pages &&
            !loading && (
              <Pagination
                page={jobsData.page}
                count={jobsData.total_pages}
                onChange={(e, v) => {
                  if (isMobile) {
                    firstRef.current &&
                      firstRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest'
                      })
                  }
                  searchFunc(null, v, searchParams)
                  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                }}
                shape='rounded'
                color={'primary'}
              />
            )}
        </div>
      </div>
    </div>
  )
}

enum RemoteWorldwideType {
  OnSite = 1,
  Hybrid = 2,
  Remote = 3
}

const translateCountries = (countries, config) => {
  const country_list = config?.country_lists || []
  const result = countries.map((item) => {
    return country_list.find((country) => country.id === item.id)?.value
  })
  return result
}

export const getLocation = (props, lang) => {
  const {
    job_region_id,
    job_location_id,
    work_arrangement_id,
    remote_countries = [],
    is_remote_worldwide
  } = props

  const { config } = useCompanyDetail()

  // remote work and not worldwide
  if (work_arrangement_id === RemoteWorldwideType.Remote && !is_remote_worldwide) {
    return translateCountries(remote_countries, config).join(',')
  }
  // remote work and worldwide
  if (work_arrangement_id === RemoteWorldwideType.Remote && is_remote_worldwide) {
    return lang.global
  }
  // on site and hybrid
  const location_list = config?.location_lists || []
  const region = location_list.find((item) => item.id === job_region_id)?.locations || []
  const location = region.find((item) => item.id === job_location_id)?.value || ''
  return `${location}`
}

const JobsSearchCard = (props: JobData) => {
  const { lang, config, dictionary } = useCompanyDetail()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const degree_list = config?.degrees || []
  const xp_lvl_list = config?.xp_lvls || []
  const job_type_list = config?.job_types || []
  const _tagsData = [...tagsData]
  _tagsData[0].name = xp_lvl_list.find((item) => item.id === props.xp_lvl_id)?.value || ''
  _tagsData[1].name = degree_list.find((item) => item.id === props.degree_id)?.value || ''
  _tagsData[2].name = job_type_list.find((item) => item.id === props.job_type_id)?.value || ''
  return (
    <div className={style.search_card}>
      <div className={style.search_title_layout}>
        <Link
          href={'/' + lang + props.job_url}
          target='_blank'
          title={props.job_title}
          className={style.title}
        >
          {props.is_urgent && <span className={style.urgentLabel}>{dictionary?.search?.urgent}</span>}
          <span style={{ verticalAlign: 'bottom' }}>{props.job_title}</span>
        </Link>
      </div>

      <div className={style.jobcard_salary_wrapper}>
        <div className={style.salary}>
          <ShowSalary dictionary={{
            salaryType: dictionary?.salaryType
          }} jobs={props} size={16} />
          {/* {props.local_salary_range_value} */}
        </div>
        <Link className={style.chat_now} href={'/' + lang + props.job_url + '?source=company_detail'} target='_blank'>
          {overview.jobs.card.chatNow}
        </Link>
      </div>

      {/* {isMobile && <div className={style.salary}>
            {props.local_salary_range_value}
        </div>} */}
      <div className={style.content}>
        {_tagsData
          .map((item, index) => {
            const value = props[item.field]
            if (!value) return null
            return (
              <span className={style.mobile_tag} key={index}>
                {item.name}
              </span>
            )
          })
          .slice(0, 3)}
        {!isMobile && <JobsTag {...props} />}
      </div>
      <div className={style.footer}>
        <div className={style.chat_footer}>
          <div className={style.avatar}>
            <Image fill src={props.recruiter_avatar} alt='img' />
            <div
              className={style.status}
              style={{ backgroundColor: props.recruiter_is_online ? '#0ebd5c' : '#E5E6EB' }}
            />
          </div>
          <Link className={style.name} href={'/' + lang + props.job_url + '?source=company_detail'} target='_blank'>
            <span title={props.recruiter_full_name}>{props.recruiter_full_name}</span>
            {props.recruiter_job_title && (
              <>
                &nbsp;<div style={{ position: 'relative', top: -2 }}>.</div>&nbsp;
                <span title={props.recruiter_job_title}>{props.recruiter_job_title}</span>
              </>
            )}
          </Link>
        </div>
        <div className={style.location} title={getLocation(props, overview)}>
          {getLocation(props, overview)}
        </div>
      </div>
    </div>
  )
}

interface TagProps extends JobData {
  count?: number
  classNames?: any
  type?: 'background'
  style?: React.CSSProperties
}

export const tagsData = [
  { name: '', field: 'xp_lvl_id' },
  { name: '', field: 'degree_id' },
  { name: '', field: 'job_type_id' }
]
export const JobsTag = (props: TagProps) => {
  const { config } = useCompanyDetail()
  const degree_list = config?.degrees || []
  const xp_lvl_list = config?.xp_lvls || []
  const job_type_list = config?.job_types || []
  const _tagsData = [...tagsData]
  _tagsData[0].name = xp_lvl_list.find((item) => item.id === props.xp_lvl_id)?.value || ''
  _tagsData[1].name = degree_list.find((item) => item.id === props.degree_id)?.value || ''
  _tagsData[2].name = job_type_list.find((item) => item.id === props.job_type_id)?.value || ''
  return (
    <div className={style.tags} style={props.style ? props.style : null}>
      {_tagsData
        .map((item, index) => {
          const value = props[item.field]
          if (!value || !item.name) return null
          return (
            <div className={style.tag_item + ' ' + ' tag_flag'} key={index}>
              {item.name}
            </div>
          )
        })
        .slice(0, props.count ?? 3)}
    </div>
  )
}

export default SearchPanel
