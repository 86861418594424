'user client'
import { memo, useContext } from 'react'
import Link from 'components/Link'
// import Avatar from '@mui/material/Avatar'
import styles from 'app/index.module.scss'
// import Image from 'next/image'
import { languageContext } from 'app/components/providers/languageProvider'
import { getCookie, setCookie } from 'helpers/cookies'
import { chatSVG } from 'images/svg'
import ShowSalary from 'app/components/ShowSalary'
import { setJobViewCookiesTracker, viewJobSourceTracker } from 'components/ReportTracker/util'

import { addEventTag } from 'helpers/utilities'

const JobDetail = ({ detail, dictionary, langKey, tabValue, prefJobTitle }: any) => {
  const isLogin = getCookie('sessionid') ? true : false
  const { home } = useContext(languageContext) as any
  const { jobLocation, financingStage, xpLvlValue, degreeValue, industry } = detail
  const handleAddJobView = () => {
    setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.home,
      reco_from: detail?.reco_from ? detail?.reco_from : null,
      query_id: detail?.query_id ? detail?.query_id : null
    })

    if (isLogin) {
      addEventTag("hp_job_for_you_card_click")
    }

    if (!isLogin) {
      addEventTag("hp_popular_jobs_card_click")
    }
  }
  return (
    <div className={styles.job_detail}>
      <div className={styles.job_info} onClick={() => handleAddJobView()}>
        <Link to={'/' + langKey + detail.job_url}>
          <div className={styles.job_titleWrapper}>
            <div className={styles.job_info_title}>{detail?.job_title}</div>
          </div>
          <div className={styles.transBox}>
            <div className={styles.job_info_salary}>
              <ShowSalary jobs={detail} dictionary={dictionary} size={16} />
            </div>
            <div className={styles.job_info_chat}>
              {/* <Image
                  src={HomePageChat}
                  alt='Boss job chat now'
                  width='18'
                  height='18'
                  quality={0}
                  style={{ paddingRight: '4px' }}
                />{' '} */}
              {chatSVG}
              {home.jobCard.chatNow}
            </div>
          </div>
          <div className={styles.job_tags}>
            {jobLocation && xpLvlValue && <div>
              {jobLocation} <span>|</span>
            </div>}
            <div>
              {xpLvlValue}
              {(jobLocation || xpLvlValue) && degreeValue && <span>|</span>}
            </div>
            <div>{degreeValue}</div>
          </div>
        </Link>
      </div>

      <Link className={styles.job_companyInfo} to={'/' + langKey + detail.company_url}>
        <div className={styles.job_avatarWrapper}>
          <div className={styles.job_box}>
            <img
              alt={detail?.job_title}
              src={detail?.company_logo}
              width={24}
              height={24}
            // quality={0}
            ></img>
            <div className={styles.job_companyInfo_name} title={detail?.company_name}>{detail?.company_name}</div>
          </div>
          <div
            className={styles.job_companyInfo_industry}
            title={[industry, financingStage].join(' | ')}
          >
            <i>{industry}</i>
            {industry && financingStage ? <span>|</span> : null}
            <i>{financingStage}</i>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default memo(JobDetail)
