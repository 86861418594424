'use client'

import { useContext, useMemo } from 'react'

import styles from '../../../page.module.scss'
import { useSharedData } from 'bossjob-remote/dist/hooks'

const Remote = (props: any) => {
  const { languageRemoteDetail, isMobile,
    remoteTimezones,
    remoteJoblanguages: languages,
    countries,
    remoteLanguages,
    remote_countries,
    is_remote_worldwide,
    languages: dic
  } = props

  const show = countries?.length || languages?.length || remoteTimezones?.length
  const { world_region_lists } = useSharedData('CONFIG') || {}
  // const dic = useSharedData("DICTIONARY") || {}
  const { companyDetail } = dic
  const [country, countryMap, stateToCountryMap] = useMemo(() => {
    const covertCountry2Opt = (continent = []) => {
      let countryMap: Record<string, any> = {}
      let stateToCountryMap: Record<string, any[]> = {}
      const all = continent
        .map((v: any) => {
          let children: { label: any, value: any }[] = []
          if (v.countries) {
            let childIds: any[] = []

            children = v.countries.map(item => {
              countryMap[item.id] = v.id
              childIds.push(item.id)

              return { value: item.id, label: item.value || item.display_name }
            })

            stateToCountryMap[v.id] = childIds
          }

          return { value: v.id, label: v.value || v.display_name, children }
        })
      return [all, countryMap, stateToCountryMap] as const
    }
    const country = world_region_lists
    return [...covertCountry2Opt(country)]
  }, [world_region_lists])

  const remoteValue = useMemo(() => {
    let remote_country_ids = remote_countries.map(v => v.id)
    let newValue = {}

    const addToNewValue = (key, value) => {
      let arr = newValue[key] || [];
      arr.push(value)

      newValue[key] = arr
    }

    let displayState = []
    let displayCountry = []

    if (!remote_country_ids?.length || !Object.keys(countryMap).length || !world_region_lists?.length) {
      return null
    }

    remote_country_ids.forEach(v => {
      addToNewValue(countryMap[v], v)
    })

    Object.keys(newValue).forEach(key => {
      const state = world_region_lists.find(v => Number(v.id) === Number(key));
      if (!state) {
        console.error(`world_region_lists has no this state, the state id is ${key}`)
        return
      }
      if (newValue[key].length === stateToCountryMap?.[key]?.length) {
        // all countries in this state have been found, so should display the state on page
        displayState.push(state)
      } else {
        let selectedCountries = newValue[key];
        (state.countries || []).forEach(item => {
          if (selectedCountries.includes(item.id)) {
            displayCountry.push(item)
          }
        });

      }
    })

    return { displayState, displayCountry }
  }, [remote_countries, countryMap, stateToCountryMap, world_region_lists])

  const isWorldwide = is_remote_worldwide
  const countryStr = isWorldwide ? companyDetail?.overview?.worldwide : [...(remoteValue?.displayState || []).map(v => {
    return `${v.value}`
  }), ...(remoteValue?.displayCountry || []).map(v => {
    return `${v.value} `
  })].join(', ')

  return (
    <>
      {show ? (
        <>
          <section className={styles.remote}>
            <div className={styles.remote_container}>
              <h5>{languageRemoteDetail?.remoteDetails}</h5>
              <ul className={styles.remote_container_list}>
                {countryStr ? <>
                  <li>
                    {languageRemoteDetail?.openCountry}:{' '}
                    {
                      <span>
                        <i></i>
                        {countryStr}
                      </span>
                    }
                  </li>
                </>
                  : null
                }
                {
                  languages.length ? (
                    <li>
                      {languageRemoteDetail?.languageRequirements}:{' '}
                      <span>
                        <i></i>
                        {languages}{' '}
                      </span>
                    </li>
                  ) : null
                }
              </ul>
            </div>
          </section>
          {isMobile && <div className={styles.page_line}></div>}
        </>
      ) : null}
    </>
  )
}

export default Remote
