/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef, useCallback } from 'react'
// import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { Cascader, Popover } from 'antd'

// import { useSharedData } from 'bossjob-remote/dist/hooks'

import styles from './index.module.scss'

const arrowIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1em',
  height: '1em'
}

const ArrowIcon = (props) => {
  return (
    <div {...props} style={arrowIconStyle}>
      <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
        <path
          d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
          fill='#86909C'
        />
      </svg>
    </div>
  )
}

const mapCountryIdToContinent = (data) => {
  let map = {};
  data = data || [];

  data.forEach(continent => {
    let { id: cid, value, countries } = continent;
    countries.forEach(country => {
      const { id } = country
      map[id] = cid
    });
  });
  return map
}


export const CountrySelectorForPC = ({
  label,
  className,
  value,
  world_region_lists,
  onChange,
  text
}: any) => {
  const data = world_region_lists

  const [innerValue, setInnerValue] = useState([])
  const ref = useRef({
    initValue: {},
    timer: undefined
  })
  // const langDic = useSharedData('DICTIONARY') || {}

  const safeCallOnChange = useCallback((value) => {
    clearTimeout(ref.current.timer)
    ref.current.timer = setTimeout(() => {
      onChange?.(value)
    }, 700);
    // some bugs here
  }, [onChange])

  useEffect(() => {
    if (value === undefined || !data.length) {
      return
    }
    const countryContinentMap = mapCountryIdToContinent(data);
    let newValue = [];
    if (value) {
      (Array.isArray(value) ? value : [value]).forEach(id => {
        id = Number(id)
        let cid = countryContinentMap[id]
        newValue.push([cid, id])
      });
    }
    setInnerValue(newValue)
    ref.current.initValue = newValue;
  }, [value, data])


  return (
    <>
      <FormControl fullWidth className={className} size='small' style={{ width: 190 }}>
        <Popover
          arrow={false}
          placement={'bottomLeft'}
          rootClassName={styles.countryCascader}
          content={<Cascader.Panel
            multiple
            expandTrigger='hover'
            options={world_region_lists}
            style={{ maxHeight: 400 }}
            value={innerValue}
            fieldNames={{
              label: 'value',
              value: 'id',
              children: 'countries'
            }}
            onChange={(val) => {
              let outValue = []
              val.forEach(v => {
                if (v[1]) {
                  outValue.push(v[1])
                } else {
                  let child = world_region_lists.find(item => item.id === v[0])?.countries || [];
                  child.forEach(item => {
                    outValue.push(item.id)
                  });
                }
              })
              setInnerValue(val)

              safeCallOnChange(outValue)
            }}
          />}
        >
          <div style={{
            fontSize: 14, 'color': '#4E5969',
            display: 'flex', alignItems: 'center',
            justifyContent: 'space-between',
            textTransform: 'capitalize',
            cursor: 'pointer',
            height: '100%',
            padding: '0 20px',
            fontFamily: "auto !important"
          }}>
            {`${text}${value?.length ? `(${value?.length || 0})` : ''} `}
            <ArrowIcon />
          </div>
        </Popover>
      </FormControl>
    </>
  )
}
