import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import SimpleSteps from '../Step/Simple';
import { ReactComponent as ResumeIconH5 } from '../../images/svg/resume_h5.svg';
import { ReactComponent as ResumeSuccessIcon } from '../../images/svg/resume_success.svg';
import SafeInfo from '../Common/SafeInfo';
import { jumpPage } from '../utils';

const CompleteProfileH5 = (props: {
  lang: any
}) => {
  const { lang } = props;
  const profile = lang.profile || {}
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    if (countdown === 0) {
      jumpPage && jumpPage()
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [countdown]);

  const handleBack = () => {
    jumpPage && jumpPage()
  }

  return (
    <>
      <CompleteProfileH5Wrap>
        <div className='resumeHeader'>
          <ResumeIconH5 className="resumeIcon" />
          <SimpleSteps currentStep={2} totalSteps={2} />
        </div>
        <h1>{profile.attachedResume?.title}</h1>
        <div className="successMessage">
          <ResumeSuccessIcon />
          <p className="successText">{profile.completeProfile?.info}</p>
        </div>
        <div className='backBtn' onClick={handleBack}>
          <p className="countdownText">{profile.completeProfile?.button} ({countdown})</p>
        </div>
      </CompleteProfileH5Wrap>
      <SafeInfo lang={lang} />
    </>
  );
};

const CompleteProfileH5Wrap = styled.div`
  display: flex;
  min-height: 600px;
  padding: 32px 20px;
  flex-direction: column;
  align-items: start;
  background: #FFFFFF;

  .resumeHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;
    align-items: center;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
  }

  p.subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div.successMessage {
    display: flex;
    /* height: 225px; */
    margin-top: 40px;
    margin-bottom: 56px;
    padding: 36px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 4px;
    background: #FCFCFC;

    p.successText {
      color: #121212;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.135px;
    }
  }

  p.countdownText {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  .backBtn {
    display: flex;
    height: 44px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 30px;
    background: #2378E5;
    cursor: pointer;
  }

  svg.resumeIcon {
    width: 32px;
    height: 32px;
    text-align: left;
  }
`;

export default CompleteProfileH5;