import { getCountryId } from "helpers/country"
import { useEffect, useState } from "react"
import { getSchool } from "store/services/users/school"

export const useSchool = () => {
  const [school, setSchool] = useState([])
  const country_id = getCountryId()
  useEffect(() => {
    getSchool({ country_id }).then(res => {
      setSchool(res?.data?.data || [])
    })
  }, [])

  return school
}