'use client'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import styles from '../index.module.scss'
import FootBtn from './footBtn'
import JobFunctionSelector from 'components/JobFunctionSelector'
import { Controller, useForm } from 'react-hook-form'
import { getCountryKey } from 'helpers/country'
import { getCountryId } from 'helpers/country'
import MaterialBasicSelect from 'components/MaterialBasicSelect'
import { usePathname } from 'next/navigation'
import {
  assistTrigger,
  createUserPreferencesService,
  updateUserPreferencesService
} from 'store/services/users/addUserPreferences'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import {
  getSalaryOptions,
  getCountryList,
  getCurrencyList,
  initSalaryTypeLists
} from 'helpers/jobPayloadFormatter'
import { getValueById } from 'helpers/config/getValueById'
import { getLang, countryForCurrency } from 'helpers/country'
import { LinkContext } from 'app/components/providers/linkProvider'
import { generateUserResumeService } from 'store/services/users/generateUserResume'
import { handleUserCookiesConfig, setCookie } from 'helpers/cookies'
import { fetchJobsPreferences } from 'store/services/jobs/fetchJobsForYouLogin'
// import SiteGuideModal from 'components/SiteGuideModal'
import Cascader from 'components/Cascader'
import Toast from 'app/components/Toast'
import { addParams } from 'helpers/utilities'

const initType = {
  1: 'salary_ranges_lists',
  2: 'daily_salary_range_lists',
  3: 'hourly_salary_range_lists'
}

const isStudent = (me) => {
  const isStudying = me?.is_fresh_graduate

  return Boolean(isStudying)
}

const DesiredJob = (props: any) => {
  const { lang, userDetail, config } = props
  const { resumes } = userDetail
  const pathname = usePathname()
  const { push } = useContext(LinkContext)
  // const preference = userDetail?.job_preferences?.[0]
  const [currencyLists] = useMemo(() => {
    return [getCurrencyList(config), getCountryList(config)]
  }, [config])
  const salaryTypeLists = useMemo(() => {
    return initSalaryTypeLists(config?.salary_type_lists.filter(item => item.id !== 4) || [])
  }, [config])
  const country = getCountryKey()
  const isStudying = isStudent(userDetail)

  const { handleSubmit, setValue, getValues, control, watch } = useForm({
    defaultValues: {
      minSalary: '',
      maxSalary: '',
      location: '',
      currency: countryForCurrency(country),
      arrangement: '',
      timezone: '',
      language: [],
      payType: 1,
      jobType: isStudying ? "5" : '1'
    }
  })
  const watchCurrency = watch('currency')
  // const [isShowCountry, setIsShowCountry] = useState(userDetail?.location === 'Overseas')
  const [minSalary, setMinSalary] = useState(getValues().minSalary)
  const [maxSalary, setMaxSalary] = useState(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [locationData, setLocationData] = useState(null)
  const [maxSalaryOptions, setMaxSalaryOptions] = useState([])
  const [jobFunction, setJobFunction] = useState({ id: undefined, value: '' })
  const [arrangement, setArrangement] = useState('')
  const [timezone, setTimezone] = useState('')
  const [language, setLanguage] = useState('')
  const langKey = getLang()
  const [preference, setPreference] = useState(null)
  const [payType, setPayType] = useState(1)

  const getMaxSalaryOptions = (minSalary) => {
    const maxSalaryOptions = getSalaryOptions(
      config[initType[payType]].find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === getValues('currency')).id
      ),
      Number(minSalary),
      true
    )

    const maxSalaryOrg = maxSalaryOptions?.length > 0 ? maxSalaryOptions[0].value : null
    setValue('maxSalary', maxSalaryOrg)
    setMaxSalary(maxSalaryOrg)
    setMaxSalaryOptions(maxSalaryOptions)
  }
  // const locationList = config.location_lists
  // const formattedLocationList = flatMap(locationList, (l) => l.locations)

  // const location = useMemo(() => {
  //   if (preference?.location_id) {
  //     return formattedLocationList.find((l) => l.id === preference?.location_id)
  //   }
  // }, [formattedLocationList, preference?.id])

  const minSalaryOptions = useMemo(
    () =>
      getSalaryOptions(
        config[initType[payType]].find(
          (el) => el.currency_id === currencyLists.find((el) => el.key === getValues('currency')).id
        )
      ),
    [
      JSON.stringify(currencyLists),
      JSON.stringify(config.salary_ranges_lists),
      watchCurrency,
      payType
    ]
  )

  const formatData = (data, label, value) => {
    return data?.map((item) => ({
      label: item[label],
      value: item[value]
    }))
  }

  const arrangementData = formatData(config.work_arrangement_lists, 'name', 'id')
  const timezoneData = formatData(config.timezone_lists, 'name', 'id')
  const languageData = formatData(config.job_languages, 'name', 'id')
  const jobType = formatData(config.job_types, 'value', 'id')
  // console.log({ jobType })
  const errorCode = lang.errorcode || {}

  useEffect(() => {
    fetchJobsPreferences().then((res) => {
      if (res?.data?.data && res?.data?.data?.length) {
        setPreference(res.data.data[0])
      }
    })
  }, [])

  useEffect(() => {
    if (preference?.id) {
      const {
        salary_range_from,
        salary_range_to,
        location_id,
        work_arrangement,
        timezones,
        languages,
        currency_key,
        salary_type,
        daily_salary_range_from,
        daily_salary_range_to,
        hourly_salary_range_from,
        hourly_salary_range_to,
        pref_country_id,
        pref_region_id,
        job_type_id
      } = preference
      const { id } = salary_type
      const from =
        id === 1 ? salary_range_from : id == 2 ? daily_salary_range_from : hourly_salary_range_from
      const to =
        id === 1 ? salary_range_to : id == 2 ? daily_salary_range_to : hourly_salary_range_to
      //  const salaryFrom = Number(salary_range_from)
      setMinSalary(from)
      setMaxSalary(to)
      // setMaxSalaryOptions(maxSalaryOptions)
      setValue('minSalary', String(Number(from)))
      setValue('maxSalary', String(Number(to)))
      setValue('jobType', job_type_id ? String(Number(job_type_id)) : "")
      setValue('location', pref_country_id || pref_region_id || location_id)
      setValue('arrangement', String(work_arrangement?.id))
      setValue('timezone', timezones?.map((el) => el.id)[0])
      setValue(
        'language',
        languages?.map((el) => el.id)
      )
      if (work_arrangement?.id === 3) {
        setValue('currency', currency_key)
      }

      setJobFunction({
        id: preference?.function_job_title_id,
        value:
          getValueById(config, preference?.function_job_title_id, 'function_job_title_id') ?? ''
      })

      setLocationData({
        id: location_id
      })
      setArrangement(String(work_arrangement?.id))
      setTimezone(timezones?.map((el) => el.id)[0])
      setLanguage(languages?.map((el) => el.id).join(','))
      // setMinSalary(salary_range_from)
      setPayType(preference?.salary_type?.id)
      setValue('payType', preference?.salary_type?.id)
    }
  }, [preference?.id])

  useEffect(() => {
    if (minSalary) {
      getMaxSalaryOptions(minSalary)
    }
  }, [minSalary])

  useEffect(() => {
    if (Number(arrangement) < 3) {
      if (jobFunction?.id && maxSalary && locationData) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      if (jobFunction?.id && maxSalary
        // && timezone 
        && language) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }, [jobFunction?.id, maxSalary, JSON.stringify(locationData), arrangement,
    //  timezone,
    language])
  const getSalaryRangeKey = (payType) => {
    switch (payType) {
      case 1:
        return 'salary_range'
      case 2:
        return 'daily_salary_range'
      case 3:
        return 'hourly_salary_range'
      default:
        return ''
    }
  }

  const handleUpdateProfile = async (data) => {
    const { currency, location, maxSalary, minSalary, payType, jobType } = data || {}

    let params = {
      job_title: jobFunction.value || '',
      function_job_title_id: jobFunction.id,
      // function_job_title: jobFunction.value,
      currency_key: currency,
      country_id: Number(arrangement) < 3 ? getCountryId() : undefined,
      // location_id: Number(arrangement) < 3 ? location?.id : undefined,
      work_arrangement_id: arrangement || undefined,
      timezone_ids: Number(arrangement) === 3 ? timezone : undefined,
      language_ids: Number(arrangement) === 3 ? language : undefined,
      salary_type_id: payType,
      job_type_id: jobType
    }

    params = addParams(params, location)

    const salaryRangeKey = getSalaryRangeKey(payType)
    if (salaryRangeKey) {
      params[`${salaryRangeKey}_from`] = Number(minSalary)
      params[`${salaryRangeKey}_to`] = Number(maxSalary)
    }

    setLoading(true)
    if (!resumes) {
      await generateUserResumeService()
    }

    if (preference?.id) {
      updateUserPreferencesService({
        preferenceId: preference?.id,
        params
      })
        .then((res) => {
          if (res?.data) {
            jumPage()
          }
        })
        .catch(([, originError]) => {
          Toast.error(errorCode[originError?.data?.code])
        })
        .finally(() => setLoading(false))
    } else {
      createUserPreferencesService({ params })
        .then((res) => {
          if (res.data) {
            jumPage()
          }
        })
        .catch(([, originError]) => {
          // Toast.error(originError.data.message)
          Toast.error(errorCode[originError?.data?.code])
        })
        .finally(() => setLoading(false))
    }
  }


  const jumPage = () => {
    // getUserInfo?.()
    fetchUserOwnDetailService().then((res) => {
      return assistTrigger().then(() => {
        const userDetail = res?.data?.data
        const userCookie = handleUserCookiesConfig(userDetail)
        setCookie('user', userCookie)
        const isChatRedirect = sessionStorage.getItem('isChatRedirect')
        const redirectPage = sessionStorage.getItem('redirectPage')
        if (isChatRedirect) {
          sessionStorage.removeItem('isChatRedirect')
          push(isChatRedirect)
        } else if (redirectPage) {
          sessionStorage.removeItem('redirectPage')
          push(redirectPage)
        } else {
          push(`/${langKey}/my-jobs`)
        }
      })
    })
  }

  const {
    desiredJob,
    desiredJobTitle,
    // currentLocation,
    desiredLocation,
    thisFieldIsRequired,
    submit,
    back,
    desiredSalary,
    desiredSalaryCurrency,
    workArrangement,
    workTimezone,
    workLanguage,
    skip
  } = lang?.profile || {}

  const { comm } = lang || {}

  const backClick = () => {
    push(`${pathname}?step=3`)
  }

  return (
    <div className={styles.work}>
      <div className={styles.workContainer}>
        <div className={styles.box}>
          <div className={styles.headerInfo}>{desiredJob}</div>
          <div className={styles.body}>
            <p className={styles.title}>
              <span>*</span>
              {desiredJobTitle}
            </p>
            <div id='jobFunctionDesign' className={styles.stepFieldItem}>
              <JobFunctionSelector
                // label={lang?.profile?.jobFunction}
                placeholder={lang?.profile?.jobFunction}
                title={lang?.profile?.jobFunction}
                lang={lang}
                name='jobFunction'
                variant='standard'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                isTouched
                fullWidth
                value={jobFunction}
                onChange={(value) => setJobFunction(value)}
              />
            </div>

            {
              <>
                <p className={styles.title}>
                  <span>*</span>
                  {/* job type */}
                  {comm?.jobType}
                </p>
                <div className={styles.stepFieldItem}>
                  <Controller
                    control={control}
                    name='jobType'
                    rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { value, onChange } = field

                      return (
                        <MaterialBasicSelect
                          options={jobType}
                          fieldRef={{
                            variant: 'standard'
                          }}
                          {...fieldState}
                          {...field}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                          }}
                          inputFontSize='16px'
                          ref={undefined}
                        />
                      )
                    }}
                  />
                </div>
              </>
            }

            <p className={styles.title}>
              <span>*</span>
              {workArrangement}
            </p>
            <div className={styles.stepFieldItem}>
              <Controller
                control={control}
                name='arrangement'
                rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field
                  return (
                    <MaterialBasicSelect
                      // label="james"
                      // required
                      options={arrangementData}
                      fieldRef={{
                        variant: 'standard'
                      }}
                      {...fieldState}
                      {...field}
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        setArrangement(e.target.value)
                        setMaxSalary('')
                        setMinSalary('')
                        setValue('maxSalary', '')
                        setValue('minSalary', '')
                        if (e.target.value === 3) {
                          setValue('currency', countryForCurrency('us'))
                        } else {
                          setValue('currency', countryForCurrency(country))
                        }
                      }}
                      inputFontSize='16px'
                      ref={undefined}
                    />
                  )
                }}
              />
            </div>

            {arrangement && Number(arrangement) === 3 ? (
              <>
                {/* <p className={styles.title}>
                  <span>*</span>
                  {workTimezone}
                </p>
                <div className={styles.stepFieldItem}>
                  <Controller
                    control={control}
                    name='timezone'
                    rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { value, onChange } = field
                      return (
                        <MaterialBasicSelect
                          // label={lang?.profile?.minSalary}
                          options={timezoneData}
                          fieldRef={{
                            variant: 'standard'
                          }}
                          {...fieldState}
                          {...field}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setTimezone(e.target.value)
                          }}
                          inputFontSize='16px'
                          ref={undefined}
                        />
                      )
                    }}
                  />
                </div> */}

                <p className={styles.title}>
                  <span>*</span>
                  {workLanguage}
                </p>
                <div className={styles.stepFieldItem}>
                  <Controller
                    control={control}
                    name='language'
                    rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { value, onChange } = field
                      return (
                        <MaterialBasicSelect
                          // label={lang?.profile?.minSalary}
                          multiple
                          options={languageData}
                          checkRequired={true}
                          fieldRef={{
                            variant: 'standard'
                          }}
                          {...fieldState}
                          {...field}
                          onChange={(e) => {
                            onChange(e)
                            setLanguage(e.target.value.join(','))
                          }}
                          inputFontSize='16px'
                          multipleValue={value}
                          renderValue={(selected) =>
                            selected
                              .map((id) => ({
                                id,
                                label: languageData.find((item) => item.value === id)?.label
                              }))
                              .map((item) => item.label)
                              .join(', ')
                          }
                          ref={undefined}
                        />
                      )
                    }}
                  />
                </div>
              </>
            ) : null}

            {arrangement && Number(arrangement) < 3 ? (
              <>
                <p className={styles.title}>
                  <span>*</span>
                  {desiredLocation}
                </p>
                <div className={styles.stepFieldItem}>
                  <Controller
                    control={control}
                    name={'location'}
                    rules={{ required: thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { onChange } = field
                      return (
                        <Cascader
                          label={desiredLocation}
                          required={true}
                          preference={preference}
                          onChange={(location) => {
                            setLocationData(location)
                            onChange(location)
                          }}
                          variant='borderLess'
                        />
                        // <MaterialLocationField
                        //   className={styles.stepFullwidth}
                        //   // label={currentLocation}
                        //   required
                        //   disableClearable={true}
                        //   fieldRef={{
                        //     variant: 'standard',
                        //     placeholder: desiredLocation
                        //   }}
                        //   {...fieldState}
                        //   {...field}
                        //   onChange={(_, location) => {
                        //     // setIsShowCountry(location?.key === 'overseas')
                        //     setLocationData(location)
                        //     onChange(location)
                        //   }}
                        // />
                      )
                    }}
                  />
                </div>
              </>
            ) : null}
            {/* {isShowCountry && (
              <div className={styles.stepFieldToItem}>
                <Controller
                  control={control}
                  name={'country'}
                  rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                  render={({ field, fieldState }) => {
                    return (
                      <MaterialBasicSelect
                        className={styles.stepFullwidth}
                        label={countryText}
                        options={countryList}
                        useID={true}
                        required
                        {...fieldState}
                        {...field}
                      />
                    )
                  }}
                />
              </div>
            )} */}

            <p className={styles.title}>
              <span>*</span>
              {desiredSalary}
            </p>
            <div className={styles.step1Salary}>
              <div className={styles.step1SalaryRanges}>
                <div className={styles.currency} style={{ marginRight: '20px' }}>
                  <Controller
                    control={control}
                    name='payType'
                    rules={{ required: thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { onChange, value } = field
                      return (
                        <MaterialBasicSelect
                          label={lang?.salaryType?.payType}
                          options={salaryTypeLists}
                          variant='standard'
                          {...fieldState}
                          {...field}
                          ref={undefined}
                          value={value}
                          onChange={(e) => {
                            setMaxSalary('')
                            setMinSalary('')
                            setValue('maxSalary', '')
                            setValue('minSalary', '')
                            setPayType(e.target.value)
                            onChange(e)
                          }}
                        />
                      )
                    }}
                  />
                </div>
                <div className={styles.currency}>
                  <Controller
                    control={control}
                    name='currency'
                    rules={{ required: thisFieldIsRequired }}
                    render={({ field, fieldState }) => {
                      const { onChange, value } = field
                      return (
                        <MaterialBasicSelect
                          label={desiredSalaryCurrency}
                          options={currencyLists}
                          disabled={Number(arrangement) < 3}
                          variant='standard'
                          {...fieldState}
                          {...field}
                          ref={undefined}
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setMaxSalary('')
                            setMinSalary('')
                            setValue('maxSalary', '')
                            setValue('minSalary', '')
                          }}
                        />
                      )
                    }}
                  />
                </div>
                <div className={styles.salaryBox}>
                  <div className={styles.minSalary}>
                    <Controller
                      control={control}
                      name={'minSalary'}
                      rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                      render={({ field, fieldState }) => {
                        const { value, onChange } = field
                        return (
                          <MaterialBasicSelect
                            label={lang?.profile?.minSalary}
                            options={minSalaryOptions}
                            required
                            fieldRef={{
                              variant: 'standard'
                            }}
                            {...fieldState}
                            {...field}
                            value={value}
                            onChange={(e) => {
                              onChange(e)

                              setMinSalary(e.target.value)
                            }}
                            ref={undefined}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={styles.minSalary}>
                    <Controller
                      control={control}
                      name={'maxSalary'}
                      rules={{ validate: (value) => !!value || thisFieldIsRequired }}
                      render={({ field, fieldState }) => {
                        const { value, onChange } = field
                        return (
                          <MaterialBasicSelect
                            label={lang?.profile?.maxSalary}
                            rules={{ required: thisFieldIsRequired }}
                            required
                            options={maxSalaryOptions}
                            variant='standard'
                            {...fieldState}
                            {...field}
                            onChange={(e) => {
                              onChange(e)
                              setMaxSalary(e.target.value)
                            }}
                            value={value}
                            ref={undefined}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FootBtn
          loading={loading}
          rightText={submit}
          backText={back}
          backClick={backClick}
          disabled={isDisabled}
          skipText={skip}
          handleClick={handleSubmit(handleUpdateProfile)}
        />
      </div>
    </div>
  )
}

export default DesiredJob
