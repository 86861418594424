import React from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import { DotButton, useDotButton } from './EmblaCarouselDotButton'
import useEmblaCarousel from 'embla-carousel-react'

import styles from './index.module.scss'

type PropType = {
  data: any[],
  renderItem: (data, index) => React.ReactNode
}

export const EmblaCarouselForMobile: React.FC<PropType> = (props) => {
  const { data, renderItem } = props
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  return (
    <section className={styles["embla"]}>
      <div className={styles["embla__viewport"]} ref={emblaRef}>
        <div className={styles["embla__container"]}>
          {data.map((v, index) => (
            <div className={styles["embla__slide"]} key={index}>
              {renderItem(v, index)}
            </div>
          ))}
        </div>
      </div>

      <div className={styles["embla__controls"]}>
        <div className={styles["embla__dots"]}>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={styles['embla__dot'].concat(
                index === selectedIndex ? ` ${styles['embla__dot--selected']}` : ''
              )}
            />
          ))}
        </div>
      </div>
    </section>
  )
}


