import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs'

export const defaultEduFormData = {
  degree: null,
  schoolName: null,
  fieldOfStudy: null,
  startDate: null,
  endDate: null,
  description: null,
  isToday: false
}

export const defaultWorkFormData = {
  noExp: false,
  companyName: null,
  startDate: null,
  endDate: null,
  isToday: false,
  jobTitle: { id: undefined, value: '' },
  hideCompany: false,
  description: null,
}

type defaultEduFormDataType = typeof defaultEduFormData
type defaultWorkFormDataType = typeof defaultWorkFormData

interface AttachedResumeContextType {
  step: number;
  eduData: defaultEduFormDataType;
  workData: defaultWorkFormDataType;
  userDetail: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setEduData: React.Dispatch<React.SetStateAction<defaultEduFormDataType>>;
  setWorkData: React.Dispatch<React.SetStateAction<defaultWorkFormDataType>>;
  setUserDetail: React.Dispatch<React.SetStateAction<any>>;
  initData: (userDetail: any) => void
}

export const AttachedResumeContext = createContext<AttachedResumeContextType | undefined>(undefined);

const Provider = AttachedResumeContext.Provider;

interface AttachedResumeProviderProps {
  children: ReactNode;
}

function initWorkData(userDetail) {
  const {
    work_experiences = [],
    is_fresh_graduate,
    is_work_experiences
  } = userDetail

  const work_experience = work_experiences[0] || {}

  if (work_experience?.id) {
    const {
      company,
      is_currently_work_here,
      working_period_from,
      working_period_to,
      description,
      function_job_title_id,
      function_job_title,
    } = work_experience

    return {
      noExp: !is_work_experiences ? false : is_fresh_graduate,
      companyName: company,
      hideCompany: !company,
      startDate: working_period_from ? dayjs(working_period_from) : null,
      endDate: working_period_to ? dayjs(working_period_to) : null,
      description,
      isToday: is_currently_work_here,
      jobTitle: { id: function_job_title_id, value: function_job_title }
    }
  } else {
    return {
      ...defaultWorkFormData,
      noExp: !is_work_experiences ? false : is_fresh_graduate
    }
  }
}

function initEduData(userDetail) {
  const {
    educations = [],
  } = userDetail

  const education = educations[0] || {}
  if (education?.id) {
    const {
      school,
      degree_id,
      field_of_study,
      study_period_from,
      study_period_to,
      is_currently_studying,
      description
    } = education

    return {
      description,
      schoolName: school,
      degree: degree_id,
      fieldOfStudy: field_of_study,
      startDate: study_period_from ? dayjs(study_period_from) : null,
      endDate: study_period_to ? dayjs(study_period_to) : null,
      isToday: is_currently_studying
    }
  } else {
    return {
      ...defaultEduFormData
    }
  }
}

export const AttachedResumeProvider: React.FC<AttachedResumeProviderProps> = ({ children }) => {
  const [step, setStep] = useState<number>(1);
  const [eduData, setEduData] = useState({ ...defaultEduFormData });
  const [workData, setWorkData] = useState({ ...defaultWorkFormData });
  const [userDetail, setUserDetail] = useState<any>({});

  function initData(userDetail) {
    if (!userDetail || !userDetail?.id) return
    setWorkData(initWorkData(userDetail))
    setEduData(initEduData(userDetail))
  }

  useEffect(() => {
    if (userDetail?.id) {
      const {
        is_educations,
        is_work_experiences
      } = userDetail

      if (!is_educations) {
        setStep(1)
      } else {
        !is_work_experiences && setStep(2)
      }
      initData(userDetail)
    }
  }, [userDetail])

  return (
    <Provider
      value={{
        step, eduData, workData, userDetail, initData,
        setStep, setEduData, setWorkData, setUserDetail
      }}
    >
      {children}
    </Provider>
  );
};

export const useAttachedResumeContext = (): AttachedResumeContextType => {
  const context = useContext(AttachedResumeContext);
  if (!context) {
    throw new Error('useAttachedResumeContext must be used within a AttachedResumeProvider');
  }
  return context;
};

export const withAttachedResumeProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  extraProps?: P,
) => {
  return function AttachedResumeProviderComponent(props: P) {
    return (
      <AttachedResumeProvider>
        <WrappedComponent {...props} {...extraProps} />
      </AttachedResumeProvider>
    );
  };
};