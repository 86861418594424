import { useEffect, useMemo, useState, useRef } from 'react'

/* Vendors */
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'

/* Components */
import Modal from 'components/Modal'
import MaterialBasicSelect from 'components/MaterialBasicSelect'
import MaterialLocationField from 'components/MaterialLocationField'
import { useFirstRender } from 'helpers/useFirstRender'
/* Helpers */
import {
  getCountryList,
  getJobTypeList,
  getSalaryOptions,
  getCurrencyList
} from 'helpers/jobPayloadFormatter'
import { flat } from 'helpers/formatter'
import { getCountryId, getCountryKey, countryForCurrency, getLang } from 'helpers/country'

import { updateUserPreferencesRequest } from 'store/actions/users/updateUserPreferences'
import { getValueById } from 'helpers/config/getValueById'
// import { getSalaryOptions, getCountryList, getCurrencyList } from 'helpers/jobPayloadFormatter'

/* Styles */
import styles from './EditJobPreferencesModal.module.scss'
import JobFunctionSelector from 'components/JobFunctionSelector'
import Toast from 'app/components/Toast'
import Cascader from 'components/Cascader'
import { addParams } from 'helpers/utilities'

type EditJobPreferencesModalProps = {
  modalName: string
  showModal: boolean
  config: any
  userDetail: any
  handleModal: Function
  preference?: any
  lang: Record<string, any>
  onSubmitCallback: () => void
}

const formatLocationConfig = (locationList) => {
  const locationConfig = locationList?.map((region) => region.locations)
  return locationConfig
}

const getIdByValue = (options: any[], value) => {
  const selectedOption = options.find((item) => item.value === value)
  return selectedOption?.id
}
const EditJobPreferencesModal = ({
  modalName,
  showModal,
  config,
  handleModal,
  preference,
  lang,
  onSubmitCallback
}: EditJobPreferencesModalProps) => {
  const {
    manageProfile: {
      tab: {
        preference: { editModal }
      }
    }
  } = lang
  const langKey = getLang()

  const locationList = useSelector((store: any) => store.config.config.response?.location_lists)
  // const currencyLists = useSelector((store: any) => (store.config.config.response?.currency_lists ?? []))
  const [currencyLists] = useMemo(() => {
    return [getCurrencyList(config), getCountryList(config)]
  }, [config])

  const arrangementList = useSelector((store: any) =>
    (store.config.config.response?.work_arrangement_lists ?? config?.work_arrangement_lists)?.map(
      (item) => ({
        label: item.name,
        value: item.id
      })
    )
  )
  const timezoneList = useSelector((store: any) =>
    (store.config.config.response?.timezone_lists ?? config?.timezone_lists)?.map((item) => ({
      label: item.name,
      value: item.id
    }))
  )
  const languageList = useSelector((store: any) =>
    (store.config.config.response?.job_languages ?? config?.job_languages)?.map((item) => ({
      label: item.name,
      value: item.id
    }))
  )
  // const salaryRangeList = useSelector(
  //   (store: any) => store.config.config.response?.salary_ranges_lists ?? config?.salary_ranges_lists
  // )

  const initConfig = useSelector((store: any) => store.config.config.response ?? config)
  const salaryRangeList = {
    1: initConfig?.salary_ranges_lists || [],
    2: initConfig?.daily_salary_range_lists || [],
    3: initConfig?.hourly_salary_range_lists || [],
    4: initConfig?.annually_salary_range_lists || []
  }
  const [initial, setInital] = useState(true)
  const defaultValues = {
    jobTitle: {
      id: preference?.function_job_title_id ?? '',
      value: preference?.function_job_title ?? ''
    },
    jobType: preference?.job_type_key,
    minSalary: preference?.salary_range_from ? Number(preference?.salary_range_from) : '',
    maxSalary: preference?.salary_range_to ? Number(preference?.salary_range_to) : '',
    location: preference?.pref_country_id || preference?.pref_region_id || preference?.location_id,

    industry: getValueById(config, preference?.industry_id, 'industry_id'),
    country: preference?.country_key,
    currencyKey: preference?.currency_key ?? countryForCurrency(getCountryKey()),
    arrangement: preference?.work_arrangement?.id ?? '',
    timezone: preference?.timezones?.[0]?.id ?? '',
    language: preference?.languages ?? [],
    salaryTypeId: 1
  }

  const dispatch = useDispatch()
  const [maxSalaryOptions, setMaxSalaryOptions] = useState([])
  const isUpdating = useSelector((store: any) => store.users.updateUserPreferences.fetching)
  const response = useSelector((store: any) => store.users.updateUserPreferences.response)
  const updateError = useSelector((store: any) => store.users.updateUserPreferences.error)

  const countryList = getCountryList(config)
  const [isShowCountry, setIsShowCountry] = useState(preference?.location_key === 'overseas')
  const jobTypeList = getJobTypeList(config)

  const { handleSubmit, getValues, setValue, control, reset, trigger, watch } = useForm({
    defaultValues
  })
  const [minSalary, setMinSalary] = useState(getValues().minSalary)
  const [maxSalary, setMaxSalary] = useState(getValues().maxSalary)
  const [arrangement, setArrangement] = useState('')
  const [industry, setIndustry] = useState('')
  const [timezone, setTimezone] = useState('')
  const [language, setLanguage] = useState('')
  const [jobFunction, setJobFunction] = useState({ id: undefined, value: '' })
  const watchCurrency = watch('currencyKey')
  const errorCode = lang.errorcode || {}
  // const [salaryTypeId, setSalaryTypeId] = useState(1)
  const salaryTypeId = watch('salaryTypeId')
  const jobType = watch('jobType')
  const [salaryTypeLists, setSalaryTypeLists] = useState([])
  const firstRender = useFirstRender()
  const minSalaryOptions = useMemo(
    () =>
      getSalaryOptions(
        salaryRangeList[salaryTypeId]?.find(
          (el) =>
            el.currency_id === currencyLists.find((el) => el.key === getValues('currencyKey'))?.id
        )
      ),
    [JSON.stringify(currencyLists), JSON.stringify(salaryRangeList), watchCurrency, salaryTypeId]
  )
  const getMaxSalaryOptions = (minSalary) => {
    const maxSalaryOptions = getSalaryOptions(
      salaryRangeList[salaryTypeId]?.find(
        (el) =>
          el?.currency_id === currencyLists.find((el) => el.key === getValues('currencyKey')).id
      ),
      Number(minSalary),
      true
    )
    const maxSalaryOrg =
      maxSalaryOptions?.length > 0
        ? maxSalary > maxSalaryOptions[0].value
          ? maxSalary
          : maxSalaryOptions[0].value
        : null
    setValue('maxSalary', maxSalaryOrg)
    setMaxSalary(maxSalaryOrg)
    setMaxSalaryOptions(maxSalaryOptions)
  }

  useEffect(() => {
    const data = initConfig.salary_type_lists
    if (data?.length > 0) {
      const arr = ['full_time', 'internship']

      if (arr.includes(jobType)) {
        setValue('salaryTypeId', 1)
        setSalaryTypeLists(data.filter((e) => e.id === 1))
      } else {
        setSalaryTypeLists(data)
      }
    }
  }, [jobType, initConfig])
  useEffect(() => {
    if (firstRender) return
    if (minSalary) {
      getMaxSalaryOptions(minSalary)
      trigger('maxSalary')
    }
  }, [minSalary])

  useEffect(() => {
    if (initial) {
      setInital(false)
    } else {
      handleCloseModal()
    }
  }, [response])
  const industryOptions = useMemo(() => {
    return (
      config?.industry_lists?.map((industry) => ({
        ...industry,
        label: industry.value,
        value: industry.value,
        id: industry.id
      })) ?? []
    )
  }, [config?.industry_lists])

  useEffect(() => {
    if (preference?.id) {
      const {
        salary_type,
        salary_range_from,
        salary_range_to,
        daily_salary_range_from,
        daily_salary_range_to,
        hourly_salary_range_from,
        hourly_salary_range_to
      } = preference
      const { id } = salary_type
      const from =
        id === 1 ? salary_range_from : id == 2 ? daily_salary_range_from : hourly_salary_range_from
      const to =
        id === 1 ? salary_range_to : id == 2 ? daily_salary_range_to : hourly_salary_range_to
      setValue('minSalary', Number(from) || '')
      setValue('maxSalary', Number(to) || '')
      setValue('arrangement', String(preference?.work_arrangement?.id) || '')
      setArrangement(String(preference?.work_arrangement?.id) || null)

      setMaxSalary(Number(to) || '')
      setMinSalary(Number(from) || '')

      setJobFunction({
        id: preference?.function_job_title_id,
        value:
          getValueById(config, preference?.function_job_title_id, 'function_job_title_id') ?? ''
      })
      setTimezone(preference?.timezone)
      setLanguage(preference?.languages?.map((el) => el.id).join(','))
      setValue(
        'language',
        preference?.languages?.map((el) => el.id)
      )
      setValue('salaryTypeId', preference?.salary_type?.id || '')
    }
  }, [
    preference?.id
    // preference?.salary_range_from,
    // preference?.work_arrangement?.id
    // maxSalaryOptions
  ])

  // useEffect(() => {
  //   if (Number(arrangement) === 3) {
  //     setValue('currencyKey', 'us')
  //   }
  // }, [arrangement])

  // useEffect(() => {
  //   minSalary && setMaxSalary(preference?.salary_range_to)
  // }, [minSalary, preference?.salary_range_to, maxSalaryOptions])
  const onSubmit = (data) => {
    // to add workSetting
    const {
      jobTitle,
      jobType,
      minSalary,
      maxSalary,
      location,
      // industry,
      currencyKey,
      salaryTypeId
    } = data // jobType is a key
    const industry_id = getIdByValue(industryOptions, industry)
    const job_type_id = getIdByValue(jobTypeList, jobType)
    const getSalaryRangeKey = (payType) => {
      switch (payType) {
        case 1:
          return 'salary_range'
        case 2:
          return 'daily_salary_range'
        case 3:
          return 'hourly_salary_range'
        default:
          return ''
      }
    }
    const payload = {
      preferences: {
        action: preference?.id ? 'update' : 'create',
        preferenceId: preference?.id,
        params: {
          job_title: jobTitle.value || '',
          function_job_title_id: jobTitle.id,
          function_job_title: jobTitle.value,
          job_type_key: jobType || '',
          job_type_id,
          //   location_key: Number(arrangement) < 3 ? location?.key : undefined,
          //  location_id: Number(arrangement) < 3 ? location?.id : undefined,
          //  salary_range_from: Number(minSalary),
          //  salary_range_to: Number(maxSalary),
          industry_key: industry,
          industry_id,
          currency_key: currencyKey,
          currency_id: currencyLists.find((item) => item.value === currencyKey)?.id ?? null,
          country_key: getCountryKey(),
          country_id: getCountryId(),
          work_arrangement_id: arrangement || undefined,
          timezone_ids: Number(arrangement) === 3 ? timezone : undefined,
          language_ids: Number(arrangement) === 3 ? language : undefined,
          salary_type_id: salaryTypeId
        }
      }
    }
    payload.preferences.params = addParams(payload.preferences.params, location)

    const salaryRangeKey = getSalaryRangeKey(salaryTypeId)
    if (salaryRangeKey) {
      payload.preferences.params[`${salaryRangeKey}_from`] = Number(minSalary)
      payload.preferences.params[`${salaryRangeKey}_to`] = Number(maxSalary)
    }
    dispatch(updateUserPreferencesRequest(payload))
    //   updateUserPreferencesService({
    //     preferenceId: preference?.id,
    //     params
    //   })
    //     .then((res) => {
    //       if (res.data) {
    //         jumPage()
    //       }
    //     })
    //     .finally(() => setLoading(false))
    // } else {
    //   createUserPreferencesService({ params })
    //     .then((res) => {
    //       if (res.data) {
    //         jumPage()
    //       }
    //     })
    //     .catch(err => {
    //       Toast.error(err.data.message)
    //     })
    //     .finally(() => setLoading(false))

    // setTimeout(() => handleCloseModal(true), 500)
  }
  const isUpdatingRef = useRef(isUpdating)

  useEffect(() => {
    if (!isUpdating && isUpdatingRef.current) {
      handleCloseModal(true)
      onSubmitCallback?.()
    }
    if (updateError && updateError?.data && isUpdatingRef.current) {
      Toast.error(errorCode[updateError?.data?.code])
    }

    isUpdatingRef.current = isUpdating
  }, [isUpdating, updateError])

  const handleCloseModal = (refresh = false) => {
    handleModal(modalName, false, refresh)
    reset()
  }

  const initData = (data = []) => {
    return data?.map((item) => ({
      label: item.value,
      value: item.id
    }))
  }

  const modalJobPreferenceContent = (
    <div className={styles.jobPreferences}>
      <div className={styles.jobPreferencesForm}>
        <div className={styles.jobPreferencesFormGroup}>
          <Controller
            control={control}
            name={'jobTitle'}
            rules={{
              validate: (data) => !!data.value || lang.editJobPreferencesModal.validate.jobTitle
            }}
            render={({ field, fieldState }) => {
              return (
                <JobFunctionSelector
                  className={styles.jobPreferencesFormInput}
                  control={control}
                  label={editModal.jobTitle}
                  variant='outlined'
                  autoComplete='off'
                  jobTitle={preference?.function_job_title}
                  title={lang.profile.desiredJobFunction}
                  helperText={fieldState?.error?.message}
                  required
                  value={jobFunction}
                  {...fieldState}
                  {...field}
                // onChange={(value)=>setValue('jobTitle',value)}
                />
              )
            }}
          />
        </div>
        <div className={styles.jobPreferencesFormGroup}>
          <Controller
            control={control}
            name={'jobType'}
            rules={{ required: lang.editJobPreferencesModal.validate.jobType }}
            render={({ field, fieldState }) => {
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.jobType}
                  options={jobTypeList}
                  required
                  {...fieldState}
                  {...field}
                />
              )
            }}
          />
        </div>
        <div className={styles.jobPreferencesFormGroup}>
          <Controller
            control={control}
            name='arrangement'
            rules={{ required: lang.editJobPreferencesModal.validate.arrangement }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.arrangement}
                  options={arrangementList}
                  value={value}
                  required
                  {...fieldState}
                  {...field}
                  onChange={(e) => {
                    onChange(e)
                    setArrangement(e.target.value)
                    setMaxSalary('')
                    setMinSalary('')
                    setValue('maxSalary', '')
                    setValue('minSalary', '')
                    if (e.target.value === 3) {
                      setValue('currencyKey', countryForCurrency('us'))
                    } else {
                      setValue('currencyKey', countryForCurrency(getCountryKey()))
                    }
                  }}
                />
              )
            }}
          />
        </div>

        {arrangement && Number(arrangement) === 3 ? (
          <>
            {/* <div className={styles.jobPreferencesFormGroup}>
              <Controller
                control={control}
                name='timezone'
                rules={{ required: lang.editJobPreferencesModal.validate.timezone }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field
                  return (
                    <MaterialBasicSelect
                      // multiple
                      className={styles.jobPreferencesFormInput}
                      label={editModal.timezone}
                      options={timezoneList}
                      required
                      value={value}
                      {...fieldState}
                      {...field}
                      onChange={(e) => {
                        onChange(e)
                        setTimezone(e.target.value)
                      }}
                    />
                  )
                }}
              />
            </div> */}
            <div className={styles.jobPreferencesFormGroup}>
              <Controller
                control={control}
                name='language'
                rules={{ required: lang.editJobPreferencesModal.validate.language }}
                render={({ field, fieldState }) => {
                  const { value, onChange } = field
                  return (
                    <MaterialBasicSelect
                      multiple
                      checkRequired={true}
                      className={styles.jobPreferencesFormInput}
                      label={editModal.language}
                      options={languageList}
                      required
                      value={value}
                      {...fieldState}
                      {...field}
                      multipleValue={value}
                      renderValue={(selected) =>
                        selected
                          .map((id) => ({
                            id,
                            label: languageList.find((item) => item.value === id)?.label
                          }))
                          .map((item) => item.label)
                          .join(', ')
                      }
                      onChange={(e) => {
                        onChange(e)
                        setLanguage(e.target.value.join(','))
                      }}
                    />
                  )
                }}
              />
            </div>
          </>
        ) : null}

        {arrangement && Number(arrangement) < 3 ? (
          <div className={styles.jobPreferencesFormGroup} style={{ marginBottom: 34 }}>
            <Controller
              control={control}
              name={'location'}
              rules={{ required: lang.editJobPreferencesModal.validate.location }}
              render={({ field, fieldState: { error } }) => {
                const { onChange } = field
                return (
                  <Cascader
                    error={error}
                    required
                    label={editModal.location}
                    preference={preference}
                    onChange={(location) => {
                      // setIsShowCountry(location?.key === 'overseas')
                      onChange(location)
                    }}
                  />
                  // <MaterialLocationField
                  //   className={styles.jobPreferencesFormInput}
                  //   label={editModal.location}
                  //   required
                  //   {...fieldState}
                  //   {...field}
                  //   onChange={(_, location) => {
                  //     setIsShowCountry(location?.key === 'overseas')
                  //     onChange(location)
                  //   }}
                  // />
                )
              }}
            />
          </div>
        ) : null}

        <div className={styles.jobPreferencesFormGroup}>
          <Controller
            control={control}
            name={'currencyKey'}
            rules={{
              required: lang.editJobPreferencesModal.validate.currency
            }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.currencyType}
                  IconComponent={null}
                  options={currencyLists}
                  disabled={Number(arrangement) < 3}
                  required
                  {...fieldState}
                  {...field}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    setValue('minSalary', '')
                    setValue('maxSalary', '')
                    setMinSalary('')
                    setMaxSalary('')
                  }}
                />
              )
            }}
          />
          <div style={{ width: '20px', height: '24px' }}></div>
          <Controller
            control={control}
            name={'salaryTypeId'}
            rules={{
              required: '' // lang.editJobPreferencesModal.validate.minSalary
            }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={lang?.salaryType?.payType}
                  options={initData(salaryTypeLists.filter(item => item.id !== 4))}
                  required
                  {...fieldState}
                  {...field}
                  value={value}
                  onChange={(e) => {
                    onChange(e)
                    setValue('minSalary', '')
                    setValue('maxSalary', '')
                    setMinSalary('')
                    setMaxSalary('')
                  }}
                />
              )
            }}
          />

          <div style={{ width: '20px', height: '24px' }}></div>
          <Controller
            control={control}
            name={'minSalary'}
            rules={{
              required: lang.editJobPreferencesModal.validate.minSalary
            }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.minSalary}
                  options={minSalaryOptions}
                  required
                  {...fieldState}
                  {...field}
                  value={value}
                  onChange={(e) => {
                    onChange(e)

                    setMinSalary(e.target.value)
                  }}
                />
              )
            }}
          />

          <div style={{ width: '20px', height: '24px' }}></div>
          <Controller
            control={control}
            name={'maxSalary'}
            rules={{
              required: lang.editJobPreferencesModal.validate.maxSalary
            }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              return (
                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.maxSalary}
                  required
                  options={minSalaryOptions}
                  {...fieldState}
                  {...field}
                  value={value}
                  onChange={(e) => {
                    onChange(e)

                    setMaxSalary(e.target.value)
                  }}
                />
              )
            }}
          />
        </div>

        {isShowCountry && (
          <div className={styles.jobPreferencesFormGroup}>
            <Controller
              control={control}
              name={'country'}
              rules={{ required: lang.editJobPreferencesModal.validate.country }}
              render={({ field, fieldState }) => {
                return (
                  <MaterialBasicSelect
                    className={styles.jobPreferencesFormInput}
                    label={editModal.country}
                    options={countryList}
                    {...fieldState}
                    {...field}
                  />
                )
              }}
            />
          </div>
        )}
        <div className={styles.jobPreferencesFormGroup}>
          <Controller
            control={control}
            name={'industry'}
            rules={{ required: lang.editJobPreferencesModal.validate.industry }}
            render={({ field, fieldState }) => {
              const { value, onChange } = field
              const { error } = fieldState
              return (
                // <div className={styles.jobPreferencesFormInput}>
                //   <IndustrySelect
                //     error={error}
                //     required
                //     lang={lang}
                //     options={industryOptions}
                //     style={{
                //       width: '100%',
                //       height: '44px'
                //     }}
                //     value={value || null}
                //     onChange={onChange}
                //     placeholder={editModal.industry}
                //   />

                // </div>

                <MaterialBasicSelect
                  className={styles.jobPreferencesFormInput}
                  label={editModal.industry}
                  required
                  optionCate="industry"
                  options={industryOptions}
                  key={value}
                  value={value}
                  onChange={onChange}
                  onSelect={(data) => {
                    setValue('industry', data.value)
                    setIndustry(data.key)

                  }}
                  {...fieldState}
                  {...field}
                />
              )
            }}
          />
        </div>
      </div >
    </div >
  )

  return (
    <Modal
      showModal={showModal}
      handleModal={handleCloseModal}
      headerTitle={editModal.title}
      firstButtonText={editModal.btn1}
      secondButtonText={editModal.btn2}
      isSecondButtonLoading={isUpdating}
      firstButtonIsClose
      handleFirstButton={handleCloseModal}
      handleSecondButton={handleSubmit(onSubmit)}
      fullScreen
      closeModalOnOutsideClick={true}
      className={styles.job_preferences_modal}
    >
      {modalJobPreferenceContent}
    </Modal>
  )
}

export default EditJobPreferencesModal
