import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import { IconButton, InputAdornment } from '@mui/material'
interface MaterialSelectCheckMarksProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  label?: string | React.ReactNode
  options: Array<OptionType>
  value: any
  onSelect?: any
  greyBg?: boolean
  fieldRef?: any
  error?: any
  IconComponent?: any
}

interface OptionType {
  value: any
  label: string | boolean
}

const arrowIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1em',
  height: '1em'
}

const ArrowIcon = (props) => {
  return (
    <div {...props} style={arrowIconStyle}>
      <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
        <path
          d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
          fill='#86909C'
        />
      </svg>
    </div>
  )
}

const SingleSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  value,
  fieldRef,
  error,
  style
}: MaterialSelectCheckMarksProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event

    if (onSelect) {
      onSelect(value)
    }
  }
  return (
    <FormControl fullWidth className={className} size='small'>
      <InputLabel id={`${id}-select-label`}>{label}</InputLabel>
      <Select
        {...fieldRef}
        variant='filled'
        error={error}
        labelId={`${id}-select-label`}
        id={id}
        value={value}
        style={{ ...style, background: value?.length ? '#E7F1FB' : '#F0F0F0' }}
        label={label}
        onChange={handleChange}
        input={<OutlinedInput label='Tag' />}
        IconComponent={(props) => <ArrowIcon {...props} />}
        renderValue={(value: any) => {
          if (!value) {
            return (
              <div
                style={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  position: 'relative',
                  left: 13,
                  top: 2,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                title={label as string}
              >
                {label}
              </div>
            )
          } else {
            const filterLabel = options.find((option) => option.value === value)?.label
            return (
              <div
                style={{
                  position: 'relative',
                  left: 13,
                  top: 2,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                title={filterLabel as string}
              >
                {filterLabel}
              </div>
            )
          }
        }}
      >
        {options &&
          options.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default SingleSelect
