/* eslint-disable @typescript-eslint/no-empty-function */



'use client'
import React, { createContext, useContext } from 'react'
import { useLazyConnectLogin } from 'helpers/lazyConnnectLogin'

export const LoginModalContext = createContext({ showLogin: () => { } })
const Provider = LoginModalContext.Provider

export const useLoginModal = () => {
    return useContext(LoginModalContext);
}

const LoginModalProvider = ({ children }: any) => {
    const { showLoginModal } = useLazyConnectLogin()
    return <Provider value={{ showLogin: showLoginModal }}>
        {children}
    </Provider>
}

export default LoginModalProvider
