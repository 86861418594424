'use client'
import React, { Attributes, FunctionComponent, useState, useEffect, useContext, useLayoutEffect, useRef } from 'react'
import { HoverableProps, hoverable } from 'components/highLevel/hoverable'
import { usePageGrouped } from './hooks'
import { LocationContext } from 'app/components/providers/locationProvier'
import styles from 'app/index.module.scss'
import { buildQuery } from 'app/(main-page)/helper'
import Link from 'components/Link'
import classNames from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import { ArrowForwardIosIcon } from 'app/components/MuiIcons'
import { ArrowBackIosIcon } from 'app/components/MuiIcons'
import { addEventTag } from 'helpers/utilities'

interface MainData {
  title: string
  simpleTitle?: string
  descripttions: string[]
  children: SectionData[]
}

interface SubData {
  label: string
  value: string
}

interface SectionData {
  title: string
  children: SubData[]
}
interface FunctionFilterProps {
  list: MainData[]
  subTitlesList: string[][]
  contentWidths: number[]
  langKey: string
}

type MainProps = Attributes & {
  setHoverData: (data: SectionData[]) => void
  data: MainData
  hoverTitle: string
  subTitles: string[]
  setHoverTitle: (string) => void
  contentWidth: number
  langKey: string
}
type SectionProps = { data: SectionData; langKey: string } & Attributes
type SubItemProps = { data: SubData; langKey: string } & Attributes

const getDisplayWidth = (ref, parentRef) => {
  function getVisibleWidthPercentage(element, parentElement) {
    const parentRect = parentElement.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const visibleLeft = parentRect.left
    const visibleRight = parentRect.right

    if (elementRect.right < visibleRight) {
      // fully visible
      return { visible: true, visibleWidth: elementRect.width }
    }

    const visibleWidth = visibleRight - elementRect.left;

    return {
      visible: false,
      visibleWidth
    }
  }

  return getVisibleWidthPercentage(ref, parentRef)
}
const CalculateLink = ({ href, subTitle, onMouseOver, onClick, parentRef, bindRef, style }) => {
  const ref = useRef()
  // const [sty, setStyle] = useState({})


  return <Link
    href={href}
    style={style}
  >
    <div
      ref={bindRef}
      title={subTitle}
      className={styles.mainTitleSub}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      {subTitle}
    </div>
  </Link>
}

const MainItem: FunctionComponent<MainProps> = hoverable((props: HoverableProps & MainProps) => {
  const {
    isHover,
    setHoverData,
    data,
    onMouseEnter,
    onMouseLeave,
    setHoverTitle,
    contentWidth,
    hoverTitle,
    subTitles = []
  } = props

  const { location } = useContext(LocationContext)
  const ref = useRef(null)
  const childRef = useRef({})
  const [childStyle, setChildStyle] = useState([])

  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    let childRefObj = childRef.current
    let newStyle = Object.keys(childRefObj).map(key => {
      const { visibleWidth, visible } = getDisplayWidth(childRefObj[key], ref.current)
      if (visible) {
        return {}
      }
      if (visibleWidth < 50) {
        return { display: 'none' }
      } else {
        return {
          display: 'flex',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }
      }
    })
    setChildStyle(newStyle)
  }, []);

  useLayoutEffect(() => {
    if (isHover) {
      setHoverData(data.children)
      setHoverTitle(data.title)
    }
  }, [isHover])

  return (
    <div
      className={classNames({
        [styles.mainItem]: true,
        [styles.isHover]: isHover || hoverTitle === data.title
      })}
      key={props.key}
      title={data.title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.mainTitle}>
        <div className={styles.mainTitleFirst}>{data.simpleTitle || data.title}</div>
        <div className={styles.subContainer} style={{ overflow: 'hidden' }} ref={ref}>
          {subTitles.map((subTitle, index, array) => (
            <CalculateLink
              parentRef={ref}
              style={childStyle[index]}
              bindRef={ref => (childRef.current[index] = ref)}
              key={subTitle + index}
              href={'/' + props.langKey + buildQuery(location?.seo_value, subTitle)}
              onMouseOver={() => addEventTag("hp_job_function_hover")}
              onClick={() => addEventTag("hp_job_function_click")}
              subTitle={subTitle}
            />
          ))}
        </div>
        <span style={{ display: 'inline-flex', marginLeft: 12, height: 12, width: 12, flexShrink: 0 }}>
          <ArrowForwardIosIcon className={styles.more} />
        </span>
      </div>
    </div>
  )
})

const SectionItem = (props: SectionProps) => {
  const { data }: any = props
  return (
    <div className={styles.sectionItems} key={data?.id + data['seo-value']}>
      <label className={styles.sectionName}>{data.title}</label>
      <div className={styles.subItems}>
        {data?.children.map((item, index) => (
          <SubItem data={item} langKey={props.langKey} key={item.value + index} />
        ))}
      </div>
    </div>
  )
}
const SubItem: FunctionComponent<SubItemProps> = hoverable(
  (props: SubItemProps & HoverableProps) => {
    const { location } = useContext(LocationContext)
    const { data } = props
    return (
      <Link
        className={styles.subItem}
        href={'/' + props.langKey + buildQuery(location?.seo_value, data?.label)}
      >
        <Tooltip title={data.label} placement='top-start'>
          <div className={styles.linkText}> {data.label}</div>
        </Tooltip>
      </Link>
    )
  }
)

const FunctionFilter: FunctionComponent<FunctionFilterProps> = hoverable(
  (props: FunctionFilterProps & HoverableProps) => {
    const { list, isHover, subTitlesList, contentWidths, langKey, ...rest } = props
    const [hoverTitle, setHoverTitle] = useState('')
    const { currentPage, totalPages, pageDatas, nextEnable, preEnable, onNext, onPre } =
      usePageGrouped(list)

    const [hoverData, setHoverData] = useState<SectionData[] | null>(null)
    useEffect(() => {
      if (!isHover && hoverData) {
        setHoverData(null)
        setHoverTitle('')
      }
    }, [isHover, hoverData])

    return (
      <div className={styles.functionFilter} {...rest}>
        <div className={styles.mainItems}>
          {pageDatas.map((main, index) => (
            <MainItem
              key={main.title}
              hoverTitle={hoverTitle}
              setHoverData={setHoverData}
              data={main}
              subTitles={subTitlesList[(currentPage - 1) * 5 + index]}
              setHoverTitle={setHoverTitle}
              contentWidth={contentWidths[index]}
              langKey={langKey}
            />
          ))}

          <div className={styles.pagination}>
            <label>
              {currentPage}/<span>{totalPages}</span>
            </label>
            <div>
              <button
                type='button'
                disabled={!preEnable}
                className={styles.prePage}
                style={{ paddingLeft: '6px' }}
                onClick={onPre}
              >
                <ArrowBackIosIcon className={styles.icon} />
              </button>
              <button type='button' disabled={!nextEnable} onClick={onNext}>
                <ArrowForwardIosIcon className={styles.icon} />
              </button>
            </div>
          </div>
        </div>
        {hoverData && (
          <div className={styles.sectionContainer}>
            {hoverData?.map?.((sectionData, index) => {
              return (
                <SectionItem langKey={langKey} data={sectionData} key={sectionData.title + index} />
              )
            })}
          </div>
        )}
      </div>
    )
  }
)

export default FunctionFilter
