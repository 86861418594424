
'use client'
import React from 'react'
import Link from 'components/Link'
import styles from '../../Footer.module.scss'
import { pushToResume } from "helpers/push"

const TalentsList = (props: any) => {
  const { data, countryKey, blogAddressUrl } = props
  const { privacyPolicy, scamPrevention, AICareerCoach, latestNews } = data?.foot || {}
  return (
    <ul className={styles.footerDesktopLinkList}>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.PrivacyPolicy}
          external
          title={privacyPolicy}
        >
          <span>{privacyPolicy}</span>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.ScamPrevention}
          external
          title={scamPrevention}
        >
          <span>{scamPrevention}</span>
        </Link>
      </li>
      <li>
        <a
          className={styles.footerLink}
          // to={`https://aicv.bossjob.com/${langKey}`}
          title={AICareerCoach}
          onClick={(e) => {
            e.preventDefault()
            pushToResume()
          }}
        >
          <span>{AICareerCoach}</span>
        </a>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={`https://blog.bossjob.${countryKey === 'jp' ? 'jp' : 'ph'}`}
          title={latestNews}
          external
        >
          <span>{latestNews}</span>
        </Link>
      </li>
    </ul >
  )
}

export default TalentsList
