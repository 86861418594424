import styled from '@emotion/styled';
import React from 'react'

const UploadResumeWrap = (props: {
  children: React.ReactNode,
  lang: any
}) => {
  const { children, lang = {} } = props
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  return (
    <ContentWrap>
      <div className="modal-content">
        <h2 className='modal-title'>{profile?.uploadResumeModal?.title}</h2>
        {children}
        <div className="tips">
          <p className='title'>{profile?.uploadResumeModal?.tips}:</p>
          <p className='content'>1. {profile?.uploadResumeModal?.tip1}</p>
          <p className='content'>2. {profile?.uploadResumeModal?.tip2}</p>
        </div>
      </div>
    </ContentWrap >
  )
}

export default UploadResumeWrap

const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`,
}

const ContentWrap = styled.div`
  .modal-closed {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .modal-title {
    color: #121212;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 32px;
    text-align: center;

    ${media.sm} {
      font-size: 20px;
    }
  }

  .tips {
    text-align: left;
    margin-top: 32px;
    font-size: 14px;
    color: #666;
    .title {
      color: #515151;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      letter-spacing: 0.14px;
    }
    .content {
      margin-top: 4px;
      padding: 4px;
      color: #7D7D7D;
      font-style: normal;
      font-weight: 200;
      line-height: normal;
      letter-spacing: 0.14px;
    }
  }
`;
