import tabStyles from './tabs.module.scss'

export const ContainedTab = ({ newTabList, onChange, value }) => {

  return <>
    {newTabList.length > 0 && <div className={tabStyles.remoteTabs}>
      <div className={tabStyles.remoteTabInnerWrapper}>
        {newTabList.map((item, idx) => {
          const { value: itemValue, tab } = item
          const gtagEventValue = ['rl_active_job_filter_it', 'rl_active_job_filter_web3', 'rl_active_job_filter_sales', 'rl_active_job_filter_marketing']
          console.log('tab:', tab, itemValue)
          return <div key={itemValue}
            data-checked={itemValue === value}
            onClick={() => onChange(itemValue)}
            className={tabStyles.remoteTabItem}
            data-gtag-event={gtagEventValue[idx]}
          >
            <div className={tabStyles.tabInnerWrapper}>
              {tab}
            </div>
          </div>
        })}
      </div>
    </div>}
  </>
}