'use client'
import React, { useState, useLayoutEffect, useRef, useContext } from 'react'
import style from './index.module.scss'
import styles from '../SearchPanel/index.module.scss'
import Section from '../Section/index'
import { CompanyDetailsType, JobData, JobsResponseType } from '../../service'
import Map from 'app/(job)/[lang]/components/Main/Map/Map'
// import { languageContext } from 'app/[lang]/components/providers/languageProvider';
import JobCard from '../JobsCard/index'
import { languageContext } from '../../../../../../components/providers/languageProvider'
import { useCompanyDetail } from '../../DataProvider'
import Image from 'next/image'
import classNames from 'classnames'
import Link from 'components/Link'
import { useMediaQuery } from '@mui/material'
import { SocialMedia, TagContent, NewsCom } from '../Culture'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ChatItem } from '../ChatPanel'
import 'swiper/swiper.min.css'
import { formatTemplateString } from 'helpers/formatter'
import Lightbox from 'react-image-lightbox'
import Tooltip from '@mui/material/Tooltip'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import BusinessInformationExplanation from '../BusinessInformationExplanation'
import CompanyBrandList from '../CompanyBrandList'
import BrandSwiper from 'app/components/companyBrand/brandSwiper'
import { Preview } from '../Preview'
interface Props extends React.PropsWithChildren<CompanyDetailsType> {
  jobs: JobData[]
  onMore: (e: React.SyntheticEvent) => void
  isMobile: Boolean
}

const CompanyInfo = (_props: Props) => {
  const props = { ..._props }
  const isMobile = props.isMobile
  const { config, detail, news, jobs, hr, businessInfo } = useCompanyDetail()

  const [isOpenLightbox, setLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const brandProductList = detail.brand_products || []


  if (!props.company_business_info) {
    props.company_business_info = {}
  }
  if (props.company_business_info) {
    props.company_business_info.full_address = _props.full_address
    props.company_business_info.name = _props.legal_name
    // @ts-ignore
    props.turnover = config.turnover_lists.filter((_) => {
      return _.id === _props.turnover_id
    })[0]?.value
    props.company_business_info.company_type = config.company_types.find(
      (_) => _.id === props.company_business_info.type_of_enterprise_id
    )?.value
    // props.company_business_info.industry = _props.industry;
  }
  // @ts-ignore
  props.turnover = (config?.turnover_lists || []).filter((_) => {
    return _.id === _props.turnover_id
  })?.[0]?.value

  const contextLang = useContext(languageContext)
  const { overview, tab } = contextLang.companyDetail
  const info = [
    {
      id: 'Introduction',
      title: overview.Introduction
    },
    {
      id: 'Company Album',
      title: overview.CompanyAlbum
    },
    {
      id: 'Brand or Product',
      title: overview.BrandorProduct
    },
    {
      id: 'Business information',
      title: overview.BusinessInformation
    },
    {
      id: 'Address',
      title: contextLang.myJobs.address
    },

    {
      id: 'Job openings',
      title: overview.JobOpenings
    }
  ]

  const business_info = [
    {
      field: 'legal_name',
      name: overview.legalName
    },
    {
      field: 'founding_date',
      name: overview.foundingDate
    },
    {
      field: 'operating_status',
      name: overview.operatingStatus
    },
    {
      field: 'social_credit_code',
      name: overview.UnifiedSocialCreditCode
    },
    {
      field: 'company_type',
      name: overview.companyType
    },
    {
      field: 'registered_capital',
      name: overview.registeredCapital
    },
    {
      field: 'country',
      name: overview.registerCountry
    },
    {
      field: 'founder',
      name: overview.founder
    },
    {
      field: 'full_address',
      name: overview.RegisteredAddress
    },
    {
      field: 'ceo',
      name: overview.CEO
    }

    // {
    //   field: 'name',
    //   name: overview.CompanyName
    // },
    // {
    //   field: 'social_credit_code',
    //   name: overview.UnifiedSocialCreditCode
    // },
    // {
    //   field: 'legal_representative',
    //   name: overview.LegalRepresentative
    // },
    // {
    //   name: overview.RegisteredCapital,
    //   field: 'registered_capital'
    // },
    // {
    //   name: overview.PaidUpCapital,
    //   field: 'paid_in_capital'
    // },
    // {
    //   name: overview.DateOfEstablishment,
    //   field: 'establishment_date'
    // },
    // {
    //   name: overview.OrganizationCode,
    //   field: 'organization_code'
    // },
    // {
    //   name: overview.BusinessRegistrationNumber,
    //   field: 'business_registration_number'
    // },
    // {
    //   name: overview.TaxpayerIdentificationNumber,
    //   field: 'taxpayer_identification_number'
    // },
    // {
    //   name: overview.TypeOfEnterprise,
    //   field: 'type_of_enterprise'
    // },
    // {
    //   name: overview.ApprovalDate,
    //   field: 'approval_date'
    // },
    // {
    //   name: overview.RegisteredAddress,
    //   field: 'full_address'
    // },
    // {
    //   name: overview.CompanyType,
    //   field: 'company_type'
    // }
  ]

  if (isMobile) {
    return (
      <div className={style.tab_content_wrapper}>
        {props.description && props.description != '-' && Introduction(0, info[0], true, props)}
        {props.pictures?.length > 0 && <Section title={info[1].title}>{MobileAlbum()}</Section>}
        <BrandSwiper brandProduct={brandProductList} pageType="company-detail" />
        <BusinessInfo
          index={4}
          item={info[3]}
          noSplit={true}
          business_info={business_info}
          props={businessInfo}
        // 4, info[3], true, business_info, businessInfo
        />
        {props.cultures && props.cultures.length > 0 && (
          <Section title={overview.CompanyFeatures}>
            <TagContent type={'culture'} {...props}></TagContent>
          </Section>
        )}
        {props.benefits && props.benefits?.length > 0 && (
          <Section title={overview.CompanyBenefits}>
            <TagContent type={'benefits'} {...props}></TagContent>
          </Section>
        )}
        {hr?.length > 0 && <Section title={overview.HiBoss}>{MobileHiBoss(overview)}</Section>}
        {props.full_address && (
          <Section title={info[4].title}>
            <Map
              lat={Number(props.latitude)}
              lng={Number(props.longitude)}
              full_address={props.full_address}
              lang={contextLang.jobDetail}
            />
          </Section>
        )}

        {<SocialMedia {...detail}></SocialMedia>}
        {news?.data?.length > 0 && <NewsCom news={news}></NewsCom>}
      </div>
    )
  }
  return (
    <div className={style.tab_content_wrapper}>
      {info.map((item, index) => {
        const noSplit = index === 0
        if (item.id === 'Introduction' && props.description && props.description != '-') {
          return Introduction(index, item, noSplit, props)
        }

        if (item.id === 'Company Album' && props.pictures?.length > 0) {
          return (
            <>
              <Section key={index} title={item.title} split={!noSplit}>
                <div className={style.album_wrapper}>
                  {padArrayToMultiple(props.pictures.sort((a, b) => a.sort_order - b.sort_order))(
                    3
                  ).map((item, index) => {
                    if (!item)
                      return (
                        <div className={style.album_item} style={{ width: 220, height: 150 }}></div>
                      )
                    return (
                      <Image
                        key={index}
                        src={item.url}
                        alt='alt'
                        className={style.album_item}
                        onClick={() => {
                          setPhotoIndex(index)
                          setLightbox(true)
                        }}
                        width={220}
                        height={134}
                        style={{ objectFit: 'cover' }}
                      />
                    )
                  })}
                </div>
              </Section>
              {isOpenLightbox && (
                <Lightbox
                  mainSrc={props.pictures[photoIndex].url}
                  nextSrc={props.pictures[(photoIndex + 1) % props.pictures.length].url}
                  prevSrc={
                    props.pictures[(photoIndex + props.pictures.length - 1) % props.pictures.length]
                      .url
                  }
                  onCloseRequest={() => setLightbox(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex) =>
                        (photoIndex + props.pictures.length - 1) % props.pictures.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex) => (photoIndex + 1) % props.pictures.length)
                  }
                />
              )}
            </>
          )
        }

        if (item.id === 'Brand or Product' && brandProductList.length) {
          return <Section key={index} title={item.title} split={true}>
            <CompanyBrandList />
          </Section>
        }

        if (item.id === 'Business information' && props.company_business_info) {
          return <BusinessInfo
            index={index}
            item={item}
            noSplit={noSplit}
            business_info={business_info}
            props={businessInfo}
          />
        }
        if (item.id === 'Address' && props.full_address) {
          return (
            <Section key={index} title={item.title} split={!noSplit}>
              <Map
                lat={Number(props.latitude)}
                lng={Number(props.longitude)}
                full_address={props.full_address}
                lang={contextLang.jobDetail}
              />
            </Section>
          )
        }
        if (item.id === 'Job openings' && props.jobs?.length) {
          return (
            <Section key={index} title={item.title}>
              {props.jobs
                .map((item, index) => {
                  return <JobCard {...item} key={index}></JobCard>
                })
                .slice(0, 5)}
              {jobs.total_num > 5 && (
                <div className={style.containerMore}>
                  <div className={style.more} onClick={props.onMore}>
                    {formatTemplateString(overview.AllJobs, { total_num: jobs.total_num })}
                    {/* {`More ${jobs.total_num} Jobs`} */}
                  </div>
                </div>
              )}
            </Section>
          )
        }
      })}
    </div>
  )
}

export default CompanyInfo

export const padArrayToMultiple = (arr) =>
  function <T>(num: number) {
    const length = arr.length
    const remainder = length % num
    if (remainder === 0) {
      return arr // Array length is already a multiple of num
    }
    const paddingLength = num - remainder
    const padding = new Array(paddingLength).fill(null)
    return arr.concat(padding) as T[]
  }

function Introduction(
  index: number,
  item: { id: string; title: string },
  noSplit: boolean,
  props: {
    jobs: JobData[]
    onMore: (e: React.SyntheticEvent) => void
    id: number
    coordinator_id: number
    legal_name: string
    name: string
    num_of_members: number
    cover_pic_url: string
    logo_url: string
    logo_tmm: any
    company_size: string
    industry: string
    full_address: string
    longitude: number
    latitude: number
    unit_num: any
    website: string
    facebook_url: string
    instagram_url: string
    linkedin_url: string
    youtube_url: string
    description: string
    description_html: string
    twitter_url: string
    turnover_id: number
    cultures: { id: number; value: string; category: string }[]
    benefits: { id: number; value: string; category: string }[]
    pictures: { id: number; url: string; sort_order: number }[]
    company_business_info: any
    company_url: string
    is_verify: boolean
    document: any
    financing_stage: string
    working_addresses: any[]
    industry_id: number
    company_size_id: number
    financing_stage_id: any
    listing_info: any
    country_id: number
    children?: React.ReactNode
  }
): JSX.Element {
  const ref = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(150)
  const calculateContentHeight = () => {
    setContentHeight(ref.current?.scrollHeight)
  }
  const [showMore, setShow] = useState(false)

  useLayoutEffect(() => {
    calculateContentHeight()
    if (isContentOverflowing(ref.current)) {
      setShow(true)
    }
  })
  const handleClick = () => {
    setIsVisible(!isVisible)
  }

  const contextLang = useContext(languageContext)
  const { overview, tab } = contextLang.companyDetail
  if (!props.description) return null
  return (
    <Section key={index} title={item.title} split={true}>
      <div
        className={classNames({
          [style.introduce_wrapper]: true
        })}
      >
        {isVisible ? (
          <div
            className={style.introduction}
            ref={ref as React.RefObject<HTMLDivElement>}
            dangerouslySetInnerHTML={{
              __html: filterScriptContent(props.description)
            }}
          ></div>
        ) : (
          <div onClick={handleClick}>
            <HTMLEllipsis
              unsafeHTML={filterScriptContent(props.description)}
              maxLine={5}
              ellipsisHTML={`... <span style="color:#2378E5" >${overview.More}</span>`}
              basedOn='letters'
            />
          </div>
        )}
      </div>

      {isVisible && (
        <div className={`${style.more} ${style.moreInfo}`} onClick={handleClick}>
          {isVisible ? overview.Less : overview.More}
        </div>
      )}
    </Section>
  )
}

function BusinessInfo({ index, item, noSplit, business_info, props }: {
  index: number,
  item: { id: string; title: string },
  noSplit: boolean,
  business_info: { field: string; name: string }[],
  props: CompanyDetailsType
}): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)
  const [contentHeight, setContentHeight] = useState(150)
  const [isShowTips, setIsShowTips] = useState(false)
  const handleClick = () => {
    setIsVisible(!isVisible)
  }
  const contentRef = useRef(null)
  const calculateContentHeight = () => {
    setContentHeight(contentRef.current?.scrollHeight)
  }
  useLayoutEffect(() => {
    calculateContentHeight()
  })
  const isMobile = useMediaQuery('(max-width: 768px)')

  const contextLang = useContext(languageContext)
  const { overview, tab } = contextLang.companyDetail
  if (!props) return null

  const _resArr = business_info.filter((_) => props[_?.field])
  const showMore = _resArr.length > 6

  return (
    <Section
      key={index}
      title={item.title + ' '}
      id={item.id}
      split={!noSplit}
      onHandelClose={() => setIsShowTips(true)}
    >
      <BusinessInformationExplanation
        isShowTips={isShowTips}
        onClose={() => setIsShowTips(false)}
      />
      <div className={`${item.id === 'Business information' ? style.BusinessInformation : null}`}>
        <div
          className={style.animation_wrapper}
          style={{
            height: !isVisible ? (!showMore ? 'auto' : 128) : contentHeight
          }}
        >
          <div className={style.overview_item_wrapper} ref={contentRef}>
            {_resArr.map((item) => {
              const value = props[item?.field]
              const is_url = isURL(value)
              return (
                <div key={item?.field} className={style.business_item}>
                  <div className={style.overview_item_name}>{item?.name}</div>

                  {item && !isMobile && (
                    <Tooltip
                      title={props[item?.field]}
                      placement='top-start'
                      arrow
                    >
                      <p
                        style={{
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {is_url ? (
                          <Link
                            href={value}
                            className={style.overview_item_value_PC}
                            target={'_blank'}
                          >
                            {value}
                          </Link>
                        ) : (
                          props[item?.field]
                        )}
                      </p>
                    </Tooltip>
                  )}
                  {(() => {
                    if (item && isMobile && is_url)
                      return (
                        <Link
                          href={value}
                          className={style.overview_item_value_mobile}
                          target={'_blank'}
                          style={{ color: '#2378E5' }}
                        >
                          {value}
                        </Link>
                      )
                    if (item && isMobile)
                      return (
                        <div className={style.overview_item_value_mobile}>{props[item?.field]}</div>
                      )
                  })()}
                </div>
              )
            })}
          </div>
        </div>
        {showMore && (
          <div className={style.containerMore}>
            <div
              className={`${style.more} ${isVisible ? style.lessMore : ''}`}
              onClick={handleClick}
            >
              {isVisible ? overview.Less : overview.More}
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}

export function filterScriptContent(str: string): string {
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
  return str.replace(regex, '')
}

export function isURL(str) {
  // Regular expression pattern to match a URL
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/

  // Test the string against the pattern
  return urlPattern.test(str)
}

function isContentOverflowing(element) {
  return (
    element?.scrollWidth > element?.clientWidth || element?.scrollHeight > element?.clientHeight
  )
}

export function MobileHiBoss(overview) {
  const { hr } = useCompanyDetail()
  const [recruiterId, setRecruiterId] = useState(null)

  return (
    <div className={styles.filter_container}>
      {hr.map((item, index) => {
        return (
          <div key={index}>
            <ChatItem {...item} setRecruiterId={setRecruiterId}></ChatItem>
          </div>
        )
      })}
      {recruiterId && <Preview Id={recruiterId} setRecruiterId={setRecruiterId} overview={overview}></Preview>}
    </div>
  )
}

function MobileAlbum() {
  const { detail } = useCompanyDetail()
  const [isOpenLightbox, setLightbox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const res = detail.pictures
  if (!res?.length) return null
  return (
    <div>
      <Swiper
        spaceBetween={30}
        slidesPerView={1.2}
        scrollbar={{ draggable: true }}
      >
        {res.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className={style.mobile_album}>
                <Image
                  style={{ objectFit: 'cover' }}
                  fill
                  src={item.url}
                  alt='album'
                  onClick={() => {
                    setPhotoIndex(index)
                    setLightbox(true)
                  }}
                ></Image>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {isOpenLightbox && (
        <Lightbox
          mainSrc={res[photoIndex].url}
          nextSrc={res[(photoIndex + 1) % res.length].url}
          prevSrc={res[(photoIndex + res.length - 1) % res.length].url}
          onCloseRequest={() => setLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + res.length - 1) % res.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex) => (photoIndex + 1) % res.length)}
        />
      )}
    </div>
  )
}
