import React from 'react'
// import React, { useState } from 'react'

/* Material */
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

/* Helpers */
// import { formatLocationConfig } from 'helpers/jobPayloadFormatter'
import { flat } from 'helpers/formatter'
import { Button, IconButton, InputAdornment } from '@mui/material'
import { getCountryKey } from 'helpers/country'
import filter from 'lodash-es/filter'

const textFieldTheme = (value, height = '44px', width = '90vw') => createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          width: width,
          height: '100%',
          padding: '0px',
          top: '0px',
          left: '0px'
        },

      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          overflow: 'hidden',
          // border:'1px solid #ccc',
          // backgroundColor:'while'
          paddingTop: '0px',
          height,
        },
        input: {
          paddingTop: '0px'
        },
        endAdornment: {
          right: '0 !important',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // fontSize: '14px',
          letterSpacing: '1px',
          borderRadius: '10px',
          backgroundColor: 'white',
          border: 'none',
          height: height,
          background: !!value ? '#E7F1FB' : '#F0F0F0',
          color: '#136FD3',
          marginTop: '0px',
          paddingTop: '0px',
          fontSize: '14px'
        },

        // input: {
        //   color: '#136FD3',
        // },
        // focused: {
        //   background: '#E7F1FB'
        // },
        notchedOutline: {
          '&.Mui-focused': {
            border: 'none'
          },
          border: 'none',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          letterSpacing: '1px',
          top: '-8px',
          height: height,
          lineHeight: height,
          paddingTop: '0px'
        },
        shrink: {
          display: 'none',
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            fontSize: '10px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        },
      }
    }
  },
})

const formatLocationConfig = (locationList) => {
  const locationConfig = locationList?.map((region) => region.locations)
  return locationConfig
}
const MaterialLocationField = ({ className, label, locationList, width, disableClearable = false, defaultValue, required, fieldRef, error, value, height, isRemoteWork, ...rest }: any) => {

  const formattedLocationList = flat(formatLocationConfig(locationList))
  const countryList = filter(locationList, (o) => o.id != 241) || []
  const locationOptions = isRemoteWork ? countryList : formattedLocationList
  const isSg = getCountryKey() === 'sg'
  return (
    <ThemeProvider theme={textFieldTheme(value, height, width)}>
      <Autocomplete
        id='location-autocomplete'
        options={locationOptions}
        groupBy={isSg ? undefined : (option: any) => option.region_display_name}
        getOptionLabel={(option: any) => option.value || ''}
        size='small'
        value={value}
        disableClearable={disableClearable}
        className={className}
        classes={{}}
        onChange={rest?.onChange}
        popupIcon={<IconButton>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='11'
            height='7'
            viewBox='0 0 11 7'
            fill='none'
          >
            <path
              d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
              fill='#86909C'
            />
          </svg>
        </IconButton>}
        renderInput={(params) => {
          return (
            <TextField
              id='location12'
              {...fieldRef}
              error={!!error}
              style={{ height: '100%' }}
              required={rest.required}
              helperText={error?.message}
              label={<span>{label ? label : 'Location'} {required ? <span style={{ color: 'red' }}>{' *'}</span> : ''}</span>}
              variant='outlined'
              size='small'
              {...params}
              inputProps={{ ...params?.inputProps }}
            />
          )
        }}
        defaultValue={defaultValue}
        {...rest}
      />
    </ThemeProvider>
  )
}
export default MaterialLocationField
