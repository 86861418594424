import { useEffect, useMemo, useState } from 'react'
import { pickBy, isEmpty } from 'ramda'

import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'next/navigation'

function useRemoteSearchSelect(config, searchValues) {
  const { country_lists, world_region_lists, job_languages, timezone_lists } = config ?? {}
  const [countryList, setCountryList] = useState<any[]>([])
  const [jobLanguages, setJobLanguages] = useState<any[]>([])
  const [timezoneLists, setTimezoneLists] = useState<any[]>([])
  const params = useSearchParams()


  const [country, setCountry] = useState<number[]>(() => {
    const country = (params.get('remote_country_ids') || '').split(',').filter(Boolean).map(v => Number(v)) || []
    return country
  })

  const [language, setLanguage] = useState<string | number | undefined>(() => {
    if (isMobile) {
      return searchValues.language_ids?.[0] ?? []
    } else {
      return searchValues.language_ids ?? ''
    }
  })

  const [timezone, setTimezone] = useState<string | number | undefined>(() => {
    if (isMobile) {
      return searchValues.timezone_ids?.[0] ?? []
    } else {
      return searchValues.timezone_ids ?? ''
    }
  })

  useEffect(() => {
    if (isMobile) {
      setTimezoneLists(searchValues.timezone_ids?.[0] ?? '')
      setJobLanguages(searchValues.language_ids?.[0] ?? '')
      setCountryList(searchValues.remote_country_ids?.[0] ?? '')
    } else {
      setTimezoneLists(searchValues.timezone_ids ?? '')
      setJobLanguages(searchValues.language_ids ?? '')
      setCountryList(searchValues.remote_country_ids ?? '')
    }
  }, [searchValues])

  useEffect(() => {
    // const list = country_lists?.map((item) => ({
    //   ...item,
    //   label: item.value,
    //   value: String(item.id)
    // }))
    let list = country_lists[country_lists.length - 1]?.region_lists || []
    list.forEach(ele => {
      ele.value = ele.display_name
    });
    setCountryList(list)
  }, [country_lists])

  useEffect(() => {
    const list = job_languages?.map((item) => ({
      ...item,
      // label: item.name || item.value,
      // value: String(item.id)
    }))
    setJobLanguages(list)
  }, [job_languages])

  useEffect(() => {
    const list = timezone_lists?.map((item) => ({
      ...item,
      label: item.name || item.value,
      value: String(item.id)
    }))
    setTimezoneLists(list)
  }, [timezone_lists])

  const searchCountryList = useMemo(() => {
    if (country) {
      return [String(country)]
    } else {
      return []
    }
  }, [country])

  const searchLanguageList = useMemo(() => {
    if (language) {
      return [String(language)]
    } else {
      return []
    }
  }, [language])

  const searchTimezoneList = useMemo(() => {
    if (timezone) {
      return [String(timezone)]
    } else {
      return []
    }
  }, [timezone])

  const removeEmptyValues = (obj) => {
    return pickBy((value) => !isEmpty(value), obj)
  }

  return {
    countryList,
    jobLanguages,
    timezoneLists,
    world_region_lists,

    country,
    language,
    timezone,

    setCountry,
    setLanguage,
    setTimezone,

    searchCountryList,
    searchLanguageList,
    searchTimezoneList,

    removeEmptyValues
  }
}

export default useRemoteSearchSelect
