import React, { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import FootBtn from './footBtn'
import { useSyncResumeContext } from '../context'
import { updateUserCompleteProfileService } from 'store/services/users/updateUserCompleteProfile'
import { SkillSection } from './Skill'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import Loading from '../loading'


export const Skills = () => {
  const { value, onSkipClick, onSubmit } = useSyncResumeContext()
  const [data, setData] = useState(() => (value.value || [])?.map((v, i) => {
    return { label: v, id: i }
  }))
  const [load, setLoad] = useState(0)
  const [selectedData, setSelectedData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.scrollingElement.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    setLoad(1)
    let unmounted = false
    fetchUserOwnDetailService().then(res => {
      if (unmounted) {
        return
      }
      let skill = res.data?.data?.skills || [];
      if (!skill.length) {
        return
      }
      skill = skill.map((v, i) => {
        return { label: v, id: i }
      })

      setData(pre => {
        const data = [...skill];
        pre.forEach((v, i) => {
          v.id = data.length + i
          data.push(v)
        })
        return data
      })
      setSelectedData([...skill])
    }).finally(() => {
      !unmounted && setLoad(4)
    })
    return () => {
      unmounted = true
    }
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (selectedData.length) {
        await updateUserCompleteProfileService({ skills: selectedData.map(v => v.label).join(',') });
      }
      onSubmit()
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  if (load < 4) {
    return <div className={styles.loadingRoot}>
      <Loading />
    </div>
  }

  return (
    <div className={styles.work}>
      <SkillSection
        options={data}
        value={selectedData}
        onChange={(options, value, e) => {
          setSelectedData(value)
        }}
      />
      <div style={{ height: 40 }}></div>
      <FootBtn
        loading={loading}
        handleClick={handleSubmit}
        skipClick={onSkipClick}
      />
    </div>
  )
}

