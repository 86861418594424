'use client'
import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import Remote from '../Remote'
import ReadMore from './ReadMore'
import { DefaultAvatar } from 'images'
import { transStateNew } from 'helpers/utilities'
import styles from '../../../page.module.scss'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import { Image as AntImage } from 'antd'
import { fetchJobRecruiter } from 'store/services/jobs/fetchJobDetail'

type propsType = {
  description?: string
  requirements?: string
  skills?: Array<any>
  job_identity_restrictions?: Array<any>
  logo?: string
  name?: string
  shareParams: any
  languages: Record<string, any>
  work_arrangement: any
  languageRemoteDetail: any
  isMobile
  jobId
}

const Desc = (props: propsType) => {
  const {
    description,
    requirements,
    skills,
    job_identity_restrictions,
    languages,
    work_arrangement,
    isMobile,
    jobId
  } = props
  const { content } = languages

  const [requirementTopNear, setRequirementTopNear] = useState<number>(0)
  const [online, setOnline] = useState<any>({})
  const [recruiter, setRecruiter] = useState<any>()
  const chatResponseRate = recruiter?.response_rate ?? 0
  useEffect(() => {
    if (jobId) {
      fetchJobRecruiter(jobId)
        .then(res => res?.data?.data)
        .then(setRecruiter)
    }
  }, [])
  useEffect(() => {
    const debounce = (func, delay) => {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func(...args)
        }, delay)
      }
    }

    const handleWatchScrollY = () => {
      const headClientHight = document.querySelector('#head')?.clientHeight ?? 0
      let near = (document.querySelector('#Requirement') as any)?.offsetTop

      if (!isMobile) {
        near -= headClientHight
      }

      if (near) {
        setRequirementTopNear(near)
      }
    }

    const debouncedHandleWatchScrollY = debounce(handleWatchScrollY, 300)

    window.addEventListener('scroll', debouncedHandleWatchScrollY)

    return () => window.removeEventListener('scroll', debouncedHandleWatchScrollY)
  }, [isMobile])

  useEffect(() => {
    if (!recruiter?.id) {
      return
    }
    const id = recruiter?.id
    if (recruiter.last_active_at && id) {
      fetchQueryOnlineStatus({
        ids: [id]
      }).then((res) => {
        setOnline(res?.data?.data?.[0])
      })
    }
  }, [recruiter?.id])
  return (
    <section className={styles.desc} id='JobDescription'>
      {isMobile && (
        <>
          <div className={styles.desc_mobileHead}>
            <div className={styles.desc_mobileHead_info}>
              <span
                className={classNames(styles.desc_footer_name, styles.desc_mobileHead_info_name)}
              >
                {recruiter?.full_name || ''}
              </span>
              <div>
                {online?.user_id ? (
                  <span
                    className={classNames(
                      styles.desc_footer_lineStatus,
                      transStateNew(online.last_online_time, null, online.is_online)?.state !== 1
                        ? styles.desc_footer_notLine
                        : null
                    )}
                  >
                    {transStateNew(online.last_online_time, content?.state, online.is_online)?.text}
                  </span>
                ) : null}
              </div>
              {chatResponseRate > 48 && (
                <span
                  className={classNames(styles.desc_footer_chat, styles.desc_mobileHead_info_rate)}
                >
                  {chatResponseRate}% &nbsp;{content.rate}
                </span>
              )}
            </div>
            <AntImage
              src={recruiter?.avatar || DefaultAvatar}
              width={52}
              height={52}
              alt=''
              style={{ borderRadius: 26 }}
            />
          </div>
          <div className={styles.page_line}></div>
        </>
      )}

      {work_arrangement?.id == 3 && (
        <>
          <Remote {...props} />
          {/* {isMobile && <div className={styles.page_line}></div>} */}
        </>
      )}

      <div className={styles.desc_jobDescWrapper} id='jd'>
        <div className={styles.desc_title}>
          <h5>{content.JD}</h5>
        </div>

        <div className={styles.desc_labels}>
          {skills?.map((skill) => (
            <div key={skill.value} title={skill.value}>
              {skill.value}
            </div>
          ))}
        </div>
        {
          job_identity_restrictions?.length > 0 ? <div className={styles.desc_identity}>
            <div className={styles.restrictions}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M1.7998 17.8421C1.7998 19.2722 2.95915 20.4316 4.38928 20.4316H19.7103C21.1405 20.4316 22.2998 19.2722 22.2998 17.8421V3.59998H4.38928C2.95915 3.59998 1.7998 4.75932 1.7998 6.18945V17.8421ZM8.92597 11.6546C7.77436 11.6546 6.84079 10.721 6.84079 9.5694C6.84079 8.41779 7.77436 7.48422 8.92597 7.48422H11.0112V9.5694C11.0112 10.721 10.0776 11.6546 8.92597 11.6546ZM12.8153 15.8597C12.8153 15.9207 12.7652 15.9693 12.7042 15.9693H5.14773C5.08672 15.9693 5.03662 15.9207 5.03662 15.8597C5.03662 13.7117 6.77794 11.9703 8.92597 11.9703C11.074 11.9703 12.8153 13.7117 12.8153 15.8597ZM15.7959 8.46727C15.7959 7.93097 16.2307 7.49622 16.767 7.49622H18.5718C19.1081 7.49622 19.5429 7.93097 19.5429 8.46727C19.5429 9.00357 19.1081 9.43832 18.5718 9.43832L16.767 9.43832C16.2307 9.43832 15.7959 9.00357 15.7959 8.46727ZM16.767 11.181H18.5718C19.1081 11.181 19.5429 11.6158 19.5429 12.1521C19.5429 12.6884 19.1081 13.1231 18.5718 13.1231H16.767C16.2307 13.1231 15.7959 12.6883 15.7959 12.1521C15.7959 11.6158 16.2307 11.181 16.767 11.181Z" fill="#2378E5" />
              </svg>
              <p>{content.jobOpenTo}{job_identity_restrictions?.join(', ')}</p>
            </div>
          </div> : null
        }


        {!isMobile && (
          <>
            <div className={styles.desc_context}>
              <p className={styles.desc_context_title}>{content?.description} :</p>
              <div
                className={styles.desc_context_desc}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </div>

            <div className={styles.desc_context}>
              <p className={styles.desc_context_title}>{content?.requirement} :</p>
              <div
                className={styles.desc_context_desc}
                style={{ marginBottom: 0 }}
                dangerouslySetInnerHTML={{ __html: requirements }}
              ></div>
            </div>
            <div className={styles.page_line} style={{ padding: 0, marginTop: 20 }}></div>
          </>
        )}

        {isMobile && (
          <ReadMore
            key={'description'}
            expandText={content.showMore}
            shrinkText={content.showLess}
            className={styles.desc_context}
            text={`
            <div class="${styles.desc_context}">
              <p class="${styles.desc_context_title}">${content?.description} :</p>
              <div
                class="${styles.desc_context_desc}"
              >${description}</div>
            </div>
            <div class="${styles.desc_context}">
              <p class="${styles.desc_context_title}">${content?.requirement} :</p>
              <div
              class="${styles.desc_context_desc}"
              style="margin-bottom: 0;"
              >${requirements}</div>
            </div>  
          `}
            line={15}
            lineHeight={24}
            isScroll={{
              top: 0
            }}
          />
        )}

        {!isMobile && !!recruiter && (
          <div className={styles.jobseeker}>
            <div className={styles.avatatBox}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: chatResponseRate > 49 ? 0 : '20px'
                }}
              >
                <span className={styles.footer_name} title={recruiter?.full_name}>
                  {recruiter?.full_name} <i style={{ padding: '0 4px' }}>{' · '}</i>
                  {recruiter?.work_experience?.job_title || ''}
                </span>
                {online?.user_id ? (
                  <span
                    className={classNames(
                      styles.lineStatus,
                      transStateNew(online.last_online_time, null, online.is_online)?.state !== 1
                        ? styles.notLine
                        : null
                    )}
                  >
                    {transStateNew(online.last_online_time, content?.state, online.is_online)?.text}
                  </span>
                ) : null}
              </div>
              {chatResponseRate > 49 && (
                <span className={styles.chat}>
                  {chatResponseRate}% &nbsp;{content.rate}
                </span>
              )}
            </div>
            <AntImage
              src={recruiter?.avatar || DefaultAvatar}
              width={52}
              height={52}
              alt=''
              style={{ borderRadius: 52 }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default Desc
