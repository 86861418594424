"use client"
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import React, { useState, createContext, useEffect, useTransition } from 'react';
export const SortContext = createContext({ sort: '1', handleChange: null, routerLoading: false });

const Provider = SortContext.Provider
const SortProvider = ({ children }: any) => {
    const params = useSearchParams()
    const sort = params.get('sort') || '2'
    // const [sort, setSort] = useState('2')
    const router = useRouter()
    const pairs = []
    for (const p of params.entries()) {
        pairs.push(p)
    }
    const [loading, startTransition] = useTransition()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        startTransition(() => {
            const newParams = new URLSearchParams(pairs)
            newParams.set('sort', newValue)
            newParams.set('page', '1')
            const url = location.pathname + '/?' + newParams.toString()
            router.push(url)
        })
    }

    return <Provider value={{ sort, handleChange, routerLoading: loading }}>
        {children}
    </Provider>

}

export default SortProvider;