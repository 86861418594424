import React, { useState, useEffect, useRef } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import Menu from '@mui/material/Menu'

import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { FormHelperText } from '@mui/material'
import styles from 'styles/maintenance.module.scss'
import classNames from 'classnames'
import styled from '@emotion/styled'
import Image from 'next/image'
import ListItemText from '@mui/material/ListItemText'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const regroupData = (
  data,
  { categoryKey = 'category_value', valueKey = 'value', labelKey = 'label' } = {}
) => {
  const result = [...new Set([...data].map((item) => item[categoryKey]))].map((item) => ({
    category: item,
    children: [] as any
  }))

  result.forEach((item) => {
    data.forEach((el) => {
      if (el[categoryKey] === item.category) {
        item.children.push({
          [valueKey]: el.value,
          [labelKey]: el.value,
          id: el.id,
          key: el.key
        } as any)
      }
    })
  })
  return result
}

const CategoryArrow = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
    <path
      d='M6.46039 12.4592L5.54116 11.54L9.08154 7.99961L5.54116 4.45923L6.46039 3.53999L10.92 7.99961L6.46039 12.4592Z'
      fill='black'
      fillOpacity='0.6'
    />
  </svg>
)

const IndustryMenuData = ({ options, menuOpen, currentValue, useID, handleSelected }) => {
  const [index, setIndex] = useState(Math.max(regroupData(options).findIndex(item => item.children.find(item => item.value === currentValue)), 0))
  const tabRef = useRef(null)
  const contentRef = useRef(null)

  const renderTab = () => {
    const tabData = regroupData(options)
    return tabData.map((option, idx) => (
      <MenuItem
        key={idx}
        className={classNames({
          selected: index === idx
        })}
        onClick={(e) => e.stopPropagation()}
        onMouseOver={(e) => {
          e.stopPropagation()
          setIndex(idx)
        }}
      >
        <ListItemText primary={option.category} />
        <CategoryArrow />
      </MenuItem>
    ))
  }

  const renderContent = () => {
    const tabData = regroupData(options)

    const data = tabData
      .map(({ ...item }, index) =>
        item.children.map((item) => ({
          ...item,
          index
        }))
      )
      .flat()

    return data.length
      ? data.map(({ ...option }) => (
        <MenuItem
          key={option.id}
          value={useID ? option.id : option.value}
          className={currentValue === option.value ? 'selected' : ''}
          style={{
            display: index === option.index ? '' : 'none',
            // background: currentValue === option.value ? '#F5F7FB' : 'trasparent'
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleSelected({
              ...option
            })
          }}
        >
          <ListItemText primary={option.label} />
          {currentValue === option.value && <CheckedIcon />}
        </MenuItem>
      ))
      : null
  }
  const renderIndustryData = () => {
    return (
      <IndustryDataWrapped>
        <div className='tab' ref={tabRef}>{renderTab()}</div>
        <div className='content' ref={contentRef}>{renderContent()}</div>
      </IndustryDataWrapped>
    )
  }
  useEffect(() => {
    if (menuOpen) {
      const tabSelected = tabRef.current?.querySelector('.selected')
      const itemSelected = contentRef.current?.querySelector('.selected')
      setTimeout(() => {
        tabSelected?.scrollIntoView({
          block: 'center'
        })
      }, 1)
      setTimeout(() => {
        itemSelected?.scrollIntoView({
          block: 'center'
        })
      }, 100)

    }
  }, [menuOpen])
  return renderIndustryData()

}

const CheckedIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M16.1788 6.15422L8.40169 14.2554C8.24454 14.4191 8.02744 14.5116 7.80053 14.5116C7.57362 14.5116 7.35652 14.4191 7.19938 14.2554L3.82227 10.7376L5.02458 9.58333L7.80053 12.475L14.9765 5L16.1788 6.15422Z" fill="#2378E5" />
</svg>
const MaterialBasicSelect = ({
  id,
  label,
  options,
  className,
  onSelect,
  onOpen,
  greyBg,
  defaultValue,
  fieldRef,
  disabled,
  required,
  error,
  inputFontSize,
  optionCate,
  multipleValue = [],
  checkRequired = false,
  useID = false,
  hiddenLabel = false,
  isShowFlag = false,
  test = false,

  ...rest
}: any) => {
  const [value, setValue] = useState(defaultValue || '1')
  const [menuOpen, setMenuOpen] = useState(false)
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value)
    if (onSelect) {
      onSelect(event.target.value)
    }
  }
  const anchorRef = useRef(null);

  const getMenuPosition = () => {
    if (!anchorRef.current) return {}
    const { top, bottom } = anchorRef.current?.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const distanceToBottom = viewportHeight - bottom

    if (top > distanceToBottom) {
      return {
        vertical: 'top',
        horizontal: 'left',
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }
    } else {
      return {
        vertical: 'bottom',
        horizontal: 'left',
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }
    }
  };

  const MenuProps = {
    getContentAnchorEl: null,
    anchorEl: anchorRef.current,
    anchorOrigin: getMenuPosition(),
    transformOrigin: getMenuPosition().transformOrigin,
    PaperProps: {
      style: {
        width: anchorRef.current?.offsetWidth
      }
    }
  }

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            transform: 'translate(14px, 10px) scale(1)',
            letterSpacing: '1px',
            '&.Mui-focused': {
              fontSize: '10px',
              transform: 'translate(14px, -10px) scale(1)'
            },
            lineHeight: '26px',
            padding: '0 5px',
            background: '#fff'
          },
          shrink: {
            fontSize: '10px',
            transform: 'translate(14px, -10px) scale(1)'
          },
          outlined: {
            '&.MuiInputLabel-shrink': {
              fontSize: '10px'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            height: '44px',
            backgroundColor: greyBg ? '#E2E2E2' : 'white',
            lineHeight: '16px',
            alignItems: 'self-end'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: inputFontSize || '14px',
            letterSpacing: '1px'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            letterSpacing: '1px',
            padding: '10px 16px'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': {
              width: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '6px'
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              boxShadow: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '.MuiSelect-select': {
              ':focus': {
                background: 'unset'
              }
            }
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={classNames({
          [className]: true,
          [styles.hiddenLabel]: hiddenLabel
        })}
        size='small'
        error={!!error}
        fullWidth
      >
        <InputLabel
          id={`${id}-select-label`}
        // className={'testInput'}
        >
          {
            <span>
              {required ? <span style={{ color: 'red', marginRight: '5px' }}>{' *'}</span> : ''}
              {label}
            </span>
          }
        </InputLabel>
        <Select
          {...fieldRef}
          labelId={`${id}-select-label`}
          id={id}
          value={value}
          ref={anchorRef}
          label={label}
          onChange={handleChange}
          onClick={() => {
            setMenuOpen(open => !open)
          }}
          open={menuOpen}
          onOpen={onOpen}
          disabled={disabled}
          helpertext={error?.message}
          MenuProps={MenuProps}
          renderValue={optionCate === 'industry' ? value => {
            return value
          } : false}
          {...rest}
        >
          {options?.length ? (

            optionCate === 'industry' ? (
              <IndustryMenuData
                {...rest}
                useID={useID}
                menuOpen={menuOpen}
                currentValue={rest.value}
                options={options}
                handleSelected={(value) => {
                  setValue(value)
                  onSelect?.(value)
                  setMenuOpen(false)
                }}
              />
            ) : (
              options.map((option) => (
                <MenuItem key={option.value} value={useID ? option.id : option.value}>
                  {isShowFlag ? (
                    <>
                      {option.flag ? (
                        <Image
                          width={30}
                          height={22.5}
                          src={option.flag}
                          alt={`${option.country} flag`}
                          style={{
                            marginRight: '10px',
                            borderRadius: '2px',
                            border: '1.563px solid #E4E4E4'
                          }}
                        />
                      ) : null}
                      {option.label}
                    </>
                  ) : // option.label
                    checkRequired ? (
                      <>
                        {' '}
                        <Checkbox checked={multipleValue?.indexOf(option.value) >= 0} />
                        {option.label}
                      </>
                    ) : (
                      option.label
                    )}
                </MenuItem>
              ))
            )
          ) : null}
        </Select>

        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    </ThemeProvider >
  )
}
const IndustryDataWrapped = styled.div`
  display: flex;
  height: 352px;
  overflow: hidden;
  .tab {
    width: 50%;
    padding: 0 6px;
    white-space: pre-wrap;
    box-sizing: border-box;
    overflow-y: auto;
    border-right: 1px solid #e7e7e7;
    & .MuiMenuItem-root {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      min-height: 40px;
      border-radius: 3px;
      box-sizing: border-box;
      white-space: pre-wrap;
      line-height: 1.1;
      word-wrap: break-word;
      word-break: break-word;
      color: #121212;
      &:hover {
        background: #f5f7fb;
      }
      & .MuiCheckbox-root {
        padding: 0;
        margin-right: 10px;
      }
      & .MuiTypography-root {
        line-height: 1;
        font-size: 14px;
      }
    }

    & .MuiListItemText-root {
      white-space: pre-wrap;
      flex: 1;
    }
    > .selected {
      background: #f5f7fb;
    }
  }
  .content {
    width: 50%;
    padding: 0 6px;
    box-sizing: border-box;
    overflow-y: auto;
    & .MuiMenuItem-root {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      min-height: 40px;
      box-sizing: border-box;
      border-radius: 3px;
      white-space: pre-wrap;
      line-height: 1.1;
      color: #121212;
      
      &:hover {
        background: #f5f7fb;
      }
      & .MuiCheckbox-root {
        padding: 0;
        margin-right: 10px;
      }
      & .MuiTypography-root {
        line-height: 1;
        font-size: 14px;
        flex: 1;
      }
    }
    .selected {
      background: #f5f7fb;
    }

    & .MuiListItemText-root {
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      flex: 1;
    }
  }
`
export default MaterialBasicSelect
