'use client'
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useSharedData } from 'bossjob-remote/dist/hooks'

const EmptyH5 = ({ description, lang }: any) => {
  const container = useRef(null)
  const langDic = useSharedData('DICTIONARY') || {}
  const langDicH5 = langDic?.h5 || {}

  return (
    <EmptyH5Styled>
      <div ref={container}>
        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="65" viewBox="0 0 130 65" fill="none">
          <path d="M69.2108 64.2316C101.725 64.2316 128.083 57.8913 128.083 50.0699C128.083 42.2485 101.725 35.9082 69.2108 35.9082C36.6967 35.9082 10.3389 42.2485 10.3389 50.0699C10.3389 57.8913 36.6967 64.2316 69.2108 64.2316Z" fill="url(#paint0_linear_24397_4783)" />
          <path opacity="0.182" d="M22.3339 4.55527C27.4149 4.91027 31.5844 7.88112 33.1536 11.8012L34.0878 11.8664C39.0784 12.2155 42.8414 16.5443 42.4924 21.5349L42.4295 22.4327C42.0804 27.4233 37.7516 31.1864 32.761 30.8374L9.25963 29.1942C4.26902 28.8452 0.506429 24.5163 0.855454 19.5257L0.917912 18.6279C1.26694 13.6373 5.59577 9.87424 10.5864 10.2233L10.6107 10.2246C12.7104 6.56125 17.2523 4.19981 22.3339 4.55527Z" fill="url(#paint1_linear_24397_4783)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M116.714 35.5039H117.199C117.4 35.5039 117.563 35.6669 117.563 35.8681V40.8045C117.563 40.8523 117.554 40.8997 117.536 40.9439C117.517 40.9881 117.491 41.0282 117.457 41.062C117.423 41.0958 117.383 41.1227 117.339 41.141C117.294 41.1593 117.247 41.1687 117.199 41.1687H116.714C116.666 41.1687 116.619 41.1593 116.574 41.141C116.53 41.1227 116.49 41.0958 116.456 41.062C116.422 41.0282 116.396 40.9881 116.377 40.9439C116.359 40.8997 116.35 40.8523 116.35 40.8045V35.8681C116.35 35.6669 116.513 35.5039 116.714 35.5039Z" fill="#F0F0F3" fillOpacity="0.9" />
          <path fillRule="evenodd" clipRule="evenodd" d="M116.09 28.8265C115.07 30.8724 111.559 37.1484 116.479 37.8511C121.4 38.5537 121.18 34.3764 120.317 32.8816C119.454 31.3868 118.48 30.5495 118.48 28.8265C118.48 27.1029 117.11 26.7801 116.09 28.8265Z" fill="url(#paint2_linear_24397_4783)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20.8329 49.6172H21.4565C21.7183 49.6172 21.93 49.8294 21.93 50.0907V56.4746C21.9301 56.5368 21.9179 56.5983 21.8941 56.6558C21.8704 56.7132 21.8355 56.7654 21.7916 56.8094C21.7477 56.8534 21.6955 56.8883 21.6381 56.9121C21.5807 56.9359 21.5191 56.9481 21.457 56.9481H20.8324C20.7702 56.9481 20.7087 56.9359 20.6513 56.9121C20.5939 56.8883 20.5417 56.8534 20.4978 56.8094C20.4538 56.7654 20.419 56.7132 20.3953 56.6558C20.3715 56.5983 20.3593 56.5368 20.3594 56.4746V50.0907C20.3594 49.8294 20.5711 49.6172 20.8324 49.6172H20.8329Z" fill="#F0F0F3" fillOpacity="0.9" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20.0241 40.9772C18.7042 43.6256 14.1595 51.7474 20.5274 52.6567C26.8948 53.566 26.6106 48.1602 25.4937 46.2254C24.3773 44.2911 23.1166 43.2077 23.1166 40.9772C23.1166 38.7471 21.3439 38.3292 20.0241 40.9772Z" fill="url(#paint3_linear_24397_4783)" />
          <path opacity="0.302" d="M120.428 4.6497C122.956 4.82605 125.013 6.35809 125.696 8.34983C127.806 8.82331 129.311 10.7792 129.156 13.0024C128.984 15.4543 126.858 17.3033 124.405 17.132L113.911 16.3981C111.459 16.2268 109.61 14.0996 109.782 11.6477C109.953 9.1953 112.08 7.34638 114.532 7.51814L114.722 7.53146C115.691 5.68162 117.923 4.47427 120.428 4.6497Z" fill="url(#paint4_linear_24397_4783)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M64.0039 0.300781V37.6378L101.011 42.6348V9.20829L64.0039 0.300781Z" fill="#DDDDE7" />
          <path fillRule="evenodd" clipRule="evenodd" d="M36.3984 9.20829L64.0045 0.300781V33.0196L36.3984 43.0762V9.20829Z" fill="#ECECF4" />
          <path d="M36.3984 9.20898V43.0769L73.9238 54.3816V18.7209L36.3984 9.20898Z" fill="url(#paint5_linear_24397_4783)" />
          <path d="M73.9238 18.7209V54.3816L101.012 42.6355V9.20898L73.9238 18.7209Z" fill="url(#paint6_linear_24397_4783)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M36.3985 9.20898L27.333 22.6359L63.1706 33.0208L73.9238 18.7209L36.3985 9.20898Z" fill="url(#paint7_linear_24397_4783)" />
          <path fillRule="evenodd" clipRule="evenodd" d="M73.9238 18.7209L82.9076 34.2465L111.037 21.919L101.012 9.20898L73.9238 18.7209Z" fill="url(#paint8_linear_24397_4783)" />
          <defs>
            <linearGradient id="paint0_linear_24397_4783" x1="69.2108" y1="67.614" x2="69.2108" y2="38.3266" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FCFCFE" stop-opacity="0" />
              <stop offset="1" stop-color="#E8E8EE" />
            </linearGradient>
            <linearGradient id="paint1_linear_24397_4783" x1="22.7885" y1="4.58696" x2="21.0103" y2="30.0158" gradientUnits="userSpaceOnUse">
              <stop stop-color="#D0D0DF" />
              <stop offset="1" stop-color="#DEDEE6" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_24397_4783" x1="117.361" y1="27.4102" x2="117.361" y2="37.9301" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EBEBEE" />
              <stop offset="1" stop-color="#FCFCFD" />
            </linearGradient>
            <linearGradient id="paint3_linear_24397_4783" x1="21.6686" y1="39.1448" x2="21.6686" y2="52.7591" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EBEBEE" />
              <stop offset="1" stop-color="#FCFCFD" />
            </linearGradient>
            <linearGradient id="paint4_linear_24397_4783" x1="120.008" y1="4.61985" x2="119.158" y2="16.765" gradientUnits="userSpaceOnUse">
              <stop stop-color="#B0B0C4" />
              <stop offset="1" stop-color="#DEDEE6" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint5_linear_24397_4783" x1="58.7334" y1="22.8374" x2="58.7334" y2="42.8906" gradientUnits="userSpaceOnUse">
              <stop stop-color="#C2C2CE" />
              <stop offset="1" stop-color="#E4E4EB" />
            </linearGradient>
            <linearGradient id="paint6_linear_24397_4783" x1="87.4679" y1="49.0429" x2="87.4679" y2="20.8132" gradientUnits="userSpaceOnUse">
              <stop stop-color="#EEEEF3" />
              <stop offset="1" stop-color="#DDDDE5" />
            </linearGradient>
            <linearGradient id="paint7_linear_24397_4783" x1="45.7172" y1="25.908" x2="51.1136" y2="15.1152" gradientUnits="userSpaceOnUse">
              <stop stop-color="#E2E2EB" />
              <stop offset="1" stop-color="#E3E3EA" />
            </linearGradient>
            <linearGradient id="paint8_linear_24397_4783" x1="99.1676" y1="17.7881" x2="90.436" y2="30.951" gradientUnits="userSpaceOnUse">
              <stop stop-color="#ECECF1" />
              <stop offset="1" stop-color="#EBEDF3" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='description'>
        {description ? (
          description
        ) : (
          <>
            <div>{langDicH5?.filteredEmpty}</div>
          </>
        )}
      </div>
    </EmptyH5Styled>
  )
}

export default EmptyH5

const EmptyH5Styled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  text-align: center;
  margin-top: 115px;

  .description {
    margin-top: 8px;

    div {
      color: #7D7D7D;
      font-size: 14px;
    }
  }
`
