'use client'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter, usePathname } from 'next/navigation'
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import classNames from 'classnames';
import styled from '@emotion/styled';

/* Redux */
import { connect, useSelector } from 'react-redux'
import { logoutRequest } from 'store/actions/auth/logout'
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'
import { postNotification } from 'bossjob-remote/dist/clientStorage'

/* Components */
import Link from 'components/Link'
import Text from 'components/Text'

/* Helpers */
import { getCookie } from 'helpers/cookies'
import styles from './HamburgerMenu.module.scss'
import { getCountry, getCountryKey, getLanguage } from 'helpers/country'
import linkToHunt from 'helpers/linkToHunt'
import { ChangeLangAndRegion } from 'components/Header/Common/ChangeLangAndRegion'
import { getLang } from 'helpers/country'

type MenuItem = Required<MenuProps>['items'][number];

const Divider = () => <div className={styles.divider} />

interface HamburgerMenuProps {
  openState: boolean
  toggleMenu: Function
  header: any
}

const HamburgerMenu = ({ openState, toggleMenu, header }: HamburgerMenuProps) => {
  const {
    downloadApp,
    findJobs,
    companies,
    careerGuide,
    hiring,
    manageResume,
    myJobs,
    accountSettings,
    logOut,
    change,
    home,
    remoteJobs,
    recommendedJobs,
    onSiteJobs
  } = header || {}
  const pathname = usePathname()
  const router = useRouter()
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [current, setCurrent] = useState('mail');
  const [openSwitchNationModal, setOpenSwitchNationModal] = useState<boolean>(false)
  const userCookie = getCookie('user')
  const [show, setShow] = useState(false)
  const userDetail = useSelector((store: any) => store.users.fetchUserOwnDetail?.response ?? {})
  const [showUnCompletedDot, setShowUnCompletedDot] = useState(false)
  const [countryKey, setCountryKey] = useState('ph')

  const langKey = getLang()

  useEffect(() => {
    if (userDetail?.id) {
      const hasJobPreferences = userDetail?.job_preferences.length > 0
      setShowUnCompletedDot(!userDetail?.is_profile_completed || !hasJobPreferences)
    }
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [userDetail])

  // useEffect(() => {
  //   setOpenSwitchNationModal(false)
  // }, [openState])

  useEffect(() => {
    setIsAuthenticated(getCookie('sessionid') ? true : false)
  }, [userDetail])

  const handleLogOut = () => {
    // enable body scroll again
    document.body.style.position = ''
    document.body.style.top = ''
    dispatch(logoutRequest())

    if (pathname === '/') {
      location.reload()
    } else {
      router.push('/')
    }
  }

  const handleClick = () => {
    // hide switch nation modal
    if (openSwitchNationModal) {
      setOpenSwitchNationModal(false)
      return
    }

    // enable body scroll again
    document.body.style.position = ''
    document.body.style.top = ''
    toggleMenu()
  }

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const textStyle = 'base'

  return (
    <div className={openState ? [styles.mobileFullPageMenu, styles.open].join(' ') : styles.close}>
      <div className={styles.mobileFullPageMenuContainer}>
        <ul className={styles.menuListWrapper}>
          <React.Fragment>
            <Link className={styles.defaultLink} to='/talents' target='_blank' title='Bossjob App'>
              <li className={styles.menuList}
                {
                ...(
                  pathname.endsWith('/remote-jobs') && {
                    'data-gtag-event': 'rl_active_app'
                  })
                }
              >
                <Text textStyle={textStyle} className={styles.downLoadApp}>
                  {downloadApp}
                </Text>
              </li>
            </Link>
            <Divider />
            <Link className={styles.defaultLink} to='/' title='Home'>
              <li className={styles.menuList} onClick={handleClick}
                {
                ...(
                  pathname.endsWith('/remote-jobs') && {
                    'data-gtag-event': 'rl_active_home'
                  })
                }
              >
                <Text textStyle={textStyle}>{home}</Text>
              </li>
            </Link>
            {/* <Link className={styles.defaultLink} to='/jobs-hiring/job-search' title='Jobs'>
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{findJobs}</Text>
              </li>
            </Link> */}
            <div className={classNames([styles.defaultLink, styles.findJobMenu])}>
              {/* <li className={styles.menuList} > */}
              {/* <Text textStyle={textStyle}>{findJobs}----</Text> */}
              <MenuStyled onClick={onClick} mode="inline" selectable={false}>
                <Menu.SubMenu key="sub1" title={findJobs}>
                  <Menu.Item key="1">
                    <Link className={styles.defaultLink} to={'/' + langKey + '/remote-jobs-hiring/jobs'} title="Remote Jobs">
                      <li className={styles.menuList}
                        {
                        ...(
                          pathname.endsWith('/remote-jobs') && {
                            'data-gtag-event': 'rl_active_find_jobs_remote'
                          })
                        }
                      >
                        <Text textStyle={textStyle}>
                          {remoteJobs}
                        </Text>
                      </li>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link className={styles.defaultLink} to={'/' + langKey + '/jobs-hiring/job-search'} title="On-site  Jobs">
                      <li className={styles.menuList}
                        {
                        ...(
                          pathname.endsWith('/remote-jobs') && {
                            'data-gtag-event': 'rl_active_find_jobs_onsite'
                          })
                        }
                      >
                        <Text textStyle={textStyle}>
                          {onSiteJobs}
                        </Text>
                      </li>
                    </Link>
                  </Menu.Item>
                  {isAuthenticated && <Menu.Item key="3">
                    <Link className={styles.defaultLink} to={'/' + langKey + '/my-jobs?page=1'} title="Recommended Jobs">
                      <li className={styles.menuList}
                        {
                        ...(
                          pathname.endsWith('/remote-jobs') && {
                            'data-gtag-event': 'rl_active_find_jobs_recommend'
                          })
                        }
                      >
                        <Text textStyle={textStyle}>
                          {recommendedJobs}
                        </Text>
                      </li>
                    </Link>
                  </Menu.Item>}
                </Menu.SubMenu>
              </MenuStyled>
              {/* </li> */}
            </div>
            <Link className={styles.defaultLink} to='/companies' title='Companies'>
              <li className={styles.menuList} onClick={handleClick}
                {
                ...(
                  pathname.endsWith('/remote-jobs') && {
                    'data-gtag-event': 'rl_active_company'
                  })
                }
              >
                <Text textStyle={textStyle}>{companies}</Text>
              </li>
            </Link>
            <Link className={styles.defaultLink} to='/remote-jobs' title='remote-jobs'>
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}> {remoteJobs}</Text>
              </li>
            </Link>
            {isAuthenticated && (
              <>
                <Link
                  className={styles.defaultLink}
                  to='/manage-profile'
                  // to={
                  //   userCookie?.is_profile_completed
                  //     ? '/manage-profile'
                  //     : '/jobseeker-complete-profile'
                  // }
                  // to={userCookie?.is_profile_completed ? authPathToOldProject(null, '/dashboard/profile/jobseeker') : '/jobseeker-complete-profile/1'}
                  // aTag
                  title='Manage Resume'
                >
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text
                      textStyle={textStyle}
                      // className={
                      //   styles.activeLink + ' ' + (showUnCompletedDot ? styles.unCompleted : '')
                      // }
                      className={showUnCompletedDot ? styles.unCompleted : ''}
                    >
                      {manageResume}
                    </Text>
                  </li>
                </Link>
                <Link className={styles.defaultLink} to='/my-jobs?page=1' title='My Jobs'>
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text textStyle={textStyle}>{recommendedJobs}</Text>
                  </li>
                </Link>
                <Link
                  className={styles.defaultLink}
                  to='/settings'
                  title='Account Settings'
                >
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text textStyle={textStyle}>{accountSettings}</Text>
                  </li>
                </Link>
                {/* <Link className={styles.defaultLink} title='Jobs' to='/chat/list'>
                  <li className={styles.menuList} onClick={handleClick}>
                    <Text textStyle={textStyle}>{Chat}</Text>
                  </li>
                </Link> */}
              </>
            )}
            <Divider />
            {/* <Link
              className={`${styles.defaultLink}`}
              to='https://academy.bossjob.ph/courses/search-courses'
              aTag
              title='Courses'
            >
              <li className={styles.menuList} onClick={handleClick}>
                <Text textStyle={textStyle}>{courses}</Text>
              </li>
            </Link> */}
            {!isAuthenticated && (
              <>
                <Link
                  className={styles.defaultLink}
                  to={`https://blog.bossjob.${countryKey === 'jp' ? 'jp' : 'ph'}`}
                  external
                  title='Career Guide'
                >
                  <li className={styles.menuList} onClick={handleClick}
                    {
                    ...(
                      pathname.endsWith('/remote-jobs') && {
                        'data-gtag-event': 'rl_active_career_guide'
                      })
                    }
                  >
                    <Text textStyle={textStyle}>{careerGuide}</Text>
                  </li>
                </Link>
                <Divider />
                <Link
                  className={`${styles.defaultLink}`}
                  to={linkToHunt('')}
                  aTag
                  title='Employers'
                >
                  <li className={styles.menuList} onClick={handleClick}
                    {
                    ...(
                      pathname.endsWith('/remote-jobs') && {
                        'data-gtag-event': 'rl_active_employer'
                      })
                    }
                  >
                    <Text textStyle={textStyle}>{hiring}</Text>
                  </li>
                </Link>
                <Divider />
              </>
            )}
            {isAuthenticated && (
              <>
                <Link
                  className={styles.defaultLink}
                  to='https://blog.bossjob.ph/'
                  aTag
                  title='Career Guide'
                >
                  <li className={styles.menuList} onClick={handleClick}
                    {
                    ...(
                      pathname.endsWith('/remote-jobs') && {
                        'data-gtag-event': 'rl_active_career_guide'
                      })
                    }
                  >
                    <Text textStyle={textStyle}>{careerGuide}</Text>
                  </li>
                </Link>

                <Divider />

                <Link to={linkToHunt('')} aTag className={styles.defaultLink}>
                  <li className={styles.menuList}
                    {
                    ...(
                      pathname.endsWith('/remote-jobs') && {
                        'data-gtag-event': 'rl_active_employer'
                      })
                    }
                  >
                    <Text textStyle='base'>{hiring}</Text>
                  </li>
                </Link>

                <Divider />
                <div className={`${styles.defaultLink}`}>
                  <li className={styles.menuList} onClick={() => handleLogOut()}>
                    <Text textStyle={textStyle}>{logOut}</Text>
                  </li>
                </div>
              </>
            )}
            <Divider />
            <li
              className={styles.defaultLink}
              onClick={() => {
                // postNotification('SHOW_SIDE_GUIDE_MODAL')
                // handleClick()
                setShow(true)
              }}
              style={{ padding: '14px' }}
              {
              ...(
                pathname.endsWith('/remote-jobs') && {
                  'data-gtag-event': 'rl_active_country_language'
                })
              }

            >
              <div className={styles.menuList}>
                <Text textStyle={textStyle}>
                  {getCountry()}, {getLanguage()} -{' '}
                  <span style={{ color: '#136FD3' }}>{change}</span>
                </Text>
              </div>
            </li>
            <ChangeLangAndRegion
              zIndex={1002}
              show={show}
              onAction={({ show }) => {
                setShow(show)
              }}
            />
          </React.Fragment>
        </ul>
      </div>
    </div >
  )
}

const MenuStyled = styled(Menu)`
  &.ant-menu&.ant-menu-root&.ant-menu-inline&.ant-menu-light { 
    border-inline-end: none;
    margin: 0;
  }

  .ant-menu-submenu-title {
    margin: 0;
    padding-left: 14px !important;
    padding-right: 14px;
  }

  .ant-menu-submenu-title:hover {
    background-color: #fff !important;
  }

  .ant-menu-submenu {
    padding: 0;

    .ant-menu-submenu-title {
      height: 50px;

      span {
        color: #353535;
      }

      .ant-menu-submenu-arrow {
        color: #353535;
      }
    }

    .ant-menu {
      background: #F6F6F6 !important;

      .ant-menu-item {
        display: flex;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        padding: 0;
        margin: 0;
        padding-left: 14px !important;  
        padding-right: 14px;
        height: 43px;
        line-height: 43px;

        .ant-menu-title-content {
          a {
            padding: 0;
            color: #121212;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
          }
        }
      }

      .ant-menu-item:hover {
        background: #F6F6F6;
      }
    }
  }
`

const mapStateToProps = (state) => {
  return {
    openState: state.navbar.toggleMenu.menu
  }
}
const mapDispatchToProps = (dispatch) => ({
  toggleMenu: () => dispatch(toggleMenu())
})

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu)
