"use client"
import React from 'react'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import NotificationProvider from 'components/NotificationProvider'
import { store } from '../../models/store'
import MaintenancePage from 'app/maintenance/page'
import LocationProvider from './locationProvier'
import CountryProvider from './countryProvider'
import LoadingProvider from './loadingProvider'
import LanguageProvider from './languageProvider'
import LoginModalProvider from './loginModalProvider'
import { ProfileProvider } from './profileProvider'
import ContextComposer from './ContextComposer'
import ConfigProvider from './configProvider'

const VIf = props => props.show ? <>{props.children}</> : null

interface ClientProvidersProps {
    children: React.ReactNode
    lang: any
}

const ClientProviders = (props: ClientProvidersProps) => {
    const { children }: React.PropsWithChildren = props
    const { lang }: any = props
    return <Provider store={store} key={'MaintainerProvider'}>
        <VIf show={process.env.MAINTENANCE === 'true'}>
            <MaintenancePage />
        </VIf>

        <VIf show={process.env.MAINTENANCE !== 'true'}>
            <ContextComposer
                contexts={[
                    <ConfigProvider key='ConfigProvider' />,
                    <CountryProvider key={'CountryProvider'} />,
                    <CookiesProvider key={'CookiesProvider'} />,
                    <LocationProvider key={'LocationProvider'} />,
                    <LanguageProvider lang={lang} key={'LanguageProvider'} />,
                    <ProfileProvider key={'ProfileProvider'} />,
                    <NotificationProvider key={'NotificationProvider'} />,
                    <LoginModalProvider lang={lang} key={'LoginModalProvider'} />,
                    <LoadingProvider lang={lang} key={'LoadingProvider'} />,
                ]}
            >
                {children}
            </ContextComposer>
        </VIf>
    </Provider>
   
}

export default ClientProviders