
import { S3BucketJobseeker } from 'images'
const baseUrl = `${S3BucketJobseeker}/remote_page/images/user/`

export const userImg = {
  alex: baseUrl + 'alex.png',
  carlos: baseUrl + 'carlos.png',
  Linda: baseUrl + 'Linda.png',
  emily: baseUrl + 'emily.png',
  mark: baseUrl + 'mark.png'
}