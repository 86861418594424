/* Components */
import Link from 'components/Link'
import Image from 'next/image'
/* Helpers */
import { formatTemplateString, truncateWords } from 'helpers/formatter'

// Styles
import styles from '../Companies.module.scss'
import { useMemo } from 'react'
import { getValueById } from 'helpers/config/getValueById'
import { CompanyDetail } from './../typed'
import { isMobile } from 'react-device-detect'
import { setCookie } from 'helpers/cookies'
import { getDeviceUuid } from 'helpers/guest'
import { useLanguage } from 'app/components/providers/languageProvider'
import {
  ValueOfCompanySourceTracker,
  getDeviceNameTracker,
  setCompanyViewCookiesTracker,
  viewCompanySourceTracker
} from 'components/ReportTracker/util'
import { Tooltip } from 'antd'

interface ICompanyCard {
  company: CompanyDetail
  transitions: Record<string, any>
  langKey: string
  config: any
  source?: ValueOfCompanySourceTracker
  lang: any
}

const CompanyCard = (props: ICompanyCard) => {
  const { company, transitions, langKey, config, lang } = props

  const companyUrl = company?.company_url || '/'
  const { search } = useLanguage()

  const viewJobString = () => {
    return formatTemplateString(transitions.allJobs, {
      totalActiveJobs: `<span>${company.num_of_active_jobs || company.total_num_of_active_remote_jobs
        }</span>`
    })
  }

  const sendViewCompany = async () => {
    const device_udid = await getDeviceUuid()
    const params = {
      id: company?.id,
      payload: {
        source: props?.source || viewCompanySourceTracker.remoteCompanies,
        device: getDeviceNameTracker(isMobile),
        country_id: company?.country_id || '',
        device_udid,
        reco_from: company?.reco_from || null
      }
    }
    setCompanyViewCookiesTracker(setCookie, params)
  }

  const industryValue = useMemo(() => {
    return getValueById(config, company?.industry_id, 'industry_id') || company?.industry
  }, [company?.industry])
  return (
    <Link className={isMobile ? '' : styles.compnayCardWrapper}
      to={'/' + langKey + companyUrl}
      target='_blank'
      onClick={sendViewCompany}
    >
      <div className={styles.companyCard}>
        <div className={styles.companyCardLeft}>
          <div

            className={styles.companyCardImage}
          >
            <Image
              fill={true}
              objectFit='cover'
              src={company?.logo_url || company?.logo}
              alt={company?.name}
            />
          </div>
          <div className={styles.companyVerify}>
            <Tooltip title={lang.search.verifiedCompanyBadge}>
              <Image
                alt={'fill'}
                fill
                src={`${process.env.S3_BUCKET_URL}/companies/verify1.svg`}
              ></Image>
            </Tooltip>

          </div>
        </div>
        <div className={styles.companyCardRight}>
          <div className={styles.companyCardName}>
            <Link
              to={'/' + langKey + companyUrl}
              title={company?.name}
              target='_blank'
              onClick={sendViewCompany}
            >
              {truncateWords(company.name, 60)}
            </Link>
          </div>
          <p className={styles.companyCardCategory} title={industryValue}>
            {industryValue}
          </p>
          <div className={styles.split}>
            <div className={styles.split_hover}></div>
          </div>
          <Link
            to={`/${langKey}${companyUrl}/remoteJobs`}
            target='_blank'
            className={styles.companyCardOpenings}
            onClick={sendViewCompany}
          >
            <p dangerouslySetInnerHTML={{ __html: viewJobString() }}></p>
            <span>{search.viewMore}</span>
          </Link>
        </div>
      </div>
      <Link
        to={`/${langKey}${companyUrl}/remoteJobs`}
        target='_blank'
        className={styles.companyCardOpenings + ' ' + styles.mobileOpenings}
        onClick={sendViewCompany}
      >
        <p dangerouslySetInnerHTML={{ __html: viewJobString() }}></p>
      </Link>
    </Link>
  )
}

export default CompanyCard
