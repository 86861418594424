'use client'
import React, { useState, useEffect } from 'react'
import styles from '../../index.module.scss'
import Pagination from '../Pagination'
import JobCard from '../jobCard'
import MobileTable from './mobile'
import Empty from 'app/components/empty/empty'
import { uniq } from 'lodash-es'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import { getAlertData } from '../jobAlert/getAlertData'
import { isEmpty } from 'lodash-es'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { submitFeedbackService } from 'store/services/alerts/submitFB'
import Toast from 'app/components/Toast'
import { isMobile } from 'react-device-detect'

const getFirstValue = (value: any) => {
  return Array.isArray(value) ? value[0] : value
}

function SubmitFeedback({ searchValues }) {
  const sharedConfig = useSharedData('CONFIG')
  const config = isEmpty(sharedConfig) ? {} : sharedConfig
  const [disable, setDisable] = useState(false)
  const getIdsFromAlertData = (objData: Record<string, any>) => {
    const result: Record<string, any> = {};
    Object.keys(objData).forEach(key => {
      const value = objData[key]
      if (value && key.includes('ids')) {
        key = key.replace('ids', 'id');
        result[key] = value
      }
    })

    return result
  }



  return <div className={styles.EmptyResultDesc}>
    No jobs found, Sorry about that - we're working hard to add new jobs that match.
    <a
      data-disabled={disable}
      onClick={
        !disable ? () => {
          setDisable(true)
          const alertData = getAlertData(searchValues, config)
          const keyword = getFirstValue(alertData.keyword);
          const idObj = getIdsFromAlertData(alertData)
          idObj.is_remote = 1
          if (keyword) {
            idObj.keyword = keyword
          }
          submitFeedbackService({ ...idObj, source: isMobile ? "mobile_web" : "web" }).then(error => {
            Toast.success('Successful!  Thank you for your feedback.')
          })
          // console.log({ idObj })
        } : null
      }>
      {" Submit feedback"}
    </a>
  </div>

}

const Main = (props: any) => {
  const { page, totalPages, searchValues } = props

  const [jobs, setJobs] = useState(props.jobs)
  useEffect(() => {
    if (props.jobs?.length > 0) {
      getRecruiterIsOnline(props.jobs)
    }
  }, [props])
  const getRecruiterIsOnline = (oldData) => {
    const Ids = uniq(oldData.map((e) => e.recruiter_id))
    fetchQueryOnlineStatus({
      ids: Ids
    })
      .then((res) => {
        const data = res.data.data
        oldData.map((e) => {
          data.map((k) => {
            if (e.recruiter_id == k.user_id) {
              e.recruiter_is_online = k.is_online
              e.recruiter_last_active_at = k.last_online_time
            }
          })
        })
        setJobs([...oldData])
      })
      .catch((e) => console.log(e))
  }


  return (
    <>
      {jobs?.length ? (
        <div className={styles.tableContainer}>
          {jobs.map((job) => {
            return (
              <div className={styles.jobContainer} key={job?.id}>
                <JobCard {...job} />
              </div>
            )
          })}
          {totalPages > 1 ? <Pagination count={+totalPages} page={+page} /> : null}
        </div>
      ) : (
        <Empty {...props} description={<SubmitFeedback searchValues={searchValues} />} />
      )}
      <div>{jobs?.length ? <MobileTable {...props} /> : null}</div>
    </>
  )
}

export default Main
