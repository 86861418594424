import { HTMLProps } from 'react'
import styles from './company.module.scss'
import classNames from 'classnames'

// want color some text? wrap it with .coloredText class.
export const SectionTitle = ({ innerHTML }) => {
  return <div className={styles.sectionTitle} dangerouslySetInnerHTML={{ __html: innerHTML }}></div>
}

export const SectionSubtitle = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props
  return <div className={classNames(styles.subtitle, className)} {...rest}>
  </div>
}