'use client'
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { isMobileDevice } from 'helpers/utilities';

import { ReactComponent as ResumeIcon } from '../../images/svg/resume.svg';
import { ReactComponent as ResumeSuccessIcon } from '../../images/svg/resume_success.svg';
import Step from '../Step';
import SafeInfo from '../Common/SafeInfo';
import CompleteProfileH5 from './h5';
import { jumpPage } from '../utils';

const CompleteProfile = (props: {
  lang: any
}) => {
  const { lang } = props;

  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const steps = [
    {
      title: profile.steps?.text1,
      content: 'First-content',
    },
    {
      title: profile.steps?.text2,
      content: 'Second-content',
    }
  ];

  const [countdown, setCountdown] = useState(3);
  const isMobile = isMobileDevice()

  if (isMobile) {
    return <CompleteProfileH5 lang={lang} />
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    if (countdown === 0) {
      jumpPage && jumpPage()
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [countdown]);

  const handleBack = () => {
    jumpPage && jumpPage()
  }

  return (
    <CompleteProfileWrap>
      <div className='profileStep'>
        <Step items={steps} current={1} />
      </div>
      <div className='profileWrap'>
        <ResumeIcon className="resumeIcon" />
        <h1>{profile.attachedResume?.title}</h1>
        <div className="successMessage">
          <ResumeSuccessIcon />
          <p className="successText">{profile.completeProfile?.info}</p>
        </div>
        <div className='backBtn' onClick={handleBack}>
          <p className="countdownText">{profile.completeProfile?.button} ({countdown})</p>
        </div>
      </div>
      <SafeInfo lang={lang} />
    </CompleteProfileWrap>
  );
};

const CompleteProfileWrap = styled.div`
  display: flex;
  width: 600px;
  margin: 48px auto;
  flex-direction: column;
  
  .profileWrap {
    min-height: 600px;
    padding: 32px 48px;
    border-radius: 12px;
    background: linear-gradient(168deg, #E5F1FF 1.67%, #FFF 11.82%, #FFF 91.29%);
    margin-bottom: 48px;
  }

  .profileStep {
    margin-bottom: 24px;
    padding-left: 48px;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
  }

  p.subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div.successMessage {
    display: flex;
    position: relative;
    margin-top: 40px;
    margin-bottom: 56px;
    height: 225px;
    padding: 36px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 4px;
    background: #FCFCFC;

    p.successText {
      color: #121212;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.135px;
    }
  }

  p.countdownText {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  .backBtn {
    display: flex;
    height: 44px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 30px;
    background: #2378E5;
    cursor: pointer;
  }

  svg.resumeIcon {
    width: 48px;
    height: 48px;
    margin-bottom: 32px;
  }
`;

export default CompleteProfile;