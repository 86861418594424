'use client'
import React, { useEffect, useState, useContext } from 'react'
import styles from '../index.module.scss'
import { useSearchParams } from 'next/navigation'
import BasicInformation from './basicInformation'
import WorkExperience from './workExperience'
import EducationExperience from './educationExperience'
import DesiredJob from './desiredJob'
import { useSelector } from 'react-redux'
import { getCookie, handleUserCookiesConfig, setCookie } from 'helpers/cookies'
import { fetchUserOwnDetailRequest } from 'store/actions/users/fetchUserOwnDetail'
import { useDispatch } from 'react-redux'
import Header from './header'
import { LinkContext } from 'app/components/providers/linkProvider'
import { getLang } from 'helpers/country'
import Stepper from './stepper'
import { seekerBanner } from 'images'
import { useMediaQuery } from '@mui/material'
import { formatTemplateString, truncateWords } from 'helpers/formatter'
import MaintainNotification from 'components/MaintainNotification'

const Main = (props: any) => {
  const sessionid = getCookie('sessionid')
  const { push } = useContext(LinkContext)
  const isMobile = useMediaQuery('(max-width:768px)')

  const langKey = getLang()
  if (!sessionid) {
    push(`/${langKey}/get-started?`)
  }
  const searchParams = useSearchParams()
  const search = searchParams.get('step')
  const getUserInfo = () => {
    dispatch(fetchUserOwnDetailRequest())
  }
  useEffect(() => {
    getUserInfo()
  }, [])
  const [newProps, setNewProps] = useState<any>({ ...props, getUserInfo })
  const [step, setStep] = useState(1)
  const userDetail = useSelector((store: any) => store?.users.fetchUserOwnDetail.response)

  const dispatch = useDispatch()

  const { securityGuideLink, guideLink } = props.lang.profile

  useEffect(() => {
    const hasStep = [1, 2, 3, 4, 5, 6, 7].includes(+search)
    if (search && hasStep) {
      setStep(Number(search))
    }
  }, [search])

  useEffect(() => {
    setNewProps({
      ...props,
      userDetail,
      getUserInfo,
      step
    })
    if (userDetail?.id) {
      const userCookie = handleUserCookiesConfig(userDetail)
      setCookie('user', userCookie)
    }
  }, [JSON.stringify(userDetail)])

  // const newProps = {...props,userDetail,getUserInfo}

  return (
    <div className={styles.profile}>
      <Header lang={props.lang} langKey={langKey} step={step} />
      <MaintainNotification lang={props.lang?.header} langKey={langKey} />
      <div className={styles.bg}></div>
      <div className={styles.banner}>
        <div
          className={styles.bannerBox}
          style={{
            backgroundImage: `url(${seekerBanner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: isMobile ? 'cover' : '100% 100%'
          }}
        >
          <div className={styles.bannerMain}>
            <h2>{props?.lang?.profile?.updateYouronLineResume} </h2>
            {/* <p>{props?.lang?.profile?.getMoreRelevantJobRecommendations} </p> */}
            <div style={{
              display: 'flex',
              marginTop: 10,
              alignItems: 'center',
              fontSize: 16,
              color: '#515151'
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
                <path d="M14.9792 3.05287L14.2539 3.04108C14.2362 3.04108 12.6128 3.0057 10.9344 2.32568C9.21274 1.62798 8.10427 0.826103 8.09445 0.816277L7.65617 0.499851L7.22182 0.816277C7.21199 0.824138 6.10156 1.62601 4.38185 2.32568C2.70539 3.0057 1.08002 3.04108 1.0643 3.04108L0.337113 3.05287L0.337113 10.4309C0.337113 14.1356 5.09921 18.0526 7.65813 18.0526C10.219 18.0526 14.9811 14.1356 14.9811 10.4309C14.9811 9.69647 14.982 8.54132 14.9811 6.46796C14.9805 4.93336 14.9792 3.05287 14.9792 3.05287Z" fill="#6CABFF" />
                <path d="M4.42709 9.11199L7.01698 11.7297L11.6072 6.85778" stroke="white" strokeWidth="1.57577" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              {!isMobile ? <p style={{
                marginLeft: 7,
                textTransform: 'none',
                fontSize: 16
              }}
                dangerouslySetInnerHTML={{
                  __html: formatTemplateString(securityGuideLink, {
                    guideLink: `<a
                    href="https://bossjob.crisp.help/en/article/bossjob-user-data-guide-19m0q4y/"
                    target="_blank"
                    style="
                      margin-left: 5px;
                      text-decoration: underline;
                      color: #2378E5
                    ">${formatTemplateString(guideLink, {})}</a>`
                  })
                }}
              /> : <p style={{
                marginLeft: 7,
                marginTop: -7,
                textTransform: 'none',
                fontSize: 12
              }}
                dangerouslySetInnerHTML={{
                  __html: formatTemplateString(securityGuideLink, {
                    guideLink: `<a
                    href="https://bossjob.crisp.help/en/article/bossjob-user-data-guide-19m0q4y/"
                    target="_blank"
                    style="
                      text-decoration: underline;
                      color: #2378E5
                    ">${formatTemplateString(guideLink, {})}</a>`
                  })
                }}
              />}

              {/* <a href="#" style={{
                  marginLeft: 7,
                  textDecoration: 'underline',
                  color: '#2378E5'
                }}>Bossjob User Date Guide</a></p> */}
            </div>

          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.steps}>
          <Stepper step={step - 1} lang={props.lang} />
        </div>
        <div className={styles.rightContainer}>
          {step === 1 && <BasicInformation {...newProps} />}
          {step === 2 && <WorkExperience {...newProps} />}
          {step === 3 && <EducationExperience {...newProps} />}
          {step === 4 && <DesiredJob {...newProps} />}
        </div>
      </div>
    </div >
  )
}

export default Main
