"use client"
import React, { useMemo, Suspense } from 'react'
import dynamic from 'next/dynamic'
import { isEmpty } from 'lodash-es'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getAlertData, sortSearchValuesToString, getSearchFiltersIds } from '../jobAlert/getAlertData'

import { decoder } from '../../../interpreters/encoder'
const JobAlert = dynamic(() => import('components/jobAlert'), { ssr: false })

const AlertWrapper = (props) => {
  const config = useSharedData('CONFIG')
  const searchValues = useMemo(() => {
    if (isEmpty(config)) {
      return null
    }
    let all = decoder(config)(props.params.path, props.searchParams) as any
    if (all.query && Array.isArray(all.query)) {
      all.query = all.query.join()
    }

    if (all.remote_country_ids && all.remote_country_ids == '') {
      delete all.remote_country_ids
    }
    return all
  }, [props.params.path, props.searchParams])

  return searchValues ? (
    <Suspense>
      <JobAlert searchValues={searchValues} getAlertData={getAlertData} sortSearchValuesToString={sortSearchValuesToString} getSearchFiltersIds={getSearchFiltersIds} />
    </Suspense>
  ) : null
}

export default AlertWrapper