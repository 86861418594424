'use client'
import React, { useRef, useEffect } from 'react'
import styles from './index.module.scss'
import lottie from 'lottie-web'
import excellentResumeData from 'images/notResult.json'
import { throttle } from 'lodash-es'
const Empty = ({ description, lang }: any) => {
  const container = useRef(null)
  useEffect(() => {
    // let animation
    // const loadAnimation = throttle(() => {
    //   animation = lottie.loadAnimation({
    //     container: container.current,
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: true,
    //     animationData: excellentResumeData
    //   })
    // }, 100)

    // loadAnimation()
  }, [])
  return (
    <div className={styles.container}>
      <div ref={container} className={styles.jsonContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="120" viewBox="0 0 115 91" fill="none">
          <path d="M52 34L14 41V73L52 66V34Z" fill="#E4EBFB" />
          <path d="M52 34L81 41V73L52 66V34Z" fill="#CBD6ED" />
          <path d="M31.9029 40.4741L27.4697 44.9073L27.9183 47.81L33.6709 49.5516L57.6048 45.9629V44.1421L56.0743 39.2339L47.2871 43.9837L37.6819 40.4741L36.1514 45.4087L35.5181 44.5643L31.9029 40.4741Z" fill="#ECEEF9" />
          <path d="M14 41L43 48V80L14 73V41Z" fill="#CBD6ED" />
          <path d="M48.4467 39.4321L44.251 42.7834L47.2856 44.8681L51.0063 42.7834L48.4467 39.4321Z" fill="white" />
          <path d="M81 41L43 48V80L81 73V41Z" fill="#D5DFF5" />
          <path d="M14 41L43 48L31 58L2 51L14 41Z" fill="#D5DFF5" />
          <path d="M37.1277 43.0601L33.9744 45.8016L40.7265 47.4417L37.1277 43.0601Z" fill="white" />
          <path d="M81 41L43 48L53 63L91 56L81 41Z" fill="#E4EBFB" />
          <path d="M100.754 77.9521C91.4652 77.6988 85.7302 74.1523 84.0238 72.4106L100.754 74.0467L109.515 75.2606C107.896 76.1578 103.878 77.9521 100.754 77.9521Z" fill="#D3D9ED" />
          <path d="M53.3856 86.8412C47.5591 84.8146 45.6451 77.8693 45.4164 74.6499L53.755 80.8775L71.4349 83.0941C67.4943 84.0265 58.3676 86.0812 53.3856 86.8412Z" fill="#D3D9ED" />
          <path d="M63.2256 11.606L53.3564 21.6334L61.5367 30.0775L71.4058 20.2084L63.2256 11.606Z" fill="#F0F4FF" />
          <path d="M53.8252 85.2579C47.5765 82.3869 45.768 76.9897 45.6449 74.6499C50.2946 75.9518 53.0997 76.4069 58.5222 76.7082C60.671 80.4908 63.4777 81.9212 71.9273 82.9885C68.3738 83.4987 59.7783 84.6668 53.8252 85.2579Z" fill="#F7F9FF" />
          <path d="M96.1578 68.7622C93.709 69.9444 86.834 71.6121 83.7026 72.2982C91.9885 78.6313 105.341 77.3119 110.724 68.7622C102.385 73.3537 98.1105 69.7649 96.1578 68.7622Z" fill="#F7F9FF" />
          <path d="M110.281 48.666H107.22L106.218 49.4049V51.3576C106.992 51.2872 108.677 51.1359 109.226 51.0937C109.775 51.0515 110.158 51.7446 110.281 52.0964L111.02 54.313C111.126 53.363 111.369 51.157 111.495 49.9326C111.622 48.7082 110.739 48.578 110.281 48.666Z" fill="#2378E5" />
          <path d="M79.0875 25.1504C75.4725 25.9222 72.4701 27.7399 71.4208 28.5522V35.3051L72.2839 39.367L74.1117 46.2722L77.8182 50.9433L91.8824 52.5173L107.165 51.7049L107.47 48.3539C104.61 48.1677 97.9447 47.6024 94.1672 46.8307C90.3897 46.0589 88.0575 43.1919 87.3636 41.8549L83.3525 35.9144L85.9419 33.985C87.262 33.1388 90.41 30.7152 92.4409 27.7907C94.4718 24.8661 94.0657 20.0731 93.6087 18.0422L91.9332 6.82129L89.1914 7.63365C89.1068 8.25986 88.8969 10.3145 88.7345 13.5234C88.5314 17.5345 88.2267 18.9561 87.3636 21.0886C86.5004 23.2211 83.6064 24.1857 79.0875 25.1504Z" fill="#39425D" />
          <path d="M64.8708 32.005C59.3061 37.3261 57.881 43.1244 57.8641 45.3584C64.3123 45.5446 77.3306 45.328 77.8181 42.9721C78.4273 40.0272 79.6459 39.8242 80.1029 39.5703C80.5598 39.3164 81.2199 37.6917 81.0168 35.153C80.8137 32.6143 79.2397 30.0249 78.2242 29.5679C77.2088 29.111 71.8268 25.3537 64.8708 32.005Z" fill="#2378E5" />
          <path d="M89.3465 5.52334L89.584 7.66078L91.5631 7.26492C91.5016 6.82512 91.3731 5.86108 91.352 5.52334C91.3309 5.18561 91.484 5.11872 91.5631 5.12749L93.0936 4.01922C92.258 3.97524 90.4284 3.91367 89.7951 4.01922C89.1618 4.12477 89.2322 5.06595 89.3465 5.52334Z" fill="#2378E5" />
          <path d="M80.7175 38.4028C78.1843 38.4873 72.7308 41.8508 70.3207 43.5221L78.0787 42.2027C77.9908 42.0268 78.0471 41.4744 78.9759 40.6722C80.137 39.6695 80.7175 39.6167 80.7175 38.4028Z" fill="#1C5BAE" />
          <path d="M45.1859 47.5915L48.4163 47.0115C49.1552 46.6597 51.336 46.0652 54.1225 45.6008C57.6057 45.0202 63.5694 50.6145 65.3638 51.1423C66.7993 51.5645 66.5249 53.6403 66.2082 54.6255C64.2379 54.9773 59.4107 55.776 55.8641 56.156C54.0909 51.8917 47.9831 48.6118 45.1859 47.5915Z" fill="url(#paint0_linear_3224_17403)" fillOpacity="0.7" />
          <path d="M54.7244 51.729C52.4445 48.9847 48.0746 47.1023 46.1747 46.5042C48.0043 46.1172 52.6978 45.2482 56.8354 44.8682C58.9465 45.3431 65.2796 50.5152 67.6017 52.7318C66.124 54.5473 59.8085 55.1067 56.8354 55.1595C57.0817 55.1595 57.0043 54.4734 54.7244 51.729Z" fill="white" />
          <defs>
            <linearGradient id="paint0_linear_3224_17403" x1="51.1135" y1="50.8573" x2="57.9744" y2="48.3614" gradientUnits="userSpaceOnUse">
              <stop stopColor="#97A8DB" />
              <stop offset="1" stopColor="#97A8DB" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className={styles.description}>
        {description ? (
          description
        ) : (
          <>
            <div>{lang?.noResultsFound}</div>
            <div className={styles.pleaseUseDifferentKeyword}>
              {lang?.pleaseUseDifferentKeyword}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Empty
