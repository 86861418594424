/* eslint-disable react/no-unknown-property */
'use client'
import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from 'react'
import styles from './web.module.scss'
import useChatNow from 'app/models/hooks/useChatNow'
import Image from 'next/image'
import classNames from 'classnames'
import MaterialButton from 'components/MaterialButton'
import Text from 'components/Text'
import { postSaveJobService } from 'store/services/jobs/postSaveJob'
import { deleteSaveJobService } from 'store/services/jobs/deleteSaveJob'
import { getCookie, setCookie, userKey } from 'helpers/cookies'
import { fetchJobDetailService } from 'store/services/jobs/fetchJobDetail'
import { CircularProgress } from 'app/components/MUIs'
import { useParams, useRouter } from 'next/navigation'
import useNotSuitable from './hooks'
import NotSuitableModal from '../notSuitable'
import { ChatInfoContext } from 'app/components/chatInfoProvider'
import { useSelector } from 'react-redux'
import { changeJobValue } from 'helpers/config/changeJobValue'
import { getValueById } from 'helpers/config/getValueById'
import { languageContext, useLanguage } from 'app/components/providers/languageProvider'
import { QRCodeSVG } from 'qrcode.react'
import { SortContext } from '../searchForms/SortProvider'
import { cloneDeep } from 'lodash-es'
import { isMobile } from 'react-device-detect'
import { getDeviceUuid } from 'helpers/guest'
import ShowSalary from 'app/components/ShowSalary'
import QrCodeDraw from 'components/QrCodeDraw/QrCodeDraw'
import { CloseIcon, HeartIcon } from './CloseIcon'
import {
  getDeviceNameTracker,
  setCompanyViewCookiesTracker,
  setJobViewCookiesTracker,
  viewCompanySourceTracker,
  viewJobSourceTracker
} from 'components/ReportTracker/util'
import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
import { addJobViewService } from 'store/services/jobs/addJobView'

const useShowPop = (titleHover, popHover) => {
  const [showPopup, setShowPopup] = useState(false)
  const titleHoverRef = useRef(titleHover)
  const popHoverRef = useRef(popHover)

  useEffect(() => {
    titleHoverRef.current = titleHover
    popHoverRef.current = popHover
  }, [titleHover, popHover])
  const timerRef = useRef<any>()
  const closeTimerRef = useRef<any>()

  useEffect(() => {
    if (titleHover && !popHover) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      timerRef.current = setTimeout(() => {
        if (titleHoverRef.current) {
          setShowPopup(true)
        }
        clearTimeout(timerRef.current)
        timerRef.current = null
      }, 300)
    } else if (!titleHover && !popHover) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      if (closeTimerRef.current) {
        clearTimeout(closeTimerRef.current)
        closeTimerRef.current = null
      }
      closeTimerRef.current = setTimeout(() => {
        if (!titleHoverRef.current && !popHoverRef.current) {
          setShowPopup(false)
        }
        clearTimeout(closeTimerRef.current)
        closeTimerRef.current = null
      }, 500)
    }
  }, [titleHover, popHover])
  return showPopup
}

const useSaveJob = (jobId, defaultSaved, jobTitleId) => {
  const [isSaved, setIsSaved] = useState(defaultSaved)
  const [isSaving, setIsSaving] = useState(false)
  const sessionid = getCookie('sessionid')
  const router = useRouter()
  const { lang: langKey } = useParams()
  const save = useCallback(() => {
    if (isSaving) {
      return
    }
    if (!sessionid) {
      router.push(`/${langKey}` + '/get-started', { scroll: true })
      return
    }
    if (!isSaved) {
      setIsSaving(true)
      postSaveJobService({ job_id: jobId, job_title_id: jobTitleId })
        .then(() => setIsSaved(true))
        .finally(() => setIsSaving(false))
    } else {
      setIsSaving(true)
      deleteSaveJobService(jobId)
        .then(() => setIsSaved(false))
        .finally(() => setIsSaving(false))
    }
  }, [isSaved, jobId, sessionid, isSaving])

  return [isSaved, isSaving, save]
}

const useJobDetail = (jobId) => {
  const [detailLoading, setDetailLoading] = useState(false)
  const [jobDetail, setJobDetail] = useState(null)
  const startLoading = useCallback(() => {
    if (!detailLoading && !jobDetail) {
      setDetailLoading(true)
      fetchJobDetailService({ jobId })
        .then((response) => setJobDetail(response.data.data))
        .finally(() => setDetailLoading(false))
    }
  }, [detailLoading, jobDetail])
  return [jobDetail, detailLoading, startLoading]
}

const Labels = ({ data = [] }) => {
  return (
    <div className={styles.componylabels}>
      {data.map((label) => (
        <div key={label} className={styles.label} title={label}>
          {label}
        </div>
      ))}
    </div>
  )
}
const JobCard = (props: any) => {
  const { sort } = useContext(SortContext)
  const { job: originJob, jobTitleId, preference, dictionary } = props
  const { lang: langKey } = useParams()
  const config = useSelector((store: any) => store.config.config.response)
  const memoedJob = useMemo(() => {
    const job = cloneDeep(originJob)
    changeJobValue(config, job)
    return job
  }, [originJob])

  const jobBenefits = useMemo(() => {
    const benefits = originJob?.job_benefits || []
    return benefits
      .map((benefit) => {
        return getValueById(config, benefit?.id, 'job_benefit_id', 'name')
      })
      .filter(Boolean)
      .join(' | ')
  }, [originJob?.job_benefits, config])

  const {
    job_title,
    local_salary_range_value: salary_range_value,

    // job_type,
    // job_location,
    // xp_lvl,
    // degree,
    recruiter_full_name,
    recruiter_job_title,
    recruiter_is_online,
    job_skills,
    company_logo,
    company_name,
    //  company_industry,
    //   company_size,
    //   company_financing_stage,
    recruiter_avatar,
    job_benefits,
    external_apply_url,
    id,
    recruiter_id,
    is_saved,
    job_url,
    company_url,
    is_urgent,
    job_type_id,
    job_location_id,
    xp_lvl_id,
    degree_id,
    company_industry_id,
    company_size_id,
    company_financing_stage_id,
    reco_from,
    company_id,
    is_remote_worldwide,
    work_arrangement_id,
    remote_countries,
    is_all_location
  } = memoedJob
  const { chatInfos } = useContext(ChatInfoContext)
  const chat = useMemo(() => {
    return chatInfos.find((chatInfo) => chatInfo.recruiter_id === recruiter_id)
  }, [chatInfos])
  const { search, companyDetail } = useContext(languageContext) as any
  const dic = useLanguage()
  const labels = [
    getValueById(config, job_type_id, 'job_type_id'),
    work_arrangement_id == 3
      ? is_remote_worldwide
        ? companyDetail?.overview?.worldwide
        : remote_countries
          .map((e) => getValueById(config, e.id, 'country_id'))
          ?.filter((e) => e)
          ?.join(',')
      : getSingaporeAddress(is_all_location, dic.home.allRegions, getValueById(config, job_location_id, 'location_id')),
    // getValueById(config, job_location_id, 'location_id'),
    getValueById(config, xp_lvl_id, 'xp_lvl_id'),
    getValueById(config, degree_id, 'degree_id')
  ].filter((a) => a)
  const companyLabels = [
    getValueById(config, company_industry_id, 'industry_id'),
    getValueById(config, company_size_id, 'company_size_id'),
    getValueById(config, company_financing_stage_id, 'company_financing_stage_id')
  ].filter((a) => a)
  const router = useRouter()
  const [loading, chatNow, modalChange] = useChatNow({ ...originJob, chat }, "recommended_jobs")
  const [titleHover, setTitleHover] = useState(false)
  const [popHover, setPopHover] = useState(false)

  const showPopup = useShowPop(titleHover, popHover)
  const sessionid = getCookie('sessionid')
  const [isSaved, isSaving, save] = useSaveJob(id, is_saved, jobTitleId)
  const [jobDetail, detailLoading, startLoading] = useJobDetail(id)
  const userInfo = getCookie(userKey)

  useEffect(() => {
    if (showPopup && !jobDetail && !detailLoading) {
      startLoading()
    }
  }, [showPopup, jobDetail, detailLoading])

  const modalProps = useNotSuitable(preference?.id, id)
  const { showSelection, refreshing } = modalProps
  const [isChatHover, setIsChatHover] = useState(false)

  const handleRouterToPath = (job_url: string) => {
    setJobViewCookiesTracker(setCookie, {
      source:
        sort == '1'
          ? viewJobSourceTracker.myJobsForLatest
          : viewJobSourceTracker.myJobsForRecommend,
      reco_from: reco_from || null,
      query_id: originJob?.query_id ? originJob?.query_id : null
    })
    router.push(`/${langKey}` + job_url, { scroll: true })
  }

  useEffect(() => {
    if (showPopup) {
      addView()
    }
  }, [showPopup])

  const addView = async () => {
    const device_udid = await getDeviceUuid()
    addJobViewService({
      jobId: id,
      source: sort == '1'
        ? viewJobSourceTracker.myJobsForLatest
        : viewJobSourceTracker.myJobsForRecommend,
      status: 'public',
      device: getDeviceNameTracker(isMobile),
      device_udid,
      is_login: sessionid ? 1 : 0,
      reco_from: reco_from || null,
      query_id: originJob?.query_id ? originJob?.query_id : null
    })
  }

  const sendViewCompany = async () => {
    const device_udid = await getDeviceUuid()
    const params = {
      id: company_id,
      payload: {
        source:
          sort == '2'
            ? viewCompanySourceTracker.myJobsForRecommend
            : viewCompanySourceTracker.myJobsForLatest,
        device: getDeviceNameTracker(isMobile),
        device_udid
      }
    }

    const url = `/${langKey}` + company_url
    setCompanyViewCookiesTracker(setCookie, params)
    window.location.href = url
  }

  const skills = (job_skills ?? '').split(',').join(' | ')

  return (
    <div
      className={classNames({
        [styles.jobCard]: true,
        [styles.aboutDisappear]: refreshing
      })}
    // onClick={() => handleRouterToPath(job_url)}
    >
      <>
        <div
          id={'job_card_container_' + id}
          className={styles.container}
          onClick={() => handleRouterToPath(job_url)}
        >
          <div
            className={styles.closeButton}
            onClick={(ev) => {
              ev.stopPropagation()
              showSelection()
            }}
          >
            <CloseIcon />
          </div>
          <div className={styles.topContainer}>
            <div className={styles.left}>
              <div
                key={job_title + id}
                onMouseEnter={() => setTitleHover(true)}
                onMouseLeave={() => setTitleHover(false)}
                className={styles.titleContainer}
                title={`${job_title}`}
              >
                {work_arrangement_id == 3 ? <div className={styles.remote}>{search.remote}</div> : null}

                {!!is_urgent ? <div className={styles.urgent}>{search.urgent}</div> : null}
                <div className={styles.title}>{`${job_title}`}</div>
              </div>
              <div className={styles.salary}>
                <ShowSalary dictionary={{
                  salaryType: dictionary?.salaryType
                }} jobs={memoedJob} size={12} />
                {/* {salary_range_value} */}
              </div>
              <div className={styles.labelContainer}>
                {labels.map((label) => (
                  <div key={label} className={styles.label} title={label}>
                    {label}
                  </div>
                ))}
              </div>
              <div
                className={styles.recruiterContainer}
                onMouseEnter={() => setIsChatHover(true)}
                onMouseLeave={() => setIsChatHover(false)}
              >
                <div style={{ height: 24, display: 'flex', flexDirection: 'row' }}>
                  <div className={styles.imgContainer}>
                    <Image
                      src={recruiter_avatar}
                      alt={''}
                      style={{ overflow: 'hidden', borderRadius: 12, marginRight: 6 }}
                      width={24}
                      height={24}
                    ></Image>
                    {!!recruiter_is_online && <div className={styles.isOnline} />}
                  </div>

                  <div className={styles.recruiter}>
                    <div
                      className={classNames({
                        [styles.info]: true,
                        [styles.isHover]: isChatHover
                      })}
                    >
                      {`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
                    </div>
                    {userInfo?.id != jobDetail?.recruiter?.id && (
                      <MaterialButton
                        className={classNames({
                          [styles.button]: true,
                          [styles.isHover]: isChatHover
                        })}
                        capitalize={true}
                        variant='outlined'
                        style={{ height: 25, textTransform: 'capitalize' }}
                        isLoading={loading as boolean}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                            ; (chatNow as any)()
                        }}
                      >
                        <Text textColor='white' bold>
                          {external_apply_url
                            ? search.jobCard.apply
                            : chat?.is_exists
                              ? search.jobCard.continue
                              : search.jobCard.chat}
                        </Text>
                      </MaterialButton>
                    )}
                  </div>
                  {!!recruiter_is_online && (
                    <div className={styles.online}>{search.jobCard.online}</div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={styles.right}
              onClick={(e) => {
                e.stopPropagation()
                sendViewCompany()
              }}
            >
              <div className={styles.company}>
                <Image className={styles.logo} src={company_logo} width={50} height={50} alt='' />
                <div className={styles.labelContainer}>
                  <div className={styles.name}>{company_name}</div>
                  <Labels data={companyLabels} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.skills} title={skills}>
              {skills}
            </div>
            <div
              className={styles.benefits}
              title={'benefits'}
              onClick={(e) => {
                e.stopPropagation()
                sendViewCompany()
              }}
            >
              {jobBenefits}
            </div>
          </div>
        </div>

        {/* popup sections  */}
        {showPopup && (
          <div
            className={classNames({
              [styles.popupDetail]: true,
              [styles.hide]: !showPopup
            })}
            onMouseEnter={() => setPopHover(true)}
            onMouseLeave={() => setPopHover(false)}
          >
            <div className={styles.popTop}>
              <div className={styles.popTopLeft}>
                <div title={`${job_title}`} className={styles.title}>
                  {`${job_title}`}
                </div>
                <div
                  className={styles.secondLabel}
                  title={`${[recruiter_full_name, recruiter_job_title]
                    .filter((a) => a)
                    .join(' · ')}`}
                >
                  {`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
                </div>
                <MaterialButton
                  className={classNames({
                    [styles.save]: true
                  })}
                  capitalize={true}
                  variant='outlined'
                  style={{ textTransform: 'capitalize' }}
                  isLoading={isSaving}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                      ; (save as any)()
                  }}
                >
                  <HeartIcon isSaved={isSaved} />
                  <Text textColor='#136FD3' bold style={{ marginLeft: 8 }}>
                    {isSaved ? search?.jobCard?.saved : search?.jobCard?.save}
                  </Text>
                </MaterialButton>
              </div>

              <div className={styles.popTopRight}>
                {/* <QRCodeSVG value={location?.origin + job_url} size={60} className={styles.qrcode} /> */}
                <QrCodeDraw
                  className={styles.qrcode}
                  text={location?.origin + job_url}
                  ecl='H'
                  width={60}
                  height={60}
                />
                Talk to Boss anywhere
              </div>
            </div>
            <div className={styles.popContent}>
              {detailLoading ? (
                <CircularProgress color={'primary'} size={20} />
              ) : (
                <>
                  <div className={styles.desTitle}>Job Description</div>
                  <div
                    className={styles.detail}
                    dangerouslySetInnerHTML={{
                      __html: jobDetail?.job_description_html ?? ''
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}

        {modalChange}
      </>
      <NotSuitableModal {...modalProps} lang={props.lang} />
    </div>
  )
}

export default JobCard
