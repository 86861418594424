'use client'
import { useRouter } from 'next/navigation'
import { BossjobLogo } from 'images'
import { Button } from 'app/components/MUIs'
import styles from '../index.module.scss'
import { getCookie, removeUserCookie } from 'helpers/cookies'
import Loading from './loading'
import { watchSharedData, postNotification } from 'bossjob-remote/dist/clientStorage'
import { LogoIcon } from 'components/Header/Common/NavLogo'
export default function Error(props: { error: any; reset: () => void }) {
  let { reset, error } = props
  const router = useRouter()
  if (error.digest === '193452068') {
    const refresh = getCookie('refreshToken')
    function redirectToGetStarted() {
      removeUserCookie()
      router.push('/get-started', { scroll: true })
    }
    if (!refresh) {
      redirectToGetStarted()
      return <Loading />
    }
    const cancel = watchSharedData('MODULE_LOADED', module => {
      const success = module['remote-login-modal']
      if (success) {
        postNotification('REQUEST_401')
        cancel()
      }
    })
    const revoker = watchSharedData('REFRESH_TOKEN_TASK',
      task => {
        task?.data?.then?.(() => {
          revoker()
          window.location.reload()
        }).catch((e) => {
          redirectToGetStarted()
        })
      }
    )
    return <Loading />
  }

  return (
    <section className={styles.errorMain}>
      <div className={styles.errorMain_loadingLogo}>
        <LogoIcon />
        {/* <img src={BossjobLogo} title='Bossjob logo' alt='Bossjob logo' /> */}
      </div>
      <h2>Something went wrong!</h2>
      <Button
        variant='outlined'
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => {
            reset?.();
            window.location.reload()
          }
        }
        sx={{
          textTransform: 'capitalize',
          marginRight: '15px'
        }}
      >
        Try again
      </Button>
      <Button
        variant='contained'
        onClick={() => router.push('/')}
        sx={{
          textTransform: 'capitalize'
        }}
      >
        Go Home
      </Button>
    </section>
  )
}
