/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
'use client'
import { useEffect, useState, useRef, useContext, useMemo } from 'react'

import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'
import JobDetail from '../JobDetail/JobDetailForRemoteHomePage'
import { fetchPopularJobs } from 'store/services/jobs/fetchRemoteJobs'
import styles from 'app/index.module.scss'
import { getCookie } from 'helpers/cookies'
import Link from 'components/Link'
import {
  fetchJobsForYouLoginRemote,
  fetchJobsPreferences
} from 'store/services/jobs/fetchJobsForYouLogin'
// import { languageContext } from 'app/components/providers/languageProvider'
import { getValueById } from 'helpers/config/getValueById'
import { throttle } from 'lodash-es'
import { backTopBtn } from 'images/svg'
import { useSelector } from 'react-redux'
import RemotePrefModal from 'components/RemotePrefModal'
import Empty from 'app/components/empty/empty'
import ShowSalary from 'app/components/ShowSalary'
import { reportTrackerJobCounts } from 'components/ReportTracker/JobCountTracker'
import { jobCountSourceTracker } from 'components/ReportTracker/util'

import vip_user_icon_url from './vip_user_icon.png'

import { SectionSubtitle, SectionTitle } from '../SectionTitle'
import { formatTemplateString } from 'helpers/formatter'
import tabStyles from './tabs.module.scss'
import { ContainedTab } from './ContainedTab'
import { isMobile } from 'react-device-detect'
import { message } from 'antd'
// import { StyledMuiTabs } from './MUITabs'

const SkeletonLoading = () => {
  return <Box sx={{ width: '100%' }}>
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((e) => (
      <Skeleton
        key={e}
        width={'100%'}
        height={20}
        animation='wave'
        sx={{ margin: '10px 0' }}
      />
    ))}
  </Box>
}

const Tabs = ({ config, dictionary, location_id, langKey }: any) => {
  // const lang = useContext(languageContext) as any
  const { home, search, comm, remotePage } = dictionary || {}
  const { tab, jobTab } = home
  const [showBtn, setShowBtn] = useState<boolean>(false)
  const userDetail = useSelector((store: any) => store?.users.fetchUserOwnDetail.response)
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', useFn)

    return () => {
      window.removeEventListener('scroll', useFn)
    }
  }, [])

  const useFn = throttle(() => {
    getScrollTop()
  }, 500)

  const getScrollTop = () => {
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop
    if (scrollTopHeight > 960) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const tabList = useMemo(() => {
    return [
      {
        tab: tab.IT,
        value: 1
      },
      {
        tab: 'Web3',
        value: 25
      },
      { tab: tab['Sales'], value: 5 },
      { value: 16, tab: "Marketing" }
    ]
  }, []);

  const tabListLogin = useMemo(() => {
    return [
      {
        tab: jobTab.reco,
        value: '2'
      },
      {
        tab: jobTab.latestJob,
        value: '1'
      }
    ]
  }, [])

  const [value, setValue] = useState<any>(null)
  const [list, setList] = useState<Array<any>>([])
  // const [open, setOpen] = useState<boolean>(false)
  // const [message, setMessage] = useState<String>('')
  const [loading, setLoading] = useState<boolean>(true)

  const sessionid = getCookie('sessionid')
  const user = getCookie('user')
  const [newTabList, setNewTabList] = useState<Array<any>>([])
  const jobseekerPrefIdRef = useRef(null)
  const [jobSeekerPreference, setJobSeekerPreference] = useState<any>()

  useEffect(() => {
    if (sessionid) {
      getJobseekerPref()
      // setNewTabList(tabListLogin)
    } else {
      setNewTabList(tabList)
      setValue(1)
    }
  }, [sessionid])

  useEffect(() => {
    if (value) {
      if (sessionid) {
        getList()
      } else {
        handleFetchPopularJobs()
      }
    }
  }, [value, location_id])

  const getJobseekerPref = async () => {
    const perData = await fetchJobsPreferences()
    const dataFilter: any = perData?.data?.data.filter((e) => e.work_arrangement?.id == 3) || []
    jobseekerPrefIdRef.current = dataFilter?.[0]
    setJobSeekerPreference(dataFilter?.[0])

    if (jobseekerPrefIdRef.current) {
      setNewTabList(tabListLogin)
    } else {
      setNewTabList([])
    }
    setValue('2')
  }

  const getList = async () => {
    if (jobseekerPrefIdRef.current && jobseekerPrefIdRef.current?.work_arrangement?.id == 3) {
      fetchJobsLogin()
    } else {
      fetchJobsLoginNoPerferse()
    }
  }
  const fetchJobsLogin = () => {
    if (value === '1' || value === '2') {
      setLoading(true)
      fetchJobsForYouLoginRemote(
        {
          jobseekerPrefId: jobseekerPrefIdRef.current?.id,
          page: 1,
          size: 6,
          sort: value
        }
      )
        .then((res) => {
          const data = res?.data?.data?.jobs
          setList(data ?? [])
          const jobsIds = (data ?? []).map((item) => item?.id).join(',')
          reportTrackerJobCounts({ source: jobCountSourceTracker.remoteHome, job_ids: jobsIds })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const fetchJobsLoginNoPerferse = () => {
    setLoading(true)
    const params = {
      size: 6,
      sort: value,
      source: 'web'
    }
    fetchPopularJobs(params)
      .then((res) => {
        const data = res?.data?.data
        setList(data.jobs ?? [])
        const jobsIds = (data.jobs ?? []).map((item) => item?.id).join(',')
        reportTrackerJobCounts({ source: jobCountSourceTracker.remoteHome, job_ids: jobsIds })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleFetchPopularJobs = () => {
    setLoading(true)
    const params = {
      size: 6,
      page: 1,
      //  remote_country_ids: location_id,
      main_function_ids: value
    }

    fetchPopularJobs(params)
      .then(({ data: data }) => {
        setList(data?.data?.jobs ?? [])
        const jobsIds = (data?.data?.jobs ?? []).map((item) => item?.id).join(',')
        reportTrackerJobCounts({ source: jobCountSourceTracker.remoteHome, job_ids: jobsIds })
      })
      .catch((error) => {
        // setMessage(error?.message ?? 'Get job list error')
        // setOpen(true)
        message.error(error?.message ?? 'Get job list error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePopularJobSearch = (search: any) => {
    //
  }

  const scrollTopFun = () => {
    document.documentElement.scrollIntoView({
      behavior: 'smooth'
    })
  }

  return (
    <div className={`${styles.popularJobsBox} ${styles.popularJobsBoxRemote}`}>
      <div style={{ padding: '80px 0 48px 0' }}>
        <SectionTitle innerHTML={`
        ${sessionid ?
            formatTemplateString(remotePage?.remoteJobForYou, {
              Remote:
                `<span class='coloredText'>${comm?.Remote}</span>`
            })

            : formatTemplateString(remotePage?.popularRemoteJobs, {
              Remote:
                `<span class='coloredText'>${comm?.Remote}</span>`
            })
          }`}>
        </SectionTitle>
        <SectionSubtitle
          style={{ marginTop: 12 }}
        >
          {remotePage?.findNextCareer}
          {/* Explore a World of Top Remote Opportunities and Find Your Next Career Move */}
        </SectionSubtitle>
      </div>
      <div className={styles.webTab}>
        <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper', backgroundColor: 'transparent' }}>
          <>
            {
              !sessionid && <ContainedTab
                newTabList={newTabList}
                onChange={setValue}
                value={value}
              />
            }
            {
              sessionid && <div style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                <ContainedTab value={value} onChange={setValue}
                  newTabList={newTabList} />
              </div>
            }

            {sessionid ? (
              <div className={styles.preference}>
                {jobseekerPrefIdRef.current ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {user?.avatar ? (
                      !userDetail?.vip?.is_vip ? (
                        <img src={user?.avatar} />
                      ) : (
                        <div className={styles.vipAvatar}>
                          <img
                            src={vip_user_icon_url.src}
                            width='15'
                            height='6'
                            alt=''
                            style={{
                              position: 'absolute',
                              bottom: '-2px',
                              right: '-17px',
                              border: 0,
                              borderRadius: 0,
                              height: 'auto'
                            }}
                          />
                          <img src={user?.avatar} />
                        </div>
                      )
                    ) : null}
                    <div>
                      {home.jobPreference}:{' '}
                      {jobSeekerPreference?.work_arrangement?.id == '3' ? (
                        <Link

                          href={`/${langKey}/manage-profile?tab=job-preferences`}
                          className={styles.link}
                        >
                          {home.remote} |{' '}
                          {getValueById(
                            config,
                            jobseekerPrefIdRef.current?.function_job_title_id,
                            'function_job_title_id'
                          )}{' '}
                          |{' '}
                          <ShowSalary dictionary={dictionary} jobs={jobseekerPrefIdRef.current} size={13} showTip={false} />
                        </Link>
                      ) : (
                        <span
                          className={styles.link}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenModal(true)}
                        >
                          {home.pleaseSetYourRemoteWorkingPreferences}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <p>
                    {home.improveRecommend}{' '}
                    <span
                      className={styles.link}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setOpenModal(true)}
                    >
                      {home.pleaseSetYourRemoteWorkingPreferences}
                    </span>
                  </p>
                )}
              </div>
            ) : null}
          </>
          <div className={styles.tabContainer} style={{ paddingTop: 40 }}>
            {!loading ? (
              <div className={tabStyles.jobListForRemoteHoemPage}>
                {list?.map((item) => (
                  <JobDetail
                    config={config}
                    langKey={langKey}
                    key={item.id}
                    detail={item}
                    tabValue={value}
                    prefJobTitle={jobseekerPrefIdRef.current?.function_job_title_id}
                  />
                ))}
              </div>
            ) : (
              <SkeletonLoading />
            )}

            {!loading && list?.length === 0 ? (
              <Empty lang={search} />
            ) : (
              <div className={styles.tabContainer_more} style={{ margin: "40px 0 80px 0" }}>
                <Link
                  className={tabStyles.seeMoreRemoteJobsBtn}
                  href={`/${langKey}/remote-jobs-hiring/jobs`}
                  data-gtag-event="rl_active_more_jobs"
                >
                  {home.seeMoreBtn}
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M9.63811 2.19526C9.37776 1.93491 8.95565 1.93491 8.6953 2.19526C8.43495 2.45561 8.43496 2.87772 8.6953 3.13807L12.8906 7.33333L2.50004 7.33333C2.13185 7.33333 1.83337 7.63181 1.83337 8C1.83337 8.36819 2.13185 8.66667 2.50004 8.66667L12.8906 8.66667L8.6953 12.8619C8.43495 13.1223 8.43495 13.5444 8.6953 13.8047C8.95565 14.0651 9.37776 14.0651 9.63811 13.8047L14.9714 8.4714C15.2318 8.21106 15.2318 7.78895 14.9714 7.5286L9.63811 2.19526Z" fill="#1B66C9" />
                  </svg>
                </Link>
              </div>
            )}
          </div>
        </Box>
      </div>

      <div className={styles.mobileTab}>
        {tabList.map((item) => (
          <Button
            key={item.tab}
            variant='outlined'
            className={styles.mobileTab_btn}
            sx={{
              width: 'calc(50% - 5px)',
              marginBottom: '15px',
              borderColor: 'transparent',
              height: '30px',
              background: '#ffffff',
              borderRadius: '16px',
              fontWeight: '400',
              fontSize: '14px',
              alignItems: 'center',
              textAlign: 'center',
              letterSpacing: '0.0075em',
              color: '#136fd3'
            }}
            onClick={() => handlePopularJobSearch(item.value)}
          >
            {item.tab}
          </Button>
        ))}
      </div>

      {/* <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert severity='error'>{message}</Alert>
      </Snackbar> */}
      {
        showBtn && (
          <div className={styles.backBtn} onClick={() => scrollTopFun()}>
            {backTopBtn}
          </div>
        )
      }
      <RemotePrefModal
        lang={dictionary}
        open={openModal}
        configs={config}
        onClose={() => setOpenModal(false)}
        onFinish={() => {
          setOpenModal(false)
          getJobseekerPref()
        }}
      />
    </div >
  )
}

export default Tabs
