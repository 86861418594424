import React, { useState, useEffect, useContext } from 'react'
import styles from '../index.module.scss'

import { DefaultAvatar } from 'images'
import { Avatar } from '@mui/material'
import { compressImage } from 'helpers/imageCompression'
import { Controller, useForm } from 'react-hook-form'
import MaterialTextField from 'components/MaterialTextField'
import avatar1 from '../images/1.png'
import avatar2 from '../images/2.png'
import avatar3 from '../images/3.png'
import avatar4 from '../images/4.png'
import avatar5 from '../images/5.png'
import FootBtn from './footBtn'
import { cameraSVG } from 'images/svg'
import { uploadUserAvatarService } from 'store/services/users/uploadUserAvatar'
import { updateUserCompleteProfileService } from 'store/services/users/updateUserCompleteProfile'
import { LinkContext } from 'app/components/providers/linkProvider'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { usePathname } from 'next/navigation'
import { getCountryKey } from 'helpers/country'
import { addEventTagForH5 } from 'helpers/utilities'
import { isMobile } from 'react-device-detect'
const identityTagEvent = {
  1: 'sgh5_register_first_pr',
  2: 'sgh5_register_first_passholder',
  3: 'sgh5_register_first_foreigner'
}

const avatarList = [avatar1, avatar2, avatar3, avatar4, avatar5]
const BasicInformation = (props: any) => {
  const {
    config: { notice_period_lists: noticePeriodLists, user_identity_lists },
    lang: { profile },
    userDetail,
    getUserInfo,
  } = props

  const initSingaporeIdentity = user_identity_lists.length ? user_identity_lists[0].id : ''

  const countryKey = getCountryKey()
  const { push } = useContext(LinkContext)
  const isExperienced = typeof window !== 'undefined' ? (sessionStorage.getItem('isExperienced') || '1') : '1'
  const [selectedAvatar, setSelectedAvatar] = useState(null)
  const [selectedAvatarDefault, setSelectedAvatarDefault] = useState<number>(-1)
  const [selectedAvailability, setSelectedAvailability] = useState<number>(5)
  const [selectedExperienced, setSelectedExperienced] = useState<string>(isExperienced)
  const [selectedSingaporeIdentity, setSingaporeIdentity] = useState<string>(initSingaporeIdentity)

  const [preview, setPreview] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    basicInformation,
    profilePhoto,
    uploadAphoto,
    name,
    IAm,
    singaporeIdentity,
    Next1,
    back,
    availability,
    firstName,
    lastName,
    experienced,
    freshGraduate,
    thisFieldIsRequired,
    skip,
    basicInfoUserName,
    availabilityTips
  } = profile || {}

  const pathname = usePathname()
  const experiencedList = [
    {
      label: experienced,
      value: '1'
    },
    {
      label: freshGraduate,
      value: '2'
    }
  ]

  const { handleSubmit, setValue, control } = useForm({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  })
  useEffect(() => {
    if (userDetail?.id) {
      const { avatar, first_name, last_name, notice_period_id } = userDetail
      setPreview(avatar)
      setValue('firstName', first_name)
      setValue('lastName', last_name)
      setSelectedAvailability(notice_period_id || 5)
    }
  }, [userDetail])

  const handleChosenPhoto = async (e) => {
    const file = e.target.files[0]
    let img
    let aspectRatio
    if (file) {
      img = new Image()
      const objectUrl = URL.createObjectURL(file)
      img.onload = async function () {
        aspectRatio = this.width / this.height
        const compressedFile = await compressImage(file, 100, aspectRatio, 400) // 100kb
        const preview = URL.createObjectURL(compressedFile)
        let createNewFromData
        if ((compressedFile as any)?.name == undefined) {
          createNewFromData = new File([compressedFile], file.name, { type: 'image/*' })
          setSelectedAvatar(createNewFromData)
        } else {
          setSelectedAvatar(compressedFile)
        }
        setPreview(preview)
        URL.revokeObjectURL(objectUrl)
      }
      img.src = objectUrl
      setSelectedAvatarDefault(-1)
    }
  }
  const handleChoosePhoto = () => {
    document.getElementById('uploadUserAvatar').click()
  }

  const handleUpdateProfile = async (data) => {
    const { firstName, lastName } = data || {}
    const payload = {
      first_name: firstName,
      last_name: lastName,
      notice_period_id: selectedAvailability,
      is_fresh_graduate: selectedExperienced === '2' ? 1 : 0 //0 有经验的，1应届生
    }

    if (countryKey === 'sg') {
      payload['user_identity_id'] = selectedSingaporeIdentity
      const tagEvent = identityTagEvent[selectedSingaporeIdentity]
      addEventTagForH5('sgh5_register_first', tagEvent)
    }

    setLoading(true)
    if (selectedAvatar) {
      await uploadUserAvatarService(selectedAvatar)
    }

    updateUserCompleteProfileService(payload)
      .then((res) => {
        if (res.data) {
          getUserInfo?.()
          let url = `${pathname}?step=2`
          sessionStorage.removeItem('isExperienced')
          if (selectedExperienced === '2') {
            sessionStorage.setItem('isExperienced', '2')
            url = `${pathname}?step=3`
          }
          push(url)
          // router.push(url)
        }
      })
      .finally(() => setLoading(false))
  }

  const changeAvator = async (item, index) => {
    setSelectedAvatarDefault(index)
    const xhr = new XMLHttpRequest()
    xhr.open('get', item.src, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      if (this.status == 200) {
        const file = new File([this.response], item.src, { type: 'image/*' })
        setSelectedAvatar(file)
      }
    }
    xhr.send()
  }

  return (
    <>
      <div className={styles.basicInfo}>
        <div className={styles.topModule}>
          <div className={styles.headerInfo}>
            <h2>{basicInformation}</h2>
            {/* <p>{theseInformationWillBeShown}</p> */}
          </div>
          <div className={styles.container}>
            <h3>{profilePhoto}</h3>
            <p className={styles.uploadTips}>{uploadAphoto}</p>
            <ul className={styles.avatarList}>
              <li className={`${selectedAvatarDefault === -1 ? styles.active : ''}`}>
                <div className={styles.uploadAvatarDisplay} onClick={handleChoosePhoto}>
                  <Avatar sx={{ width: '64px', height: '64px' }} src={preview || DefaultAvatar} />
                  <input
                    id='uploadUserAvatar'
                    accept='image/*'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={handleChosenPhoto}
                  />
                  <button className={styles.uploadAvatarButton}>{cameraSVG}</button>
                </div>
                {selectedAvatarDefault === -1 && (
                  <span className={styles.selected}>
                    <CheckCircleRoundedIcon
                      style={{
                        color: '#5BB647',
                        fontSize: '28px',
                        position: 'relative',
                        top: '-2px',
                        left: '-2px'
                      }}
                    />
                  </span>
                )}
              </li>
              {avatarList.map((item, index) => (
                <li
                  key={index}
                  onClick={() => changeAvator(item, index)}
                  className={`${selectedAvatarDefault === index ? styles.active : ''}`}
                >
                  <Avatar sx={{ width: '100%', height: '100%' }} src={item.src || DefaultAvatar} />
                  {selectedAvatarDefault === index && (
                    <span className={styles.selected}>
                      <CheckCircleRoundedIcon
                        style={{
                          color: '#5BB647',
                          fontSize: '28px',
                          position: 'relative',
                          top: '-2px',
                          left: '-2px'
                        }}
                      />
                    </span>
                  )}
                </li>
              ))}
            </ul>
            {/* <p className={styles.photoTips}>
              <InfoOutlinedIcon
                sx={{
                  fontSize: '16px',
                  color: '#FE574A',
                  marginRight: '4px'
                }}
              />
              {havingArealPhoto}
            </p> */}

            <div className={styles.nameBox}>
              <p className={styles.name}>
                <span>*</span> {name}
              </p>
              <p style={{
                color: '#707070',
                fontSize: 14,
                marginBottom: 15
              }}>{basicInfoUserName}</p>
              <div>
                <div className={styles.nameFlex}>
                  <div className={styles.namesFirst}>
                    <Controller
                      control={control}
                      name={'firstName'}
                      rules={{ required: thisFieldIsRequired }}
                      render={({ field, fieldState }) => {
                        return (
                          <MaterialTextField
                            className={styles.stepFullwidth}
                            // label={firstName}
                            placeholder={firstName}
                            variant='standard'
                            maxLength={50}
                            required
                            {...fieldState}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className={styles.namesFirst}>
                    <Controller
                      control={control}
                      name={'lastName'}
                      rules={{ required: thisFieldIsRequired }}
                      render={({ field, fieldState }) => {
                        return (
                          <MaterialTextField
                            className={styles.stepFullwidth}
                            // label={lastName}
                            placeholder={lastName}
                            variant='standard'
                            maxLength={50}
                            required
                            {...fieldState}
                            {...field}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.Im}>
              <p className={styles.name}>
                <span>*</span> {IAm}
              </p>
              <div className={styles.btnList} style={{ flexWrap: 'nowrap' }}>
                {experiencedList.map((item) => (
                  <button
                    key={item.value}
                    onClick={() => setSelectedExperienced(item.value)}
                    className={`${styles.btn}  ${selectedExperienced === item.value ? styles.active : ''
                      }`}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>

            {
              countryKey === 'sg' ? <div className={styles.singaporeIdentity}>
                <p className={styles.name}>
                  <span>*</span> {singaporeIdentity}
                </p>
                <div className={styles.btnList}>
                  {user_identity_lists.map((item) => (
                    <button
                      key={item.id}
                      onClick={() => setSingaporeIdentity(item.id)}
                      className={`${styles.btn}  ${selectedSingaporeIdentity === item.id ? styles.active : ''
                        }`}
                    >
                      {item.value}
                    </button>
                  ))}
                </div>
              </div> : null
            }

            <div className={styles.availability}>
              <p className={styles.name}>
                <span>*</span>
                {availability}
              </p>
              <p style={{
                color: '#707070',
                fontSize: 14,
                marginBottom: 15
              }}>
                {availabilityTips}
              </p>
              <div className={styles.btnList}>
                {noticePeriodLists.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => setSelectedAvailability(item.id)}
                    className={`${item.id === selectedAvailability ? styles.active : ''} ${styles.btn
                      }`}
                  >
                    {item.value}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <FootBtn
          showBack={isMobile}
          loading={loading}
          backText={back}
          rightText={Next1}
          skipText={skip}
          handleClick={handleSubmit(handleUpdateProfile)}
        />
      </div>
    </>
  )
}

export default BasicInformation
