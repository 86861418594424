import React from 'react'
import Link from 'components/Link'
import linkToHunt from 'helpers/linkToHunt'
import { LogoIcon } from 'components/Header/Common/NavLogo'

import styles from '../../common.module.scss'

export const CommonHeader = (props: {
  lang: any;
  langKey: string;
}) => {

  const { lang } = props;

  return (
    <header className={styles.header}>
      <Link className={styles.logo} href={'/'}>
        <LogoIcon />
      </Link>
      <div>
        <Link className={styles.link} href={linkToHunt('boss')}>
          <button className={styles.button}>
            {lang?.profile?.ImHiring}
          </button>
        </Link>
      </div>
    </header>
  )
}

export default CommonHeader