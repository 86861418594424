'use client'
import React, { Suspense, useEffect } from 'react'
import { useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import MaterialTextField from 'components/MaterialTextField'
import { addJobViewService } from 'store/services/jobs/addJobView'

import { buildQuery } from 'app/(main-page)/helper'
import styles from '../../../page.module.scss'
import dynamic from 'next/dynamic'
import { getCookie, removeCookie } from 'helpers/cookies'
import { getDeviceUuid } from 'helpers/guest'
import {
  JOB_VIEW_BURIED_COOKIE,
  getDeviceNameTracker,
  viewJobSourceTracker
} from 'components/ReportTracker/util'
import { isMobile } from 'react-device-detect'

type pagePropsType = {
  jobId?: number
  searchLang?: any
}
const SearchIcon = dynamic(() => import('@mui/icons-material/Search'), { ssr: true })

const Search = ({ jobId, searchLang }: pagePropsType) => {
  const router = useRouter()
  const { location, setLocation } = useSharedData('LOCATION_CONTEXT') ?? {}
  const [searchValue, setSearchValue] = useState<string>('')
  const token = getCookie('sessionid')
  const viewJobTracker = getCookie(JOB_VIEW_BURIED_COOKIE)
  const searchParams = useSearchParams()
  const handleUpdatePath = () => {
    const path = buildQuery(location?.seo_value, searchValue)
    router.push(path)
  }
  useEffect(() => {
    if (jobId) {
      handleFetchAddJobViewService()
    }
  }, [jobId])
  const handleFetchAddJobViewService = async () => {
    try {
      if (!viewJobTracker) return
      const query = {
        jobId,
        status: 'public',
      }

      if (token) {
        query.status = token ? 'protected' : 'public'
      }

      const { source, reco_from, query_id } = viewJobTracker || {}
      const deviceUuid = await getDeviceUuid()
      const tokenData = {
        source: searchParams.get('source') || source || viewJobSourceTracker.jobsHiring,
        device: getDeviceNameTracker(isMobile),
        reco_from: reco_from || null,
        device_udid: deviceUuid,
        is_login: token ? 1 : 0,
        query_id: query_id || null
      }
      if (!tokenData.reco_from) {
        delete tokenData.reco_from
      }

      const params = Object.assign(query, tokenData)

      addJobViewService(params).then(() => {
        removeCookie(JOB_VIEW_BURIED_COOKIE)
      })
    } catch (error) {
      //
    }
  }
  return (
    <section className={styles.search}>
      <div className={styles.inputBox}>
        <MaterialTextField
          className={styles.search_field}
          label={
            <div className={styles.search_label}>
              <Suspense>
                <SearchIcon sx={{ width: '16px', height: '16px', marginRight: '3px' }} />
              </Suspense>
              <span>{searchLang.title}</span>
            </div>
          }
          variant='outlined'
          size='small'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target?.value)}
          onKeyUp={(e) => {
            if (e.code == 'Enter' || e.keyCode === 13) {
              handleUpdatePath()
            }
          }}
          maxLength={60}
          sx={{
            fontSize: '16px',
            top: '8px',
            '> .MuiOutlinedInput-root': {
              borderRadius: '8px  0 0 8px',
              fontSize: '16px',
              '> .MuiOutlinedInput-notchedOutline': {
                borderWidth: '0.5px'
              }
            },
            '> .MuiInputLabel-root': {
              fontSize: '16px'
            }
          }}
        />
      </div>
    </section>
  )
}

export default Search
