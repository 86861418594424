import { flatMap } from 'lodash-es'
import { getValueById } from 'helpers/config/getValueById'

export const getRemoteSearchFiltersIds = (searchValues) => {
  const {
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    salary_range_filter_ids,
    company_size_ids,
    company_financing_stage_ids,
    country_ids,
    language_ids,
    timezone_ids,
    is_company_verified
  } = searchValues
  return {
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    salary_range_filter_ids,
    company_size_ids,
    company_financing_stage_ids,
    remote_country_ids: country_ids,
    language_ids,
    timezone_ids,
    is_company_verified
  }
}

export const getSearchFiltersIds = (searchValues) => {
  const {
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    salary_range_filter_ids,
    company_size_ids,
    company_financing_stage_ids,
    country_ids
  } = searchValues
  return {
    ...searchValues,
    location_ids,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    industry_ids,
    xp_lvl_ids,
    degree_ids,
    job_type_ids,
    salary_range_filter_ids,
    company_size_ids,
    company_financing_stage_ids,
    remote_country_ids: country_ids
  }
}

export const formatJobAlertFilter = (config, job, isRemote = false) => {
  const {
    locations,
    main_job_functions,
    job_functions,
    function_job_titles,
    industries,
    company_sizes,
    company_financing_stages,
    xp_lvls,
    degrees,
    job_types,
    salary_range_filters,
    hourly_salary_range_filters,
    daily_salary_range_filters,
    remote_countries,
    languages,
    timezones
  } = job

  const locationValues = (locations || [])
    .map((item) => {
      return getValueById(config, item.id, 'location_id')
    })
    ?.filter(Boolean)
    .join(',')

  const main_job_functions_values = main_job_functions
    ?.map((item) => {
      return getValueById(config, item.id, 'main_job_function_id')
    })
    ?.filter(Boolean)
    .join(',')

  const job_functions_values = job_functions
    ?.map((item) => {
      return config.job_functions?.find((job_function) => job_function.id === item.id)?.value
    })
    ?.filter(Boolean)
    .join(',')

  const function_job_titles_values = function_job_titles
    ?.map((item) => {
      return getValueById(config, item.id, 'function_job_title_id')
    })
    ?.filter(Boolean)
    .join(',')

  const industries_values = industries
    ?.map((item) => getValueById(config, item.id, 'industry_id'))
    ?.filter(Boolean)
    .join(',')

  const company_sizes_values = company_sizes
    ?.map((item) => getValueById(config, item.id, 'company_size_id'))
    ?.filter(Boolean)
    .join(',')

  const company_financing_stages_values = company_financing_stages
    ?.map((item) => getValueById(config, item.id, 'company_financing_stage_id'))
    ?.filter(Boolean)
    .join(',')

  const xp_lvls_values = xp_lvls
    ?.map((item) => getValueById(config, item.id, 'xp_lvl_id'))
    ?.filter(Boolean)
    .join(',')

  const degrees_values = degrees
    ?.map((item) => getValueById(config, item.id, 'degree_id'))
    ?.filter(Boolean)
    .join(',')

  const job_types_values = job_types
    ?.map((item) => getValueById(config, item.id, 'job_type_id'))
    ?.filter(Boolean)
    .join(',')

  let salaryObj = {
    salary_range_filters,
    hourly_salary_range_filters,
    daily_salary_range_filters
  }

  let nonEmptySalary =
    Object.entries(salaryObj).filter(([key, value]) => Boolean(value?.length)) || []
  const salary_range_filters_values = nonEmptySalary
    .map(([key, item]) => {
      let prefix = key.replaceAll('salary_range_filters', '') || ''
      let value = item
        ?.map((v) => getValueById(config, v.id, prefix + 'salary_range_filter_id'))
        ?.filter(Boolean)
        ?.map((v) => {
          let suffix = prefix || 'monthly'
          return v + `${suffix}`.replace('_', '')
        })

      return value
    })
    .filter(Boolean)
    .join(', ')

  const countries = (remote_countries || [])
    .map((item) => {
      return getValueById(config, item.id, 'country_id') || item.value
    })
    .filter(Boolean)
    .join(',')

  const job_languages_values = languages
    ?.map((item) => {
      return config.job_languages?.find((language) => language.id === item.id)?.name
    })
    ?.filter(Boolean)
    .join(',')

  const timezone_lists_values = languages
    ?.map((item) => {
      return config.timezone_lists?.find((language) => language.id === item.id)?.name
    })
    ?.filter(Boolean)
    .join(',')

  let result = []
  if (!isRemote) {
    result = [
      countries,
      locationValues,
      main_job_functions_values,
      job_functions_values,
      function_job_titles_values,
      salary_range_filters_values,
      job_types_values,
      xp_lvls_values,
      degrees_values,
      industries_values,
      company_financing_stages_values,
      company_sizes_values
    ]
  } else {
    result = [
      countries,
      job_types_values,
      salary_range_filters_values,
      job_languages_values,
      timezone_lists_values,
      xp_lvls_values,
      degrees_values,
      industries_values,
      company_financing_stages_values,
      company_sizes_values
    ]
  }

  return result
}

export function getAlertData(searchValues, config) {
  const industryList = config.industry_lists
  const functionsTitleList = config.function_titles
  const jobFunctionList = config.main_functions.map((e) => e.children).flat()
  const locationLists = flatMap(config.location_lists, (item) => item.locations)
  const qualificationList = config.degrees

  // it is a single select
  const salaryType = (
    searchValues.salaryType?.join() ||
    config.salary_type_lists?.[0]?.value ||
    ''
  ).toLocaleLowerCase()
  // let salaryTypeIds = config.salary_type_lists.find(({value})=>value?.toLocaleLowerCase() === salaryType)?.id;
  const prefixForSalaryType =
    salaryType === 'monthly' ? '' : salaryType === 'daily' ? 'daily_' : 'hourly_'
  const salaryList = config[`${prefixForSalaryType}salary_range_filters`]

  const workExperienceList = config.xp_lvls
  const jobTypeList = config.job_types
  const companySizeList = config.company_sizes
  const financingStageList = config.company_financing_stage_lists

  function getValue(values, items, compareKey = 'seo-value', property = 'id') {
    return values
      ?.map?.((key) => items.find((item) => item?.[compareKey] === key)?.[property])
      ?.join(',')
  }

  const location_ids = getValue(searchValues.location, locationLists, 'seo_value')
  const location_values = getValue(searchValues.location, locationLists, 'seo_value', 'value')
  // leve1
  const main_job_function_ids = getValue(
    searchValues?.mainFunctions,
    config.main_functions,
    'seo_value'
  ) // ?.map?.(seo => mainFunctionList.find(item => item. === seo)?.value)?.join?.(',')

  // leve2
  const job_function_ids = getValue(searchValues?.jobFunctions, jobFunctionList)

  // leve3
  const function_job_title_ids = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value'
  )

  const main_function_values = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value',
    'value'
  )
  const industry_ids = getValue(searchValues.industry, industryList)
  const industry_values = getValue(searchValues.industry, industryList, undefined, 'value')
  const xp_lvl_ids = getValue(searchValues.workExperience, workExperienceList)
  const xp_lvl_values = getValue(
    searchValues.workExperience,
    workExperienceList,
    undefined,
    'value'
  )
  const degree_ids = getValue(searchValues.qualification, qualificationList)
  const degree_values = getValue(searchValues.qualification, qualificationList, undefined, 'value')
  const job_type_ids = getValue(searchValues.jobType, jobTypeList)
  const job_type_values = getValue(searchValues.jobType, jobTypeList, undefined, 'value')
  const salary_range_filter_ids = getValue(searchValues.salary, salaryList)
  const salary_range_values = (getValue(searchValues.salary, salaryList, undefined, 'value') || '')
    .split(',')
    .filter(Boolean)
    .map((v) => `${v} ${salaryType}`)
    .join(',')
  const company_size_values = getValue(
    searchValues.companySizes,
    companySizeList,
    undefined,
    'value'
  )
  const company_size_ids = getValue(searchValues.companySizes, companySizeList, undefined, 'id')
  const company_financing_stage_values = getValue(
    searchValues.financingStages,
    financingStageList,
    'key',
    'value'
  )
  const company_financing_stage_ids = getValue(
    searchValues.financingStages,
    financingStageList,
    'key',
    'id'
  )

  const is_company_verified = searchValues.verifiedCompany && 1

  return {
    location_ids,
    // location_values,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    // main_function_values,
    industry_ids,
    industry_values,
    xp_lvl_ids,
    xp_lvl_values,
    degree_ids,
    degree_values,
    job_type_ids,
    job_type_values,
    [`${prefixForSalaryType}salary_range_filter_ids`]: salary_range_filter_ids,
    salary_range_values: salary_range_values ? salary_range_values : undefined,
    company_size_values,
    company_size_ids,
    company_financing_stage_values,
    company_financing_stage_ids,
    keyword: searchValues.query,
    salaryType: salaryType,
    is_company_verified
    // salary_type_ids:salaryTypeIds
  }
}

export function getRemoteAlertData(searchValues, config) {
  const industryList = config.industry_lists
  const functionsTitleList = config.function_titles
  const jobFunctionList = config.main_functions.map((e) => e.children).flat()
  const locationLists = flatMap(config.location_lists, (item) => item.locations)
  const qualificationList = config.degrees

  const salaryType = (
    searchValues.salaryType?.join() ||
    config.salary_type_lists?.[0]?.value ||
    ''
  ).toLocaleLowerCase()
  const prefixForSalaryType =
    salaryType === 'monthly' ? '' : salaryType === 'daily' ? 'daily_' : 'hourly_'
  const salaryList = config[`${prefixForSalaryType}salary_range_filters`]

  const workExperienceList = config.xp_lvls
  const jobTypeList = config.job_types
  const companySizeList = config.company_sizes
  const financingStageList = config.company_financing_stage_lists

  const countryLists = config.country_lists
  const jobLanguages = config.job_languages
  const timezoneLists = config.timezone_lists

  function getValue(values, items, compareKey = 'seo-value', property = 'id') {
    return values
      ?.map?.((key) => items.find((item) => item?.[compareKey] == key)?.[property])
      .join(',')
  }

  const location_ids = getValue(searchValues.location, locationLists, 'seo_value')
  const location_values = getValue(searchValues.location, locationLists, 'seo_value', 'value')
  // leve1
  const main_job_function_ids = getValue(
    searchValues?.mainFunctions,
    config.main_functions,
    'seo_value'
  ) // ?.map?.(seo => mainFunctionList.find(item => item. === seo)?.value)?.join?.(',')

  // leve2
  const job_function_ids = getValue(searchValues?.jobFunctions, jobFunctionList)

  // leve3
  const function_job_title_ids = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value'
  )

  const main_function_values = getValue(
    searchValues?.functionTitles,
    functionsTitleList,
    'seo_value',
    'value'
  )
  const industry_ids = getValue(searchValues.industry, industryList)
  const industry_values = getValue(searchValues.industry, industryList, undefined, 'value')
  const xp_lvl_ids = getValue(searchValues.workExperience, workExperienceList)
  const xp_lvl_values = getValue(
    searchValues.workExperience,
    workExperienceList,
    undefined,
    'value'
  )
  const degree_ids = getValue(searchValues.qualification, qualificationList)
  const degree_values = getValue(searchValues.qualification, qualificationList, undefined, 'value')
  const job_type_ids = getValue(searchValues.jobType, jobTypeList)
  const job_type_values = getValue(searchValues.jobType, jobTypeList, undefined, 'value')
  const salary_range_filter_ids = getValue(searchValues.salary, salaryList)
  const salary_range_values = getValue(searchValues.salary, salaryList, undefined, 'value')
  const company_size_values = getValue(
    searchValues.companySizes,
    companySizeList,
    undefined,
    'value'
  )
  const company_size_ids = getValue(searchValues.companySizes, companySizeList, undefined, 'id')
  const company_financing_stage_values = getValue(
    searchValues.financingStages,
    financingStageList,
    'key',
    'value'
  )
  const company_financing_stage_ids = getValue(
    searchValues.financingStages,
    financingStageList,
    'key',
    'id'
  )

  const country_ids = getValue(searchValues.remote_country_ids, countryLists, 'id')
  const country_values = getValue(searchValues.remote_country_ids, countryLists, 'id', 'value')

  const language_ids = getValue(searchValues.language_ids, jobLanguages, 'id')
  const language_values = getValue(searchValues.language_ids, jobLanguages, 'id', 'name')

  const timezone_ids = getValue(searchValues.timezone_ids, timezoneLists, 'id')
  const timezone_values = getValue(searchValues.timezone_ids, timezoneLists, 'id', 'name')

  const is_company_verified = searchValues.verifiedCompany && 1

  return {
    location_ids,
    // location_values,
    main_job_function_ids,
    job_function_ids,
    function_job_title_ids,
    // main_function_values,
    industry_ids,
    industry_values,
    xp_lvl_ids,
    xp_lvl_values,
    degree_ids,
    degree_values,
    job_type_ids,
    job_type_values,
    salary_range_filter_ids,
    salary_range_values,
    company_size_values,
    company_size_ids,
    company_financing_stage_values,
    company_financing_stage_ids,
    keyword: searchValues.query,
    language_ids,
    language_values,
    country_ids,
    country_values,
    timezone_ids,
    timezone_values,
    is_company_verified
  }
}
