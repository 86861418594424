'use client'
import React, { useEffect, useState, useContext, useCallback } from 'react'
import styles from '../index.module.scss'
import { useRouter, useSearchParams } from 'next/navigation'
import WorkExperience from './workExperience'
import EducationExperience from './educationExperience'
import { getCookie, } from 'helpers/cookies'
import { LinkContext } from 'app/components/providers/linkProvider'
import { getLang } from 'helpers/country'
import { Step } from './Step'
import { Skills } from './Skills'
import { getSyncableContentService } from 'store/services/users/uploadUserResume'
import Loading from 'app/components/loading'
import { SyncResumeContext } from '../context'
import { skipSyncResumeContentService } from 'store/services/users/skipSyncResumeContent'

// const getStep = () => {
//   const url = new URL(window.location as any)
//   return url.searchParams.get('step')
// }

const getNextStep = (result = [], currentIndex) => {

  return result[currentIndex - 1]
}

const Main = (props: any) => {
  const sessionid = getCookie('sessionid')
  const { push } = useContext(LinkContext)
  const searchParams = useSearchParams()
  const langKey = getLang()
  const [skillsItemId, setSkillsItemId] = useState('');
  const [isSyncing, setIsSyncing] = useState(false)
  const initStep = searchParams.get('step')
  const [step, setStep] = useState(() => {
    let index = Number(initStep || 0);
    return isNaN(index) ? 0 : index
  })


  const rid = searchParams.get('rid')

  const [compare, setCompare] = useState({
    // i: 0,
    syncable_nums: 0,
    synced_nums: 0,
    total: 0,
    data: []
  });
  const lang = props.lang?.syncProfileFromResume || {}
  const headConfig = {
    exp: {
      head: lang.syncWork || 'Synchronize new work experience',
      desc: lang.clarificationForWork || "We found this new experience on your resume, please confirm the details and we'll update it to your profile."
    },
    edu: {
      head: lang.syncEdu || 'Synchronize new Education',
      desc: lang.clarificationForWork || "We found this new experience on your resume, please confirm the details and we'll update it to your profile."
    },
    skill: {
      head: lang.syncSkill || 'Synchronize new Skills',
      desc: lang.clarificationForSkill || "We found new skills you might have indicated on your resume. Please confirm the ones we'll add to your profile."
    }
  }

  const getSyncData = useCallback(() => {
    return getSyncableContentService(rid).then(res => {
      const data = res.data.data || {}
      const { add_educations: edu = [], add_skills: skill = [], add_work_experiences: exp = [], skills_item_id } = data;
      let index = 0;
      setSkillsItemId(skills_item_id)
      const result = [{ key: 'exp', data: exp }, { key: 'edu', data: edu }, { key: 'skill', data: skill }].reduce((total, item,) => {
        const { key, data } = item;
        if (key === 'skill') {
          total.push({ stepKey: key, value: data, itemKey: index++ })
        } else {
          total.push(...(data?.map(v => {
            return { ...v, stepKey: key, itemKey: index++ }
          })))
        }
        return total
      }, []);

      const stepItem = getNextStep(result, step || 1)

      if (result.length < step || !stepItem || (stepItem?.value?.length == 0)) {
        setStep(-1)
        return
      }
      if (step === 0) {
        setStep(1)
      }

      setCompare({
        total: result.length,
        syncable_nums: data.syncable_nums,
        synced_nums: data.synced_nums,
        data: result
      })
    }).catch((error) => {
      console.log(error)
    })
  }, [step])

  useEffect(() => {
    getSyncData()
  }, [])

  if (!sessionid) {
    push(`/${langKey}/get-started`)
    return
  }
  const gotoManage = () => {
    push(`/${langKey}/manage-profile?tab=resume`)
  }
  if (!rid || step == -1) {
    gotoManage()
    return
  }
  const currentData = step && getNextStep(compare.data, step) || {}
  const { stepKey } = currentData
  const head = headConfig[stepKey];

  const fetchSkipSyncResumeContentService = async (data) => {
    const { stepKey, item_id } = data
    const skipData = {}

    if (stepKey === 'exp') {
      skipData['work_experience_item_ids'] = [item_id]
    }

    if (stepKey === 'edu') {
      skipData['education_item_ids'] = [item_id]
    }

    if (stepKey === 'skill') {
      skipData['skills_item_id'] = skillsItemId
    }
    return skipSyncResumeContentService(rid, skipData)
  }

  const onSkip = async () => {
    try {
      if (isSyncing) return
      setIsSyncing(true)
      const isLast = step === compare.data.length;
      await fetchSkipSyncResumeContentService(currentData)
      if (isLast) {
        gotoManage()
        return
      }
      await getSyncData()

      setStep(() => 1)
      setIsSyncing(false)
    } catch (error) {
      setIsSyncing(false)
    }
  }

  return (
    <div className={styles.profile}>

      <div className={styles.mainContainer}>
        {
          step !== 0 && compare.data.length > 0 && <>
            <div className={styles.rightContainer}>
              <div className={styles.syncHeader}>
                {head.head}
              </div>
              <div className={styles.syncDesc}>
                <div>
                  {head.desc}
                </div>
                {/* <SimplePagination total={compare.total} current={step} /> */}
              </div>
              <Step total={compare?.syncable_nums} current={compare?.synced_nums + 1} />
              <SyncResumeContext.Provider value={{
                value: currentData,
                onSubmit: onSkip,
                onSkipClick: onSkip
              }}>
                {stepKey === 'exp' && <WorkExperience {...props} key={currentData.item_id || currentData.itemKey} />}
                {stepKey === 'edu' && <EducationExperience  {...props} key={currentData.item_id || currentData.itemKey} />}
                {stepKey === 'skill' && <Skills
                  key={skillsItemId || currentData.itemKey}
                  {...props} />}
              </SyncResumeContext.Provider>
            </div>
          </>
        }
        {(step === 0 || compare.data.length === 0) && <div className={styles.loadingRoot}>
          <Loading />
        </div>}
      </div>
    </div >
  )
}

export default Main
