import { useContext } from 'react'
import styles from './index.module.scss'
import { message } from 'antd'
import { languageContext } from 'app/components/providers/languageProvider'
import { formatTemplateString } from 'helpers/formatter'

const Close = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
    <path d="M9.18228 4.02543C9.37754 3.83017 9.37755 3.51358 9.18228 3.31832C8.98702 3.12306 8.67044 3.12306 8.47518 3.31832L6.0003 5.7932L3.52543 3.31832C3.33017 3.12306 3.01358 3.12306 2.81832 3.31832C2.62306 3.51358 2.62306 3.83017 2.81832 4.02543L5.2932 6.5003L2.81832 8.97518C2.62306 9.17044 2.62306 9.48702 2.81832 9.68228C3.01358 9.87754 3.33017 9.87754 3.52543 9.68228L6.0003 7.20741L8.47518 9.68228C8.67044 9.87754 8.98702 9.87754 9.18228 9.68228C9.37755 9.48702 9.37755 9.17044 9.18228 8.97518L6.70741 6.5003L9.18228 4.02543Z" fill="#121212" />
  </svg>
}

export const SkillSection = ({ options, value, onChange }) => {
  const lang = useContext(languageContext) as any

  return <div className={styles.skillRoot}>
    <div className={styles.skillDesc}>
      {lang.syncProfileFromResume?.skill?.suggestedSkillsFromResume || 'Suggested from your resume:'}
    </div>
    <div className={styles.skill}>
      {options?.map(v => {
        const checked = Boolean(value.find(item => item.id === v.id));
        return <span key={v.id} className={styles.skillItem}
          onClick={(e) => {
            const copyValue = [...value];
            if (value.length >= 10 && !checked) {
              message.warning(formatTemplateString(lang.syncProfileFromResume?.skill?.skillLimitError || '', { number: 10 }))
              return
            }
            if (checked) {
              let index = copyValue.findIndex(item => item.id === v.id);
              index !== -1 && copyValue.splice(index, 1)
            } else {
              copyValue.push(v)
            }
            onChange(options, copyValue, e)
          }}
          data-checked={checked}
        >
          {v.label}
        </span>
      })}
    </div>
    <div style={{ marginTop: 20 }}>
      <div>
        <span style={{ color: '#7D7D7D' }}>
          {/* Selected */}
          {lang.comm?.Selected || "Selected"}
        </span>
        <span className={styles.selectedHeaderWarm}>
          (
          {formatTemplateString(lang.syncProfileFromResume?.skill?.skillToEmployers || "", { number: 10 }) || 'Select up to 10 skills to optimize your match to Employers'}
          )
        </span>
      </div>
      {Boolean(value.length) && <div className={styles.selectedArea}>
        {value?.map((v, index) => {

          return <span key={v.id} className={styles.selectedItem}>
            {v.label}
            <span onClick={(e) => {
              const copyValue = [...value];
              copyValue.splice(index, 1)
              onChange(options, copyValue, e)
            }}>
              <Close />
            </span>
          </span>
        })}
      </div>}
    </div>
  </div>
}