'use client'
import React, { useEffect, useState, useRef } from 'react'
import { Button } from '@mui/material'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { getLang } from 'helpers/country'
import styles from './index.module.scss'
import Toast from 'app/components/Toast'
import { useLazyConnectLogin } from 'helpers/lazyConnnectLogin'
import { isMobile } from 'react-device-detect'
import vip_activity_image_url from './vip_activity_image.png'
import icon_close_url from './icon_close.svg'
import icon_copy_arrow_url from './icon_copy_arrow.svg'
import { getCookie } from 'helpers/cookies'
export const VipActivity = ({ lang }) => {
  const [referralCode, setReferralCode] = useState('')
  const [vipModal, setVipModal] = useState(false)
  const { showLoginModal: showLogin } = useLazyConnectLogin()
  const advertisingLink = lang?.advertisingLink || {}
  const sessionid = getCookie('sessionid')
  useEffect(() => {
    sessionid &&
      fetchUserOwnDetailService().then((res) => {
        if (res?.data?.data) {
          setReferralCode(res.data.data.referral_code)
        }
      })
  }, [sessionid])

  const handleVipModalClick = () => {
    if (sessionid) {
      setVipModal(true)
    } else {
      showLogin()
    }
  }

  return (
    <>
      <div className={styles.vipImage} onClick={handleVipModalClick}>
        <Button variant='contained' className={styles.btn}>
          {advertisingLink.GetVipForFree}
        </Button>
        <span className={styles.desc}>{advertisingLink.InviteFriendsToGetAIResumeCoaching}</span>
        <img
          src={vip_activity_image_url.src}
          width={514}
          height={268}
          alt='vip_activity_image'
          className={styles.image}
        />
      </div>
      {vipModal && (
        <VipShareModal
          referral_code={referralCode}
          lang={lang}
          onCloseModal={() => setVipModal(false)}
        />
      )}
    </>
  )
}

export const VipShareModal = ({ referral_code, lang, onCloseModal }) => {
  const copyTextRef = useRef(null)
  const newGetStarted = lang?.newGetStarted || {}
  const langKey = getLang()

  return (
    <div className={styles.vipShareWrapper}>
      <div className={styles.vipShareModal}>
        <img
          className={styles.close}
          src={icon_close_url}
          alt=''
          width='15'
          height='15'
          onClick={onCloseModal}
        />
        <div className={styles.main}>
          <div className={styles.left}>
            <p className={styles.buttonText}>{newGetStarted.vipText.inviteFriendsToGet}</p>
            {!isMobile && (
              <p className={styles.blueText}>{newGetStarted.vipText.AIResumeCoaching}</p>
            )}
            <p className={styles.descText}>
              {
                newGetStarted.vipText
                  .HighQualityVIPResumeTemplateAndAIAssistantToHelpYouGetHighPayingOffer
              }
            </p>

            <p className={styles.links} ref={copyTextRef}>
              {`${location.origin}/${langKey}/get-started?referral_code=${referral_code}&invited_source=resume_template`}
            </p>
            <Button
              variant='contained'
              className={styles.copyButton}
              onClick={() => {
                navigator.clipboard.writeText(copyTextRef.current.innerText)
                Toast.success('Link copied success!')
              }}
            >
              <img
                src={icon_copy_arrow_url}
                alt=''
                style={{ marginRight: isMobile ? '6px' : '20px' }}
              />
              <span className={styles.btnText}>{newGetStarted.vipText.copyLinkToInviteNow}</span>
            </Button>
          </div>
          <div className={styles.right}></div>
        </div>
      </div>
    </div>
  )
}
