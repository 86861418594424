import { useState, useTransition, useCallback } from 'react'
import useSearchHistory from 'helpers/useSearchHistory'
import { fetchSearchRemoteSuggestionService } from 'store/services/jobs/fetchSearchSuggestion'

const transObject = (params) => {
  const result = {}
  params.forEach((e, index) => {
    result[`query_histories[${index}]`] = e
  })
  return result
}

export const useSuggest = () => {
  const [loading, transitionStart] = useTransition()
  const [suggestionList, setSuggestionList] = useState([])
  const [searchHistories, addSearchHistory] = useSearchHistory()

  const handleSuggestionSearch = useCallback(
    (val) => {
      transitionStart(() => {
        const valueLength = val?.length ?? 0
        if (valueLength === 0) {
          setSuggestionList(searchHistories)
        } else if (valueLength === 1) {
          setSuggestionList([])
        } else if ((val?.length ?? 0) > 1) {
          const qs = transObject(searchHistories)
          fetchSearchRemoteSuggestionService({ size: 5, query: val, ...qs }).then((data) =>
            setSuggestionList(data.data.data.items)
          )
        }
      })
    },
    [transitionStart]
  )

  return [suggestionList, handleSuggestionSearch, addSearchHistory, loading]
}
