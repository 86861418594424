'use client'
import React, { useContext, } from 'react'
import classNames from 'classnames'
import { useParams, useRouter } from 'next/navigation'

import { languageContext, useLanguage } from 'app/components/providers/languageProvider'
import ScrollText from 'app/components/scrollText'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from '../../index.module.scss'

// import { isMobile } from 'react-device-detect'
import { getCookie } from 'helpers/cookies'
import { getValueById } from 'helpers/config/getValueById'

const Lock = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4 3.99998C4 2.52722 5.19391 1.33331 6.66667 1.33331H9.33333C10.8061 1.33331 12 2.52722 12 3.99998V4.66665H13.3333C13.7015 4.66665 14 4.96512 14 5.33331V13.3333C14 13.7015 13.7015 14 13.3333 14H2.66667C2.29848 14 2 13.7015 2 13.3333V5.33331C2 4.96512 2.29848 4.66665 2.66667 4.66665H4V3.99998ZM10.6667 3.99998C10.6667 3.2636 10.0697 2.66665 9.33333 2.66665H6.66667C5.93029 2.66665 5.33333 3.2636 5.33333 3.99998V4.66665H10.6667V3.99998ZM5.33333 7.33331C4.96514 7.33331 4.66667 7.63179 4.66667 7.99998C4.66667 8.36817 4.96514 8.66665 5.33333 8.66665H10.6667C11.0349 8.66665 11.3333 8.36817 11.3333 7.99998C11.3333 7.63179 11.0349 7.33331 10.6667 7.33331H5.33333Z" fill="#515151" />
  </svg>
}

export const CompanyCard = (props: any) => {
  let {
    company,
  } = props
  const { name, id, num_of_active_jobs = 0, company_url, logo, financing_stage_id, company_size_id, industry_id } = company
  const config = useSharedData('CONFIG') ?? {}
  const langDic = useSharedData('DICTIONARY') || {}
  let companyName = name

  const financingStage = financing_stage_id ? getValueById(config, financing_stage_id, 'company_financing_stage_id') : null
  const companySize = company_size_id ? getValueById(config, company_size_id, 'company_size_id') : '' + ' ' + langDic?.home?.companyCard?.employee || ''
  const industry = industry_id ? getValueById(config, industry_id, 'industry_id') : null
  let labels = [financingStage, companySize, industry].filter(Boolean);

  let totalJob = num_of_active_jobs + ` ${langDic?.comm?.activeJobs || 'Active Jobs'}`
  const { lang: langKey } = useParams()
  const router = useRouter()

  return (
    <>
      <div
        className={classNames(styles.jobCard, styles.companyCard)}
        onClick={() => {
          router.push(`/${langKey}/` + company_url)
        }}
      >
        <div className={styles.container}>
          <div className={styles.left}>
            <img src={logo} width={50} height={50} alt={`${name} logo`} style={{ borderRadius: 4, border: '1px solid #e5e6eb' }} />
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <div
                key={companyName + id}
                className={classNames(styles.titleContainer)}
                title={`${companyName}`}
              >
                <ScrollText className={styles.title}>{companyName}</ScrollText>
              </div>
              <div className={styles.labelContainer}>
                {labels.map((label) => (
                  <div key={label} className={styles.label}>
                    {label}
                  </div>
                ))}
              </div>
            </div>

          </div>
          <div className={styles.right}>
            <Lock />{totalJob}
          </div>
        </div>
      </div>
    </>
  )
}

// export default JobCard
