'use client'
import { setJobViewCookiesTracker, viewJobSourceTracker } from 'components/ReportTracker/util'
import { setCookie } from 'helpers/cookies'
import Link from 'components/Link'
import React from 'react'

interface AnalyticsLinkProps {
  href: string
  children: React.ReactNode
  className?: string
  data: any
}

// 埋点逻辑函数
const trackClickEvent = async ({ data }) => {
  try {
    const { reco_from, query_id } = data || {}
    await setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.jobDetailSimilar,
      reco_from: reco_from || null,
      query_id: query_id || null
    })
  } catch (error) {
    console.log('Error tracking click event:', error)
  }
}

const AnalyticsLink: React.FC<AnalyticsLinkProps> = (props) => {
  const { href = '', children, className = '', data = {}, ...rest } = props

  // 点击处理函数
  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault() // 防止默认链接跳转行为
    await trackClickEvent({ data })
    window.location.href = href // 执行跳转
  }

  return (
    <a onClick={handleClick} className={className} {...rest}>
      {children}
    </a>
  )
}

export default AnalyticsLink
