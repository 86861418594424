'use client'
import { useRouter } from 'next/navigation'
import { toPairs } from 'ramda'
import { useSharedData } from 'bossjob-remote/dist/hooks'

import { Button } from 'app/components/MUIs'
import { encode } from 'app/(jobs-hiring)/[lang]/jobs-hiring/interpreters/encoder'

import styles from '../../../../page.module.scss'

type propsType = {
  jobDetail: any
  text: string
  isMobile?: boolean
}

const SeeMore = ({ jobDetail, text, isMobile }: propsType) => {
  const router = useRouter()
  const xp_lvls = useSharedData('CONFIG')?.xp_lvls ?? []

  const handleToHomePage = () => {
    const xp_lvl = xp_lvls.find((item) => jobDetail.xp_lvl?.key == item.key)
    const searchQuery: any = {
      location: [jobDetail.location.value],
      mainFunctions: [jobDetail.function?.main_function],
      jobFunctions: [jobDetail.function?.sub_function]
      // workExperience: [xp_lvl['seo-value']]
    }

    if (xp_lvl?.['seo-value']) {
      searchQuery.workExperience = xp_lvl['seo-value']
    }

    const result = encode(searchQuery)
    const url = new URLSearchParams(toPairs(result.params)).toString()
    router.push('/jobs-hiring/' + result.searchQuery + '?' + url, {
      scroll: true
    })
  }
  
  if (isMobile) {
    return (
      <div
      className={styles.similarJobs_seeMore_h5}
      onClick={handleToHomePage}
    >
      <span>{text || 'See more'}</span>
    </div>
    )
  }

  return (
    <div
      className={styles.similarJobs_seeMore}
      onClick={handleToHomePage}
    >
      <span>{text || 'See more'}</span>
    </div>
  )
}

export default SeeMore
