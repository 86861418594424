import React, { Suspense } from 'react'
import dynamic from 'next/dynamic'

import styles from '../../../page.module.scss'

const MapClient = dynamic(() => import('./Client/index'), { ssr: false })

export type propsType = {
  lat: number
  lng: number
  full_address: string
  lang: any
}

export interface DialogTitleProps {
  id?: string
  children?: React.ReactNode
  onClose: () => void
}

const Map = ({ lat, lng, full_address, lang }: propsType) => {

  return (
    <section className={styles.map} id='WorkingLocation'>
      <h5>{lang?.workingLocation}</h5>
      <p>{full_address}</p>

      <Suspense fallback="Loading..." >
        <MapClient lat={lat} lng={lng} lang={lang} full_address={full_address} />
      </Suspense>
    </section>
  )
}

export default Map
