"use client"
import styled from '@emotion/styled';
import React from 'react'
import Image from 'next/image'

/* Images */
import partner101 from '../../images/partner101.png'
import partner102 from '../../images/partner102.png'
import partner103 from '../../images/partner103.png'
import partner104 from '../../images/partner104.png'
import partner105 from '../../images/partner105.png'
import partner201 from '../../images/partner201.png'
import partner301 from '../../images/partner301.jpg'
import partner302 from '../../images/partner302.png'

const partners = {
  ph: {
    titleKey: 'Philippines',
    imgs: [
      {
        img: partner101,
        url: 'https://www.ibpap.org/'
      }, {
        img: partner102,
        url: 'https://kmc.solutions/'
      }, {
        img: partner103,
        url: 'https://www.pmap.org.ph/'
      }, {
        img: partner104,
        url: 'https://www.qbo.com.ph/'
      }, {
        img: partner105,
        url: 'https://amchamphilippines.com/'
      }
    ]
  },
  sg: {
    titleKey: 'Singapore',
    imgs: [
      {
        img: partner201,
        url: 'https://singaporefintech.org/'
      }
    ]
  },
  jp: {
    titleKey: 'Japan',
    imgs: [
      {
        img: partner301,
        url: 'https://monolith.law/'
      }, {
        img: partner302,
        url: 'https://www.mc-lawyer.jp/'
      }
    ]
  }
}

const Partner = (props: any) => {

  const { data, countryKey } = props
  const { partnerOrganizations } = data?.foot || {}
  const titleKey = partners[countryKey]?.titleKey || ''

  return (
    <PartnerStyle>
      {
        partners[countryKey] ?
          <div className='partner_wrap'>
            {partnerOrganizations || 'Partner Organizations'}
            <span className='parter'>{titleKey && data?.foot[titleKey] || titleKey}</span>
            {
              partners[countryKey]['imgs'].map((item, index) => {
                return <a key={index} className='img' href={item.url} target='_blank'>
                  <Image alt='partner img' height={32} src={item.img}></Image>
                </a>
              })
            }
          </div> : null
      }
    </PartnerStyle>
  )
}

export default Partner

const PartnerStyle = styled.div`
  .partner_wrap {
    max-width: 1136px;
    margin: 0 auto;
    color: #B8B8B8;
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.12px;
    line-height: 1;
    margin-bottom: 30px;
    .parter {
      margin-left: 30px;
      margin-right: 10px;
      color: #7D7D7D;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0.105px;
    }
    .img {
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
`;
