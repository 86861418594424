import React, { PropsWithChildren, useContext, useState, useRef } from 'react'
import style from './index.module.scss'
import Link from 'components/Link'
import classNames from 'classnames'
import Image from 'next/image'
import { CompanyDetailsType } from '../../service'
import { MouseOverPopover } from '../../../../../../components/popover/MouseOverPopover'
import { languageContext } from 'app/components/providers/languageProvider'
import { useCompanyDetail } from '../../DataProvider'
import { LinkedinSvg, InstagramSvg, TwitterSvg, FacebookSvg, YouTubeSvg } from './svg'
import { ReactComponent as ArrowUpIcon } from 'images/icons/arrowup.svg'
import { ReactComponent as ArrowDownIcon } from 'images/icons/arrowdown.svg'
import moment from 'moment'
import { getValueById } from 'helpers/config/getValueById'

import { fetchNews } from 'store/services/companies2/fetchCompanyFilter'
import { getSmsCountryList } from 'helpers/jobPayloadFormatter'
import { useParams } from 'next/navigation'

interface Props extends PropsWithChildren<CompanyDetailsType> {
  test?: number
  type?: 'benefits' | 'culture'
}

const CulturePanel = (props: Props) => {
  const { benefits, cultures } = props
  const { config } = useCompanyDetail()
  const { company_benefit_lists, company_culture_lists } = config
  const contextLang = useContext(languageContext)
  const { overview, culture } = contextLang.companyDetail
  if (!cultures?.length && !benefits.length) return null
  console.log('@config:', config)
  return (
    <div className={style.culture_wrapper}>
      {/* <div className={style.culture_title}>{overview.CultureAndBenefits}</div>
      <div className={style.culture_content}>{overview.BenefitsDes}</div> */}
      {!!cultures?.length && (
        <div className={style.benefitsBox}>
          <div className={style.subtitle}>{overview.culture.title}</div>
          <div className={style.item_wrapper + ' ' + style.culture}>
            {cultures
              .filter((item) => company_culture_lists[item.id - 1]?.value)
              .map((item, index) => {
                return (
                  <MouseOverPopover
                    value={company_culture_lists[item.id - 1]?.value}
                    className={style.item}
                    key={index}
                  ></MouseOverPopover>
                )
              })}
          </div>
        </div>
      )}

      {!!benefits
        //.filter((item) => company_benefit_lists[item.id - 1])?.length 
        && (
          <div className={style.benefitsBox}>
            <div className={style.subtitle}>{overview.CompanyBenefits}</div>
            <div className={style.item_wrapper}>
              {benefits
                // .filter((item) => company_benefit_lists[item.id - 1]?.name)
                .map((item, index) => {
                  return (
                    <MouseOverPopover
                      // value={(item as any)?.name}
                      value={getValueById(config, item.id, 'company_benefit_id')}
                      className={style.item}
                    ></MouseOverPopover>
                  )
                })}
            </div>
          </div>
        )}
    </div>
  )
}

export function TagContent(props: Props) {
  const { cultures, benefits } = props
  const { config } = useCompanyDetail()
  const { company_benefit_lists, company_culture_lists } = config
  const _data = props.type === 'benefits' ? benefits : cultures
  const source = props.type === 'benefits' ? company_benefit_lists : company_culture_lists
  if (!_data?.length) return null
  return (
    <div className={style.culture_wrapper}>
      <div
        className={classNames({
          [style.item_wrapper]: true,
          [style.culture]: props.type !== 'benefits'
        })}
      >
        {_data.map((item, index) => {
          return (
            <MouseOverPopover
              value={props.type === 'benefits' ? getValueById(config, item.id, 'company_benefit_id') : source[item.id - 1]?.value}
              className={style.item}
              key={index}
            ></MouseOverPopover>
          )
        })}
      </div>
    </div>
  )
}

export function SocialMedia(props: Props) {
  const arr = [
    {
      icon: LinkedinSvg,
      link: props.linkedin_url,
      name: 'Linkedin'
    },
    {
      icon: InstagramSvg,
      link: props.instagram_url,
      name: 'Instagram'
    },
    {
      icon: TwitterSvg,
      link: props.twitter_url,
      name: 'Twitter'
    },
    {
      icon: FacebookSvg,
      link: props.facebook_url,
      name: 'Facebook'
    },

    {
      icon: YouTubeSvg,
      link: props.youtube_url,
      name: 'YouTube'
    }
  ]
  const res = arr.filter((item) => item.link)

  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const [showMore, setShowMore] = useState(false)
  const { config } = useCompanyDetail()
  const smsCountryList = getSmsCountryList(config)

  const handleToggleShowMore = () => {
    setShowMore(!showMore)
  }

  const displayCount = showMore ? props.phone_number_list.length : 1
  if (!props.phone_number_list || !props.email || !res) {
    return null
  }

  return (
    <div className={style.culture_wrapper + ' ' + style.social_}>
      <div className={style.culture_title} style={{ marginBottom: 20 }}>
        {'Contact'}
      </div>
      <div className={style.contact}>
        {props.website && (
          <div className={`${style.contactItem} ${style.contactItemWebsite}`}>
            <a href={props.website} target='_blank'>
              <p className={style.title}>{overview.website}</p>
              <p className={style.link}>{props.website}</p>
            </a>
          </div>
        )}

        {/* {props.phone_num && (
          <div className={style.contactItem}>
            <p className={style.title}>{overview.phoneNumber}</p>
            <p className={style.link}>{props.phone_num}</p>
          </div>
        )} */}

        {props.phone_number_list && (
          <div className={style.contactItem}>
            <p className={style.title}>{overview.phoneNumber}</p>
            {
              props.phone_number_list.slice(0, displayCount).map(item => {
                const curCountry = smsCountryList?.find(it => it.id == item.country_id)
                return <>
                  <p key={item.id} className={style.phone_item}>{`${curCountry.value}  ${item.phone_number}`}</p>
                </>
              })
            }
            {
              props.phone_number_list.length > 1 ?
                <span className={style.btn_more} onClick={() => handleToggleShowMore()}>
                  <span className={style.btn_more_text}>{!showMore ? overview.More : overview.Less}</span>
                  <span className={style.right_icon}>{!showMore ? <ArrowDownIcon /> : <ArrowUpIcon />} </span>
                </span> : null
            }
          </div>
        )}

        {props.email && (
          <div className={style.contactItem}>
            <p className={style.title}>{overview.email}</p>
            <p className={style.link}>{props.email}</p>
          </div>
        )}
      </div>
      {
        res && res.length > 0 && <div className={style.social_wrapper}>
          {res.map((item, index) => {
            return (
              <div key={index} className={style.social_item}>
                <Link target='__blank' href={item.link} title={item.link}>
                  <div className={style.social_icon}>
                    {item.icon}
                    <span>{item.name}</span>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      }
    </div>
  )
}

export const NewsCom = (props) => {
  const { keyword } = useParams()
  const id = (keyword as string)?.split('-')?.pop?.()
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const page = useRef(1)
  const [data, setData] = useState(props.news)
  const clickRef = useRef(null)
  const getNews = (page) => {
    if (clickRef.current) return
    clickRef.current = page
    fetchNews({
      companyId: id,
      page: page
    }).then((res) => {
      clickRef.current = null
      res = res.data.data
      setData({
        ...res,
        data: [...data.data, ...res.data]
      })
    })
  }
  return (
    <div className={style.news}>
      <div className={style.culture_title} style={{ marginBottom: 20 }}>
        {'News'}
      </div>
      {data?.data?.map((item) => (
        <a href={item.url} target='_blank' className={style.newsList} key={item.id}>
          <p className={style.title}>{item.title}</p>
          <p className={style.content}>{item.content}</p>
          <p className={style.date}>
            Publish Date : {moment(item.published_at).format('YYYY-MM-DD')}
          </p>
        </a>
      ))}
      {data.total_page > data?.page && (
        <div className={style.containerMore}>
          <div
            className={`${style.more}`}
            onClick={() => {
              const newPage = page.current + 1
              getNews(newPage)
            }}
          >
            {overview.More}
          </div>
        </div>
      )}
    </div>
  )
}

const UpIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.19526 11.6903C1.93491 11.4299 1.93491 11.0078 2.19526 10.7475L7.5286 5.41412C7.78895 5.15378 8.21106 5.15378 8.4714 5.41412L13.8047 10.7475C14.0651 11.0078 14.0651 11.4299 13.8047 11.6903C13.5444 11.9506 13.1223 11.9506 12.8619 11.6903L8 6.82834L3.13807 11.6903C2.87772 11.9506 2.45561 11.9506 2.19526 11.6903Z'
        fill='#7D7D7D'
      />
    </svg>
  )
}

const DownIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8047 5.4141C14.0651 5.67445 14.0651 6.09656 13.8047 6.35691L8.4714 11.6902C8.21105 11.9506 7.78894 11.9506 7.5286 11.6902L2.19526 6.35691C1.93491 6.09656 1.93491 5.67445 2.19526 5.4141C2.45561 5.15375 2.87772 5.15375 3.13807 5.4141L8 10.276L12.8619 5.4141C13.1223 5.15375 13.5444 5.15375 13.8047 5.4141Z'
        fill='#7D7D7D'
      />
    </svg>
  )
}

export default CulturePanel
