import React, { useState } from 'react'
import { Image, Popover } from 'antd'
import styled from '@emotion/styled'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import BrandPopup from 'app/components/companyBrand/brandPopover';
import { useSharedData } from 'bossjob-remote/dist/hooks'

import { useCompanyDetail } from '../../DataProvider'

const CompanyBrandList = () => {

  const { detail } = useCompanyDetail()

  const brandProductList = detail.brand_products || []
  const [showAll, setShowAll] = useState(false)

  const langDic = useSharedData('DICTIONARY') || {}
  const companyDetailLangDic = langDic?.companyDetail || {}

  const displayCount = showAll ? brandProductList.length : 6

  return (
    <>
      <CompanyBrandWrap>
        <div className='brand-wrap'>
          {brandProductList.slice(0, displayCount).map((item: any, index) => {
            return <div key={index} className='brand-card'>
              <Popover trigger="hover" content={<BrandPopup data={item} />}>
                <div className='brand-item'>
                  <span className='avatar-bg'>
                    <Image
                      src={item.logo_url}
                      preview={false}
                    />
                  </span>
                  <div className='item-right'>
                    <div className='brand-name'>{item.name}</div>
                    <div className='brand-intro'>{item.description}</div>
                  </div>
                </div>
              </Popover>
            </div>
          })}
        </div>
        {
          brandProductList.length > 6 && (
            <div className='more' onClick={() => setShowAll(!showAll)}>
              {showAll ? <span>{companyDetailLangDic?.overview?.Less}<CaretUpOutlined color="#2378E5" /></span> : <span>{companyDetailLangDic?.overview?.More}<CaretDownOutlined color="#2378E5" /></span>}
            </div>
          )
        }
      </CompanyBrandWrap>
    </>
  );
};

export default CompanyBrandList;


const CompanyBrandWrap = styled.div`
  .brand-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; /* 设置卡片之间的水平和垂直间距 */
    margin-top: 15px;
  }
  .brand-card {
    position: relative;
    display: inline-block;
    width: calc(33.33% - 12px); /* 设置卡片宽度为一行的一半减去间距 */
    border-radius: 8px;
    border: 1px solid #E5E6EB;
    background: #FFFFFF;
    .brand-item {
      display: flex;
      padding: 16px;
    }
    .item-popup {
      display: none;
			position: absolute;
			right: 0;
      top: 82px;
			z-index: 10;
    }
    .avatar-bg {
      display: inline-block;
      width: 48px;
      height: 48px;
      margin-right: 16px;
      img {
        border-radius: 6px;
        width:100%
      }
    }
    .item-right {
      width: 122px;
    }
    .brand-name {
      line-height: 1;
      color: #121212;
      font-size: 14px;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .brandSlogan {
      margin-top: 6px;
      line-height: 1.2;
      color: #7D7D7D;
      font-size: 12px;
      white-space: nowrap; /* 防止换行 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .brand-intro {
      margin-top: 6px;
      line-height: 1.2;
      color: #7D7D7D;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }
  .btn-add {
    display: flex;
    align-items: center;
    color: #136FD3;
    cursor: pointer;
    font-size: 14px;
    cursor: pointer;
  }
  .right-icon {
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
  }
  .btnOperation {
    /* display: none; */
    float: right;
    svg{
      margin-left: 18px;
      &:hover {
        cursor: pointer;
        path {
          fill: #2378E5;
        };
      }
    }
  }
  .more {
    margin-top: 14px;
    font-size: 16px;
    text-align: left;
    color: #2378E5;
    cursor: pointer;
    text-align: center;
  }
`
