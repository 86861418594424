import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { message } from 'antd';
import { ReactComponent as ResumeFailedIcon } from '../../images/svg/resume_failed.svg';
import { maxFileSize, useUploadResumeContext } from './context';
import UploadResumeWrap from './Wrap';

const UploadFailed = (props: {
  lang: any
  userDetail: any
  getUserInfo: () => Promise<any>
}) => {
  const { lang = {}, userDetail } = props
  const profile = lang.profile || {}
  const inputRef = useRef(null);
  const { uploadResumeFile, fileData } = useUploadResumeContext()

  const onChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const resumeId = userDetail?.resume?.id || fileData?.data?.id
    // console.log('resumeId', { userDetail, fileData, resumeId })
    if (file.size > maxFileSize) {
      message.error(profile?.uploadResumeModal?.errorTip)
      return
    }
    uploadResumeFile({ file, resumeId: resumeId })
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className="file-info">
          <ResumeFailedIcon />
          <p className="upload-failed">{profile?.uploadResumeModal?.uploadFailed}</p>
          <div className="upload-failed-desc">
            {profile?.uploadResumeModal?.uploadFailedTip} <span className="re-upload" onClick={handleUpload}>{profile?.uploadResumeModal?.reUploadClick}</span>
          </div>
          <input accept='.pdf, .doc, .docx' type="file" ref={inputRef} onChange={onChange} style={{ display: 'none' }} hidden />
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  );
};

const ContentWrap = styled.div`
  .file-info {
    display: flex;
    margin-top: 32px;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed #e4e4e4;
    background: #fcfcfc;

    .upload-failed {
      margin-top: 26px;
      color: #121212;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }

    .upload-failed-desc {
      margin-top: 20px;
      color: #7d7d7d;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    .re-upload {
      color: #2378e5;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default UploadFailed;