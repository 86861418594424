import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Step = styled.span`
  margin-right: 4px;
  color: #2378E5;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Total = styled.span`
  color: #121212;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const SimpleSteps = ({ currentStep, totalSteps, className = '' }) => (
  <span className={className}>
    <Step>{currentStep}</Step>
    <Total>/ {totalSteps}</Total>
  </span>
);

SimpleSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default SimpleSteps;
