import { handleUserCookiesConfig, setCookie } from 'helpers/cookies';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail';
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'

interface MainResumeContextType {
  userInfo: any;
  setUserInfo: React.Dispatch<React.SetStateAction<any>>;
  getUserInfo: () => Promise<any>;
}

export const MainResumeContext = createContext<MainResumeContextType | undefined>(undefined);

const Provider = MainResumeContext.Provider;

interface MainResumeProviderProps {
  children: ReactNode;
}

export const MainResumeProvider: React.FC<MainResumeProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>({});

  const getUserInfo = useCallback(async () => {
    return fetchUserOwnDetailService().then((res) => {
      const userDetail = res?.data?.data || {}
      if (userDetail?.id) {
        const userCookie = handleUserCookiesConfig(userDetail)
        setCookie('user', userCookie)
        setUserInfo(userDetail)
        publishSharedData('ME', userDetail)
      }
    })
  }, [])

  return (
    <Provider
      value={{
        userInfo, setUserInfo, getUserInfo
      }}
    >
      {children}
    </Provider>
  );
};

export const useMainResumeContext = (): MainResumeContextType => {
  const context = useContext(MainResumeContext);
  if (!context) {
    throw new Error('useMainResumeContext must be used within a MainResumeProvider');
  }
  return context;
};

export const withMainResumeProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  extraProps?: P,
) => {
  return function MainResumeProviderComponent(props: P) {
    return (
      <MainResumeProvider>
        <WrappedComponent {...props} {...extraProps} />
      </MainResumeProvider>
    );
  };
};