/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import { MobileCountrySelector } from '../MobileCountrySelect/MobileCountrySelect'
import { useSharedData } from 'bossjob-remote/dist/hooks'

const arrowIconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1em',
  height: '1em'
}

const ArrowIcon = (props) => {
  return (
    <div {...props} style={arrowIconStyle}>
      <svg xmlns='http://www.w3.org/2000/svg' width='11' height='7' viewBox='0 0 11 7' fill='none'>
        <path
          d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
          fill='#86909C'
        />
      </svg>
    </div>
  )
}

export const CountrySelectorForMobile = ({
  label,
  className,
  value: inputValue,
  world_region_lists,
  onChange,
}: any) => {
  const [open, setOpen] = useState(false)
  // const value = inputValue?.length ? inputValue : 'placeholder' 
  const newValue = Array.isArray(inputValue) ? inputValue : [inputValue];
  const langDic = useSharedData('DICTIONARY') || {}

  return (
    <>
      <FormControl fullWidth className={className} size='small'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            padding: '0 7px',
            paddingLeft: 14,
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: 14,
            height: '100%',
            fontFamily: "auto"
          }}
          onClick={() => setOpen(true)}
        >
          {`${langDic.remoteFindJobs?.openCountry}` + `${newValue.length ? `(${newValue.length || ''})` : ''}`}
          <ArrowIcon />
        </div>
      </FormControl>
      <MobileCountrySelector
        open={open}
        header={langDic.remoteFindJobs?.openCountry}
        data={world_region_lists}
        value={inputValue}
        onClose={() => setOpen(false)}
        onOk={values => {
          onChange?.(values);
          setOpen(false)
        }}
      />
    </>
  )
}
