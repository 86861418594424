import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import styles from '../index.module.scss'
import SkipModal from './skipModal'

import { useSearchParams } from 'next/navigation'
import { addEventTagForH5, addEventTag } from 'helpers/utilities'
import { isMobile } from 'react-device-detect'
interface btnProps {
  handleClick?: any
  backClick?: () => void
  loading?: boolean
  rightText?: string
  showBack?: boolean
  disabled?: boolean
  backText?: string
  isMobile?: boolean
  skip?: string
  skipText?: string
  step?: number
}

const skipEventTag = {
  1: 'sgh5_register_first_skip',
  2: 'sgh5_register_second_skip',
  3: 'sgh5_register_third_skip',
  4: 'sgh5_register_final_skip'
}

const nextEventTag = {
  1: 'sgh5_register_first',
  2: 'sgh5_register_second',
  3: 'sgh5_register_third',
  4: 'sgh5_register_over'
}

// 上面是活动,下面是全局
const allSkipEventTag = {
  1: 'basic_information_skip',
  2: 'work_experience_skip',
  3: 'education_skip',
  4: 'perference_skip'
}

const allNextEventTag = {
  1: 'basic_information_complete',
  2: 'work_experience_complete',
  3: 'education_complete',
  4: 'perference_complete'
}

const allBackEventTag = {
  2: 'work_experience_back',
  3: 'education_back',
  4: 'perference_back'
}

const FootBtn = ({
  handleClick,
  loading,
  backClick,
  rightText,
  showBack = true,
  disabled = false,
  backText = 'back',
  skipText,
}: btnProps) => {
  const [showDialog, setDiallog] = useState<boolean>(false)


  const searchParams = useSearchParams()
  const step = searchParams.get('step')

  const skipClick = () => {
    setDiallog(true)
    addEventTagForH5(skipEventTag[step])
    addEventTag(allSkipEventTag[step])
  }

  const handleSubmit = () => {
    handleClick && handleClick()
    addEventTagForH5(nextEventTag[step])
    addEventTag(allNextEventTag[step])
  }

  const handleBack = () => {
    backClick()
    addEventTag(allBackEventTag[step])
  }

  return (
    <>
      {showDialog && <SkipModal closeFun={() => setDiallog(false)} />}
      <div className={styles.next}>
        <div className={styles.rightBtnBox}>
          {!isMobile && (
            <span className={styles.skip} onClick={() => setDiallog(true)}>
              {skipText}
            </span>
          )}
          <LoadingButton
            onClick={() => handleSubmit()}
            loading={loading}
            variant='contained'
            disabled={disabled}
            sx={{
              width: isMobile ? '100%' : '178px',
              height: '60px',
              textTransform: 'capitalize',
              boxShadow: 'none',
              borderRadius: '10px',
              background: '#2378E5'
              // color: '#707070',
            }}
          >
            <span>{rightText}</span>
          </LoadingButton>
        </div>
        {showBack && (
          <div className={styles.rightBtnBox}>
            <LoadingButton
              onClick={isMobile ? skipClick : handleBack}
              loading={false}
              variant='contained'
              sx={{
                width: isMobile ? '100%' : '178px',
                height: '60px',
                textTransform: 'capitalize',
                boxShadow: 'none',
                borderRadius: '8px',
                border: '1px solid #E4E4E4',
                background: 'transparent',
                color: '#515151',
                '&:hover': {
                  border: '1px solid #1565c0'
                }
              }}
            >
              <span>{isMobile ? skipText : backText}</span>
            </LoadingButton>
          </div>
        )}
      </div>
    </>
  )
}

export default FootBtn
