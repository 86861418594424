"use client"
import { useState } from 'react'
import { getCookie } from 'helpers/cookies'
import GoogleMap from 'components/GoogleMap/GoogleMap'
import Modal from '@mui/material/Modal'
import { IconButton, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import styles from '../../../../page.module.scss'

const Client = ({ data, lang }) => {
  const [open, setOpen] = useState(false)
  const { latitude, longitude, distance, address } = data ?? {}
  const userInfo = getCookie('user') || {}

  const handleMapLayer = () => {
    setOpen(true)
  }

  return <>
    {latitude && longitude ? (
      <>
        {userInfo?.latitude && (
          <p className={styles.mapDistance}>
            {lang?.distanceFromHome}: {distance}
          </p>
        )}
        <div className={styles.map_context} onClick={handleMapLayer}>
          <GoogleMap
            lat={Number(latitude)}
            lng={Number(longitude)}
            gestureHandling='none'
            zoomControl={false}
            fullscreenControl={false}
            streetViewControl={false}
            clickable={false}
          />
        </div>
      </>
    ) : null}
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      keepMounted
    // hideBackdrop={true}
    >
      <div className={styles.workLocal}>
        <div className={styles.workLocal_head}>
          <span className={styles.workLocal_head_title}>{lang?.workLocation}</span>

          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: '#BCBCBC' }} />
          </IconButton>
        </div>

        <div className={styles.workLocal_map}>
          <div className={styles.workLocal_map_wrapper}>
            <div className={styles.workLocal_map_wrapper_content} id='googleMapSoaWAErd4'>
              <GoogleMap
                id='googleMapSoaWAErd4'
                lat={Number(latitude)}
                lng={Number(longitude)}
                gestureHandling='auto'
                zoomControl={true}
                fullscreenControl={true}
                streetViewControl={true}
                clickable={true}
                infoWindow={address}
              />
            </div>
          </div>
        </div>

        <div className={styles.workLocal_footer}>
          <Button
            variant='contained'
            sx={{
              width: '153px',
              height: '44px',
              background: '#136FD3',
              borderRadius: '10px',
              float: 'right',
              marginRight: '20px',
              textTransform: 'capitalize'
            }}
            onClick={() => setOpen(false)}
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  </>
}

export default Client