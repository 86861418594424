import React, { useEffect, useRef } from 'react'
// import { Snackbar, Alert } from '@mui/material'
import classNames from 'classnames'
import { message } from 'antd'
import styles from './Toast.module.scss'

type AlertColor = 'success' | 'info' | 'warning' | 'error';

interface SnackProps {
  content: string,
  duration?: number,
  type?: AlertColor
}



const ToastItem = ({ content, duration = 3000, type = 'success' }: SnackProps) => {
  const [open, setOpen] = React.useState(true)
  // const timerRef = useRef<any>(undefined)
  // const handleClose = () => {

  // }
  // const toastStyles = classNames({
  //   [styles.toast]: true,
  //   [styles.success]: type === 'success',
  //   [styles.error]: type === 'error'
  // })

  // useEffect(() => {
  //   timerRef.current = setTimeout(() => {
  //     setOpen(false)
  //   }, duration)
  //   return () => {
  //     clearTimeout(timerRef.current)
  //     timerRef.current = undefined
  //   }
  // }, [duration])

  useEffect(() => {
    if (open) {
      if (type === 'success') {
        message.success(content)
      }
      else if (type === 'error') {
        message.error(content)
      }
    }
  }, [open, type])
  return null
  // return (
  //   <Snackbar
  //     open={open}
  //     autoHideDuration={duration}
  //     anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  //     onClose={handleClose}
  //   >
  //     <Alert onClose={handleClose} severity={type}>
  //       {content}
  //     </Alert>
  //   </Snackbar>
  // )
  // return (
  //   open ? <div className={styles.toastWrap}>
  //     <div
  //       className={toastStyles}
  //       style={{ animation: `toastAnimation ${0.4}s forwards linear` }}
  //     >
  //       <div className={styles.leftContent}>
  //         {type === 'success' ? (
  //           // <img className={styles.icon} src={Correct} alt="Correct" />
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="20"
  //             height="20"
  //             viewBox="0 0 20 20"
  //             fill="none"
  //           >
  //             <path
  //               d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM9.85793 13.0547C9.70267 13.2287 9.48155 13.3298 9.24831 13.3332C9.01507 13.3367 8.79107 13.2422 8.63073 13.0728L5.75472 10.0336L6.96528 8.88802L9.21784 11.2684L13.2786 6.71538L14.5224 7.82474L9.85793 13.0547Z"
  //               fill="#34C759"
  //             />
  //           </svg>
  //         ) : null}
  //         {type === 'error' ? (
  //           // <img className={styles.icon} src={Correct} alt="Correct" />
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             width="20"
  //             height="20"
  //             viewBox="0 0 20 20"
  //             fill="none"
  //           >
  //             <path
  //               d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM10.8345 5.38721V12.0539H9.16781V5.38721H10.8345ZM9.16699 14.9998V13.3332H10.8337V14.9998H9.16699Z"
  //               fill="#FE4A4B"
  //             />
  //           </svg>
  //         ) : null}
  //         <div className={styles.text}>{content}</div>
  //       </div>
  //       {/* <span onClick={() => hideToastRequest()} className={styles.close}>
  //         ✕
  //       </span> */}
  //     </div>
  //   </div > : null
  // )
}

export default ToastItem