'use client'
import React from 'react'
import styles from '../Footer.module.scss'
import Copyright from './Mobile/Copyright'

const Mobile = (props: any) => {
  const { data, countryKey, ...restProps } = props
  return (
    <div className={styles.footerMobileContainer}>
      {/* <AboutLink data={data} {...restProps}/>

      <TalentsLink data={data} {...restProps}/>

      <RecruiterLink data={data} {...restProps}/>

      <PopularJobsLink data={data} {...restProps} /> */}

      {/* <Company data={data} /> */}

      {/* <DownloadApp data={data} /> */}

      {/* <SocialLink data={data} /> */}

      <Copyright data={data} countryKey={countryKey} />
    </div>
  )
}

export default Mobile
