
"use client"
import { Skeleton, Stack } from 'app/components/MUIs'
import styled from '@emotion/styled'


const Loading = () => {
  return <StackDiv>
    <Stack spacing={1} sx={{ width: '100%' }} direction='row' >
      <Skeleton variant='text' width={100} height={44} />
      <Skeleton variant='text' width={100} height={44} />
    </Stack>
  </StackDiv>
}

const StackDiv = styled.div`
  .MuiSkeleton-root {
    transform: unset
  }
`

export default Loading