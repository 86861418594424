export const AI = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M3 5.30078C3 3.64393 4.34315 2.30078 6 2.30078H18C19.6569 2.30078 21 3.64393 21 5.30078V13.6133H19V5.30078C19 4.7485 18.5523 4.30078 18 4.30078H6C5.44772 4.30078 5 4.7485 5 5.30078V18.3008C5 18.8531 5.44771 19.3008 6 19.3008H10.8571V21.3008H6C4.34314 21.3008 3 19.9576 3 18.3008V5.30078Z" fill="#121212" />
    <path d="M7 8.30078C7 7.7485 7.44772 7.30078 8 7.30078L15.5 7.30078C16.0523 7.30078 16.5 7.7485 16.5 8.30078C16.5 8.85307 16.0523 9.30078 15.5 9.30078L8 9.30078C7.44772 9.30078 7 8.85307 7 8.30078Z" fill="#121212" />
    <path d="M7 12.3008C7 11.7485 7.44772 11.3008 8 11.3008H12C12.5523 11.3008 13 11.7485 13 12.3008C13 12.8531 12.5523 13.3008 12 13.3008H8C7.44772 13.3008 7 12.8531 7 12.3008Z" fill="#121212" />
    <path d="M20.7752 22.2915C20.6102 22.2915 20.4764 22.1577 20.4764 21.9926V16.5996C20.4764 16.4346 20.6102 16.3008 20.7752 16.3008H21.6775C21.8426 16.3008 21.9764 16.4346 21.9764 16.5996V21.9926C21.9764 22.1577 21.8426 22.2915 21.6775 22.2915H20.7752Z" fill="#121212" />
    <path d="M14.0664 22.0944C14.0338 22.1912 14.1058 22.2915 14.208 22.2915H15.4854C15.5523 22.2915 15.6109 22.2471 15.6292 22.1829L15.9412 21.0841C15.9594 21.0198 16.0181 20.9754 16.0849 20.9754H17.8145C17.8813 20.9754 17.94 21.0198 17.9582 21.0841L18.2702 22.1829C18.2884 22.2471 18.3471 22.2915 18.4139 22.2915H19.6914C19.7936 22.2915 19.8656 22.1912 19.833 22.0944L17.916 16.4025C17.8955 16.3417 17.8385 16.3008 17.7744 16.3008H16.125C16.0609 16.3008 16.0039 16.3417 15.9834 16.4025L14.0664 22.0944ZM16.9312 17.6183C16.9335 17.6101 16.9411 17.6044 16.9497 17.6044C16.9583 17.6044 16.9658 17.6101 16.9682 17.6183L17.5577 19.6891C17.5848 19.7845 17.5132 19.8794 17.414 19.8794H16.4854C16.3862 19.8794 16.3145 19.7845 16.3417 19.6891L16.9312 17.6183Z" fill="#121212" />
  </svg>
}

export const Web = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M22 12.3008C22 17.8236 17.5228 22.3008 12 22.3008C6.47715 22.3008 2 17.8236 2 12.3008C2 6.77793 6.47715 2.30078 12 2.30078C17.5228 2.30078 22 6.77793 22 12.3008ZM8.01975 13.3008H4.06189C4.44342 16.3604 6.55268 18.8834 9.38254 19.8628C8.63032 18.234 8.12359 15.9156 8.01975 13.3008ZM10.0214 13.3008C10.1143 15.4508 10.5009 17.31 11.0285 18.6291C11.3347 19.3944 11.6492 19.8796 11.8961 20.1407C11.9365 20.1834 11.9711 20.2163 12 20.2415C12.0289 20.2163 12.0635 20.1834 12.1039 20.1407C12.3508 19.8796 12.6653 19.3944 12.9715 18.6291C13.4991 17.31 13.8857 15.4508 13.9786 13.3008H10.0214ZM13.9786 11.3008C13.8857 9.15079 13.4991 7.29158 12.9715 5.97249C12.6653 5.20712 12.3508 4.72196 12.1039 4.46085C12.0635 4.41819 12.0289 4.38529 12 4.36007C11.9711 4.38529 11.9365 4.41819 11.8961 4.46085C11.6492 4.72196 11.3347 5.20712 11.0285 5.97249C10.5009 7.29158 10.1143 9.15079 10.0214 11.3008H13.9786ZM15.9802 13.3008C15.8764 15.9156 15.3697 18.234 14.6175 19.8628C17.4473 18.8834 19.5566 16.3604 19.9381 13.3008H15.9802ZM19.9381 11.3008C19.5566 8.24118 17.4473 5.71814 14.6175 4.73879C15.3697 6.36752 15.8764 8.686 15.9802 11.3008H19.9381ZM4.06189 11.3008H8.01975C8.12359 8.686 8.63032 6.36752 9.38254 4.73879C6.55268 5.71814 4.44342 8.24118 4.06189 11.3008Z" fill="#121212" />
  </svg>
}

export const Employer = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path d="M8.99986 3.30273C6.51573 3.30273 4.50195 5.31651 4.50195 7.80064C4.50195 10.2848 6.51573 12.2985 8.99986 12.2985C11.484 12.2985 13.4978 10.2848 13.4978 7.80064C13.4978 5.31651 11.484 3.30273 8.99986 3.30273ZM6.49776 7.80064C6.49776 6.41877 7.61799 5.29854 8.99986 5.29854C10.3817 5.29854 11.502 6.41877 11.502 7.80064C11.502 9.18251 10.3817 10.3027 8.99986 10.3027C7.61799 10.3027 6.49776 9.18251 6.49776 7.80064Z" fill="#121212" />
    <path d="M5.99986 13.3027C3.79188 13.3027 2.00195 15.0927 2.00195 17.3006V21.2985H15.9978V17.3006C15.9978 15.0927 14.2078 13.3027 11.9999 13.3027H5.99986ZM3.99776 17.3006C3.99776 16.1949 4.89413 15.2985 5.99986 15.2985H11.9999C13.1056 15.2985 14.002 16.1949 14.002 17.3006V19.3027H3.99776V17.3006Z" fill="#121212" />
    <path d="M14 9.80078C14 8.14393 15.3431 6.80078 17 6.80078C18.6569 6.80078 20 8.14393 20 9.80078C20 11.4576 18.6569 12.8008 17 12.8008C15.3431 12.8008 14 11.4576 14 9.80078ZM17 8.80078C16.4477 8.80078 16 9.2485 16 9.80078C16 10.3531 16.4477 10.8008 17 10.8008C17.5523 10.8008 18 10.3531 18 9.80078C18 9.2485 17.5523 8.80078 17 8.80078Z" fill="#121212" />
    <path d="M22 20.3008H17V18.3008H20V17.2383C20 16.6515 19.5243 16.1758 18.9375 16.1758H17V14.1758H18.9375C20.6289 14.1758 22 15.5469 22 17.2383V20.3008Z" fill="#121212" />
  </svg>
}