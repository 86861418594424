import React, { useState, useEffect, useContext } from 'react'
import styles from '../index.module.scss'
import MaterialTextField from 'components/MaterialTextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Text from 'components/Text'
import MaterialDatePicker from 'components/MaterialDatePicker'
import { addUserEducationService } from 'store/services/users/addUserEducation'
import FootBtn from './footBtn'
import moment from 'moment'
import { removeEmptyOrNullValues } from 'helpers/formatter'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useSyncResumeContext } from '../context'
import { RequiredLabel } from './RequireLabel'

const getDate = (date) => {
  return date ? new Date(date) : null
}
const isSubmitDisabled = (form, { isCurrent, from, to }) => {
  let disabled = false
  const keys = Object.keys(form)
  let key = keys.find(key => {
    return !form[key]
  })

  disabled = Boolean(key)
  if (!disabled) {
    disabled = Boolean(isCurrent ? !from : !(from && to))
  }
  return disabled
}
const removeFieldStudy = (form) => {
  let copy = { ...form }
  delete copy.fieldStudy

  return copy
}

const EducationExperience = (props: any) => {
  const {
    config: { degrees },
    lang,
  } = props
  const { value, onSkipClick, onSubmit } = useSyncResumeContext()

  const [isCurrentStudying, setIsCurrentStudying] = useState(value.is_currently_study_here)
  const [studyPeriodFrom, setStudyPeriodFrom] = useState(() => getDate(value.from_date))
  const [studyPeriodTo, setStudyPeriodTo] = useState(() => getDate(value.to_date))
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    schoolName: value.school_name || '',
    eduLevel: "",
    fieldStudy: value.course || ""
  })

  useEffect(() => {
    document.scrollingElement.scrollTo(0, 0)
  }, [])

  const {
    schoolName,
    fieldOfStudy,
    currentlyAttending,
    from,
    to,
    educationExperience,
    educationLevel,
    fillThisLater,
    Next3,
    back,
    studyPeriod,
    skip
  } = lang?.profile || {}
  const {
    comm = {}
  } = lang;
  const educationData = {
    school: form.schoolName,
    is_currently_studying: isCurrentStudying,
    study_period_from: `${moment(new Date(studyPeriodFrom)).format('yyyy-MM')}-01`,
    study_period_to: isCurrentStudying
      ? null
      : `${moment(new Date(studyPeriodTo)).format('yyyy-MM')}-01`,
    field_of_study: form.fieldStudy,
    degree_id: form.eduLevel
  }

  const handleSubmit = () => {
    setLoading(true)
    const educationPayload = {
      educationData: removeEmptyOrNullValues(educationData)
    }
    addUserEducationService(educationPayload)
      .then((res) => {
        onSubmit()
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.work}>
      <div className={styles.workContainer}>
        <div className={styles.box}>
          <div className={styles.body}>

            <div className={styles.stepField}>
              <MaterialTextField
                className={styles.stepFullwidth}
                label={<RequiredLabel text={schoolName} />}
                // placeholder={schoolName}
                size='small'
                value={form.schoolName}
                variant='outlined'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                defaultValue={form.fieldStudy}
                onChange={(e) => setForm(pre => {
                  return { ...pre, schoolName: e.target.value }
                })}
              />
            </div>

            <div className={styles.stepField}>
              <FormControl fullWidth
                className={styles.eduSelectWrapper}>
                <InputLabel id="edu-select-label">
                  <RequiredLabel text={educationLevel || 'edu level'} />
                </InputLabel>
                <Select
                  labelId="edu-select-label"
                  className={styles.stepFullwidth}
                  label={<RequiredLabel text={educationLevel || 'edu level'} />}
                  size='small'
                  value={form.eduLevel}
                  variant='outlined'

                  defaultValue={form.eduLevel}
                  onChange={(e) => {
                    const { value } = e.target
                    setForm(pre => {
                      return { ...pre, eduLevel: value }
                    })
                  }}
                >
                  {degrees.map(v => {
                    return <MenuItem key={v.id} value={v.id}>{v.value} </MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }} className={styles.stepFieldToItem}>
              <p className={`${styles.title} ${styles.titlePeriod}`}>
                {studyPeriod}<span>*</span>
              </p>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCurrentStudying}
                    onChange={() => setIsCurrentStudying(!isCurrentStudying)}
                    name='currentStudent'
                  />
                }
                label={<Text textStyle='base'>{currentlyAttending}</Text>}
              />
              <p className={styles.title}>
                {comm.From}
              </p>
              <div className={styles.stepFieldDateItem}>
                <MaterialDatePicker
                  // style={{ padding: '10px 12px' }}
                  label={comm['month/year'] || 'Month / Year'}
                  views={['year', 'month']}
                  inputFormat='MMM yyyy'
                  value={studyPeriodFrom}
                  onDateChange={(value) => {
                    setStudyPeriodFrom(value)
                  }}
                />
              </div>

              {!isCurrentStudying && (
                <>
                  <p className={styles.title}>
                    {/* To */}
                    {comm.To}
                  </p>
                  <div className={styles.stepFieldDateItem}>
                    <MaterialDatePicker
                      // label={to}
                      label={comm['month/year'] || 'Month / Year'}
                      views={['year', 'month']}
                      inputFormat='MMM yyyy'
                      value={studyPeriodTo}
                      onDateChange={(value) => {
                        setStudyPeriodTo(value)
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            <div className={styles.stepField}>
              <MaterialTextField
                className={styles.stepFullwidth}
                placeholder={fieldOfStudy}
                label={fieldOfStudy}
                size='small'
                value={form.fieldStudy}
                variant='outlined'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                defaultValue={form.fieldStudy}
                onChange={(e) => {
                  setForm(pre => {
                    return { ...pre, fieldStudy: e.target.value }
                  })
                }}
              />
            </div>
          </div>
        </div>

        <FootBtn
          handleClick={handleSubmit}
          skipClick={onSkipClick}
          loading={loading}
          disabled={isSubmitDisabled(removeFieldStudy(form), { isCurrent: isCurrentStudying, from: studyPeriodFrom, to: studyPeriodTo })}
        />
      </div>
    </div>
  )
}

export default EducationExperience
