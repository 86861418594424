/* eslint-disable @next/next/no-img-element */
import React from 'react'
import styles from '../index.module.scss'
import { BossjobLogo } from 'images'
import Link from 'components/Link'
import MaterialButton from 'components/MaterialButton'
import linkToHunt from 'helpers/linkToHunt'
import { LogoIcon } from 'components/Header/Common/NavLogo'
const Header = (props: any) => {
  const { lang, langKey } = props
  return (
    <div className={styles.header}>
      <div className={styles.headerMain}>
        <Link href={'/'}>
          <LogoIcon />
        </Link>
        <div>
          <Link style={{ marginLeft: '20px' }} href={linkToHunt('boss')}>
            <MaterialButton
              variant='outlined'
              size='medium'
              capitalize
              sx={{
                height: '40px !important',
                border: '1px solid #2378E5',
                borderRadius: '4px',
                backgroundColor: '#fff',
                color: '#2378E5',
                padding: '0 20px',
                ':hover': {
                  border: '1px solid #2378E5',
                  background: '#2378E5',
                  color: '#fff'
                }
              }}
            >
              {lang?.profile?.ImHiring}
            </MaterialButton>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
