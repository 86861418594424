import styles from './index.module.scss'
import { KeyboardArrowLeftOutlined, Close } from '@mui/icons-material'
import classNames from 'classnames'

const Header = (props: any) => {
  const { title, onBack, onClose } = props
  return (
    <div
      className={classNames({
        [styles.header]: true
      })}
    >
      <div className={styles.headerContainer}>
        <div className={styles.headerLeft}>
          {onBack && (
            <span onClick={onBack} style={{ padding: '4px 0px 0 0px' }}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='17'
                viewBox='0 0 16 17'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M11.1377 14.3047C10.8774 14.5651 10.4553 14.5651 10.1949 14.3047L4.8616 8.9714C4.60125 8.71106 4.60125 8.28895 4.8616 8.0286L10.1949 2.69526C10.4553 2.43491 10.8774 2.43491 11.1377 2.69526C11.3981 2.95561 11.3981 3.37772 11.1377 3.63807L6.27582 8.5L11.1377 13.3619C11.3981 13.6223 11.3981 14.0444 11.1377 14.3047Z'
                  fill='#121212'
                />
              </svg>
            </span>
          )}
          <label>{title}</label>
        </div>
        {onClose && <Close onClick={onClose} />}
      </div>
    </div>
  )
}
export default Header
