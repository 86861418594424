'use client'
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Input, Tooltip, Radio, Space, DatePicker } from 'antd';
import { addEventTag, isMobileDevice } from 'helpers/utilities';
import { getCountryKey } from 'helpers/country';
import { usePathname } from 'next/navigation';
import dayjs from 'dayjs';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { LinkContext } from 'app/components/providers/linkProvider';

import { uploadUserAvatarService } from 'store/services/users/uploadUserAvatar';
import { updateUserCompleteProfileService } from 'store/services/users/updateUserCompleteProfile';

import { avatar3 } from '../../images'

import { getDefaultAvatarNumber, getUrlSearchString, isChatProcess, jumpPage } from '../utils';
import Step from '../Step';
import SafeInfo from '../Common/SafeInfo';
import AvatarModal from '../AvatarModal';
import { AvatarIcon, HelperIcon, InfoIcon, UploadIcon } from './icons';
import BaseProfileH5 from './h5';

import LoadingButton from '../Common/LoadingButton';
import { useMainResumeContext } from '../Main/context';

const BaseProfile = (props: {
  lang: any
  config: any
}) => {
  const { lang = {} } = props
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const [loading, setLoading] = useState(false)
  const profile = lang.profile || {}
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  // console.log('profile::', profile)

  const steps = [
    {
      title: profile.steps?.text1,
      content: 'First-content',
    },
    {
      title: profile.steps?.text2,
      content: 'Second-content',
    }
  ];

  const experiencedList = [
    {
      label: lang?.profile?.experienced,
      value: 1
    },
    {
      label: lang?.profile?.freshGraduate,
      value: 2
    }
  ]

  const sgIdentityList = useMemo(() => {
    return props.config?.user_identity_lists || []
  }, [props.config])

  const noticePeriodList = useMemo(() => {
    return props.config?.notice_period_lists || []
  }, [props.config])

  // console.log('experiencedList::', experiencedList)
  // console.log('sgIdentityList::', sgIdentityList)
  // console.log('noticePeriodList::', noticePeriodList)

  const [openAvatarModal, setOpenAvatarModal] = useState(false)

  const countryKey = getCountryKey()
  const isSG = countryKey === 'sg'
  const chatProcess = isChatProcess()

  const [hasWorkExp, setHasWorkExp] = useState(false)
  const [needNextStep, setNeedNextStep] = useState(true)

  const [formData, setFormData] = useState({
    avatar: null,
    avatar_file: null,
    first_name: null,
    last_name: null,
    work_exp: null,
    availability: null,
    sg_identity: null,
    work_since: null,
    select_avatar_index: null
  })

  const disabledSubmit = useMemo(() => {
    if (formData.work_exp == 1 && !formData.work_since) {
      return true
    }
    if (isSG && !formData.sg_identity) {
      return true
    }

    if (formData.first_name?.length > 50 || formData.last_name?.length > 50) {
      return true
    }

    return !formData.first_name || !formData.last_name || !formData.availability || !formData.avatar
  }, [formData])

  // console.log('disabledSubmit::', { disabledSubmit, formData })

  useEffect(() => {
    // console.log('userDetail::', userDetail)
    if (userDetail?.id) {
      const { first_name, last_name,
        avatar, notice_period_id, is_basic_info,
        working_since, is_fresh_graduate, user_identity,
        is_resume, is_educations, is_work_experiences
      } = userDetail

      const numberPart = getDefaultAvatarNumber(avatar)

      setFormData({
        ...formData,
        select_avatar_index: numberPart || null,
        avatar: avatar || avatar3.src,
        avatar_file: null,
        first_name: first_name || null,
        last_name: last_name || null,
        work_exp: !is_basic_info ? null : is_fresh_graduate ? 2 : 1,
        availability: notice_period_id || null,
        sg_identity: user_identity ? user_identity.id : null,
        work_since: working_since ? dayjs(working_since) : null,
      })
      // 是否进入一下步骤
      setNeedNextStep(!(is_resume || (is_educations && is_work_experiences)))
      setHasWorkExp(!is_basic_info ? false : is_fresh_graduate ? false : true)
    }
  }, [JSON.stringify(userDetail)])

  const handleBack = () => {
    if (chatProcess) {
      const searchWords = getUrlSearchString(1)
      push(`${pathname}${searchWords}`)
    } else {
      window.history.back()
    }
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        notice_period_id: formData.availability,
        is_fresh_graduate: formData.work_exp == 1 ? 0 : 1  //0 有经验的，1应届生
      }

      if (countryKey === 'sg') {
        payload['user_identity_id'] = formData.sg_identity
      }

      if (formData.work_exp == 1) {
        payload['working_since'] = formData.work_since ? formData.work_since.format('YYYY-MM-DD') : null
      }

      handleUpdateProfile(payload)
      // push(`${pathname}?step=3`)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUploadAvatar = () => {
    setOpenAvatarModal(true)
  }


  const handleUpdateProfile = async (payload) => {
    console.log('payload', { payload, formData })

    setLoading(true)
    const searchWords = getUrlSearchString(3)

    addEventTag(chatProcess ? 'new_user_chat_basic_next' : 'new_user_complete_basic_next')

    if (formData.avatar_file) {
      let newFile = formData.avatar_file
      if (formData.select_avatar_index) {
        const filename = `icon-avatar-${formData.select_avatar_index}.png`;
        newFile = new File([formData.avatar_file], filename, { type: formData.avatar_file.type });
      }
      // console.log('newFile', newFile)
      await uploadUserAvatarService(newFile).catch((error) => {
        console.log('error', error)
      })
    }

    updateUserCompleteProfileService(payload)
      .then((res) => {
        if (res.data) {
          if (getUserInfo) {
            getUserInfo().then(() => {
              if (needNextStep) {
                let url = `${pathname}${searchWords}`
                push(url)
              } else {
                jumpPage()
              }
            })
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => setLoading(false))
  }

  const handleAvatarImageFile = (file: any, index: number) => {
    console.log('get file', file, index)
    setFormData({
      ...formData,
      avatar: URL.createObjectURL(file),
      avatar_file: file,
      select_avatar_index: index
    })
    setOpenAvatarModal(false)
  }

  const isMobile = isMobileDevice()

  if (isMobile) {
    return <BaseProfileH5 {...props} />
  }

  const validateNameErrorTip = (first, last) => {
    if (first?.length > 50 || last?.length > 50) {
      return false
    }
    return true
  }

  const disabledDate = (current) => {
    return current && current > dayjs();
  }

  return (
    <WrapContainer>
      <div className={'baseProfile'}>
        {
          needNextStep && (<div className={'baseProfile_step'}>
            <Step items={steps} current={0} />
          </div>)
        }
        <div className={'baseProfile_main'}>
          <AvatarIcon className={'baseProfile_icon'} />
          <h1 className={'baseProfile_title'}>{profile.baseProfile?.title}</h1>
          <p className={'baseProfile_subtitle'}>{profile.baseProfile?.subtitle}</p>

          <div className={'baseProfile_avatar'}>
            <div className={'baseProfile_avatarImg'} onClick={handleUploadAvatar}>
              <Avatar src={formData.avatar} alt="avatar" size={60} />
              <UploadIcon className={'baseProfile_uploadIcon'} />
            </div>
            <p className={'baseProfile_avatarText'}>
              <InfoIcon /> {profile.baseProfile?.avatarTip}
            </p>
          </div>

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.baseProfile?.fullName}</p>
            <p className={'baseProfile_info'}>
              {profile.baseProfile?.fullNameTip}
              <Tooltip overlayStyle={{ maxWidth: '400px' }} placement="top" title={profile.baseProfile?.fullNameMoreTip}>
                <HelperIcon className={'baseProfile_helperIcon'} />
              </Tooltip>
            </p>
            <div className={'baseProfile_value'}>
              <Input
                value={formData.first_name}
                onChange={(e) => setFormData({ ...formData, first_name: (e.target.value || '').trimStart() })}
                className={'baseProfile_input'}
                maxLength={100}
                placeholder={profile.baseProfile?.firstName} />
              <Input
                value={formData.last_name}
                onChange={(e) => setFormData({ ...formData, last_name: (e.target.value || '').trimStart() })}
                className={'baseProfile_input'}
                maxLength={100}
                placeholder={profile.baseProfile?.lastName} />
            </div>
            {
              validateNameErrorTip(formData.first_name, formData.last_name) ? null : (
                <p className={'baseProfile_error'}>{profile.baseProfile?.checkNameTip}</p>
              )
            }
          </div>

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.baseProfile?.workExperience}</p>
            <div className={'baseProfile_value'}>
              <Radio.Group
                className={'baseProfile_radio'}
                value={formData.work_exp}
                onChange={(e) => {
                  setHasWorkExp(e.target.value == 1)
                  setFormData({ ...formData, work_exp: e.target.value })
                }}
              >
                <Space direction="vertical">
                  {
                    experiencedList.map(item => (
                      <Radio
                        value={item.value}
                        key={item.value}
                      >
                        {item.label}
                      </Radio>
                    ))
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>

          {
            hasWorkExp && (
              <div className={'baseProfile_item'}>
                <p className={'baseProfile_label'}>{profile.baseProfile?.startWorkSince}</p>
                <div className={'baseProfile_value'}>
                  <DatePicker
                    picker="month"
                    className={'baseProfile_date'}
                    value={formData.work_since}
                    inputReadOnly={true}
                    disabledDate={disabledDate}
                    placeholder={profile.baseProfile?.startWorkSincePlaceholder}
                    onChange={(e) => {
                      setFormData({ ...formData, work_since: e })
                    }}
                  />
                </div>
              </div>
            )
          }

          <div className={'baseProfile_item'}>
            <p className={'baseProfile_label'}>{profile.availability}</p>
            <p className={'baseProfile_info'}>{profile.availabilityTips}</p>
            <div className={'baseProfile_value'}>
              <Radio.Group
                className={'baseProfile_radio'}
                value={formData.availability}
                onChange={(e) => setFormData({ ...formData, availability: e.target.value })}
              >
                <Space direction="vertical">
                  {
                    noticePeriodList.map(item => (
                      <Radio
                        value={item.id}
                        key={item.id}
                      >
                        {item.value}
                      </Radio>
                    ))
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>

          {
            isSG && (
              <div className={'baseProfile_item'}>
                <p className={'baseProfile_label'}>{profile.singaporeIdentity}</p>
                <div className={'baseProfile_value'}>
                  <Radio.Group
                    className={'baseProfile_radio'}
                    value={formData.sg_identity}
                    onChange={(e) => setFormData({ ...formData, sg_identity: e.target.value })}
                  >
                    <Space direction="vertical">
                      {
                        sgIdentityList.map(item => (
                          <Radio
                            value={item.id}
                            key={item.id}
                          >
                            {item.value}
                          </Radio>
                        ))
                      }
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            )
          }

          <div className={'baseProfile_footer'}>
            {chatProcess && <button className={'baseProfile_back'} onClick={handleBack}>{profile.back}</button>}

            <LoadingButton
              className={classNames('baseProfile_continue')}
              loading={loading}
              disabledSubmit={disabledSubmit || loading}
              onSubmit={handleSubmit}
              text={needNextStep ? profile.continue : profile.confirm}
            />
          </div>
        </div>
      </div>
      <SafeInfo lang={lang} />

      {openAvatarModal && <AvatarModal
        open={openAvatarModal}
        lang={lang}
        onOk={handleAvatarImageFile}
        selectAvatarIndex={formData.select_avatar_index}
        onCancel={() => setOpenAvatarModal(false)}
      />}
    </WrapContainer>
  );
};

export default BaseProfile;

const WrapContainer = styled.div`
.baseProfile {
  width: 600px;
  box-sizing: border-box;
  margin: 48px auto;

  &_step {
    margin-bottom: 24px;
    padding-left: 48px;
  }

  &_main {
    display: flex;
    margin: 0 auto;
    width: 600px;
    min-height: 600px;
    padding: 32px 48px;
    flex-direction: column;
    align-items: start;
    border-radius: 12px;
    background: linear-gradient(168deg, #e5f1ff 1.67%, #fff 11.82%, #fff 91.29%);
    box-sizing: border-box;
  }

  &_icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  &_title {
    color: #121212;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 32px;
  }

  &_subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }

  &_avatar {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  &_avatarImg {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    position: relative;
    border: 1px solid #f4f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-avatar {
      border: 0;
    }
  }

  &_uploadIcon {
    position: absolute;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    z-index: 1;
    bottom: 0;
    right: 0;
  }

  &_avatarText {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 16px;

    color: #ff9500;
    // font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;

    svg {
      flex-shrink: 0;
    }
  }

  &_item {
    margin-top: 32px;
    width: 100%;
  }

  &_label {
    color: #121212;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 4px;
  }

  &_info {
    color: #b8b8b8;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_helperIcon {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    margin-left: 4px;
    vertical-align: bottom;
    cursor: pointer;
  }

  &_helperIcon:hover path{
    fill: #515151;
  }

  &_value {
    margin-top: 12px;
    display: flex;
    gap: 8px;

    .ant-input {
      height: 42px;
      border-radius: 4px;
    }
  }

  &_radio {
    .ant-space {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &_input {
    width: 50%;
    height: 42px;
    outline: none;
    padding: 4px 11px;
    border-width: 1px;
    color: #515151;
    border-style: solid;
  }

  &_input::placeholder {
    color: #7d7d7d;
  }

  &_date {
    height: 42px;
    border-radius: 4px;
    width: 100%;
    .ant-picker {
      height: 42px;
      border-color: #d9dbde;
    }
    .ant-picker-input input::placeholder{
      color: #7d7d7d;
    }
  }

  &_footer {
    margin-top: 56px;
    display: flex;
    gap: 13px;
  }

  &_back {
    outline: none;
    border: 0;
    display: flex;
    height: 44px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 30px;
    border: 1px solid #e4e4e4;

    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    background: #fff;
  }

  &_error {
    color: red;
    margin-top: 8px;
  }

  &_continue {
    outline: none;
    border: 0;
    display: flex;
    height: 44px;
    padding: 10px 50px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    background: #2378e5;

    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }

  &_continue_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
`