'use client'

import React, { useEffect, useRef, useState } from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"

import styles from './mobileCountrySelect.module.scss'
import Button from "components/Button"

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M6.5 6.79993L17.5 17.7999" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M6.5 17.7999L17.5 6.79993" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>

const UpIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M12.4595 9.83955L11.5402 10.7588L7.99985 7.21841L4.45947 10.7588L3.54023 9.83955L7.99985 5.37993L12.4595 9.83955Z" fill="black" fillOpacity="0.6" />
</svg>

const CheckIcon = React.memo(() => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <rect y="0.299927" width="16" height="16" rx="3" fill="#0052D9" />
  <path d="M5.12117 10.6541C5.12112 10.654 5.12122 10.6541 5.12117 10.6541L3 8.53291L4.43479 7.13928L6.53768 9.24217L11.4949 4.29993L12.8995 5.70449L7.94224 10.6467L7.94975 10.6542L7.92887 10.6745C7.8856 10.7165 7.84091 10.7562 7.79496 10.7936L7.23299 11.3538C6.84229 11.7433 6.20995 11.7429 5.81985 11.3528L5.12117 10.6541Z" fill="white" fillOpacity="0.9" />
</svg>)

const RectIcon = React.memo(() => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <rect x="0.5" y="0.799927" width="15" height="15" rx="2.5" fill="white" fillOpacity="0.9" stroke="#DCDCDC" />
</svg>)

const IndeterminateIcon = React.memo(() => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <rect x="0.5" y="0.799927" width="15" height="15" rx="2.5" fill="#0052D9" stroke="#0052D9" />
  <rect x="4" y="7.29993" width="8" height="2" fill="white" fillOpacity="0.9" />
</svg>)

type valueType = {
  children: Set<KeyType>;
  selectAll: boolean;
}

const mapCountryIdToContinent = (data) => {
  let map = {};
  data = data || [];

  data.forEach(continent => {
    let { id: cid, value, countries } = continent;
    countries.forEach(country => {
      const { id } = country
      map[id] = cid
    });
  });
  return map
}

// see more details ==> https://mui.com/material-ui/react-drawer/
export const MobileCountrySelector = (
  {
    open,
    placement = 'bottom',
    data,
    value,
    onClose,
    onOk,
    header
  }: any
) => {
  const newData = data;
  const [expand, setExpand] = useState({})
  // there are some conditions are not strict. based on this structure, need to optimize it future
  const [innerValue, setInnerValue] = useState<Record<string, valueType>>({})
  const ref = useRef({
    initValue: {}
  })

  useEffect(() => {
    if (value === undefined || !data.length) {
      return
    }
    const countryContinentMap = mapCountryIdToContinent(data);
    let newValue = {};
    if (value) {
      (Array.isArray(value) ? value : [value]).forEach(id => {
        id = Number(id)
        let cid = countryContinentMap[id]
        let value = newValue[cid] || { children: new Set() }
        value.children.add(id)
        newValue[cid] = value
      });
    }
    setInnerValue(newValue)
    ref.current.initValue = newValue;
  }, [value, data])
  const safeClose = () => {
    setInnerValue(ref.current.initValue)
    onClose?.()
    setExpand({})
  }

  return <SwipeableDrawer
    anchor={placement as any}
    open={open}
    onClose={safeClose}
    onOpen={() => { }}
    className={styles.mobileSelectForCountryWrapper}
  >
    <div
      className={styles.mobileSelectForCountry}
    >
      {/* header */}
      <div className={styles.mobileSelectForCountryHeader}>
        {/* open country */}
        {header}
        <span onClick={safeClose}>
          <CloseIcon />
        </span>
      </div>

      <div className={styles.mobileSelectForCountryContent}>
        {newData.map(v => {
          const parentId = v.id
          const sectionOpen = Boolean(expand[parentId])
          const locations = v.countries

          const currentValue = innerValue[parentId] || {
            children: new Set(),
            // selectAll: false
          }
          const selectedLength = currentValue.children.size
          const allSelected = selectedLength === locations?.length

          return <div key={parentId}>
            <div
              className={styles.mobileSelectForCountry_sectionHeader}
              aria-expanded={sectionOpen}
              data-expand={sectionOpen}
              onClick={(e) => {
                setExpand(pre => ({ ...pre, [parentId]: !pre[parentId] }))
              }}
            >
              <span className={styles.mobileSelectForCountry_label}>
                <span onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  // check all or not
                  const newValue = allSelected ? new Set() : new Set(locations.map(v => v.id));
                  setInnerValue(pre => ({
                    ...pre, [parentId]: {
                      children: newValue,
                      // selectAll: false
                    }
                  }))
                }}>
                  {allSelected ?
                    <CheckIcon /> :
                    selectedLength ?
                      <IndeterminateIcon /> :
                      <RectIcon />}
                </span>
                {v.value}
              </span>
              <UpIcon />
            </div>
            {Boolean(locations?.length) &&
              <div
                data-expand={sectionOpen}
                className={styles.mobileSelectForCountry_sectionContent}
              >
                {locations.map(item => {
                  const currentId = item.id
                  let set = currentValue?.children
                  return <div key={currentId}
                    onClick={(e) => {
                      const newSet = new Set(set);
                      if (newSet.has(currentId)) {
                        newSet.delete(currentId);
                      } else {
                        newSet.add(currentId);
                      }

                      setInnerValue(pre => {
                        return {
                          ...pre,
                          [parentId]: {
                            children: newSet,
                            // selectAll: false
                          }
                        }
                      })

                    }}
                    className={styles.mobileSelectForCountry_label}>
                    {set.has(currentId) ? <CheckIcon /> : <RectIcon />}
                    {item.value}
                  </div>
                })}
              </div>
            }
          </div>
        })}
      </div>
      {/* footer */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 12, padding: '8px 20px' }}>
        <Button type='button' onClick={safeClose}>Cancel</Button>
        <Button primary style={{ flex: 1 }}
          onClick={() => {
            const values = [];
            Object.keys(innerValue).map(v => {
              values.push(...Array.from(innerValue[v].children))
            })
            onOk?.(values)
            setExpand({})
          }}
        >Confirm</Button>
      </div>
    </div>
  </SwipeableDrawer >
}