'use client'
import { useState, useEffect, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { Stack } from 'app/components/MUIs'
import MaterialButton from 'components/MaterialButton'
import { getCookie } from 'helpers/cookies'
import useChatNow from 'app/models/hooks/useChatNow'
import { addEventTagForH5 } from 'helpers/utilities'
import { useSearchParams } from 'next/navigation'

import SaveH5 from './SaveH5'
import JobClientH5 from './JobClientH5'

type propsType = {
  is_saved: boolean
  jobId: number
  className?: string
  jobDetail: any
  isMobile: boolean
  header: { [key: string]: string }
}

const Btn = (props: propsType) => {
  const searchParams = useSearchParams()
  const { className, jobDetail, is_saved, jobId, header } = props
  const source = searchParams.get('source')
  const chat = jobDetail?.chat ?? {}
  const userInfo = getCookie('user')
  const isLogin = getCookie('sessionid') ? true : false
  const { status_key } = jobDetail
  const [loading, chatNow, changeJobModal, userData] = useChatNow(jobDetail, source ?? "job_details")
  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    document.addEventListener('scroll', handleAddHeadBoxShadow)
    return () => document.removeEventListener('scroll', handleAddHeadBoxShadow)
  }, [])

  useEffect(() => {
    if (isLogin) {
      setDisabled(!userData?.id)
    } else {
      setDisabled(false)
    }
  }, [isLogin, userData])

  const handleAddHeadBoxShadow = () => {
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop
    const headNode = document.querySelector('#jobDetaiPagelHead')
    if (!headNode) return
    if (scrollTopHeight > 55) {
      ; (headNode as HTMLElement).style.boxShadow = '10px 5px 5px rgba(217,217,217, 0.6)'
    } else {
      ; (headNode as HTMLElement).style.boxShadow = 'unset'
    }
  }
  const handleBtnEvent = () => {
    if (!disabled) {
      ; (chatNow as any)?.(jobDetail)
      if (isLogin) {
        addEventTagForH5('sgh5_jobdetail_success_chat')
      } else {
        addEventTagForH5('sgh5_job_detail_chat')
        // sessionStorage.setItem('click_source_from', 'chat')
      }

    }
  }

  const handleGetChatNowElement = () => {
    return (
      <MaterialButton
        variant='contained'
        disabled={disabled}
        sx={{
          background: '#2378E5',
          border: disabled ? 0 : '1px solid #136FD3',
          borderRadius: '8px',
          minWidth: '160px',
          fontWeight: '400',
          lineHeight: '44px',
          height: '44px',
          color: '#ffffff',
          boxShadow: 'none',
          fontSize: '16px',
          '&:hover': {
            background: '#1B66C9',
            color: '#ffffff',
            boxShadow: 'none'
          }
        }}
        isLoading={loading as boolean}
        onClick={() => handleBtnEvent()}
      >
        <span style={{ textTransform: 'none' }}>
          {(() => {
            if (jobDetail.external_apply_url) {
              return header?.apply
            } else if (chat?.is_exists) {
              return header?.continueChat
            } else {
              return header?.chatNow
            }
          })()}
        </span>
      </MaterialButton>
    )
  }
  return (
    <>
      {status_key == 'active' ? (
        <Stack spacing={2} direction='row' className={className}>
          {isMobile && (
            <>
              <SaveH5 is_saved={is_saved} jobId={jobId} jobDetail={jobDetail} headerLang={header} />
              <JobClientH5 id={jobId} job_url={jobDetail.job_url} isLogin={isLogin} />
            </>
          )}
          {/* {userInfo?.id == jobDetail?.recruiter_id
            ? jobDetail.external_apply_url
              ? handleGetChatNowElement()
              : null
            : handleGetChatNowElement()} */}

          {userInfo?.id === jobDetail?.recruiter_id
            ? null
            : handleGetChatNowElement()}
        </Stack>
      ) : null}

      {changeJobModal}
    </>
  )
}

export default Btn
