'use client'
import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { LinkContext } from 'app/components/providers/linkProvider';
import { usePathname } from 'next/navigation';
import { addEventTag, isMobileDevice } from 'helpers/utilities';

import { ReactComponent as ResumeIcon } from '../../images/svg/resume.svg';
import { ReactComponent as UploadIcon } from '../../images/svg/upload.svg';
import { ReactComponent as EditIcon } from '../../images/svg/edit.svg';

import { useAttachedResumeContext, withAttachedResumeProvider } from './context';
import { useSchool } from './hooks';
import { getUrlSearchString, isChatProcess } from '../utils';

import SafeInfo from '../Common/SafeInfo';
import Step from '../Step';
import AttachedResumeH5 from './h5'
import ResumeOnlineModal from '../ResumeOnline';
import UploadResumeModal from '../UploadFile';
import { useMainResumeContext } from '../Main/context';

const AttachedResume = (props: {
  lang: any;
  config: any
}) => {
  const { lang } = props;
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [openOnlineModal, setOpenOnlineModal] = useState(false)
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const isMobile = isMobileDevice()
  const chatProcess = isChatProcess()
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const steps = [
    {
      title: profile.steps?.text1,
      content: 'First-content',
    },
    {
      title: profile.steps?.text2,
      content: 'Second-content',
    }
  ];

  const { setUserDetail, initData } = useAttachedResumeContext()
  const schoolList = useSchool()

  useEffect(() => {
    if (userDetail?.id) {
      setUserDetail(userDetail)
    }
  }, [userDetail])

  const handleBack = () => {
    const searchWords = getUrlSearchString(2)
    push(`${pathname}${searchWords}`)
  }

  const openUploadResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_upload' : 'new_user_complete_resume_upload')
    setOpenUploadModal(true)
  }

  const openOnlineResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_fill' : 'new_user_complete_resume_fill')
    Promise.resolve(initData(userDetail))
      .then(() => {
        setOpenOnlineModal(true)
      })
  }

  const handleUploadResume = () => {
    setOpenUploadModal(false)
  }

  const handleOnlineResume = () => {
    setOpenOnlineModal(false)
  }

  if (isMobile) {
    return (
      <AttachedResumeH5 {...props} schoolList={schoolList} />
    )
  }

  return (
    <AttachedResumeWrap>
      <div className='profileStep'>
        <Step items={steps} current={1} />
      </div>
      <div className="attachedResume">
        <ResumeIcon className="resumeIcon" />
        <h1>{profile.attachedResume?.title}</h1>
        <Options>
          <Option onClick={openUploadResumeModal}>
            <div className="textContainer">
              <p className="optionTitle">{profile.attachedResume?.upload}</p>
              <p className="optionSubtitle">{profile.attachedResume?.uploadTip}</p>
            </div>
            <UploadIcon className="icon" />
          </Option>
          <Option onClick={openOnlineResumeModal}>
            <div className="textContainer">
              <p className="optionTitle">{profile.attachedResume?.online}</p>
              <p className="optionSubtitle">{profile.attachedResume?.onlineTip}</p>
            </div>
            <EditIcon className="icon" />
          </Option>
        </Options>
        <BackButton onClick={handleBack}>
          <p className="countdownText">{profile.back}</p>
        </BackButton>
      </div>
      <SafeInfo lang={lang} />

      {
        openOnlineModal && (
          <ResumeOnlineModal
            {...props}
            userDetail={userDetail}
            getUserInfo={getUserInfo}
            open={openOnlineModal}
            onOk={handleOnlineResume}
            onCancel={() => setOpenOnlineModal(false)}
            schoolList={schoolList}
          />
        )
      }

      {
        openUploadModal && (
          <UploadResumeModal
            {...props}
            userDetail={userDetail}
            getUserInfo={getUserInfo}
            open={openUploadModal}
            onOk={handleUploadResume}
            onCancel={() => setOpenUploadModal(false)}
          />
        )
      }
    </AttachedResumeWrap>
  );
};

const AttachedResumeWrap = styled.div`
  display: flex;
  width: 600px;
  margin: 48px auto;
  flex-direction: column;

  .profileStep {
    margin-bottom: 24px;
    padding-left: 48px;
  }

  .attachedResume {
    min-height: 600px;
    padding: 32px 48px;
    border-radius: 12px;
    background: linear-gradient(168deg, #E5F1FF 1.67%, #FFF 11.82%, #FFF 91.29%);
    margin-bottom: 48px;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    margin-bottom: 4px;
  }

  p.subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg.resumeIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 32px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 36px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 20px;
  margin-bottom: 20px;
  background: #F8FBFE;
  border-radius: 4px;

  &:hover {
    background: #EFF7FF;
    cursor: pointer;
  }

  .textContainer {
    flex-grow: 1;
    max-width: calc(100% - 40px);
  }

  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .optionTitle {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }

  .optionSubtitle {
    margin-top: 6px;
    font-size: 14px;
    color: #7D7D7D;
    word-wrap: break-word; /* Ensures long text will wrap */
  }
`;

const BackButton = styled.div`
  display: inline-block;
  height: 44px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #E4E4E4;
  cursor: pointer;

  p.countdownText {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }
`;

export default withAttachedResumeProvider(AttachedResume);