/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
'use client'
import React, { useState, useCallback, useEffect, useMemo, useRef, useContext } from 'react'
import { flushSync } from 'react-dom'
import { flatMap } from 'lodash-es'
import JobSearchBar from '../../../../../../components/commons/location/search'
import styles from '../../index.module.scss'
import Single from 'app/components/mobile/select/single'
import MultyGroup from 'app/components/mobile/select/groupedMulty'
import { useSuggest } from './hooks'
import theme from 'app/components/mobile/theme'
import { ThemeProvider } from '@mui/material/styles'
import JobFunction from 'app/components/commons/jobFunction'
// import { useRouter } from 'next/navigation'
import { toPairs } from 'ramda'
import { useDispatch } from 'react-redux'
import { fetchConfigSuccess } from 'store/actions/config/fetchConfig'
import { useFirstRender } from 'helpers/useFirstRender'
import { filter } from 'ramda'
import { LoadingContext } from 'app/components/providers/loadingProvider'
import Image from 'next/image'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { cloneDeep } from 'lodash-es'
import { languageContext } from 'app/components/providers/languageProvider'
import { HistoryIcons } from 'images'
import useRemoteSearchSelect from '../OverrideMuiSelectSingle/useRemoteSearchSelect'
import Multiple from 'app/components/mobile/select/multiple'
import { Button } from 'app/components/MUIs'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import classNames from 'classnames'
import SalarySelect from 'app/components/commons/salarySelect'
import { CountrySelectorForMobile } from './CountrySelectorForMobile'

const SearchArea = (props: any) => {
  const { config, searchValues, search, remoteFindJobs } = props
  const {
    jobLanguages,
    world_region_lists,
    country,
    setCountry,
    setTimezone,
    searchCountryList,
    searchTimezoneList,
    removeEmptyValues
  } = useRemoteSearchSelect(config, searchValues)
  const { myJobs } = useContext(languageContext) as any
  const sortOptions = [
    { label: search?.newest, value: '1' },
    { label: search?.relevance, value: '2' },
    { label: search?.highestSalary, value: '3' }
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchConfigSuccess(config))
  }, [])

  const page = searchValues.page ?? '1'

  const locations = flatMap(config.location_lists, (item) => item.locations)
  const [location, setLocation] = useState(
    locations.filter((location) => searchValues?.location?.includes(location.seo_value))
  )
  const [industry, setIndustry] = useState(searchValues.industry ?? [])
  const industryList =
    config.industry_lists.map?.((item) => ({ value: item?.['seo-value'], label: item.value })) ?? []
  const [queryFields, setQueryFields] = useState(searchValues.queryFields)
  const [jobFunctionValue, jobFunctionChange] = useState({
    functionTitles: searchValues?.functionTitles ?? [],
    jobFunctions: searchValues?.jobFunctions ?? [],
    mainFunctions: searchValues?.mainFunctions ?? []
  })
  const moreDataOptions = useMemo(() => {
    const workExperience =
      config.xp_lvls.map?.((item) => ({ value: item?.['seo-value'], label: item.value })) ?? []
    const qualification =
      config.degrees.map?.((item) => ({ value: item?.['seo-value'], label: item.value })) ?? []
    const salary =
      config?.salary_range_filters?.map?.((item) => ({
        value: item?.['seo-value'],
        label: item.value
      })) ?? []
    const jobType =
      config.job_types.map?.((item) => ({ value: item?.['seo-value'], label: item.value })) ?? []
    const companySizes =
      config.company_sizes.map?.((item) => ({ value: item?.['seo-value'], label: item.value })) ??
      []
    const financingStages =
      config.company_financing_stage_lists?.map?.((item) => ({
        value: item?.key,
        label: item.value
      })) ?? []
    return {
      workExperience,
      qualification,
      salary,
      jobType,
      companySizes,
      financingStages
    }
  }, [config])
  const labels = [
    search.searchModal.exp,
    search.searchModal.edu,
    search.salary,
    search.type,
    search.searchModal.companySize,
    search.searchModal.finance
  ]
  const [sort, setSort] = useState(searchValues?.sort?.[0] ?? '2')
  const [language, setLanguage] = useState(searchValues.language_ids ?? '')
  const [salaries, setSalaries] = useState({
    salary: searchValues?.salary ?? [],
    salaryType: searchValues?.salaryType ?? []
  })
  const [jobTypes, setJobtypes] = useState(searchValues?.jobType ?? [])
  const jobTypeList =
    config?.job_types?.map?.((item) => ({
      value: item?.['seo-value'],
      label: item.value
    })) ?? []

  const [moreData, setMoreData] = useState(
    filter((a) => a !== null && a !== undefined && a != '')({
      workExperience: searchValues?.workExperience ?? null,
      qualification: searchValues?.qualification ?? null,
      salary: searchValues?.salary ?? null,
      jobTypes: searchValues?.jobType ?? null,
      verifiedCompany: searchValues?.verifiedCompany ?? null,
      companySizes: searchValues?.companySizes ?? null,
      financingStages: searchValues?.financingStages ?? null
    })
  )
  const [searchValue, setSearchValue] = useState(searchValues.query)
  const [suggestionList, handleSuggestionSearch, addSearchHistory] = useSuggest() as any[]

  const filterParams = useMemo(() => {
    return filter((a) => a !== null && a !== undefined && a != '')({
      query: searchValue,
      queryFields,
      //salary: salaries,
      industry: industry.length ? industry : null,
      location: location?.map((a) => a['seo_value']),
      jobType: jobTypes,
      remote_country_ids: searchCountryList,
      language_ids: language,
      timezone_ids: searchTimezoneList,
      sort: sort,
      page: page,
      ...salaries,
      ...jobFunctionValue,
      ...moreData
    })
  }, [
    searchValue,
    salaries,
    industry,
    moreData,
    location,
    sort,
    jobFunctionValue,
    queryFields,
    searchCountryList,
    language,
    searchTimezoneList,
    jobTypes
  ])
  console.log({ jobLanguages })
  const result = useMemo(() => {
    // return encode(filterParams)
    return {
      params: removeEmptyValues(filterParams),
      searchQuery: 'job-search'
    }
  }, [filterParams])

  const firstRender = useFirstRender()

  const { push } = useContext(LoadingContext)

  const reload = useCallback(() => {
    if (firstRender) {
      return
    }
    // @ts-ignore
    const url = new URLSearchParams(toPairs(result.params)).toString()
    push('/remote-jobs-hiring/' + result.searchQuery + '?' + url)
  }, [result])

  const reloadRef = useRef(reload)

  useEffect(() => {
    reloadRef.current = reload
  }, [reload])

  useEffect(reload, [
    location,
    industry,
    moreData,
    sort,
    salaries,
    jobFunctionValue,
    queryFields,
    searchCountryList,
    language,
    searchTimezoneList,
    jobTypes
  ])

  const styleleSelect = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  const newTheme = cloneDeep(theme)
  newTheme.components.MuiPaper.styleOverrides.root['height'] = 'calc(100% - 64px)'

  const salaryOptions =
    config?.salary_range_filters?.map?.((item) => ({
      value: item?.['seo-value'],
      label: item.value
    })) ?? []

  return (
    <div>
      <ThemeProvider theme={newTheme}>
        <div className={styles.searchFormMoblie}>
          <div className={styles.searchArea}>
            <JobSearchBar
              id='search'
              // label={search.title}
              variant='outlined'
              placeholder={search.title}
              size='small'
              className={styles.search}
              value={searchValue}
              maxLength={255}
              renderOption={(props, option) => {
                const { type, is_history: isHistory, value, logo_url: logoUrl } = option || {}
                return type === 'company' ? (
                  <li {...props} style={styleleSelect}>
                    <Image src={logoUrl} alt={value} width='22' height='22' />
                    <span style={{ paddingLeft: '10px' }}>{value}</span>
                  </li>
                ) : isHistory ? (
                  <li {...props} style={{ ...styleleSelect, color: '#136fd3' }}>
                    <AccessTimeIcon />
                    <span style={{ paddingLeft: '10px' }}>{value}</span>
                  </li>
                ) : (
                  <li {...props} style={styleleSelect}>
                    <Image src={HistoryIcons} alt='history icons' width='17' height='17' />
                    <span style={{ paddingLeft: '10px' }}>{value || option}</span>
                  </li>
                )
              }}
              searchFn={handleSuggestionSearch as any}
              updateSearchValue={setSearchValue}
              enterKeyHint='search'
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  setQueryFields('')
                  flushSync(() => {
                    setSearchValue((e.target as HTMLInputElement).value)
                  })
                  addSearchHistory?.((e.target as HTMLInputElement).value)
                    ; ((e.target ?? {}) as any)?.blur?.()
                  reloadRef?.current?.()
                }
              }}
              options={suggestionList}
              onSelect={(value: any) => {
                const newValue = value?.value || value || ''
                setQueryFields(value?.type || '')
                flushSync(() => {
                  setSearchValue(newValue)
                })
                addSearchHistory(newValue)
                reloadRef.current()
              }}
            />
            <button
              className={styles.searchButton}
              onClick={() => {
                flushSync(() => {
                  setSearchValue(searchValue)
                })
                addSearchHistory(searchValue)
                reloadRef.current()
                setQueryFields('')
              }}
            >
              {search?.find}
            </button>
          </div>
          <div className={styles.filters}>
            <Single
              options={sortOptions}
              value={sort}
              onSelect={setSort}
              className={styles.filterItems}
              label={myJobs.sortBy}
              menuClassName={'popMOdalContainer'}
            />

            <CountrySelectorForMobile
              className={styles.filterItems}
              world_region_lists={world_region_lists}
              value={country}
              onChange={setCountry}
            />

            {/* 
              <Multiple
                value={jobTypes}
                options={jobTypeList}
                className={styles.filterItems}
                onSelect={setJobtypes}
                label={search.type}
                menuClassName={'popMOdalContainer'}
              /> 
            */}

            <JobFunction
              id='jobFunction'
              label={search.function}
              value={jobFunctionValue}
              className={[styles.filterItems, styles.jobFunction]}
              onChange={jobFunctionChange}
            />

            {/* <Multiple
              label={search.salary}
              value={salaries}
              options={salaryOptions}
              className={styles.filterItems}
              onSelect={setSelaries}
              lang={search}
              menuClassName={'popMOdalContainer'}
            /> */}
            {/* <SalarySelect
              id='SalarySelect'
              salaryTypeLists={config.salary_type_lists}
              label={search.salary}
              value={salaries}
              lang={search}
              className={classNames([styles.filterItems, styles.jobSalary])}
              onChange={setSalaries}
            /> */}
            <Multiple
              value={language}
              options={jobLanguages}
              className={styles.filterItems}
              onSelect={setLanguage}
              label={remoteFindJobs.language}
              lang={search}
              menuClassName={'popMOdalContainer'}
            />
            <MultyGroup
              label={search.more}
              value={moreData}
              labels={labels}
              options={moreDataOptions}
              className={styles.filterItems}
              onSelect={setMoreData}
              lang={search}
              menuClassName={'popMOdalContainer'}
            />

          </div>
          <Button
            className={classNames([styles.filterItems, styles.resetFilters])}
            variant='text'
            style={{ color: '#86909C', marginBottom: 20, fontSize: 12, lineHeight: '16px' }}
            onClick={() => {
              setSearchValue('')
              setLocation([])
              setSearchValue('')
              setSort('1')
              jobFunctionChange({
                jobFunctions: [],
                mainFunctions: [],
                functionTitles: []
              })
              setJobtypes([])
              setSalaries({} as any)
              setMoreData({} as any)
              setCountry([])
              setLanguage([])
              setTimezone('')
              // setPage('1')
            }}
            startIcon={<AutorenewIcon />}
          >
            <span className={styles.resetFilters_content} title={search.reset}>
              {search.reset}
            </span>
          </Button>
        </div>
      </ThemeProvider>
    </div>
  )
}
export default SearchArea
