'use client'
import React, { Suspense, useEffect } from 'react'
import { useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'
import { isMobile } from 'react-device-detect'
import { Button } from 'app/components/MUIs'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import MaterialLocationField from 'components/MaterialLocationField'
import MaterialTextField from 'components/MaterialTextField'

import { buildQuery } from 'app/(main-page)/helper'
import { removeCookie } from 'helpers/cookies'
import { getCookie } from 'helpers/cookies'
import { getDeviceUuid } from 'helpers/guest'
import { addJobViewService } from 'store/services/jobs/addJobView'
import styles from '../../../page.module.scss'
import dynamic from 'next/dynamic'
import {
  JOB_VIEW_BURIED_COOKIE,
  getDeviceNameTracker,
  viewJobSourceTracker
} from 'components/ReportTracker/util'

type pagePropsType = {
  jobId?: number
  searchLang?: any
}
const SearchIcon = dynamic(() => import('@mui/icons-material/Search'), { ssr: false })
const Search = ({ jobId, searchLang }: pagePropsType) => {
  const router = useRouter()
  const token = getCookie('sessionid')
  const viewJobTracker = getCookie(JOB_VIEW_BURIED_COOKIE)
  const searchParams = useSearchParams()
  const { location, setLocation } = useSharedData('LOCATION_CONTEXT') ?? {}
  const [searchValue, setSearchValue] = useState<string>('')
  useEffect(() => {
    if (jobId) {
      handleFetchAddJobViewService()
    }
  }, [jobId])
  const handleUpdatePath = () => {
    const path = buildQuery(location?.seo_value, searchValue)
    router.push(path)
  }

  const handleFetchAddJobViewService = async () => {
    try {
      if (!viewJobTracker) return
      const query = {
        jobId,
        status: 'public',
      }

      if (token) {
        query.status = token ? 'protected' : 'public'
      }
      const { source, reco_from, query_id } = viewJobTracker || {}
      const deviceUuid = await getDeviceUuid()
      const tokenData = {
        source: searchParams.get('source') || source || viewJobSourceTracker.jobsHiring,
        device: getDeviceNameTracker(isMobile),
        reco_from: reco_from ? reco_from : null,
        device_udid: deviceUuid,
        is_login: token ? 1 : 0,
        query_id: query_id || null
      }
      if (!tokenData.reco_from) {
        delete tokenData.reco_from
      }

      const params = Object.assign(query, tokenData)

      addJobViewService(params).then(() => {
        removeCookie(JOB_VIEW_BURIED_COOKIE)
      })
    } catch (error) { }
  }

  return (
    <section className={styles.search}>
      <MaterialLocationField
        className={styles.search_location}
        value={location}
        // isClear={false}
        label={searchLang?.location}
        key={location?.value ?? 'default location'}
        defaultValue='Las Pinas'
        disableClearable
        sx={{
          fontSize: '16px',
          '.MuiInputLabel-root': {
            top: '12px'
          },
          '> .MuiFormControl-root': {
            '> .MuiOutlinedInput-root': {
              borderRadius: '8px',
              height: '60px',

              '> .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff',
                borderWidth: '0.5px'
              }
            }
          }
        }}
        onChange={(e, value) => setLocation(value)}
      />
      <span className={styles.line}></span>
      <div className={styles.inputBox}>
        <MaterialTextField
          className={styles.search_field}
          label={
            <div className={styles.search_label}>
              <Suspense>
                <SearchIcon sx={{ width: '16px', height: '16px', marginRight: '3px' }} />
              </Suspense>
              <span>{searchLang.title}</span>
            </div>
          }
          variant='outlined'
          size='small'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target?.value)}
          onKeyUp={(e) => e.code == 'Enter' && handleUpdatePath()}
          maxLength={60}
          sx={{
            fontSize: '16px',
            top: '8px',
            '> .MuiOutlinedInput-root': {
              borderRadius: '8px  0 0 8px',
              fontSize: '16px',
              '> .MuiOutlinedInput-notchedOutline': {
                borderColor: '#fff !important',
                borderWidth: '0.5px'
              }
            },
            '> .MuiInputLabel-root': {
              fontSize: '16px'
            }
          }}
        />

        <Button
          id='handleUpdatePath'
          variant='contained'
          sx={{
            textTransform: 'capitalize',
            width: '119px',
            height: '60px',
            background: '#2378E5',
            fontSize: '20px',
            borderRadius: '0px 8px 8px 0px'
          }}
          onClick={handleUpdatePath}
          className={styles.search_field_bingo}
        >
          {searchLang.btn}
        </Button>
      </div>
    </section>
  )
}

export default Search
