import { Dropdown, Input } from "antd"
import classNames from "classnames"
import { useMemo, useRef, useState } from "react"
import styles from './index.module.scss';

const SchoolPopover = ({ onChange, value, placeholder, schoolList }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const filteredList = useMemo(() => {
    if (!value) {
      return schoolList.map(v => {
        return { key: v.id, label: v.name }
      })
    }
    const lowerValue = value.toLocaleLowerCase()
    return schoolList.filter(v => {
      const lowerCase = (v.name || '').toLocaleLowerCase()
      return lowerCase.includes(lowerValue)
    }).map(v => {
      return { key: v.id, label: v.name }
    })
  }, [schoolList, value])

  const onItemClick = (listItem) => {
    return () => {
      setTimeout(() => {
        onChange(listItem.label)
      }, 300);
    }
  }

  return <>
    <div ref={ref} style={{ position: 'relative' }}>
      <Dropdown menu={{ items: filteredList }}
        getPopupContainer={() => ref.current}
        open={open}
        mouseLeaveDelay={300}
        dropdownRender={(originalNode) => {
          return <div className={classNames(styles.schoolDropDown, !filteredList.length && styles.noData)}>
            {
              filteredList.map(v => {
                return <div key={v.key} className={styles.item} onMouseDown={onItemClick(v)}>{v.label}</div>
              })
            }
          </div>
        }}
      >
        <Input
          style={{ width: '100%', height: 42 }}
          onClick={() => {
            setOpen(true)
          }}
          onBlur={() => {
            setOpen(!true)
          }}
          placeholder={placeholder}
          value={value}
          // defaultValue={defaultValue}
          onChange={(e) => {
            const value = e.target.value
            onChange(value.trimStart())
          }}
        />
      </Dropdown>
    </div>
  </>
}

export default SchoolPopover