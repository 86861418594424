'use client'
import { useState, useEffect, useRef } from 'react'
import { Stack } from 'app/components/MUIs'
import ModalShare from 'components/ModalShare'
import ModalReportJob from 'components/ModalReportJob'
import { postReportRequest } from 'store/actions/reports/postReport'
import { initialState } from 'store/reducers/config/fetchConfig'
import styles from '../../../../page.module.scss'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { fetchUserShare } from 'store/services/users/share'
// import { FacebookIcon, LinkedinIcon, TwitterIcon, CopyIcon } from 'images'
import Toast from 'app/components/Toast'

import Text from 'components/Text'
export type sharePropsType = {
  id: number
  job_url: string
  recruiter: {
    id: number
  }
  company: {
    id: number
  }
  isLogin?: boolean
  showText?: boolean
  isMobile?: boolean
}

const JobClient = (props: sharePropsType) => {
  const { isLogin, showText = true } = props
  const [isOnSharedButtion, setIsOnSharedButton] = useState(false)
  const [isOnSharedPop, setIsOnSharedPop] = useState(false)
  const showSharePop = isOnSharedButtion || isOnSharedPop
  const { jobDetail } = useSharedData('DICTIONARY') ?? { jobDetail: { header: {} } }
  const shareModal = jobDetail?.shareModal ?? {}

  const [reportJobReasonList, setReportJobReasonList] = useState<Array<any>>(null)
  const config = useSharedData('CONFIG') ?? initialState.response
  useEffect(() => {
    setReportJobReasonList(config?.report_job_reasons ?? initialState.response.report_job_reasons)
  }, [config])
  const isPostingReport = useSharedData('POST_REPORT_FETCHING')
  const postReportResponse = useSharedData('POST_REPORT_RESPONSE')
  const [isShowSearch, setIsShowSearch] = useState<boolean>(false)
  const [isShowReportJob, setIsShowReportJob] = useState<boolean>(false)
  const handlePostReportJob = (reportJobData) => {
    const postReportJobPayload = {
      reportJobData,
    }
    postNotification('DISPATCH', postReportRequest(postReportJobPayload))
  }
  const meInfo = useSharedData('ME')

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
    setTimeout(() => {
      Toast.success('Link copied success!')
    }, 500)
  }
  const tokenData = () => {
    if (!meInfo?.id) {
      return
    }
    const data = {
      jobseeker_id: meInfo.id,
      recruiter_id: props.recruiter.id,
      company_id: props.company.id,
      job_id: props.id
    }

    fetchUserShare(data)
      .then(({ status }: any) => {
        if (status === 200) {
          //
        }
      })
      .catch(() => {
        //
      })
  }
  return (
    <>
      {reportJobReasonList?.length ? (
        <Stack direction='row' spacing={2}>

          <div
            onClick={() => setIsShowSearch(true)}
            className={styles.jobClient_btn_wrapper}
            onMouseEnter={() => {
              setIsOnSharedButton(true)
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setIsOnSharedButton(false)
              }, 200)
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='21'
              viewBox='0 0 20 21'
              fill='none'
            >
              <path
                d='M11.6667 3.8335H16.6667V8.8335'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M16.6666 12.5275V16.0557C16.6666 16.6694 16.1691 17.1668 15.5555 17.1668H4.44436C3.83071 17.1668 3.33325 16.6694 3.33325 16.0557V4.94461C3.33325 4.33096 3.83071 3.8335 4.44436 3.8335H7.7777'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M10.8333 9.66683L16.6666 3.8335'
                stroke='#515151'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            {showText && jobDetail?.content?.jobShare}
            <div
              className={styles.shareMenu}
              style={{
                display: showSharePop ? 'flex' : 'none'
              }}
              onMouseEnter={() => {
                setIsOnSharedPop(true)
              }}
              onMouseLeave={() => {
                setIsOnSharedPop(false)
              }}
            >

              <div className={styles.shareItem}>

                <div
                  onClick={() => handleCopyLink(`${location.origin}${props?.job_url}`)}
                  className={styles.copyLink}
                >
                  <CopyLinkIcon />
                  <Text textStyle='base'>{shareModal.linkCopied}</Text>
                </div>
              </div>
              <div className={styles.shareItem} onClick={tokenData}>
                <FacebookShareButton url={`${location.origin}${props?.job_url}`} className={styles.ModalShareItemLink}>
                  <FacebookIcon />
                  <Text textStyle='base'>{shareModal.facebook}</Text>
                </FacebookShareButton>
              </div>
              <div className={styles.shareItem} onClick={tokenData}>
                <TwitterShareButton url={`${location.origin}${props?.job_url}`} className={styles.ModalShareItemLink}>

                  <TwitterIcon />
                  <Text textStyle='base' >
                    {shareModal.twitter}
                  </Text>
                </TwitterShareButton>
              </div>
              <div className={styles.shareItem} onClick={tokenData}>
                <LinkedinShareButton url={`${location.origin}${props?.job_url}`} className={styles.ModalShareItemLink}>

                  <LinkedinIcon />
                  <Text textStyle='base' >
                    {shareModal.linkedin}
                  </Text>
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          {isLogin && (
            <div onClick={() => setIsShowReportJob(true)} className={styles.jobClient_btn_wrapper}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='21'
                viewBox='0 0 20 21'
                fill='none'
              >
                <path
                  d='M10 9.09442V12.2194'
                  stroke='#515151'
                  strokeWidth='1.36364'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.92187 4.09441L2.04687 15.9694C1.93734 16.1591 1.87959 16.3743 1.8794 16.5933C1.8792 16.8124 1.93657 17.0276 2.04576 17.2175C2.15495 17.4074 2.31212 17.5653 2.50154 17.6753C2.69095 17.7853 2.90595 17.8436 3.12499 17.8444H16.875C17.094 17.8436 17.309 17.7853 17.4985 17.6753C17.6879 17.5653 17.845 17.4074 17.9542 17.2175C18.0634 17.0276 18.1208 16.8124 18.1206 16.5933C18.1204 16.3743 18.0626 16.1591 17.9531 15.9694L11.0781 4.09441C10.9694 3.90446 10.8125 3.7466 10.6231 3.63681C10.4338 3.52703 10.2188 3.46921 9.99999 3.46921C9.78114 3.46921 9.56617 3.52703 9.37685 3.63681C9.18753 3.7466 9.03057 3.90446 8.92187 4.09441V4.09441Z'
                  stroke='#515151'
                  strokeWidth='1.36364'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10 15.9694C10.5178 15.9694 10.9375 15.5497 10.9375 15.0319C10.9375 14.5142 10.5178 14.0944 10 14.0944C9.48223 14.0944 9.0625 14.5142 9.0625 15.0319C9.0625 15.5497 9.48223 15.9694 10 15.9694Z'
                  fill='#515151'
                />
              </svg>
              {showText && jobDetail?.content?.report}
            </div>
          )}
        </Stack >
      ) : null}

      {/* <ModalShare
        selectedJob={props}
        jobDetailUrl={props?.job_url}
        isShowModalShare={isShowSearch}
        handleShowModalShare={setIsShowSearch}
      /> */}

      {
        isShowReportJob && reportJobReasonList.length && (
          <ModalReportJob
            isShowReportJob={isShowReportJob}
            handleShowReportJob={setIsShowReportJob}
            reportJobReasonList={reportJobReasonList}
            selectedJobId={props?.['id']}
            handlePostReportJob={handlePostReportJob}
            isPostingReport={isPostingReport}
            postReportResponse={postReportResponse}
          />
        )
      }
    </>
  )
}

const CopyLinkIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
  <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z" fill="#121212" />
  <path d="M9.60491 8.3396L11.2254 6.71914C11.822 6.12251 12.7694 6.10258 13.3415 6.67464C13.9135 7.24671 13.8936 8.1941 13.297 8.79075L11.6765 10.4112" stroke="white" strokeWidth="0.833333" strokeLinecap="square" strokeLinejoin="round" />
  <path d="M8.35027 9.59814L6.72981 11.2186C6.13319 11.8152 6.0559 12.7341 6.68531 13.3347C7.31473 13.9353 8.20479 13.8868 8.80142 13.2902L10.4219 11.6697" stroke="white" strokeWidth="0.833333" strokeLinecap="square" strokeLinejoin="round" />
  <path d="M10.5765 9.3908L11.3867 8.58057" stroke="white" strokeWidth="0.833333" strokeLinecap="square" strokeLinejoin="round" />
  <path d="M8.68586 11.2814L9.49609 10.4712" stroke="white" strokeWidth="0.833333" strokeLinecap="square" strokeLinejoin="round" />
</svg>

const FacebookIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
  <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z" fill="#1977F3" />
  <path d="M15 10C15 7.25 12.75 5 10 5C7.25 5 5 7.25 5 10C5 12.5 6.8125 14.5625 9.1875 14.9375V11.4375H7.9375V10H9.1875V8.875C9.1875 7.625 9.9375 6.9375 11.0625 6.9375C11.625 6.9375 12.1875 7.0625 12.1875 7.0625V8.3125H11.5625C10.9375 8.3125 10.75 8.6875 10.75 9.0625V10H12.125L11.875 11.4375H10.6875V15C13.1875 14.625 15 12.5 15 10Z" fill="white" />
</svg>
const TwitterIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
  <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z" fill="black" />
  <path d="M5.65518 5.9375H8.44289L10.4225 8.64914L12.8248 5.9375H13.7028L10.819 9.192L14.375 14.0625H11.588L9.29073 10.9164L6.50302 14.0625H5.625L8.89425 10.3736L5.65518 5.9375ZM6.99965 6.61458L11.9543 13.3854H13.0305L8.07591 6.61458H6.99965Z" fill="white" />
</svg>

const LinkedinIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
  <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10Z" fill="#0B66C1" />
  <path d="M7.25 15H5.125V8.3125H7.25V15ZM6.1875 7.375C5.5 7.375 5 6.875 5 6.1875C5 5.5 5.5625 5 6.1875 5C6.875 5 7.375 5.5 7.375 6.1875C7.375 6.875 6.875 7.375 6.1875 7.375ZM15 15H12.875V11.375C12.875 10.3125 12.4375 10 11.8125 10C11.1875 10 10.5625 10.5 10.5625 11.4375V15H8.4375V8.3125H10.4375V9.25C10.625 8.8125 11.375 8.125 12.4375 8.125C13.625 8.125 14.875 8.8125 14.875 10.875V15H15Z" fill="white" />
</svg>

export default JobClient
