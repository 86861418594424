import React from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import styles from '../Header.module.scss'
import linkToHunt from 'helpers/linkToHunt'

interface IProps {
  langKey: string
  lang: any
  pathname: string
  // handleShowMenu: Function
}

const DropDownMenu = (props: IProps, ref: React.LegacyRef<HTMLDivElement>) => {
  const { langKey, lang } = props

  const { hiring, accountSettings, logOut, recommendedJobs } = lang || {}
  const handleLogOut = () => {
    postNotification('LOGOUT')
  }
  const style = { color: '#121212' }
  return (
    <div className={styles.headerMenu} style={{ padding: '12px 0' }} ref={ref}>
      <ul className={styles.headerMenuList}>
        <li >
          <Link to={'/' + langKey + '/my-jobs?page=1'} className={styles.headerMenuLink}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M11.333 3.16666V4.5H12.6663C13.7709 4.5 14.6663 5.39543 14.6663 6.5V13.1667C14.6663 14.2712 13.7709 15.1667 12.6663 15.1667H3.33301C2.22844 15.1667 1.33301 14.2712 1.33301 13.1667V6.5C1.33301 5.39543 2.22844 4.49999 3.33301 4.49999H4.66634V3.16666C4.66634 2.43028 5.2633 1.83333 5.99968 1.83333H9.99967C10.7361 1.83333 11.333 2.43028 11.333 3.16666ZM5.99967 7.16666H4.66634V5.83333H3.33301C2.96482 5.83333 2.66634 6.13181 2.66634 6.5L2.66634 9.83333H13.333V6.5C13.333 6.13181 13.0345 5.83333 12.6663 5.83333H11.333V7.16666H9.99967V5.83333H5.99967V7.16666ZM13.333 11.1667H2.66634L2.66634 13.1667C2.66634 13.5349 2.96482 13.8333 3.33301 13.8333H12.6663C13.0345 13.8333 13.333 13.5349 13.333 13.1667V11.1667ZM9.99967 4.5V3.16666L5.99968 3.16666V4.5H9.99967Z" fill="#7D7D7D" />
            </svg>
            <Text textStyle='base' style={style}>{recommendedJobs}</Text>
          </Link>
        </li>
        <li>
          <Link to='/settings' className={styles.headerMenuLink}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M8.0444 5.71887C6.50853 5.71887 5.26335 6.96383 5.26335 8.49971C5.26335 10.0356 6.50853 11.2805 8.0444 11.2805C9.58027 11.2805 10.8254 10.0356 10.8254 8.49971C10.8254 6.96383 9.58027 5.71887 8.0444 5.71887ZM6.59668 8.49971C6.59668 7.70034 7.24478 7.05221 8.0444 7.05221C8.84401 7.05221 9.49211 7.70034 9.49211 8.49971C9.49211 9.29908 8.84401 9.94721 8.0444 9.94721C7.24478 9.94721 6.59668 9.29908 6.59668 8.49971Z" fill="#7D7D7D" />
              <path d="M5.45099 1.83362C4.72466 1.83362 4.05542 2.2274 3.70268 2.86233L1.11008 7.529C0.774503 8.13304 0.774503 8.86753 1.11008 9.47157L3.70268 14.1382C4.05542 14.7732 4.72466 15.167 5.45099 15.167H10.5486C11.2749 15.167 11.9442 14.7732 12.2969 14.1382L14.8895 9.47157C15.2251 8.86753 15.2251 8.13304 14.8895 7.529L12.2969 2.86233C11.9442 2.2274 11.2749 1.83362 10.5486 1.83362H5.45099ZM4.86822 3.50986C4.9858 3.29821 5.20888 3.16695 5.45099 3.16695L10.5486 3.16695C10.7907 3.16695 11.0138 3.29821 11.1314 3.50986L13.7239 8.17652C13.8358 8.37787 13.8358 8.6227 13.7239 8.82405L11.1314 13.4907C11.0138 13.7024 10.7907 13.8336 10.5486 13.8336H5.45099C5.20888 13.8336 4.9858 13.7024 4.86822 13.4907L2.27563 8.82405C2.16377 8.6227 2.16377 8.37787 2.27563 8.17652L4.86822 3.50986Z" fill="#7D7D7D" />
            </svg>
            <Text textStyle='base' style={style}>{accountSettings}</Text>
          </Link>
        </li>

        <li >
          <Link
            to={linkToHunt('boss')}
            aTag
            external
            className={styles.headerMenuLink}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M5.22503 5.90809C5.22503 4.52642 6.34518 3.40628 7.72705 3.40628C9.10891 3.40628 10.2291 4.52642 10.2291 5.90809C10.2291 7.28976 9.10891 8.4099 7.72705 8.4099C6.34518 8.4099 5.22503 7.28976 5.22503 5.90809ZM7.72705 2.07574C5.61043 2.07574 3.89449 3.79151 3.89449 5.90809C3.89449 7.14684 4.48224 8.24829 5.39408 8.94886C3.39016 9.87649 2 11.9051 2 14.2584V14.9237H3.33054V14.2584C3.33054 11.7636 5.35308 9.74118 7.8481 9.74118C7.88031 9.74118 7.91199 9.73889 7.94298 9.73446C9.95915 9.62249 11.5596 7.95222 11.5596 5.90809C11.5596 3.79151 9.84367 2.07574 7.72705 2.07574ZM11.5361 9.87357C11.7314 10.0688 11.7313 10.3854 11.5361 10.5807L11.238 10.8788H13.486C13.7622 10.8788 13.986 11.1026 13.986 11.3788C13.986 11.6549 13.7622 11.8788 13.486 11.8788H10.0308C9.82856 11.8788 9.64624 11.757 9.56886 11.5701C9.49147 11.3833 9.53425 11.1682 9.67726 11.0252L10.829 9.87355C11.0243 9.67829 11.3408 9.6783 11.5361 9.87357ZM11.9806 13.9034C11.7853 14.0987 11.7853 14.4153 11.9805 14.6105C12.1758 14.8058 12.4924 14.8058 12.6877 14.6106L13.8394 13.4589C13.9824 13.3159 14.0252 13.1008 13.9478 12.914C13.8704 12.7272 13.6881 12.6053 13.4858 12.6053H10.0306C9.75449 12.6053 9.53063 12.8292 9.53063 13.1053C9.53063 13.3815 9.75449 13.6053 10.0306 13.6053H12.2787L11.9806 13.9034Z" fill="#7D7D7D" />
            </svg>
            <Text textStyle='base' style={style}>{hiring}</Text>
          </Link>
        </li>

        <li className={`${styles.headerMenuItemSpe}`}>
          <div className={styles.headerMenuLink} onClick={() => handleLogOut()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path d="M11.3333 3.83333V2.5H4C2.89543 2.5 2 3.39543 2 4.5V12.5C2 13.6046 2.89543 14.5 4 14.5H11.3333V13.1667H4C3.63181 13.1667 3.33333 12.8682 3.33333 12.5V4.5C3.33333 4.13181 3.63181 3.83333 4 3.83333H11.3333Z" fill="#7D7D7D" />
              <path d="M12.3905 9.16667L6.66667 9.16667V7.83333L12.3905 7.83333L10.8619 6.30474C10.6016 6.04439 10.6016 5.62228 10.8619 5.36193C11.1223 5.10158 11.5444 5.10158 11.8047 5.36193L14.4714 8.0286C14.7318 8.28895 14.7318 8.71106 14.4714 8.97141L11.8047 11.6381C11.5444 11.8984 11.1223 11.8984 10.8619 11.6381C10.6016 11.3777 10.6016 10.9556 10.8619 10.6953L12.3905 9.16667Z" fill="#7D7D7D" />
            </svg>
            <Text textStyle='base' style={style}>{logOut}</Text>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default React.forwardRef(DropDownMenu)
