"use client"

import { addEventTag } from 'helpers/utilities'
import Link from 'components/Link'
import styles from 'app/index.module.scss'

const Tag = ({ langKey, home }) => {
  return <Link href={'/' + langKey + '/companies'} className={styles.moreBtn} onClick={() => {
    addEventTag("hp_top_companies_more_click")
  }} >
    {home.seeMoreBtn}
  </Link>
}

export default Tag