export const File = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
    <path d="M11.5188 7.015C11.5188 5.44263 12.7935 4.16797 14.3658 4.16797H27.767H31.829H32.6954C33.465 4.16797 34.2017 4.47949 34.7379 5.03154L43.2106 13.7561C43.7265 14.2874 44.0152 14.9989 44.0152 15.7396V25.0013V42.9876C44.0152 44.56 42.7405 45.8346 41.1681 45.8346H14.3658C12.7935 45.8346 11.5188 44.56 11.5188 42.9876V7.015Z" fill="url(#paint0_linear_911_3294)" />
    <rect x="6.24165" y="15.5985" width="26.1516" height="20.3645" rx="3.03781" fill="url(#paint1_linear_911_3294)" stroke="url(#paint2_linear_911_3294)" strokeWidth="0.513394" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.6365 19.793C18.6927 24.4186 17.6846 26.5071 14.3625 29.4973L15.9208 31.7674C19.316 29.6658 21.4479 28.7782 26.1593 28.3398V25.6689C23.3208 25.0666 22.1029 24.0729 21.3962 19.793H18.6365ZM19.6384 26.8038L20.7071 24.7116L22.1537 26.2474L19.6384 26.8038Z" fill="url(#paint3_linear_911_3294)" />
    <path d="M33.4414 4.23047C33.9115 4.35003 34.1598 4.49113 34.5778 4.86026L43.2532 13.8024C43.6104 14.1533 43.7587 14.3895 43.906 14.8289H37.1926C35.1209 14.8289 33.4414 13.1494 33.4414 11.0777V4.23047Z" fill="url(#paint4_linear_911_3294)" />
    <path d="M11.5188 39.1483V36.2188H29.5104L11.5188 39.1483Z" fill="url(#paint5_linear_911_3294)" />
    <defs>
      <linearGradient id="paint0_linear_911_3294" x1="35.11" y1="-0.734611" x2="21.1257" y2="49.708" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4BBEFD" />
        <stop offset="1" stopColor="#2853F7" />
      </linearGradient>
      <linearGradient id="paint1_linear_911_3294" x1="29.3428" y1="16.9161" x2="22.5166" y2="31.6536" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.7" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint2_linear_911_3294" x1="7.43339" y1="36.9493" x2="20.4166" y2="1.01324" gradientUnits="userSpaceOnUse">
        <stop stopColor="#CEEBFF" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_911_3294" x1="22.9265" y1="18.384" x2="19.6579" y2="33.2774" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4BBEFD" />
        <stop offset="1" stopColor="#2853F7" />
      </linearGradient>
      <linearGradient id="paint4_linear_911_3294" x1="32.4682" y1="13.482" x2="35.1179" y2="8.2929" gradientUnits="userSpaceOnUse">
        <stop offset="0.364878" stopColor="white" stopOpacity="0.7" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient id="paint5_linear_911_3294" x1="22.0889" y1="28.8943" x2="25.7796" y2="38.7935" gradientUnits="userSpaceOnUse">
        <stop stopColor="#006197" />
        <stop offset="1" stopColor="#0025B3" />
      </linearGradient>
    </defs>
  </svg>
}


export const UploadIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M3.20834 4.37565H2.04167L2.04167 10.7923C2.04167 11.7588 2.82517 12.5423 3.79167 12.5423H10.7917C11.7582 12.5423 12.5417 11.7588 12.5417 10.7923V4.37565H11.375V10.7923C11.375 11.1145 11.1138 11.3757 10.7917 11.3757H3.79167C3.46951 11.3757 3.20834 11.1145 3.20834 10.7923L3.20834 4.37565Z" fill="#2378E5" />
    <path d="M7.875 2.04232L7.87501 8.45898H6.70834L6.70834 2.04232L7.875 2.04232Z" fill="#2378E5" />
    <path d="M4.54586 4.78813C4.31805 4.56032 4.31805 4.19098 4.54586 3.96317L6.87919 1.62984C7.107 1.40203 7.47635 1.40203 7.70415 1.62984L10.0375 3.96317C10.2653 4.19098 10.2653 4.56032 10.0375 4.78813C9.80968 5.01594 9.44033 5.01594 9.21253 4.78813L7.29167 2.86728L5.37082 4.78813C5.14301 5.01594 4.77367 5.01594 4.54586 4.78813Z" fill="#2378E5" />
  </svg>
}

export const DeleteIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M4.95833 1.16602C4.63616 1.16602 4.375 1.42718 4.375 1.74935C4.375 2.07152 4.63616 2.33268 4.95833 2.33268L9.04166 2.33268C9.36383 2.33268 9.625 2.07152 9.625 1.74935C9.625 1.42718 9.36383 1.16602 9.04166 1.16602H4.95833Z" fill="#2378E5" />
    <path d="M6.41666 5.83268V9.91602H5.25L5.25 5.83268H6.41666Z" fill="#2378E5" />
    <path d="M8.75 9.91602V5.83268H7.58333V9.91602H8.75Z" fill="#2378E5" />
    <path d="M1.16666 3.49935C1.16666 3.17718 1.42783 2.91602 1.75 2.91602L12.25 2.91601C12.5722 2.91601 12.8333 3.17718 12.8333 3.49935C12.8333 3.82151 12.5722 4.08268 12.25 4.08268L11.6667 4.08268V11.0827C11.6667 12.0492 10.8832 12.8327 9.91666 12.8327H4.08333C3.11683 12.8327 2.33333 12.0492 2.33333 11.0827L2.33333 4.08268L1.75 4.08268C1.42783 4.08268 1.16666 3.82152 1.16666 3.49935ZM10.5 11.0827V4.08268L3.5 4.08268L3.5 11.0827C3.5 11.4048 3.76116 11.666 4.08333 11.666H9.91666C10.2388 11.666 10.5 11.4048 10.5 11.0827Z" fill="#2378E5" />
  </svg>
}