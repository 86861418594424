'use client'
import { useEffect } from 'react'
import { getCookie } from 'helpers/cookies'
import { useUserAgent } from 'next-useragent'
import { useSearchParams, usePathname } from 'next/navigation'
import { isMobile } from 'react-device-detect'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
const AutoShowModalAppRedirect = () => {
  const userAgent = useUserAgent(globalThis?.navigator?.userAgent)
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const referralCode = searchParams.get('referral_code')
  const invitedSource = searchParams.get('invited_source')
  const modulesLoaded = useSharedData('MODULE_LOADED')
  const loginModalLoaded = modulesLoaded?.['remote-login-redirect-app']
  useEffect(() => {
    const notShow = isMobile &&
      invitedSource &&
      referralCode &&
      pathname.indexOf('get-started') > -1
    if (userAgent.isMobile && !getCookie('isAppRedirectModalClosed') && !notShow && loginModalLoaded) {
      setTimeout(() => {
        postNotification('SHOW_REDIRECT_APP_MODAL', {})
      }, 0)
    }
  }, [loginModalLoaded])
  return (<></>)
}
export default AutoShowModalAppRedirect
