import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { SkillsModal } from '@bossjob/ui'
import styles from '../index.module.scss'
import LoadingButton from '@mui/lab/LoadingButton'
import { message } from 'antd'
import Text from 'components/Text'
import { maxFileSize } from 'helpers/handleInput'
import MaterialDatePicker from 'components/MaterialDatePicker'
import JobFunctionSelector from 'components/JobFunctionSelector'
import FormControlLabel from '@mui/material/FormControlLabel'
import MaterialTextField from 'components/MaterialTextField'
import Switch from '@mui/material/Switch'
import Chip from '@mui/material/Chip'
import TextEditor from 'components/TextEditor/TextEditor'
import FootBtn from './footBtn'
import { usePathname } from 'next/navigation'
import {
  deleteResume,
  generateResumePresigned,
  parseResume,
  saveUploadResume,
  syncResumeStatus,
  uploadUserResumeService,
  uploadVideoToAmazonService
} from 'store/services/users/uploadUserResume'
import { differenceBy } from 'lodash-es'
import { updateUserCompleteProfileService, searchCompanyByDisplayName, fetchAddBlacklistCompaniesService, deleteBlackListCompaniesServie } from 'store/services/users/updateUserCompleteProfile'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'
import { addUserWorkExperienceService } from 'store/services/users/addUserWorkExperience'
import { updateUserWorkExperienceService } from 'store/services/users/updateUserWorkExperience'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { fetchResumes } from 'store/services/jobs/fetchJobsCommunicated'
import Link from 'components/Link'
import { LinkContext } from 'app/components/providers/linkProvider'
import MaterialButton from 'components/MaterialButton'
import Toast from 'app/components/Toast'
import { isMobile } from 'react-device-detect'
import { formatTemplateString, removeEmptyOrNullValues } from 'helpers/formatter'
import { DeleteIcon, File, UploadIcon } from './Icon'
import { PlainTextBtn } from './Btn'
import { SyncInfo } from './SyncInfo'
import { DeepEqual, eduKey } from '../sessionKey'
import { parseUserResumeSuccess } from 'store/actions/users/uploadUserResume'

const maxSkillsLen = 10

enum uploadLoadingState {
  none,
  uploading,
  parsing
}
const shouldAutofill = (obj) => {
  const { companyName, workPeriodFrom, description, workPeriodTo, selectedSkills } = obj

  return Boolean(!companyName && !workPeriodFrom && !workPeriodTo && !selectedSkills?.length && !description)
}

const BiggerTextBtn = ({ lang, state }: { lang: any, state: uploadLoadingState }) => {
  const [text, setText] = useState('');
  const stateText = state === uploadLoadingState.parsing ? (lang.comm.Parsing) : lang.comm.uploading
  useEffect(() => {
    let timer
    timer = setTimeout(() => {
      setText(pre => {
        let dotsNum = pre.length
        if (dotsNum >= 3) {
          dotsNum = 1
        } else {
          dotsNum++
        }
        let copy = (new Array(dotsNum).fill('.').join(''))
        return copy
      })
    }, 500);

    return () => {
      clearTimeout(timer)
    }
  }, [text])

  return <div className={styles.BiggerBtnText}>{stateText}<div style={{ display: 'inline-block', width: 15, marginLeft: -10 }}> {text}</div ></div>
}

const WorkExperience = (props: any) => {
  const { lang, userDetail, getUserInfo } = props
  const { work_experiences, educations } = userDetail

  const [resume, setResume] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [jobFunction, setJobFunction] = useState<any>({ id: undefined, value: '' })
  const [companyName, setCompanyName] = useState<string>('')
  const [isCurrentJob, setIsCurrentJob] = useState<boolean>(true)
  const [workingSince, setWorkingSince] = useState<any>(null)
  const [workPeriodFrom, setWorkPeriodFrom] = useState<any>(null)
  const [workPeriodTo, setWorkPeriodTo] = useState<any>(null)
  const [description, setDescription] = useState<string>('')
  const [skills, setSkills] = useState<any>([])
  const [selectedSkills, setSelectedSkills] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [existingResume, setExistingResume] = useState<any>([])
  const [resumeDisable, setResumeDisable] = useState<boolean>(false)
  // const [resumeLoading, setResumeLoading] = useState<boolean>(false)
  const [openSkillModal, setOpenSkillModal] = useState(false)
  const [uploadField, setUploadFiled] = useState<Record<string, any>>({ isUpload: false, parsing: false })
  const [uploadFieldLoading, setUploadFieldLoading] = useState(uploadLoadingState.none)
  const [parse, setParse] = useState<Record<string, any>>({})
  const parsedResumeId = useSelector((store: any) => store.users?.uploadUserResume?.parsedResumeId)

  const ref = useRef({
    timer: -1,
    work_experiences,
    educations,
    selectedSkills,
    companyName,
    description,
    workPeriodTo,
    workPeriodFrom,
    isCurrentJob
  })
  Object.assign(ref.current, {
    work_experiences,
    educations,
    // comparable fields
    selectedSkills,
    companyName,
    description,
    workPeriodTo,
    workPeriodFrom,
    isCurrentJob
  })
  const [hideMyResume, setHideMyResume] = useState(true)
  const [companyId, setCompanyId] = useState('')

  const dataSkills = userDetail?.skills
  const dispatch = useDispatch()
  const { push } = useContext(LinkContext)
  const inputNode = useRef(null)
  const tags = []
  const pathname = usePathname()

  useEffect(() => {
    getResumes()
  }, [])

  const getResumes = useCallback((isUpload = false) => {
    fetchResumes().then((res) => {
      setExistingResume(res?.data?.data || [])
      let value = (res?.data?.data || [])[0] || {};

      setUploadFiled(pre => {
        return { ...value, deleteLoading: false, uploadLoading: false, isUpload }
      })

    })
  }, [])

  useEffect(() => {
    if (existingResume?.length >= 3) {
      setResumeDisable(true)
    } else {
      setResumeDisable(false)
    }
  }, [existingResume])

  useEffect(() => {
    if (dataSkills?.length && skills?.length) {
      const arr = []
      dataSkills.map((e) => {
        const findItem = skills.find((k) => k.value === e)
        if (findItem) {
          arr.push(findItem)
        } else {
          arr.push({
            id: generateUUID(),
            value: e
          })
        }
      })
      setSelectedSkills(arr)
    }
  }, [dataSkills, skills])

  useEffect(() => {
    if (userDetail.working_since) {
      const { working_since } = userDetail
      setWorkingSince(working_since)
    }
    if (userDetail.work_experiences?.length) {
      const {
        company,
        description,
        description_html,
        function_job_title,
        function_job_title_id,
        working_period_from,
        working_period_to,
        is_currently_work_here
      } = userDetail.work_experiences[0]

      // company ，description ，description_html ，function_job_title_id，job_title，working_period_from

      setCompanyName(company)
      setJobFunction({ id: function_job_title_id, value: function_job_title })
      setIsCurrentJob(is_currently_work_here)
      setWorkPeriodFrom(working_period_from)
      setWorkPeriodTo(working_period_to)
      setDescription(description_html ? description_html : description)
    }
  }, [JSON.stringify(userDetail)])

  useEffect(() => {
    if (companyName && workPeriodFrom && (!isCurrentJob ? workPeriodTo : true) && jobFunction?.id) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [jobFunction, companyName, isCurrentJob, workPeriodFrom, workPeriodTo])

  const {
    workExperience,
    autofillMyInfo,
    saveTimeByImporting,
    uploadResume,
    supportedFileType,
    startedWorkingSince,
    mostRecentCompany,
    WorkingPeriod,
    mostRecentJobTitle,
    skillsWillBeSuggested,
    Next2,
    back,
    companyNameText,
    currentlyWorkHere,
    from,
    to,
    placeholder,
    skip,
    uploadSeccess,
    searchOrAddSkill,
    add,
    autofillMyinfoTips,
    mostRecentCompanyTips,
    hideResumeFromCompany,
    workExpNextTips
  } = lang?.profile || {}
  const uploadResumeFile = async ({ file }) => {
    // generate presigned url
    const presigned = await generateResumePresigned(`${file.name}`)
    const { url, fields } = presigned?.data?.data || {}
    const awsFormData = new FormData()
    Object.keys(fields).forEach((key) => {
      awsFormData.set(key, fields[key])
    })
    awsFormData.set('file', file);

    // setUploadFiled((pre) => {
    //   return { ...pre, uploadLoading: true, }
    // })
    setUploadFieldLoading(uploadLoadingState.uploading)
    // upload file to amazon
    const aws: any = await uploadVideoToAmazonService(url, awsFormData).catch(() => {
      Toast.error('upload err , please retry')
      setUploadFieldLoading(uploadLoadingState.none)
    })

    if (aws?.status >= 200 && aws?.status < 300) {
      const fileUrl = `https://${url.replace(/(^.*amazonaws\.com\/)/, '')}/${fields?.key}`
      // save upload resume url
      saveUploadResume(fileUrl, { is_register_stage: true, old_resume_id: uploadField.id })
        .then((res) => {
          if (res.data.code !== 0) {
            return
          }
          // setResumeLoading(false)
          getResumes(true)
          dispatch(
            displayNotification({
              open: true,
              message: uploadSeccess,
              severity: 'success'
            })
          )
          setResume('')
        })
        .catch(() => {
          setUploadFieldLoading(uploadLoadingState.none)
          Toast.error('upload err , please retry')
        })
    }
  }
  const fillDataIntoForm = (parse) => {
    const { work = {}, skills = [], edu } = parse;
    const { company_name, from_date, description, is_currently_work_here, to_date } = work;
    setCompanyName(company_name || '')
    setDescription(description || '')
    setIsCurrentJob(Boolean(is_currently_work_here))
    setWorkPeriodFrom(from_date ? new Date(from_date) : null)
    setWorkPeriodTo(to_date ? new Date(to_date) : null)

    if (skills.length) {
      setSelectedSkills(skills.slice(0, 10).map(v => {
        return { id: Math.random(), value: v }
      }))
    }

    let eud = removeEmptyOrNullValues({
      school: edu.school_name,
      is_currently_studying: edu.is_currently_study_here,
      study_period_from: edu.from_date,
      study_period_to: edu.to_date,
      field_of_study: edu.course,
    })

    Boolean(Object.keys(eud).length) && sessionStorage.setItem(eduKey, JSON.stringify(eud));

    setParse({})
    syncResumeStatus(uploadField.id, 3)
    // update education
  }

  useEffect(() => {
    if (resume) {
      clearTimeout(ref.current.timer);
      if (maxFileSize(resume, 5)) {
        setErrorMessage('')
        // setResumeLoading(true)
        uploadResumeFile({ file: resume })
      } else {
        setErrorMessage(lang?.profile?.fileTooHuge)
      }
    }
  }, [resume])

  useEffect(() => {
    const { id, isUpload, parsing } = uploadField;
    if (!id || parsedResumeId === id) {
      return
    }
    const modifyUpload = (parsing = false) => {
      setUploadFieldLoading(uploadLoadingState[parsing ? 'parsing' : 'none'])
    }
    modifyUpload(true)
    let unmount = false
    const request = async () => {
      // parse resume
      parseResume(id).then(res => {
        if (unmount) {
          return
        }
        const { data } = res?.data || {};

        if (data.status_key === 'finished') {
          // finished
          setLoading(false)
          dispatch(parseUserResumeSuccess({ id }))
          // compare the current form data
          const { last_education: edu, last_work_experience: work, skills } = data?.parse_data || {}
          let parseObj = { edu, work, skills };
          if (!DeepEqual(ref, { work, skills })) {
            Object.keys(parseObj).forEach(key => {
              if (!parseObj[key]) {
                delete parseObj[key]
              }
            })

            if (shouldAutofill(ref.current)) {
              // show confirm modal
              fillDataIntoForm(parseObj)
            } else {
              setParse(parseObj);
            }
            modifyUpload(false)
          }
          return
        }

        if (data.status_key === 'failed') {
          setLoading(false)
          modifyUpload(false)
          return
        }
        // not done
        ref.current.timer = window.setTimeout(() => {
          request()
        }, 1000);
      }).catch((err) => {
        Toast.error('Parsing failed, please try again!')
        setLoading(false)
        modifyUpload(false)
      })
    }
    request()
    return () => {
      unmount = true
      clearTimeout(ref.current.timer)
    }
  }, [uploadField.id])

  const handleDelete = (e, index) => {
    e.preventDefault()
    selectedSkills.splice(index, 1)
    setSelectedSkills([...selectedSkills])
  }

  const backClick = () => {
    push(`${pathname}?step=1`)
  }

  const handleSubmit = () => {
    const paramsProfile = {
      working_since: moment(new Date(workingSince)).format('yyyy-MM-DD'),
      skills: selectedSkills.map((e) => e.value)?.join(',')
    }

    const p1 = updateUserCompleteProfileService(paramsProfile)
    setLoading(true)
    let p2 = null
    const paramsWork = {
      job_title: jobFunction?.value,
      function_job_title_id: jobFunction?.id,
      company: companyName,
      working_period_from: `${moment(new Date(workPeriodFrom)).format('yyyy-MM')}-01`,
      working_period_to: isCurrentJob
        ? null
        : `${moment(new Date(workPeriodTo)).format('yyyy-MM')}-01`,
      is_currently_work_here: isCurrentJob,
      description,
      description_html: description
    }

    if (companyId) {
      message.warning({
        icon: <></>,
        // content: workExpNextTips,
        content: formatTemplateString(workExpNextTips, {
          company: companyName
        })
      })
    }

    if (isCurrentJob) {
      delete paramsWork.working_period_to
    }
    if (work_experiences?.length) {
      const data = work_experiences[0]
      const paramsUpdate = {
        workExperienceId: data.id,
        workExperienceData: paramsWork
      }
      p2 = updateUserWorkExperienceService(paramsUpdate)
    } else {
      p2 = addUserWorkExperienceService({
        workExperience: paramsWork
      })
    }

    Promise.all([p1, p2])
      .then(() => {
        getUserInfo?.()
        push(`${pathname}?step=3`)
      }).catch(err => {
        const msg = err[1]?.data?.message
        msg && Toast.error(msg)
      })
      .finally(() => setLoading(false))
  }

  function generateUUID() {
    return Math.random().toString(36).substring(2, 16)
  }


  const addSecected = (item) => {
    if (selectedSkills?.length >= maxSkillsLen) return
    setSelectedSkills([...selectedSkills, item])
  }

  const handleKeyUp = (e, isBtn = false) => {
    const val = isBtn ? inputNode.current.value : e.target.value
    const formattedVal = val.substring(0, val.length - 1).trim()

    if (
      val &&
      (e.key === 'Enter' || e.keyCode == 13 || isBtn) &&
      !tags.find((tag) => tag.toLowerCase() === val.toLowerCase())
    ) {
      setSelectedSkills([...selectedSkills, { id: generateUUID(), value: val }])
      inputNode.current.value = null
    } else if (
      formattedVal &&
      (e.key === ',' || e.keyCode == 188) &&
      !tags.find((tag) => tag.toLowerCase() === formattedVal.toLowerCase())
    ) {
      setSelectedSkills([...selectedSkills, { id: generateUUID(), value: formattedVal }])
      inputNode.current.value = null
    }
  }


  const addSkills = () => {
    setOpenSkillModal(true)
  }

  const formateSkills = (values) => {
    if (!Array.isArray(values)) return values
    return values.map((item, index) => {
      return { ...item, id: item?.id || (Date.now() + index).toString(16) }
    })
  }

  const handleConfirm = (v: any) => {
    setOpenSkillModal(false)
    const newValue = formateSkills(v)
    setSelectedSkills([...newValue])
  }

  const modalSkills = {
    ...(lang?.commonSkillsModal || {}),
    subTitle: formatTemplateString(lang.commonSkillsModal.subTitle, {
      count: `1-${maxSkillsLen}`
    })
  }

  const switchHideDisplayCompany = () => {
    if (!companyName) {
      return false
    }
    searchCompanyByDisplayName(companyName).then((res) => {
      if (res?.data?.data) {
        const companyIds = res?.data?.data
        setCompanyId(companyIds)
        sessionStorage.setItem('_companyIds', companyIds)
        setHideMyResume(true)
        fetchAddBlacklistCompaniesService({
          company_ids: [companyIds]
        }).then(() => { })
          .catch(err => Promise.resolve(err))
          .finally(() => { })
      }
      else {
        setCompanyId('')
      }
    }).catch(err => Promise.resolve(err))

  }

  const switchShowDisplayCompany = () => {
    if (!companyName) {
      return false
    }
    const companyId = sessionStorage.getItem('_companyIds')
    if (companyId) {
      deleteBlackListCompaniesServie({ id: companyId }).then(() => {
        sessionStorage.removeItem('_companyIds')
        setCompanyId('')
        // setHideMyResume(false)
      }).catch(err => Promise.resolve(err))
    }
  }

  const timerRef = useRef(null)
  useEffect(() => {
    clearTimeout(timerRef.current)
    if (companyName) {
      timerRef.current = setTimeout(() => {
        switchHideDisplayCompany()
      }, 300)
    }

  }, [companyName])
  const clearUploadInfo = () => {
    setParse({})
    setUploadFiled(pre => {
      return { ...pre, isUpload: false }
    })
    setUploadFieldLoading(uploadLoadingState.none)
  }


  return (
    <div className={styles.work}>
      <div className={styles.workContainer}>
        <div className={styles.box}>
          <div className={styles.headerInfo}>{workExperience}</div>
          <div className={styles.body}>
            <p className={styles.title}>{autofillMyInfo}</p>
            {/* <p className={styles.titleTip}>{saveTimeByImporting}</p> */}
            <p className={styles.titleTip}>{autofillMyinfoTips}</p>
            <div className={styles.upload}>
              {!uploadField.name && (uploadFieldLoading !== uploadLoadingState.none ?
                <BiggerTextBtn lang={lang} state={uploadFieldLoading} />
                :
                <LoadingButton
                  // loading={resumeLoading}
                  variant='contained'
                  component='label'
                  disabled={resumeDisable}
                  sx={{
                    textTransform: 'capitalize',
                    height: '60px',
                    border: '1px solid #136FD3',
                    borderRadius: '10px',
                    background: '#fff',
                    boxShadow: 'none',
                    color: '#136FD3'
                  }}
                >
                  <Text textColor='#136FD3'>{uploadResume}</Text>
                  <input
                    type='file'
                    hidden
                    accept='.pdf, .doc, .docx'
                    onChange={(e) => {
                      setResume(e.target.files[0])
                      clearUploadInfo()
                    }}
                  />
                </LoadingButton>)}
              {uploadField.name &&
                (uploadFieldLoading !== uploadLoadingState.none ?
                  // <div className={styles.BiggerBtnText}>Loading...</div>
                  <BiggerTextBtn lang={lang} state={uploadFieldLoading} />
                  : <>
                    <div className={styles.resumeExists}>
                      <div className={styles.fileField}>
                        <File />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                          <div className={styles.fileName}>{uploadField.name}</div>
                          <div className={styles.updateAt}>Updated at {uploadField.updated_at}</div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', gap: 8, margin: '0 0 5px 0' }}>
                        {
                          (uploadFieldLoading === uploadLoadingState.none) && <>
                            <PlainTextBtn
                              // disabled={resumeLoading}
                              onClick={(e) => {
                                const { target } = e;
                                const input = (target as HTMLSpanElement).querySelector('input')
                                input && input.click()
                              }} >
                              <UploadIcon />
                              {/* re-upload */}
                              {lang.profile.reUpload}
                              <input
                                type='file'
                                hidden
                                accept='.pdf, .doc, .docx'
                                onChange={(e) => {
                                  setResume(e.target.files[0])
                                  clearUploadInfo()
                                }}
                              />
                            </PlainTextBtn>
                            <PlainTextBtn
                              // disabled={resumeLoading}
                              onClick={() => {
                                deleteResume(uploadField.id).then(() => {
                                  setUploadFiled({})
                                  setResume('')
                                  Toast.success('Successfully Deleted.')
                                }).catch((error) => {
                                  Toast.error('Server err, please retry')
                                })
                              }}>
                              <DeleteIcon />
                              {/* Delete */}
                              {lang.manageProfile.tab.resume.changeResume.delete}
                            </PlainTextBtn>
                          </>
                        }
                        {/* {
                          uploadFieldLoading !== uploadLoadingState.none && <div>
                            {
                              uploadFieldLoading === uploadLoadingState.uploading ? 'uploading...' : 'parsing...'
                            }
                          </div>
                        } */}
                      </div>
                    </div>
                  </>)
              }
            </div>
            {/* {existingResume?.length
              ? existingResume.map((e) => (
                <div key={e.id} className={styles.resumeList}>
                  <Link
                    to={e.url}
                    target='_blank'
                    rel='noreferrer'
                    style={{ textDecoration: 'underline', textDecorationSkipInk: 'none' }}
                  >
                    <Text>{e.filename || e.name}</Text>
                  </Link>
                </div>
              ))
              : null} */}

            <p className={styles.titleTip}>{supportedFileType}</p>
            <p className={styles.title}>
              <span>*</span>
              {startedWorkingSince}
            </p>
            <div className={styles.workingSince}>
              <MaterialDatePicker
                label={'Month year'}
                views={['year', 'month']}
                inputFormat='MMM yyyy'
                value={workingSince}
                fullWidth
                onDateChange={(value) => {
                  setWorkingSince(value)
                }}
                showToolbar={false}
              />
            </div>
            <p className={styles.title}>
              <span>*</span>
              {mostRecentCompany}
            </p>
            <p style={{
              color: '#707070',
              fontSize: 14,
              marginBottom: 15
            }}>{mostRecentCompanyTips}</p>
            <div className={styles.stepCompany}>
              <MaterialTextField
                className={styles.stepFullwidth}
                // label={requiredLabel(companyNameText)}
                size='small'
                fullWidth
                variant='standard'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                placeholder={companyNameText}
                value={companyName}
                defaultValue={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value)
                }}
              // onBlur={() => switchHideDisplayCompany()}
              />
            </div>

            <div className={styles.stepFieldBody} style={{ margin: '15px 0 -15px' }}>
              <div className={styles.workingPeriod}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideMyResume}
                      onChange={(e, value) => {

                        if (value) {
                          switchHideDisplayCompany()
                        }
                        else {
                          switchShowDisplayCompany()
                        }
                        setHideMyResume(value)

                      }}
                    />
                  }
                  label={<Text textStyle='base'>{hideResumeFromCompany}</Text>}
                />
              </div>
            </div>

            <p className={`${styles.title} ${styles.titlePeriod}`}>
              <span>*</span>
              {WorkingPeriod}
            </p>
            <div className={styles.stepFieldBody}>
              <div className={styles.workingPeriod}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCurrentJob}
                      onChange={() => setIsCurrentJob(!isCurrentJob)}
                      name='currentJob'
                    />
                  }
                  label={<Text textStyle='base'>{currentlyWorkHere}</Text>}
                />
              </div>
              <div className={styles.pickBox}>
                <div className={styles.stepFieldToItem}>
                  <MaterialDatePicker
                    label={from}
                    views={['year', 'month']}
                    inputFormat='MMM yyyy'
                    value={workPeriodFrom}
                    onDateChange={(value) => {
                      setWorkPeriodFrom(value)
                    }}
                    showToolbar={false}
                  />
                </div>
                {!isCurrentJob && (
                  <div className={styles.stepFieldToItem}>
                    <MaterialDatePicker
                      label={to}
                      views={['year', 'month']}
                      inputFormat='MMM yyyy'
                      value={workPeriodTo}
                      onDateChange={(value) => {
                        setWorkPeriodTo(value)
                      }}
                      showToolbar={false}
                    />
                  </div>
                )}
              </div>
            </div>

            <p className={`${styles.title} ${styles.titlePeriod}`}>
              <span>*</span>
              {mostRecentJobTitle}
            </p>
            <div id='jobFunction' className={styles.stepJobFunction}>
              <JobFunctionSelector
                className={styles.stepFullwidth}
                // label={lang?.profile?.jobFunction}
                placeholder={lang?.profile?.jobFunction}
                title={lang?.profile?.jobFunction}
                lang={lang}
                name='jobFunction'
                isTouched
                fullWidth
                variant='standard'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                value={jobFunction}
                onChange={(value) => setJobFunction(value)}
                onChangeSkill={(value) => setSkills(value)}
              />
            </div>

            <p className={styles.titleTip}>{skillsWillBeSuggested}</p>
            {isMobile && (
              <>
                <div className={styles.skillList}>
                  {differenceBy(skills, selectedSkills, 'id').map((e) => (
                    <span key={e.id} onClick={() => addSecected(e)}>
                      {e.value}
                    </span>
                  ))}
                </div>
                <div className={styles.addSkils}>
                  <input
                    id={'job_skills'}
                    name={'job_skills'}
                    ref={inputNode}
                    type='text'
                    disabled={selectedSkills?.length >= maxSkillsLen}
                    onKeyUp={handleKeyUp}
                    // onKeyDown={handleKeyDown}
                    autoComplete='off'
                    maxLength={100}
                    placeholder={searchOrAddSkill}
                  />
                  <MaterialButton
                    variant='outlined'
                    size='medium'
                    capitalize
                    disabled={selectedSkills?.length >= maxSkillsLen}
                    onClick={() => handleKeyUp('', true)}
                    sx={{
                      height: '60px !important',
                      borderRadius: '10px',
                      backgroundColor: '#fff',
                      color: '#2378E5',
                      padding: '0 30px',
                      ':hover': {}
                    }}
                  >
                    {add}
                  </MaterialButton>
                </div>
              </>
            )}
            <div className={styles.chooseSkill}>
              <div className={styles.labelBox}>
                <div className={styles.inputTag}>
                  <div className={styles.inputSkills}>
                    {selectedSkills.map((item, index) => (
                      <Chip
                        key={item.id}
                        sx={{
                          borderRadius: '4px',
                          height: '34px',
                          background: '#136FD3',
                          margin: '10px  10px 0 0',
                          maxWidth: '100%'
                        }}
                        color='primary'
                        label={item.value}
                        onDelete={(e) => handleDelete(e, index)}
                      />
                    ))}
                    {!isMobile && (
                      <div className={styles.addSkillBtn} onClick={addSkills}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='9'
                          height='8'
                          viewBox='0 0 9 8'
                          fill='none'
                        >
                          <path
                            d='M4.5 0.833008V7.16634'
                            stroke='#515151'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></path>
                          <path
                            d='M1.3335 4H7.66683'
                            stroke='#515151'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          ></path>
                        </svg>
                        <span>
                          {/* {'Add skill'} */}
                          {lang.manageProfile.tab.profile.skill.addSkill}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <p className={`${styles.title} ${styles.titlePeriod}`}>{lang?.profile?.description}</p>

            <div className={styles.step3Editor}>
              <TextEditor value={description} setValue={setDescription} placeholder={placeholder} />
            </div>
          </div>
        </div>

        <FootBtn
          loading={loading}
          rightText={Next2}
          backClick={backClick}
          backText={back}
          skip={skip}
          disabled={isDisabled}
          skipText={skip}
          handleClick={handleSubmit}
        />
      </div>
      <SkillsModal
        options={skills}
        open={openSkillModal}
        values={selectedSkills}
        maxCount={maxSkillsLen}
        onClose={() => setOpenSkillModal(false)}
        onConfirm={handleConfirm}
        lang={modalSkills}
      />
      {
        Boolean(Object.keys(parse).length)
        &&
        <SyncInfo
          show={true}
          info={parse}
          onClose={() => {
            setParse({})
            sessionStorage.removeItem(eduKey);
            // syncResumeStatus(uploadField.id, 3)
          }}
          lang={lang}
          onOk={() => {
            fillDataIntoForm(parse)
          }}

        />}
    </div>

  )
}

export default WorkExperience
