'use client'

import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import styles from './index.module.scss'

import { AI, Employer, Web } from './Icon'
import { SectionSubtitle, SectionTitle } from '../SectionTitle'
import { EmblaCarouselForMobile } from './FeatureOnMobile/FeatureOnMobile'
// import { languageContext } from 'app/components/providers/languageProvider'
import { formatTemplateString } from 'helpers/formatter'
import { S3BucketJobseeker } from 'images'

const baseUrl = `${S3BucketJobseeker}/remote_page/images/`
const img = baseUrl + 'ai-services.png'
const img2 = baseUrl + 'opportunities.png'
const img3 = baseUrl + 'chatting.png'
// import { isMobile } from 'react-device-detect'

const FeatureOnPC = ({ data }) => {
  const [checkIndex, setCheckIndex] = useState(0)
  const preIndex = useRef(0)

  const timer = useRef(0);
  // const src = data[checkIndex].img.src || ''

  const startAnimation = useCallback(() => {
    const run = () => {
      clearTimeout(timer.current)
      timer.current = window.setTimeout(() => {
        setCheckIndex(pre => {
          let newIndex = pre + 1;
          preIndex.current = pre;
          if (newIndex >= 3) {
            newIndex = 0
          }
          return newIndex
        })
        run()
      }, 1000 * 5);
    }
    run()
  }, [])

  useEffect(() => {
    startAnimation()
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className={styles.keyFeatureInnerRoot}>
      <div className={styles.keyFeatureRootRightSection}>
        {data.map((v, i) => {
          const checked = checkIndex === i;

          return <div
            key={i}
            className={styles.item}
            data-checked={checked}
            onClick={() => {
              setCheckIndex(i)
              preIndex.current = checkIndex;
              startAnimation()
            }}

            onMouseEnter={checked ? () => {
              clearTimeout(timer.current)
            } : undefined}
            onMouseLeave={checked ? () => {
              startAnimation()
            } : undefined}
          >
            <div className={styles.leftWrapper}>
              <span className={styles.iconWrapper}>
                <v.icon />
              </span>
              <span className={styles.line} />
            </div>
            <div className={styles.rightWrapper}
              data-checked={checked}
            >
              <div className={styles.backgroundCover} />
              <div className={styles.itemHeader} data-checked={checked}>
                {v.title}
              </div>
              <div className={styles.itemClarification}>
                {v.clarification}
              </div>
            </div>
          </div>
        })}
      </div>

      <div
        className={styles.keyFeatureRootRightSectionImgWrapper}
      >
        {data.map((v, i) => {
          const show = checkIndex === i
          // const isPreOne = preIndex.current === i

          return <div
            key={i}
            style={{
              transform: `scale(${show ? '1' : '0'}) translate3d(${show ? '0%' : (i < checkIndex ? '-100%' : '100%')}, 0px, 0px)`, position: 'absolute',
              opacity: show ? 1 : 0,
              transition: 'all 250ms',
            }}>
            <img src={v.img}
            // style={{ width: '100%' }}
            />
          </div>
        })}
      </div>
    </div >
  </div>
}

const FeatureOnMobile = ({ data }) => {

  return <div className={styles.keyFeatureRootForMobile}>
    <EmblaCarouselForMobile data={data} renderItem={(item, index) => {
      return <div className={styles.keyFeatureMobileCard} key={item.title}>
        <img style={{ maxHeight: '100%', maxWidth: '400px', width: '100%' }} src={item.img} />
        <div className={styles.title}>
          <item.icon /> {item.title}</div>
        <div className={styles.clarification}>{item.clarification}</div>
      </div>
    }} />
  </div>
}

export const KeyFeature = ({ dictionary }) => {

  const [winWidth, setWinWith] = useState(1000)
  const lang = dictionary//useContext(languageContext) as any
  const { remotePage } = lang || {}

  useEffect(() => {
    let resizeHandle = () => {
      setWinWith(window.innerWidth)
    }

    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    }
  }, [])

  const data = [
    {
      title: remotePage?.aiServices,// 'AI-Enhanced Services',
      clarification: remotePage?.aiServicesClarification, // 'Utilize AI technology for efficient resume building and tailored job suggestions based on your unique profile.',
      img: img,
      icon: AI
    },
    {
      title: remotePage?.jobOpportunities,//'Abundant Remote Job Opportunities',
      clarification: remotePage?.jobOpportunitiesClarification
      // `
      // Platform offers numerous remote jobs with constantly updated, comprehensive recruitment information.
      // `
      ,
      img: img2,
      icon: Web
    },
    {
      title: remotePage?.instantOnlineChat,// 'Instant Online Chat with Employers',
      clarification: remotePage?.instantOnlineChatClarification,
      // `
      //   Initiate real-time conversations with potential employers directly online.
      // `,
      img: img3,
      icon: Employer
    }
  ]
  useEffect(() => {
    setWinWith(window?.innerWidth)
  }, [])

  return <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className={styles.keyFeatureRoot}>
      <div className={styles.backgroundShape} >
        <svg xmlns="http://www.w3.org/2000/svg" width="1066" height="880" viewBox="0 0 1066 880" fill="none">
          <path d="M1066 439.5C1066 775.565 793.565 1048 457.5 1048L-99.9999 1048L-100 -169L457.5 -169C793.565 -169 1066 103.435 1066 439.5Z" fill="url(#paint0_linear_8934_9331)" />
          <defs>
            <linearGradient id="paint0_linear_8934_9331" x1="844.5" y1="476.247" x2="1248.21" y2="551.264" gradientUnits="userSpaceOnUse">
              <stop stopColor="#7BA7FF" stopOpacity="0.05" />
              <stop offset="1" stopColor="#377CFE" stopOpacity="0.25" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div>
        <SectionTitle
          innerHTML={`
          ${formatTemplateString(remotePage?.keyFeatureTitle, {
            keyFeature: `<span class="coloredText">${remotePage?.keyFeatures}</span> `
          })}
        `} />

        <SectionSubtitle style={{ marginTop: 12 }}>
          {/* Unlock the Power of AI and Advanced Tools to Supercharge Your Job Search */}
          {remotePage?.keyFeatureSubtitle}
        </SectionSubtitle>

      </div>

      {winWidth > 850 && <FeatureOnPC data={data} />}
      {winWidth <= 850 && <FeatureOnMobile data={data} />}
    </div>
  </div>
}