'use client'

import styles from './index.module.scss'

import calculator from './calculator.png'
import { useState } from 'react'
import { MoneyExchangeCard } from 'components/MoneyExchange/MoneyExchangeCard'

const ArrowRight = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M10.7695 6.5H1.76953" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.76954 3.5L10.7695 6.5L7.76954 9.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}

export const ExchangeCard = ({ dic }) => {
  const [open, setOpen] = useState(false)
  const langDic = dic// = useSharedData('DICTIONARY') || {}

  return <>

    <div className={styles.RemoteJobListExchangeCard} onClick={() => setOpen(true)}>
      <div className={styles.leftRoot}>
        <div className={styles.header}>
          {/* Currency calculator */}
          {langDic?.currencyCalculator?.title}
        </div>
        <div className={styles.description}>
          {/* The Essential Toolbox for Remote Work */}
          {langDic?.currencyCalculator?.desc}
        </div>
        <div
          className={styles.actionBtn}
        >
          {langDic?.currencyCalculator?.goBtn}
          {/* Now Go */}
          <ArrowRight />
        </div>
      </div>
      <img
        src={calculator.src}
      />
    </div>

    <MoneyExchangeCard open={open} onClose={() => setOpen(false)} />
  </>
}