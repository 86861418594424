import React, { useState } from 'react'
import { JobFunctionModal } from 'components/JobFunctionSelector'
import classNames from 'classnames'

import styles from '../../common.module.scss'

const JobFunction = (props: {
  lang: any;
  value: any;
  onChange: (value: any) => void
  className?: string
}) => {
  const { lang = {}, value, onChange = () => { }, className = '' } = props;
  const [showModal, setShowModal] = useState(false)
  const profile = lang.profile || {}

  return (
    <div>
      <div className={classNames(styles.jobFunction_selector, className)} onClick={() => setShowModal(true)}>
        <span className={styles.jobFunction_value + ' ' + (value?.value ? '' : styles.jobFunction_placeholder)}>
          {value.value ? value?.value : profile?.jobFunctionPlaceholder}
        </span>
        {/* <span className={styles.jobFunction_arrow}><ArrowIcon /></span> */}
      </div>
      <JobFunctionModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onChange={(value) => {
          // console.log('job title', value)
          Promise.resolve(onChange(value)).then(() => {
            setShowModal(false)
          })
        }}
        value={value}
        lang={lang}
        title={lang?.profile?.jobFunction}
      />
    </div>
  )
}

export default JobFunction