'use client'

import Link from 'components/Link'
import Image from 'next/image'
import { useSharedData } from 'bossjob-remote/dist/hooks'

export const TelegramLink = ({ className, src }) => {

  const langDic = useSharedData('DICTIONARY') || {}

  return <Link
    className={className}
    to={langDic?.comm?.Do_Not_Translate_TelegramLink} // 'https://t.me/+_rL-EURIYz40MDY1'
    external
    title='Bossjob telegram'
  >
    <Image src={src} alt='telegram' width={24} height={24} />
  </Link>
}