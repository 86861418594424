export const AIIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
    <path d="M62.6002 64.752C56.2552 75.7419 35.2796 85.5329 19.5365 76.4436C3.79335 67.3544 -4.15556 39.7678 2.18945 28.7779C8.53446 17.788 33.0403 26.9919 36.1875 43.8221C51.9306 52.9114 72.7841 42.0559 62.6002 64.752Z" fill="#FFD964" />
    <path d="M18.1616 16.7603C18.1616 12.8368 21.3422 9.65625 25.2656 9.65625H48.3533C49.9748 9.65625 51.508 10.3946 52.519 11.6624L60.6761 21.8917C61.4286 22.8354 61.8384 24.0066 61.8384 25.2135V54.2814C61.8384 58.2048 58.6578 61.3854 54.7344 61.3854H25.2656C21.3422 61.3854 18.1616 58.2048 18.1616 54.2814V16.7603Z" fill="#2863FC" />
    <path d="M53.9506 55.6534C53.462 55.6534 53.0659 55.2573 53.0659 54.7687V47.4257C53.0659 46.9371 53.462 46.541 53.9506 46.541H54.4629C54.9515 46.541 55.3475 46.9371 55.3475 47.4257V54.7687C55.3475 55.2573 54.9515 55.6534 54.4629 55.6534H53.9506Z" fill="white" />
    <path d="M43.8303 55.6534C43.5278 55.6534 43.3146 55.3565 43.4111 55.0699L46.1822 46.8422C46.2428 46.6622 46.4115 46.541 46.6014 46.541H48.8012C48.9911 46.541 49.1598 46.6622 49.2204 46.8422L51.9915 55.0699C52.0881 55.3565 51.8748 55.6534 51.5723 55.6534H50.091C49.8933 55.6534 49.7195 55.5221 49.6655 55.3319L49.2798 53.9731C49.2257 53.7828 49.052 53.6516 48.8542 53.6516H46.5484C46.3506 53.6516 46.1769 53.7828 46.1229 53.9731L45.7371 55.3319C45.6831 55.5221 45.5094 55.6534 45.3116 55.6534H43.8303ZM47.7013 48.5239C47.6882 48.5239 47.6767 48.5326 47.6732 48.5451L46.8545 51.421C46.7741 51.7035 46.9862 51.9844 47.2799 51.9844H48.1227C48.4164 51.9844 48.6286 51.7035 48.5481 51.421L47.7295 48.5451C47.7259 48.5326 47.7144 48.5239 47.7013 48.5239Z" fill="white" />
    <path d="M26.885 21.5625H32.467" stroke="white" strokeOpacity="0.7" strokeWidth="5.92" strokeLinecap="round" />
    <path d="M26.885 34.3262H37.5701" stroke="white" strokeOpacity="0.9" strokeWidth="5.92" strokeLinecap="round" />
  </svg>
}

export const DownIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
    <path d="M62.6002 64.752C56.2552 75.7419 35.2796 85.5329 19.5365 76.4436C3.79335 67.3544 -4.15556 39.7678 2.18945 28.7779C8.53446 17.788 33.0403 26.9919 36.1875 43.8221C51.9306 52.9114 72.7841 42.0559 62.6002 64.752Z" fill="#FFD964" />
    <path d="M44.452 9.63281C45.0995 9.63281 45.7408 9.76037 46.3391 10.0082C46.9374 10.256 47.481 10.6193 47.939 11.0772C48.3969 11.5351 48.7601 12.0787 49.0079 12.677C49.2558 13.2753 49.3833 13.9166 49.3833 14.5642V36.7553H59.2707C60.0229 36.7557 60.7572 36.9854 61.3755 37.4138C61.9938 37.8422 62.4668 38.449 62.7313 39.1531C62.9959 39.8573 63.0394 40.6254 62.8561 41.355C62.6729 42.0845 62.2715 42.7408 61.7056 43.2364L41.9739 60.5134C41.3 61.1032 40.4349 61.4284 39.5393 61.4286C38.6437 61.4288 37.7785 61.1041 37.1042 60.5147L17.3442 43.2376C16.7778 42.7421 16.3759 42.0856 16.1924 41.3557C16.0089 40.6258 16.0525 39.8573 16.3172 39.1528C16.582 38.4483 17.0554 37.8413 17.6742 37.4129C18.293 36.9845 19.0277 36.7551 19.7803 36.7553H29.6578V14.5642C29.6578 13.9166 29.7854 13.2753 30.0332 12.677C30.2811 12.0787 30.6443 11.5351 31.1022 11.0772C31.5601 10.6193 32.1038 10.256 32.7021 10.0082C33.3004 9.76037 33.9416 9.63281 34.5892 9.63281H44.452Z" fill="#18D4BE" />
  </svg>
}

export const GuideIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
    <path d="M62.6002 64.752C56.2552 75.7419 35.2796 85.5329 19.5365 76.4436C3.79335 67.3544 -4.15556 39.7678 2.18945 28.7779C8.53446 17.788 33.0403 26.9919 36.1875 43.8221C51.9306 52.9114 72.7841 42.0559 62.6002 64.752Z" fill="#FFD964" />
    <path d="M17.4006 17.5532C17.4006 13.6298 20.5812 10.4492 24.5046 10.4492H54.5355C58.459 10.4492 61.6395 13.6298 61.6395 17.5532V53.7462C61.6395 57.6696 58.459 60.8502 54.5355 60.8502H24.5046C20.5812 60.8502 17.4006 57.6696 17.4006 53.7462V17.5532Z" fill="#FEA221" />
    <path d="M28.864 27.0859H37.744" stroke="white" strokeOpacity="0.7" strokeWidth="6.38996" strokeLinecap="round" />
    <path d="M28.864 42.0352H50.176" stroke="white" strokeOpacity="0.9" strokeWidth="6.38996" strokeLinecap="round" />
  </svg>
}

export const ArrowRight = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M9.47149 2.19526C9.21114 1.93491 8.78903 1.93491 8.52868 2.19526C8.26833 2.45561 8.26833 2.87772 8.52868 3.13807L12.7239 7.33333L2.33341 7.33333C1.96523 7.33333 1.66675 7.63181 1.66675 8C1.66675 8.36819 1.96523 8.66667 2.33341 8.66667L12.7239 8.66667L8.52868 12.8619C8.26833 13.1223 8.26833 13.5444 8.52868 13.8047C8.78903 14.0651 9.21114 14.0651 9.47149 13.8047L14.8048 8.4714C15.0652 8.21106 15.0652 7.78895 14.8048 7.5286L9.47149 2.19526Z" fill="#18D4BE" />
  </svg>
}