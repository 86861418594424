import React, { useState, useEffect, useRef } from 'react'
import styles from './index.module.scss'
import SearchPanelMobile from '../SearchPanelMobile'
import SearchPanelRemoteMobile from '../SearchPanelRemoteMobile'
import { throttle } from 'lodash-es'
const MobileCard = ({
  config,
  jobFunctions,
  jobClassesRemote,
  locationRemote,
  languageRemote,
  remoteJobs,
  xpLvlsList,
  salaryList,
  jobs,
  tab,
  salaryTypeLists,
  dailySalaryRangeFilters,
  hourlySalaryRangeFilters,
  annuallySalaryRangeFilters,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState('Jobs')
  const [open, setOpen] = useState(false)
  const inTouching = useRef(false)
  const touchStartY = useRef(0)
  const deltaY = useRef(0)
  const containerHight = '80vh'
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  const handleResize = () => {
    // setContainerHight(window.innerHeight + 'px')
  }
  const handleScroll = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const scrollPosition =
      window.scrollY || window.pageYOffset || document.documentElement.scrollTop
    const pageHeight = document.documentElement.scrollHeight

    if (pageHeight - (scrollPosition + windowHeight) <= 1) {
      setOpen(true)
    }
  }
  const throttleHandleResize = throttle(handleResize, 200)
  const throttledHandleScroll = throttle(handleScroll, 200)
  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll)
    window.addEventListener('resize', throttleHandleResize)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
      window.removeEventListener('resize', throttleHandleResize)
    }
  }, [])

  const handleTouchStart = (e) => {
    // e.stopPropagation();
    // e.preventDefault();
    touchStartY.current = e?.touches?.[0]?.clientY - currentTranslateY.current
    inTouching.current = true
  }
  const currentTranslateY = useRef(0)
  const handleTouchMove = (e) => {
    if (!inTouching.current) {
      return
    }
    e.stopPropagation()
    e.preventDefault()

    const mobileCard = document.querySelector('#mobile-card') as any
    // e.stopPropagation()
    // e.preventDefault()
    const height = mobileCard.offsetHeight - 68
    deltaY.current = e.touches[0].clientY - touchStartY.current
    currentTranslateY.current = currentTranslateY.current - deltaY.current
    mobileCard.style.transition = undefined
    if (deltaY.current < 0) {
      mobileCard.style.transform = `translateY(0px)`
    } else if (deltaY.current > height) {
      mobileCard.style.transform = `translateY(${height}px)`
    } else {
      mobileCard.style.transform = `translateY(${deltaY.current}px)`
    }
  }
  const handleTouchEnd = (e) => {
    if (!inTouching.current) {
      return
    }
    // e.stopPropagation()
    // e.preventDefault()
    const mobileCard = document.querySelector('#mobile-card') as any
    const height = mobileCard.offsetHeight - 68
    const shouldShow = open ? height / 4 : (height / 4) * 3
    // 向上滑动
    if (deltaY.current <= shouldShow) {
      currentTranslateY.current = 0
      mobileCard.style.transform = `translateY(0px)`
      mobileCard.style.transition = '0.3s'
      setOpen(true)
    } else {
      currentTranslateY.current = 0 + height
      mobileCard.style.transform = `translateY(${height}px)`
      mobileCard.style.transition = '0.3s'

      setOpen(false)
    }
    inTouching.current = false
  }
  const handleTouchCancel = (e) => {
    e.stopPropagation()
    e.preventDefault()
    inTouching.current = false
  }
  useEffect(() => {
    const mobileCardHeader = document.querySelector('#mobile-card-header')
    mobileCardHeader.addEventListener('touchstart', handleTouchStart, { passive: false })
    mobileCardHeader.addEventListener('touchmove', handleTouchMove, { passive: false })
    mobileCardHeader.addEventListener('touchend', handleTouchEnd, { passive: false })
    mobileCardHeader.addEventListener('touchcancel', handleTouchCancel, { passive: true })

    return () => {
      mobileCardHeader.removeEventListener('touchstart', handleTouchStart)
      mobileCardHeader.removeEventListener('touchmove', handleTouchMove)
      mobileCardHeader.removeEventListener('touchend', handleTouchEnd)
      mobileCardHeader.removeEventListener('touchcancel', handleTouchCancel)
    }
  }, [open])
  const firstRender = useRef(true)
  useEffect(() => {
    const mobileCard = document.querySelector('#mobile-card') as any
    const height = mobileCard.offsetHeight - 68
    if (firstRender.current) {
      mobileCard.style.transition = undefined
      firstRender.current = false
    } else {
      mobileCard.style.transition = '0.3s'
    }
    if (open) {
      currentTranslateY.current = 0
      mobileCard.style.transform = `translateY(0px)`
    } else {
      currentTranslateY.current = 0 + height
      mobileCard.style.transform = `translateY(${height}px)`
    }
    deltaY.current = currentTranslateY.current
  }, [open])
  useEffect(() => {
    // 用于存储 translateY 的变量
    let currentTranslateY = 0
    let stop = false
    // 更新元素位置的函数
    function updatePosition() {
      if (typeof window == 'undefined') return
      // 获取元素的 computed style
      const mobileCard = document.querySelector('#mobile-card') as any
      if (!mobileCard) return
      const style = window.getComputedStyle(mobileCard)
      // 获取 transform 属性的值
      const transform = style.transform
      // 解析 translateY 的值
      const match = transform.match(/matrix.*\((.+)\)/)
      if (match) {
        const values = match[1].split(', ')
        // 2D transforms 的 matrix 为 matrix(a, b, c, d, tx, ty)
        // 其中 tx 和 ty 分别是 translateX 和 translateY 的值
        // 对于 3D transforms，translateY 是第六个值
        const mask = document.querySelector('#mask') as any
        const mobileCard = document.querySelector('#mobile-card') as any
        const height = mobileCard.offsetHeight - 68
        currentTranslateY = parseFloat(values[5] || values[13]) || 0
        const opacity = (height - currentTranslateY) / height
        mask.style.opacity = opacity
        if (opacity == 0) {
          mask.style.display = 'none'
        } else {
          mask.style.display = 'flex'
        }
      }
      // 使用 requestAnimationFrame 来循环调用 updatePosition
      if (!stop) {
        requestAnimationFrame(updatePosition)
      }
    }
    updatePosition()
    return () => {
      stop = true
    }
  }, [])
  const handleJobsClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)

    setCurrentIndex('Jobs')
  }
  const handleRemoteClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
    setCurrentIndex('RemoteJobs')
  }
  useEffect(() => {
    const remoteTab = document.querySelector('#tab-remote')
    const jobsTab = document.querySelector('#tab-jobs')
    if (jobsTab) {
      jobsTab.addEventListener('click', handleJobsClick, { passive: false })
    }
    if (remoteTab) {
      remoteTab.addEventListener('click', handleRemoteClick, { passive: false })
    }
    return () => {
      if (jobsTab) {
        jobsTab.removeEventListener('click', handleJobsClick)
      }
      if (remoteTab) {
        remoteTab.removeEventListener('click', handleRemoteClick)
      }
    }
  }, [])

  const addJobsTableTouch = (id: string) => {
    let touch0Point = 0
    let touchCurrent = 0
    let delta = 0
    const jobsTable = document.querySelector('#' + id)
    if (!jobsTable) {
      return
    }
    const mobileCard = document.querySelector('#mobile-card') as any
    const height = mobileCard.offsetHeight - 68
    const touchStart = (e: any) => {
      touchCurrent = e?.touches?.[0]?.clientY
      touch0Point = 0
    }
    const touchMove = (e: any) => {
      if (jobsTable.scrollTop <= 0 && touch0Point == 0) {
        touch0Point = e?.touches?.[0]?.clientY
      }
      touchCurrent = e?.touches?.[0]?.clientY
      delta = touchCurrent - touch0Point
      if (delta > 0 && touch0Point !== 0) {
        e.stopPropagation()
        e.preventDefault()
        mobileCard.style.transform = `translateY(${delta}px)`
        mobileCard.style.transition = undefined
      }
    }

    const touchEnd = () => {
      const shouldShow = height / 5
      mobileCard.style.transition = '0.3s'
      if (jobsTable.scrollTop > 0) {
        delta = 0
      }
      // 向上滑动
      if (delta <= shouldShow) {
        currentTranslateY.current = 0
        mobileCard.style.transform = `translateY(0px)`
        setOpen(true)
      } else {
        currentTranslateY.current = 0 + height
        mobileCard.style.transform = `translateY(${height}px)`
        setOpen(false)
      }
    }
    jobsTable.addEventListener('touchstart', touchStart, { passive: true })

    jobsTable.addEventListener('touchmove', touchMove, { passive: false })
    jobsTable.addEventListener('touchend', touchEnd, { passive: true })

    return () => {
      jobsTable.removeEventListener('touchstart', touchStart)
      jobsTable.removeEventListener('touchmove', touchMove)
      jobsTable.removeEventListener('touchend', touchEnd)
    }
  }

  return (
    <>
      {<div id='mask' className={styles.mask} onClick={() => setOpen(false)}></div>}
      <div
        className={styles.mobileCard}
        id='mobile-card'
        style={{
          height: containerHight
        }}
      >
        <div className={styles.line}></div>
        <div
          id='mobile-card-header'
          className={styles.header}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpen(!open)
          }}
        >
          <div
            id='tab-jobs'
            className={`${styles.menu} ${currentIndex === 'Jobs' ? styles.menuActive : null}`}
          // onClick={(e) => {
          //   e.stopPropagation()
          //   e.preventDefault()
          //   setOpen(true)
          //   setCurrentIndex('Jobs')
          // }}
          >
            {tab.jobs} <sup>{jobs.total_num}</sup>
          </div>
          {remoteJobs.total_num > 0 && (
            <div
              id='tab-remote'
              className={`${styles.menu} ${currentIndex === 'RemoteJobs' ? styles.menuActive : null
                }`}
            // onClick={(e) => {
            //   e.stopPropagation()
            //   e.preventDefault()
            //   setOpen(true)
            //   setCurrentIndex('RemoteJobs')
            // }}
            >
              {tab.remoteJobs} <sup>{remoteJobs.total_num}</sup>
            </div>
          )}
        </div>
        <div className={styles.cardMain}>
          {currentIndex === 'Jobs' ? (
            <SearchPanelMobile
              CountryList={config}
              xpLvlsList={xpLvlsList}
              salaryList={salaryList}
              functions={jobFunctions}
              setOpen={setOpen}
              salaryTypeLists={salaryTypeLists}
              addJobsTableTouch={addJobsTableTouch}
              containerHight={containerHight}
              dailySalaryRangeFilters={dailySalaryRangeFilters}
              hourlySalaryRangeFilters={hourlySalaryRangeFilters}
              annuallySalaryRangeFilters={annuallySalaryRangeFilters}
            />
          ) : (
            <SearchPanelRemoteMobile
              functions={jobClassesRemote}
              locationRemote={locationRemote}
              languageRemote={languageRemote}
              remoteJobs={remoteJobs}
              setOpen={setOpen}
              addJobsTableTouch={addJobsTableTouch}
              containerHight={containerHight}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default MobileCard
