'use client'
import React, { useEffect } from 'react'

const Tracker = ({ gtagName }) => {
  useEffect(() => {
    const gtag = (window as any)?.gtag;

    if (process.env.ENV === 'production' && gtag) {
      gtag('event', gtagName)
    }
  }, [])

  return <></>
}

export default Tracker