// 'user client'
import { memo, useContext, useMemo } from 'react'
import Link from 'components/Link'
import Image from 'next/image'

import { languageContext } from 'app/components/providers/languageProvider'
import { getValueById } from 'helpers/config/getValueById'
import { getCookie, setCookie } from 'helpers/cookies'
// import { chatSVG } from 'images/svg'
import ShowSalary from 'app/components/ShowSalary'
import { setJobViewCookiesTracker, viewJobSourceTracker } from 'components/ReportTracker/util'

import { useSharedData } from 'bossjob-remote/dist/hooks'

import styles from './jobDetail.module.scss'

const Tags = ({ data }) => {
  if (!data?.length) {
    return null
  }

  return <div className={styles.tabRoot}>
    {data?.map(v => {
      return <span
        className={styles.tabItem}
        key={v}>{v}</span>
    })}

    {/* <div
      style={{ flex: 1 }}
    /> */}

  </div>
}

const useJobLocation = (remote_countries, is_remote_worldwide) => {
  const isWorldwide = is_remote_worldwide

  const { world_region_lists, } = useSharedData('CONFIG') || {}
  const { companyDetail } = useContext(languageContext)

  const [country, countryMap, stateToCountryMap] = useMemo(() => {
    const covertCountry2Opt = (continent = []) => {
      let countryMap: Record<string, any> = {}
      let stateToCountryMap: Record<string, any[]> = {}
      const all = continent
        .map((v: any) => {
          let children: { label: any, value: any }[] = []
          if (v.countries) {
            let childIds: any[] = []

            children = v.countries.map(item => {
              countryMap[item.id] = v.id
              childIds.push(item.id)

              return { value: item.id, label: item.value || item.display_name }
            })

            stateToCountryMap[v.id] = childIds
          }

          return { value: v.id, label: v.value || v.display_name, children }
        })
      return [all, countryMap, stateToCountryMap] as const
    }
    const country = world_region_lists
    return [...covertCountry2Opt(country)]
  }, [world_region_lists])

  const remoteValue = useMemo(() => {
    let remote_country_ids = remote_countries.map(v => v.id)
    let newValue = {}

    const addToNewValue = (key, value) => {
      let arr = newValue[key] || [];
      arr.push(value)

      newValue[key] = arr
    }

    let displayState = []
    let displayCountry = []

    if (!remote_country_ids?.length || !Object.keys(countryMap).length || !world_region_lists?.length) {
      return null
    }

    remote_country_ids.forEach(v => {
      addToNewValue(countryMap[v], v)
    })

    Object.keys(newValue).forEach(key => {
      const state = world_region_lists.find(v => Number(v.id) === Number(key));
      if (!state) {
        console.error(`world_region_lists has no this state, the state id is ${key}`)
        return
      }
      if (newValue[key].length === stateToCountryMap?.[key]?.length) {
        // all countries in this state have been found, so should display the state on page
        displayState.push(state)
      } else {
        let selectedCountries = newValue[key];
        (state.countries || []).forEach(item => {
          if (selectedCountries.includes(item.id)) {
            displayCountry.push(item)
          }
        });

      }
    })

    return { displayState, displayCountry }
  }, [remote_countries, countryMap, stateToCountryMap, world_region_lists]);

  const countryStr = isWorldwide ?
    companyDetail?.overview?.worldwide || 'worldwide' :
    [
      ...(remoteValue?.displayState || []).map(v => {
        return `${v.value}`
      }),
      ...(remoteValue?.displayCountry || []).map(v => {
        return `${v.value} `
      })
    ].join(', ')

  return countryStr
}

const JobDetail = ({ detail, config, langKey, tabValue, prefJobTitle }: any) => {
  const accessToken = getCookie('accessToken')
  const langDic = useContext(languageContext) as any
  const { home, companyDetail } = langDic
  // console.log('detail', detail)

  // let jobLocation = detail.is_remote_worldwide
  //   ? companyDetail?.overview?.worldwide
  //   : detail.remote_countries
  //     .map((e) => getValueById(config, e.id, 'country_id'))
  //     ?.filter((e) => e)
  //     ?.join(',')

  let jobLocation = useJobLocation(detail.remote_countries || [], detail.is_remote_worldwide)
  // console.log({ jobLocation })
  const language = (detail.languages || []).map(v => {
    return getValueById(config, v.id, 'job_language_id') || v.name
  }).join(',')

  // const financingStage = getValueById(
  //   config,
  //   detail?.company_financing_stage_id,
  //   'company_financing_stage_id'
  // )

  const xpLvlValue = getValueById(config, detail?.xp_lvl_id, 'xp_lvl_id')
  const degreeValue = getValueById(config, detail?.degree_id, 'degree_id')
  // const industry = getValueById(config, detail?.company_industry_id, 'industry_id')

  const handleAddJobView = () => {
    setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.remoteHome,
      reco_from: detail?.reco_from ? detail?.reco_from : null,
      query_id: detail?.query_id ? detail?.query_id : null,
    })
  }
  const href = '/' + langKey + detail.job_url
  return (
    <div className={styles.jobDetailForRemoteHomePage}
      data-gtag-event="rl_active_job_detail"
    >
      <div
        onClick={() => handleAddJobView()}

      >
        <Image
          alt={detail?.job_title}
          src={detail?.company_logo}
          width={64}
          height={64}
          quality={0}
        ></Image>
      </div>

      <Link
        className={styles.contentWrapper}
        href={href}
      >
        <div className={styles.jobTitle}>
          {detail?.job_title}
        </div>
        <div className={styles.salaryRoot}>
          <ShowSalary jobs={detail} size={16} dictionary={langDic} />
        </div>
        <div className={styles.job_tags}>
          <Tags data={[
            jobLocation,
            language,
            // xpLvlValue, degreeValue
          ].filter(Boolean)} />
        </div>
      </Link>
      <div>
        <Link href={href} >
          <div className={styles.chatAndApplyBtn}>
            {/* {chatSVG} */}
            {home.jobCard.chatNow}
          </div>
        </Link>
      </div>

    </div>
  )
}

export default memo(JobDetail)
