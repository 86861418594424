'use client'
import { useState } from 'react'
import styled from '@emotion/styled'
import { getCookie } from 'helpers/cookies'

import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'

import { postSaveJobService } from 'store/services/jobs/postSaveJob'
import { deleteSaveJobService } from 'store/services/jobs/deleteSaveJob'
import { useLazyConnectLogin } from 'helpers/lazyConnnectLogin'
import Toast from 'app/components/Toast'

const SaveStyled = styled.div`
  display: flex;
  height: 44px;
  min-width: 170px;
  padding: 0 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #2378e5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.12px;
  border-radius: 8px;
  border: 1px solid #2378e5;
  cursor: pointer;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    border: 1px solid #2378e5;
    background: #f4f9ff;
  }
`

type propsType = {
  isSaved: boolean
  jobId: number
  header: { [key: string]: string }
}

const Save = (props: propsType) => {
  const { header, jobId, isSaved } = props ?? {}
  const sessionid = getCookie('sessionid')
  const [isSave, setIsSave] = useState<boolean>(isSaved)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const { showLoginModal: showLogin } = useLazyConnectLogin()

  const handleSaveJob = () => {
    setSaveLoading(true)

    postSaveJobService({
      job_id: jobId
    })
      .then(() => {
        setIsSave(true)
        Toast.success(header?.savedSuccess)
      })
      .catch(([error]) => {
        const { response: { data } } = error
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            message: data.message ? data.message : 'Save job fail',
            severity: 'error'
          })
        )
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  const handleUnSaveJob = () => {
    setSaveLoading(true)

    deleteSaveJobService(jobId)
      .then(() => {
        setIsSave(false)
        Toast.success(header?.unSaveSuccess)
      })
      .catch(([error]) => {
        const { response: { data } } = error
        postNotification(
          'DISPATCH',
          displayNotification({
            open: true,
            message: data.message ? data.message : 'UnSave job fail',
            severity: 'error'
          })
        )
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  return (
    <SaveStyled
      onClick={() => {
        if (!sessionid) {
          sessionStorage.setItem('redirectPage', window?.location?.pathname)
          showLogin?.()
          return
        } else if (!isSave) {
          handleSaveJob?.()
        } else {
          handleUnSaveJob?.()
        }
      }}
    >
      {isSave ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='21'
          viewBox='0 0 20 21'
          fill='none'
        >
          <path
            d='M10 17.3965C10 17.3965 2.1875 13.0215 2.1875 7.70899C2.1875 6.76984 2.51289 5.8597 3.1083 5.13341C3.70371 4.40713 4.53236 3.90956 5.45328 3.72538C6.37419 3.5412 7.33047 3.68177 8.15943 4.12319C8.98838 4.5646 9.63879 5.27958 10 6.14649C10.3612 5.27958 11.0116 4.5646 11.8406 4.12319C12.6695 3.68177 13.6258 3.5412 14.5467 3.72538C15.4676 3.90956 16.2963 4.40713 16.8917 5.13341C17.4871 5.8597 17.8125 6.76984 17.8125 7.70899C17.8125 13.0215 10 17.3965 10 17.3965Z'
            fill='#2378E5'
            stroke='#2378E5'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
        >
          <path
            d='M10 16.875C10 16.875 2.1875 12.5 2.1875 7.18751C2.1875 6.24836 2.51289 5.33821 3.1083 4.61193C3.70371 3.88564 4.53236 3.38808 5.45328 3.2039C6.37419 3.01971 7.33047 3.16029 8.15943 3.6017C8.98838 4.04311 9.63879 4.7581 10 5.62501C10.3612 4.7581 11.0116 4.04311 11.8406 3.6017C12.6695 3.16029 13.6258 3.01971 14.5467 3.2039C15.4676 3.38808 16.2963 3.88564 16.8917 4.61193C17.4871 5.33821 17.8125 6.24836 17.8125 7.18751C17.8125 12.5 10 16.875 10 16.875Z'
            stroke='#2378E5'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}

      <span>{isSave ? header.undoSave : header.save}</span>
    </SaveStyled>
  )
}

export default Save
