'use client'
import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { usePathname } from 'next/navigation';
import { LinkContext } from 'app/components/providers/linkProvider';

import { getUrlSearchString, isChatProcess } from '../utils';
import { ReactComponent as ResumeIconH5 } from '../../images/svg/resume_h5.svg';
import { ReactComponent as UploadIcon } from '../../images/svg/upload.svg';
import { ReactComponent as EditIcon } from '../../images/svg/edit.svg';
import ResumeOnlineModal from '../ResumeOnline';
import UploadResumeModal from '../UploadFile';
import SimpleSteps from '../Step/Simple';
import { addEventTag } from 'helpers/utilities';
import { useAttachedResumeContext } from './context';
import { useMainResumeContext } from '../Main/context';

const AttachedResumeH5 = (props: {
  lang: any;
  config: any
  schoolList: any
}) => {
  const { lang, schoolList } = props;
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [openOnlineModal, setOpenOnlineModal] = useState(false)
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const chatProcess = isChatProcess()
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const { initData } = useAttachedResumeContext()

  const handleBack = () => {
    const searchWords = getUrlSearchString(2)
    push(`${pathname}${searchWords}`)
  }

  const openUploadResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_upload' : 'new_user_complete_resume_upload')
    setOpenUploadModal(true)
  }

  const openOnlineResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_fill' : 'new_user_complete_resume_fill')
    Promise.resolve(initData(userDetail))
      .then(() => {
        setOpenOnlineModal(true)
      })
  }

  const handleUploadResume = () => {
    setOpenUploadModal(false)
  }

  const handleOnlineResume = () => {
    setOpenOnlineModal(false)
  }

  return (
    <AttachedResumeH5Wrap>
      <div className='iconWrapper'>
        <ResumeIconH5 className="resumeIcon" />
        <SimpleSteps currentStep={2} totalSteps={2} />
      </div>
      <h1>{profile.attachedResume?.title}</h1>
      <Options>
        <Option onClick={openUploadResumeModal}>
          <div className="textContainer">
            <p className="optionTitle">{profile.attachedResume?.upload}</p>
            <p className="optionSubtitle">{profile.attachedResume?.uploadTip}</p>
          </div>
          <UploadIcon className="icon" />
        </Option>
        <Option onClick={openOnlineResumeModal}>
          <div className="textContainer">
            <p className="optionTitle">{profile.attachedResume?.online}</p>
            <p className="optionSubtitle">{profile.attachedResume?.onlineTip}</p>
          </div>
          <EditIcon className="icon" />
        </Option>
      </Options>
      <BackButton onClick={handleBack}>
        <p className="countdownText">{profile.back}</p>
      </BackButton>

      {
        openOnlineModal && (
          <ResumeOnlineModal
            {...props}
            userDetail={userDetail}
            getUserInfo={getUserInfo}
            open={openOnlineModal}
            onOk={handleOnlineResume}
            onCancel={() => setOpenOnlineModal(false)}
            schoolList={schoolList}
          />
        )
      }

      {
        openUploadModal && (
          <UploadResumeModal
            {...props}
            userDetail={userDetail}
            getUserInfo={getUserInfo}
            open={openUploadModal}
            onOk={handleUploadResume}
            onCancel={() => setOpenUploadModal(false)}
          />
        )
      }
    </AttachedResumeH5Wrap>
  );
};

const AttachedResumeH5Wrap = styled.div`
  display: flex;
  min-height: 600px;
  padding: 32px 20px;
  flex-direction: column;
  align-items: start;
  background: #FFFFFF;

  .iconWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #121212;
    margin-bottom: 4px;
  }

  p.subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg.resumeIcon {
    width: 32px;
    height: 32px;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 56px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 20px;
  margin-bottom: 20px;
  background: #F8FBFE;
  border-radius: 4px;

  &:hover {
    background: #EFF7FF;
    cursor: pointer;
  }

  .textContainer {
    flex-grow: 1;
    max-width: calc(100% - 40px);
  }

  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .optionTitle {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }

  .optionSubtitle {
    margin-top: 6px;
    font-size: 14px;
    color: #7D7D7D;
  }
`;

const BackButton = styled.div`
  display: flex;
  height: 44px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #E4E4E4;

  p.countdownText {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
  }
`;

export default AttachedResumeH5;