import React, { useState, useContext, useRef, useEffect } from 'react'
import styles from '../index.module.scss'
import Text from 'components/Text'
import MaterialDatePicker from 'components/MaterialDatePicker'
import JobFunctionSelector from 'components/JobFunctionSelector'
import FormControlLabel from '@mui/material/FormControlLabel'
import MaterialTextField from 'components/MaterialTextField'
import Switch from '@mui/material/Switch'
import TextEditor from 'components/TextEditor/TextEditor'
import FootBtn from './footBtn'

import { addUserWorkExperienceService } from 'store/services/users/addUserWorkExperience'
import moment from 'moment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { useSyncResumeContext } from '../context'
import classNames from 'classnames'
import { RequiredLabel } from './RequireLabel'
import { formatTemplateString } from 'helpers/formatter'
import Toast from 'app/components/Toast'

const isSubmitDisabled = (form, { isCurrentJob, from, to }) => {
  let disabled = false
  const keys = Object.keys(form)
  let key = keys.find(key => {
    if (key === 'jobFunction') {
      return !form[key].value
    }
    return !form[key]
  })

  disabled = Boolean(key)
  if (!disabled) {
    disabled = Boolean(isCurrentJob ? !from : !(from && to))
  }
  return disabled
}


const removeDescription = (form) => {
  const copy = { ...form };
  delete copy.description

  return copy
}

const WorkExperience = (props: any) => {
  const { lang, config } = props
  const { industry_lists = [], currency_lists = [] } = config || {};
  const { value, onSkipClick, onSubmit } = useSyncResumeContext()
  const [form, setForm] = useState({
    jobTitle: value.job_title || "",
    companyName: value.company_name || "",
    description: value.description || "",
    jobFunction: {
      id: 0,
      value: ''
    },
    currency: "",
    industry: '',
    salary: ""
  })
  const [isCurrentJob, setIsCurrentJob] = useState<boolean>(value.is_currently_work_here)
  // const [workingSince, setWorkingSince] = useState<any>(null)
  const [workPeriodFrom, setWorkPeriodFrom] = useState<any>((value.from_date && new Date(value.from_date)) || null)
  const [workPeriodTo, setWorkPeriodTo] = useState<any>(value.to_date && new Date(value.to_date) || null)
  const [loading, setLoading] = useState<boolean>(false)


  const {
    workExperience,
    autofillMyInfo,
    saveTimeByImporting,
    uploadResume,
    supportedFileType,
    startedWorkingSince,
    mostRecentCompany,
    WorkingPeriod,
    mostRecentJobTitle,
    skillsWillBeSuggested,
    Next2,
    back,
    companyNameText,
    currentlyWorkHere,
    from,
    to,
    placeholder,
    skip,
    uploadSeccess,
    searchOrAddSkill,
    add,
  } = lang?.profile || {}

  const {
    manageProfile: {
      tab: {
        profile: { expModal }
      }
    },
    comm = {}
  } = lang;


  useEffect(() => {
    document.scrollingElement.scrollTo(0, 0)
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    let p2 = null
    const { companyName, jobFunction, jobTitle } = form
    const paramsWork = {
      job_title: jobTitle,//jobFunction?.value,
      function_job_title_id: jobFunction?.id,
      company: companyName,
      working_period_from: `${moment(new Date(workPeriodFrom)).format('yyyy-MM')}-01`,
      working_period_to: isCurrentJob
        ? null
        : `${moment(new Date(workPeriodTo)).format('yyyy-MM')}-01`,
      is_currently_work_here: isCurrentJob,
      description: form.description,
      description_html: form.description,
    }

    if (isCurrentJob) {
      delete paramsWork.working_period_to
    }

    p2 = addUserWorkExperienceService({
      workExperience: paramsWork
    })

    p2.then(() => {
      onSubmit()
    }).catch(err => {
      const msg = err[1]?.data?.message
      msg && Toast.error(msg)
    }).finally(() => setLoading(false))
  }

  return (
    <div className={styles.work}>
      <div className={styles.workContainer}>
        <div className={styles.box}>
          <div className={styles.body}>
            {/* jobTitle */}
            <div className={styles.stepCompany}>
              <MaterialTextField
                className={styles.stepFullwidth}
                label={<RequiredLabel
                  // text={"Job Title"} 
                  text={comm.jobTitle || "Job Title"}
                />}
                size='small'
                fullWidth
                variant='outlined'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                value={form.jobTitle}
                defaultValue={form.jobTitle}
                onChange={(e) => setForm(pre => {
                  return { ...pre, jobTitle: e.target.value.slice(0, 100) }
                })}
              />
            </div>
            {/* company name */}
            <div className={styles.stepCompany}>
              <MaterialTextField
                className={styles.stepFullwidth}
                size='small'

                label={<RequiredLabel text={companyNameText} />}
                fullWidth
                variant='outlined'
                sx={{
                  '.MuiInput-input': {
                    padding: '4px 0 8px'
                  }
                }}
                value={form.companyName}
                defaultValue={form.companyName}
                onChange={(e) => {
                  setForm(pre => {
                    return { ...pre, companyName: e.target.value }
                  })
                }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <p className={styles.title}>
                {WorkingPeriod}
                <span>*</span>
              </p>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCurrentJob}
                      onChange={() => setIsCurrentJob(!isCurrentJob)}
                      name='currentJob'
                    />
                  }
                  label={<Text textStyle='base'>{currentlyWorkHere}</Text>}
                />
              </div>

              <div className={styles.notShirkLabelFor10px} style={{ display: 'flex', gap: 16, flexDirection: 'column' }}>
                <div className={styles.title}>{comm.From || "From"}</div>
                <div className={classNames(styles.stepFieldToItem, styles.timeFormWrapper)}>
                  <MaterialDatePicker
                    label={comm['month/year'] || 'Month / Year'}
                    views={['year', 'month']}
                    inputFormat='MMM yyyy'
                    value={workPeriodFrom}
                    onDateChange={(value) => {
                      setWorkPeriodFrom(value)
                    }}
                    showToolbar={false}
                  />
                </div>
                {!isCurrentJob && <>
                  <div className={styles.title}>
                    {comm.To || "To"}
                  </div>
                  <div className={classNames(styles.stepFieldToItem, styles.timeFormWrapper)}>
                    <MaterialDatePicker
                      // label={to}
                      label={comm['month/year'] || 'Month / Year'}
                      views={['year', 'month']}
                      inputFormat='MMM yyyy'
                      value={workPeriodTo}
                      onDateChange={(value) => {
                        setWorkPeriodTo(value)
                      }}
                      showToolbar={false}
                    />
                  </div>
                </>}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <div id='jobFunction' >
                <JobFunctionSelector
                  className={styles.stepFullwidth}
                  label={<RequiredLabel text={comm.JobFunction} />}
                  lang={lang}
                  name='jobFunction'
                  isTouched
                  fullWidth
                  variant='outlined'
                  sx={{
                    '.MuiInput-input': {
                      padding: '4px 0 8px'
                    }
                  }}
                  value={form.jobFunction}
                  onChange={(obj) => {
                    const { id, value } = obj
                    setForm(pre => {
                      return { ...pre, jobFunction: { value, id } }
                    })
                  }}
                />
              </div>
              <FormControl fullWidth className={classNames(styles.notShirkLabel, styles.normalSelect)}>
                <InputLabel id="Industry-label"><RequiredLabel text={comm.Industry || "Industry"} /></InputLabel>
                <Select
                  size='small'
                  labelId="Industry-label"
                  value={form.industry}
                  label={<RequiredLabel text={comm.Industry || "Industry"} />}
                  onChange={(event) => {
                    let value = event.target.value
                    setForm(pre => {
                      return { ...pre, industry: value }
                    })
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {industry_lists?.map(v => {
                    return <MenuItem value={v.id}>{v.value}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth className={classNames(styles.notShirkLabel, styles.normalSelect)}>
                <InputLabel id="Currency"><RequiredLabel text={comm.currency || "currency"} /></InputLabel>
                <Select
                  size='small'
                  labelId="Currency"
                  // id="demo-simple-select"
                  label={<RequiredLabel text={comm.currency || "currency"} />}
                  value={form.currency}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  onChange={(event) => {
                    let value = event.target.value
                    setForm(pre => {
                      return { ...pre, currency: value }
                    })
                  }}
                >
                  {currency_lists?.map(v => {
                    return <MenuItem value={v.id}>{v.display_symbol}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <div className={styles.field}>
                {form.currency && (
                  <MaterialTextField
                    className={styles.fullWidth}
                    maxLength={10}
                    label={<RequiredLabel
                      text={`
                        ${formatTemplateString(
                        expModal?.salary,
                        currency_lists.find(({ value, id }) => {
                          return id === form.currency
                        })?.display_symbol
                      )}
                      `} />
                    }
                    size='small'
                    value={form.salary}
                    min="0"
                    type="tel"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    onChange={(event) => {
                      let value = event.target.value
                      setForm(pre => {
                        return { ...pre, salary: value }
                      })
                    }}
                  />
                )}
              </div>
            </div>

            <p className={`${styles.title} ${styles.titlePeriod}`}>{lang?.profile?.description}</p>
            <div className={styles.step3Editor}>
              <TextEditor
                value={form.description}
                setValue={value => {
                  setForm(pre => {
                    return { ...pre, description: value }
                  })
                }}
                placeholder={placeholder} />
            </div>
          </div>
        </div>
        <FootBtn
          disabled={isSubmitDisabled(removeDescription(form), { isCurrentJob, to: workPeriodTo, from: workPeriodFrom })}
          loading={loading}
          handleClick={handleSubmit}
          skipClick={() => {
            onSkipClick()
          }} />
      </div>
    </div>
  )
}

export default WorkExperience
