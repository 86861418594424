"use client"
import React from 'react'
import { formatTemplateString } from 'helpers/formatter'
import styled from '@emotion/styled';

const companyInfo = {
  ph: {
    companyNameKey: 'corporation',
  },
  id: {
    companyNameKey: 'IndonesiaPT',
  },
  sg: {
    companyNameKey: 'SingaporeYolo',
  },
  jp: {
    companyNameKey: 'JapanYolo',
  }
}

function pathContainsSpecPath(currentPath, specPath) {
  return specPath.some(item => currentPath.includes(item));
}

const Copyright = (props: any) => {
  const { data, countryKey } = props

  const currentYear = new Date().getFullYear()
  const companyNameKey = companyInfo[countryKey]?.companyNameKey || companyInfo.sg.companyNameKey
  const companyName = data?.foot[companyNameKey] || ''
  const specPath = ['company/', 'job/']
  const currentPath = decodeURIComponent(typeof window !== 'undefined' ? location.pathname : '')
  const isSpecPath = pathContainsSpecPath(currentPath, specPath)
  let style = null
  if (isSpecPath) style = { paddingBottom: '70px' }
  if (currentPath.includes('/settings')) style = { display: 'none' }

  return (
    <CopyrightStyle style={style}>
      <div className='copyright_wrap'>
        <span className='copyright'>{formatTemplateString(data?.foot['copyright'], currentYear, companyName)}</span>
      </div>
    </CopyrightStyle>
  )
}

export default Copyright

const CopyrightStyle = styled.div`
  background: #0E1116;
  .copyright_wrap {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .copyright {
      color:#E4E4E4;
      font-family: "PingFang SC" !important;
      font-size: 14px;
    }
  }
`;