import React, { useContext, useEffect, useMemo, useState } from 'react'

/* Components */
import Text from 'components/Text'
import MaterialButton from 'components/MaterialButton'
import Link from 'components/Link'
import EditRename from './EditRename/EditRename'
import Image from 'next/image'
/* Helpers */
import { maxFileSize } from 'helpers/handleInput'

/* Styles */
import styles from './UploadResume.module.scss'
import classNames from 'classnames'
import { SnackbarTips } from './SnackbarTips'
import moment from 'moment'
import { formatTemplateString } from 'helpers/formatter'
import { languageContext } from 'app/components/providers/languageProvider'
import { ConfirmProvider } from "material-ui-confirm";
import { Tooltip } from 'antd'
import { useRouter } from 'next/navigation'
import { getLang } from 'helpers/country'
import { IconButton } from '@mui/material'
import document_url from './document.png'
import { SyncProfile } from 'app/[lang]/manage-profile/_component/syncProfile/syncProfile'
import { getSyncableContentService } from 'store/services/users/uploadUserResume'
moment.locale('en')

type resumeObject = {
  name: string
  url: string
  id: number
  updated_at: string,
  sync_status_id: number
  first_sync: boolean
}

type UploadResumeProps = {
  title: string
  resumes: resumeObject[]
  handleDelete?: Function
  handleUpload: Function
  buttonClassname?: string
  deleteResumeLoading?: boolean
  lang?: Record<string, any>
  updateResume?: () => void,
  setResume?: React.Dispatch<React.SetStateAction<any[]>>
}
const SyncIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3 5C3 3.34315 4.34315 2 6 2H18C19.6569 2 21 3.34315 21 5V12H19V5C19 4.44772 18.5523 4 18 4H6C5.44772 4 5 4.44772 5 5V18C5 18.5523 5.44771 19 6 19H9.85714V21H6C4.34314 21 3 19.6569 3 18V5Z" fill="#7D7D7D" />
    <path d="M16 9L8 9C7.44772 9 7 8.55229 7 8C7 7.44772 7.44772 7 8 7L16 7C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9Z" fill="#7D7D7D" />
    <path d="M15.253 13.4629C14.9564 13.1738 14.4815 13.1799 14.1924 13.4765L12.5088 15.204C12.2983 15.42 12.2376 15.7411 12.3548 16.019C12.4721 16.2968 12.7443 16.4775 13.0459 16.4775H20.0459C20.4601 16.4775 20.7959 16.1417 20.7959 15.7275C20.7959 15.3133 20.4601 14.9775 20.0459 14.9775H14.8241L15.2666 14.5235C15.5557 14.2268 15.5496 13.752 15.253 13.4629Z" fill="#7D7D7D" />
    <path d="M17.7929 20.582C17.4963 20.2929 17.4902 19.8181 17.7793 19.5215L18.2218 19.0674H13C12.5858 19.0674 12.25 18.7316 12.25 18.3174C12.25 17.9032 12.5858 17.5674 13 17.5674H20C20.3016 17.5674 20.5738 17.7481 20.691 18.026C20.8083 18.3039 20.7476 18.6249 20.5371 18.8409L18.8535 20.5684C18.5644 20.865 18.0895 20.8711 17.7929 20.582Z" fill="#7D7D7D" />
    <path d="M7 12C7 11.4477 7.44772 11 8 11H10C10.5523 11 11 11.4477 11 12C11 12.5523 10.5523 13 10 13H8C7.44772 13 7 12.5523 7 12Z" fill="#7D7D7D" />
  </svg>
}

const Trash = ({
  id,
  name,
  deleteResumeLoading,
  lang,
  updateResume,
  displayClear
}: {
  id: number
  deleteResumeLoading: boolean
  name: string
  lang?: Record<string, any>
  updateResume?: () => void
  displayClear: boolean
}) => {
  return (
    <div
      className={classNames([
        styles.trashDiv,
        deleteResumeLoading ? styles.disabledDelResume : null
      ])}
    >
      {/* <img
        className={classNames([
          styles.trashDiv_icon,
          deleteResumeLoading ? styles.disabledDelResume_icon : null
        ])}
        src={TrashIcon}
        alt='trash'
        width='14'
        height='14'
        onClick={onClick}
      /> */}

      <EditRename
        id={id}
        name={name}
        deleteResumeLoading={deleteResumeLoading}
        refreshList={updateResume}
        // handleDeleteResume={onClick}
        lang={lang}
        displayClear={displayClear}
      />
    </div>
  )
}

const SyncLabel = ({ children }: { children?: any }) => {
  return <div className={styles.syncLabel}
    style={{ cursor: 'default' }}
  >
    {children || 'Syncable'}
  </div>
}

const getSyncData = (rid) => {
  return getSyncableContentService(rid).then(res => {
    const data = res.data.data || {}
    const { add_educations: edu = [], add_skills: skill = [], add_work_experiences: exp = [], skills_item_id } = data;

    const result = [{ key: 'exp', data: exp }, { key: 'edu', data: edu }, { key: 'skill', data: skill }]

    return result
  }).catch((error) => {
    console.log(error)
  })
}

const UploadResume = ({
  resumes,
  handleUpload,
  buttonClassname,
  deleteResumeLoading,
  lang,
  updateResume,
  setResume
}: UploadResumeProps) => {
  const {
    manageProfile: {
      tab: { resume: transitions }
    },
    manage
  } = useContext(languageContext) as any
  const router = useRouter();
  const [isExceedLimit, setIsExceedLimit] = useState(false)
  const handleOnFileChange = (e) => {
    const file = e.target.files[0]
    if (!maxFileSize(file, 5)) {
      setIsExceedLimit(true)
      return
    }
    handleUpload?.(file)
  }

  const [syncContent, setSyncContent] = useState<{ data: any[], showId?: number }>({
    data: [],
    showId: null
  })

  const displayClear = useMemo(() => resumes.length > 1, [resumes])
  return (
    <ConfirmProvider>
      <div className={styles.uploadResumeField}>
        {resumes.length ?
          resumes.map((item, index) => {
            const { sync_status_id, first_sync, id } = item;
            const canSync = sync_status_id == 3 || sync_status_id === 2

            return (
              <div key={index + '~' + item?.id} className={styles.uploadedResume}>
                <div className={styles.leftResume}>
                  <div className={styles.documentDiv}>
                    <Image src={document_url} fill alt='document' />
                  </div>
                  <div className={styles.document_info}>
                    <span style={{ display: 'flex' }}>
                      <Link to={item?.url} external style={{ flex: 1, overflow: 'hidden' }}>
                        <Text className={styles.resumeName}>
                          {item?.name}
                        </Text>
                      </Link>
                      {canSync && <SyncLabel>{manage?.tab?.resume?.syncLabel}</SyncLabel>}
                    </span>
                    <div className={styles.resumeTime}>
                      {formatTemplateString(
                        transitions.upload.time,
                        moment(item.updated_at).format('DD MMM yyyy')
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 24 }}>
                  {canSync && <Tooltip
                    // title="Sync New Updates to Profile"
                    title={manage?.tab?.resume?.syncNewUpdate || "Sync New Updates to Profile"}
                  >
                    <IconButton
                      onClick={() => {
                        const id = item?.id;
                        const gotoSyncPage = () => {
                          router.push(`/${getLang()}/sync-profile-from-resume?rid=${item?.id}`)
                        }
                        if (first_sync) {
                          setSyncContent({ data: [], showId: id })

                          getSyncData(id).then(res => {
                            setResume?.(pre => {
                              const copy = [...pre];
                              copy[index] = { ...copy[index] };
                              copy[index].first_sync = false
                              return copy
                            })
                            if (res) {
                              setSyncContent(pre => {
                                if (pre.showId === id) {
                                  return { ...pre, data: res }
                                }
                                return pre
                              })
                              return
                            }

                            throw new Error('fetch resume failed')
                          }).catch(error => {
                            // error, goto page
                            console.log(error)
                            gotoSyncPage()
                          })
                        } else {
                          gotoSyncPage()
                        }
                      }}>
                      <SyncIcon />
                    </IconButton>
                  </Tooltip>}
                  <Trash
                    id={item.id}
                    name={item.name}
                    updateResume={updateResume}
                    deleteResumeLoading={deleteResumeLoading}
                    // onClick={() => handleDeleteResume(item.id)}
                    lang={lang}
                    displayClear={displayClear}
                  />
                </div>
              </div>
            )
          }) : null}
        {!resumes.length && (
          <div>
            <MaterialButton
              variant='outlined'
              capitalize
              component='label'
              className={classNames([styles.buttonCTA, buttonClassname])}
            >
              <Text textStyle='base' textColor='primaryBlue' bold>
                {transitions.upload.uploadBtn}
              </Text>
              <input
                type='file'
                hidden
                accept='.pdf, .doc, .docx'
                onChange={(e) => handleOnFileChange(e)}
              />
            </MaterialButton>
          </div>
        )}

        <Text textStyle='sm' block className={styles.upToFiles}>
          {formatTemplateString(transitions.upload.upToFiles, 3)}
        </Text>
        <Text textColor='darkgrey' textStyle='sm'>
          {transitions.upload.support}
        </Text>
      </div>
      <SnackbarTips
        title={transitions.upload.error.title}
        errorMessage={transitions.upload.error.tips}
        show={isExceedLimit}
        onDismiss={() => {
          setIsExceedLimit(false)
        }}
      />
      <SyncProfile
        show={Boolean(syncContent.showId)}
        onVisibleChange={visible => {
          setSyncContent(pre => {
            return { data: [], showId: null }
          })
        }}

        data={{
          id: syncContent.showId,
          result: syncContent.data
        }} />
    </ConfirmProvider>
  )
}

export default UploadResume
