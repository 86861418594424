import React, { useContext, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

import styles from '../index.module.scss'
import { languageContext } from 'app/components/providers/languageProvider'

interface btnProps {
  handleClick?: any
  skipClick?: () => void
  loading?: boolean
  disabled?: boolean
}

const FootBtn = ({ skipClick, handleClick, loading, disabled }: btnProps) => {
  const isMobile = document?.body.clientWidth < 750
  const lang = useContext(languageContext) as any
  const style: any = disabled ? { cursor: 'not-allowed', pointerEvents: 'all', color: 'rgba(0, 0, 0, 0.26)', backgroundColor: 'rgba(0, 0, 0, 0.12)' } : {}

  return (
    <div className={styles.footerArea}>
      <LoadingButton
        // loading={loading}
        disabled={loading}
        onClick={skipClick}
        // variant={'contained'}
        className={styles.skipBtn}
        variant={isMobile ? 'outlined' : 'contained'}
      >
        {/* Skip */}
        {lang.comm?.Skip || "Skip"}
      </LoadingButton>
      <LoadingButton
        loading={loading}
        onClick={handleClick}
        variant='contained'
        disabled={disabled}
        style={{ boxShadow: 'none', ...style }}
        sx={{
          height: '60px',
          textTransform: 'capitalize',
          boxShadow: 'none',
          borderRadius: '10px',
          background: '#2378E5'
          // color: '#707070',
        }}
      >
        {/* Save to Profile */}
        {lang.comm?.saveToProfile || "Save to Profile"}
      </LoadingButton>
    </div>
  )
}

export default FootBtn
