
export const ArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.084 4.86128C14.3444 5.12163 14.3444 5.54374 14.084 5.80409L8.7507 11.1374C8.49035 11.3978 8.06824 11.3978 7.80789 11.1374L2.47456 5.80409C2.21421 5.54374 2.21421 5.12163 2.47456 4.86128C2.73491 4.60093 3.15702 4.60093 3.41737 4.86128L8.2793 9.72321L13.1412 4.86128C13.4016 4.60093 13.8237 4.60093 14.084 4.86128Z" fill="#515151" />
    </svg>
  )
}

export const NavLeftSharps = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={199}
    height={245}
    fill="none"
    {...props}
  >
    <g fill="#fff" fillOpacity={0.07} clipPath="url(#a)">
      <path d="M25.218 52.413C16.69 67.185-2.2 72.246-16.972 63.717L-43.72 48.275l15.443-26.748C-19.748 6.755-.859 1.694 13.913 10.222c14.773 8.53 19.834 27.418 11.305 42.19ZM-8.005 109.956c-8.528 14.772-27.418 19.833-42.19 11.304l-26.747-15.442L-61.5 79.07c8.53-14.772 27.418-19.833 42.19-11.305C-4.536 76.294.525 95.183-8.004 109.956ZM83.052 85.801c-8.529 14.773-3.467 33.662 11.305 42.191l26.748 15.442 15.442-26.747c8.529-14.773 3.468-33.662-11.305-42.19-14.772-8.53-33.661-3.468-42.19 11.304ZM194.378 150.077c-8.529 14.772-27.418 19.833-42.19 11.304l-26.748-15.442 15.443-26.748c8.529-14.772 27.418-19.833 42.19-11.305 14.773 8.529 19.834 27.418 11.305 42.191ZM92.016 132.04c14.772 8.528 19.834 27.418 11.305 42.19l-15.443 26.748-26.747-15.443c-14.773-8.529-19.834-27.418-11.305-42.19 8.529-14.773 27.418-19.834 42.19-11.305ZM161.156 207.62c-8.529 14.772-27.418 19.833-42.191 11.304l-26.747-15.442 15.442-26.748c8.529-14.772 27.418-19.833 42.191-11.305 14.772 8.529 19.833 27.418 11.305 42.191ZM-41.396 167.789c8.529-14.773 27.418-19.834 42.19-11.305l26.748 15.442-15.443 26.748c-8.528 14.772-27.418 19.834-42.19 11.305-14.772-8.529-19.834-27.418-11.305-42.19ZM127.766 265.453c8.529-14.773 3.468-33.662-11.305-42.191l-84.58-48.832-6.782 11.747c-13.312 23.057-5.412 52.539 17.645 65.851l42.832 24.73c14.772 8.528 33.661 3.467 42.19-11.305Z" />
      <path d="M.593 95.064c-13.277 22.997-5.398 52.402 17.6 65.68l11.855 6.845 48.665-84.291c8.53-14.773 3.468-33.662-11.304-42.19-14.773-8.53-33.662-3.468-42.19 11.304L.592 95.064ZM-16.95 259.01c-8.529 14.773-3.467 33.662 11.305 42.191l26.748 15.442 15.442-26.747c8.53-14.773 3.468-33.662-11.305-42.191-14.772-8.528-33.661-3.467-42.19 11.305Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h199v245H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const NavRightSharps = (props) => (
  <svg width="274" height="245" viewBox="0 0 274 245" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_10527_25922)">
      <path d="M226.072 -11.8628C217.543 2.90947 222.604 21.7987 237.376 30.3275L264.124 45.7702L279.567 19.0226C288.096 4.25035 283.034 -14.6389 268.262 -23.1677C253.49 -31.6964 234.6 -26.6351 226.072 -11.8628Z" fill="white" fillOpacity="0.07" />
      <path d="M337.398 52.4126C328.869 67.1849 309.98 72.2462 295.208 63.7174L268.46 48.2747L283.903 21.5271C292.431 6.75486 311.321 1.69351 326.093 10.2223C340.865 18.7511 345.927 37.6403 337.398 52.4126Z" fill="white" fillOpacity="0.07" />
      <path d="M235.036 34.3756C249.808 42.9044 254.869 61.7937 246.34 76.5659L230.898 103.314L204.15 87.8708C189.378 79.342 184.317 60.4528 192.845 45.6805C201.374 30.9082 220.263 25.8469 235.036 34.3756Z" fill="white" fillOpacity="0.07" />
      <path d="M304.175 109.956C295.646 124.728 276.757 129.789 261.985 121.26L235.237 105.818L250.68 79.0701C259.209 64.2978 278.098 59.2365 292.87 67.7653C307.643 76.294 312.704 95.1833 304.175 109.956Z" fill="white" fillOpacity="0.07" />
      <path d="M101.624 70.1245C110.152 55.3523 129.042 50.2909 143.814 58.8197L170.562 74.2624L155.119 101.01C146.59 115.782 127.701 120.844 112.929 112.315C98.1563 103.786 93.0949 84.8968 101.624 70.1245Z" fill="white" fillOpacity="0.07" />
      <path d="M270.786 167.789C279.315 153.016 274.253 134.127 259.481 125.598L174.901 76.7659L168.118 88.5131C154.807 111.57 162.707 141.052 185.763 154.364L228.596 179.093C243.368 187.622 262.257 182.561 270.786 167.789Z" fill="white" fillOpacity="0.07" />
      <path d="M143.613 -2.6004C130.336 20.3964 138.215 49.8024 161.212 63.0796L173.067 69.9245L221.733 -14.3665C230.262 -29.1388 225.2 -48.028 210.428 -56.5568C195.656 -65.0856 176.766 -60.0242 168.238 -45.252L143.613 -2.6004Z" fill="white" fillOpacity="0.07" />
      <path d="M126.07 161.346C117.541 176.118 122.602 195.008 137.375 203.536L164.122 218.979L179.565 192.232C188.094 177.459 183.032 158.57 168.26 150.041C153.488 141.513 134.598 146.574 126.07 161.346Z" fill="white" fillOpacity="0.07" />
      <path d="M237.396 225.622C228.867 240.394 209.978 245.455 195.206 236.926L168.458 221.484L183.901 194.736C192.429 179.964 211.319 174.902 226.091 183.431C240.863 191.96 245.925 210.849 237.396 225.622Z" fill="white" fillOpacity="0.07" />
      <path d="M135.034 207.585C149.806 216.113 154.867 235.003 146.339 249.775L130.896 276.522L104.148 261.08C89.376 252.551 84.3146 233.662 92.8434 218.889C101.372 204.117 120.261 199.056 135.034 207.585Z" fill="white" fillOpacity="0.07" />
      <path d="M204.173 283.165C195.644 297.937 176.755 302.998 161.983 294.469L135.235 279.027L150.678 252.279C159.207 237.507 178.096 232.445 192.868 240.974C207.641 249.503 212.702 268.392 204.173 283.165Z" fill="white" fillOpacity="0.07" />
      <path d="M1.62173 243.332C10.1505 228.559 29.0398 223.498 43.812 232.027L70.5596 247.469L55.1169 274.217C46.5881 288.989 27.6989 294.051 12.9266 285.522C-1.84569 276.993 -6.90705 258.104 1.62173 243.332Z" fill="white" fillOpacity="0.07" />
      <path d="M43.6109 170.609C30.3336 193.605 38.2129 223.011 61.2098 236.289L73.0655 243.133L121.731 158.842C130.26 144.07 125.198 125.181 110.426 116.652C95.6538 108.123 76.7645 113.185 68.2358 127.957L43.6109 170.609Z" fill="white" fillOpacity="0.07" />
      <path d="M270.784 167.789C279.313 153.016 298.202 147.955 312.974 156.484L339.722 171.926L324.279 198.674C315.75 213.446 296.861 218.508 282.089 209.979C267.316 201.45 262.255 182.561 270.784 167.789Z" fill="white" fillOpacity="0.07" />
      <path d="M212.771 268.273C199.494 291.269 207.373 320.675 230.37 333.953L242.226 340.798L290.891 256.507C299.42 241.734 294.358 222.845 279.586 214.316C264.814 205.787 245.925 210.849 237.396 225.621L212.771 268.273Z" fill="white" fillOpacity="0.07" />
    </g>
    <defs>
      <clipPath id="clip0_10527_25922">
        <rect width="274" height="245" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
