import React from 'react'

import { CropImg } from '../AvatarCrop';
import styles from './index.module.scss';

const EditAvatar = (props: {
  previewImage: string
  onCancel?: () => void
  onOk?: (data: any) => void
  lang?: any
}) => {
  const { onCancel, onOk, previewImage, lang } = props;

  return (
    <div className={styles.changeAvatar}>
      <div className={styles.changeAvatar_container}>
        <CropImg
          lang={lang}
          previewImagePath={previewImage}
          onCancel={() => {
            onCancel?.()
          }}
          onConfirm={(data) => {
            // data is blob
            onOk?.(data)
          }} />
      </div>
    </div>
  )
}

export default EditAvatar

