'use client'
import Marquee from "react-fast-marquee";

import EmblaCarousel from "./Carousel";
import { Avatar, Rate } from "antd";

import styles from './UserExperience.module.scss'
import { useContext, useEffect, useState } from "react";
// import { UserCommentOnMobile } from "./UserCommentsOnMobile";
import { SectionSubtitle, SectionTitle } from "../SectionTitle";
import { allImages } from "./images";
import { userImg } from "./images/user";
import { languageContext } from "app/components/providers/languageProvider";
import { formatTemplateString } from "helpers/formatter";
import { isMobile } from "react-device-detect";

const DoubleQuot = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="32" height="21" viewBox="0 0 32 21" fill="none">
    <path d="M23.1813 20.944C21.1866 20.944 19.4639 20.196 18.0133 18.7C16.5626 17.204 15.8373 15.232 15.8373 12.784C15.8373 11.832 15.9733 10.812 16.2453 9.724C16.7439 7.684 17.6279 5.93867 18.8973 4.488C20.2119 2.992 21.7079 1.88133 23.3853 1.156C25.0626 0.385334 26.6946 0 28.2813 0C30.1399 0 31.0693 0.544002 31.0693 1.632C31.0693 1.904 31.0466 2.108 31.0013 2.244C30.8653 2.74267 30.5933 3.17334 30.1853 3.536C29.7773 3.85334 29.1426 4.30667 28.2813 4.896C27.2839 5.62133 26.5586 6.21067 26.1053 6.664C25.6519 7.11734 25.4253 7.66134 25.4253 8.296C25.4253 8.704 25.5386 9.112 25.7653 9.52C26.0373 9.928 26.4453 10.472 26.9893 11.152C27.7146 12.104 28.2586 12.8973 28.6213 13.532C28.9839 14.1667 29.1653 14.892 29.1653 15.708C29.1653 16.1613 29.0973 16.6373 28.9613 17.136C28.5986 18.36 27.9186 19.312 26.9213 19.992C25.9239 20.6267 24.6773 20.944 23.1813 20.944ZM7.67725 20.944C5.68259 20.944 3.95992 20.196 2.50925 18.7C1.05859 17.204 0.333252 15.232 0.333252 12.784C0.333252 11.832 0.469252 10.812 0.741252 9.724C1.23992 7.684 2.12392 5.93867 3.39325 4.488C4.70792 2.992 6.20392 1.88133 7.88125 1.156C9.55859 0.385334 11.1906 0 12.7773 0C14.6359 0 15.5653 0.544002 15.5653 1.632C15.5653 1.904 15.5426 2.108 15.4973 2.244C15.3613 2.74267 15.0893 3.17334 14.6813 3.536C14.2733 3.85334 13.6386 4.30667 12.7773 4.896C11.7799 5.62133 11.0546 6.21067 10.6013 6.664C10.1479 7.11734 9.92125 7.66134 9.92125 8.296C9.92125 8.704 10.0346 9.112 10.2613 9.52C10.5333 9.928 10.9413 10.472 11.4853 11.152C12.2106 12.104 12.7546 12.8973 13.1173 13.532C13.4799 14.1667 13.6613 14.892 13.6613 15.708C13.6613 16.1613 13.5933 16.6373 13.4573 17.136C13.0946 18.36 12.4146 19.312 11.4173 19.992C10.4199 20.6267 9.17325 20.944 7.67725 20.944Z" fill="#E8E8EA" />
  </svg>
}
let UserComments = [
  {
    comments: `
    Bossjob’s AI matching feature is a game-changer! It quickly identified job opportunities that perfectly matched my skills, helping me secure a remote position in no time.
    `,
    name: `Alex P.`,
    img: userImg.alex,
    time: 'June 18, 2019'
  }, {
    comments: `
    The AICV tool on Bossjob helped me create a competitive resume effortlessly. I landed a job offer within a week of applying. Highly recommend!
    `,
    name: `Linda K.`,
    img: userImg.Linda,
    time: 'October 25, 2019'
  },
  {
    comments: `
    Bossjob's direct communication with employers made my job search so much easier. Being able to chat with hiring managers directly helped me get immediate feedback and secure interviews faster.
    `,
    name: `Mark H.`,
    img: userImg.mark,
    time: 'January 28, 2020'
  },
  {
    comments: `
    I love how efficient Bossjob's AI features are. From job matching to resume building, everything is streamlined to help you find a job quickly and easily. It truly boosted my job search process.
    `,
    name: `Emily R.`,
    img: userImg.emily,
    time: 'May 5, 2021'
  },
  {
    comments: `
    Thanks to Bossjob’s direct hire model, I was able to communicate directly with the CEO of my new company. It made the whole process so much more personal and effective.
    `,
    name: `Carlos M.`,
    img: userImg.carlos,
    time: 'September 15, 2023'
  }
]

const CorporateForPC = ({ data }) => {
  return <Marquee
    pauseOnHover
    style={{ width: '100vw', position: 'relative', left: 0 }}
  >
    {[...data, ...data].map((v, i) => {
      return <div
        key={i}
      >
        <img src={v} height={100} />
      </div>
    })}
  </Marquee>
}

const CorporateForMobile = ({ data }) => {
  return <div className={styles.corporateForMobile}>
    {data.map((v, i) => {
      return <div className={styles.item} key={i}>
        <img src={v} />
      </div>
    })}
  </div>
}
export const UserExperience = ({ dictionary }) => {
  const [winWidth, setWinWith] = useState(1000)
  const lang = dictionary// useContext(languageContext) as any
  const { remotePage, comm } = lang || {}

  useEffect(() => {
    let resizeHandle = () => {
      setWinWith(window.innerWidth)
    }

    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    }
  }, [])
  useEffect(() => {
    setWinWith(window?.innerWidth)
  }, [])

  const ItemCard = ({ item }) => {
    const { comments, name, img, time } = item
    return <div className={styles.CarouselCard}>
      <DoubleQuot />
      <div className={styles.comments}>
        {comments}
      </div>
      <div className={styles.footer}>
        <div className={styles.leftPeopleWrapper}>
          <Avatar src={img} />
          <div className={styles.nameWRapper}>
            <span className={styles.name}>{name}</span>
            {/* <span>Japan</span> */}
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <Rate value={5} className={styles.userRate} disabled />
          {/* <div>{time}</div> */}
        </div>
      </div>
    </div>
  }

  return <div className={styles.userExperienceRoot}>

    <div className={styles.userExperienceInnerRoot}>
      <div style={{ textAlign: 'center' }}>
        {/* class='coloredText */}
        <SectionTitle innerHTML={`
          ${formatTemplateString(remotePage?.userExperienceTitle, { User: `<span '>${comm?.User}</span>` })}
          `} />
        <SectionSubtitle style={{ marginTop: 12 }}>
          {/* Hear What Our Satisfied Users Have to Say About Their Success Stories */}
          {remotePage?.userExperienceSubtitle}
        </SectionSubtitle>
      </div>

      {<div>
        <EmblaCarousel
          isMobile={isMobile}
          slides={UserComments}
          renderItem={(index) => {
            const item = UserComments[index]
            return <ItemCard item={item} key={index} />
          }} />
      </div>}

      <div className={styles.divider}
      // style={{ margin: '80px 0px 0 0px' }}
      >
        <span style={{ textTransform: 'uppercase' }}>
          {/* Top Companies Hiring Remotely */}
          {remotePage?.topCompany}
        </span>
      </div>
    </div>
    <div style={{ marginTop: 70, position: 'relative', }}>
      {
        winWidth > 810 ?
          <CorporateForPC data={allImages} />
          : <CorporateForMobile data={allImages} />
      }
      {
        winWidth > 810 ?
          <>
            <div className={styles.opacityCover} />
            <div className={styles.opacityCover} />
          </>
          : null
      }
    </div>
  </div >
}