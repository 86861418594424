import { HTMLProps } from 'react'
import styles from './component.module.scss'
import classNames from 'classnames'

export const PlainTextBtn = ({ children, ...rest }: HTMLProps<HTMLSpanElement>) => {
  const { className } = rest

  return <span className={classNames(styles.PlainTextBtn, className)} {...rest} role='button'>
    {children}
  </span>
}