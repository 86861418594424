/* eslint-disable react/no-unknown-property */
'use client'
import React from 'react'
import styles from '../../index.module.scss'
import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { getValueById } from 'helpers/config/getValueById'
import { transStateNew } from 'helpers/utilities'
import common_styles from 'app/index.module.scss'
import { getCookie, setCookie } from 'helpers/cookies'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { setJobViewCookiesTracker, viewJobSourceTracker } from 'components/ReportTracker/util'
import { getSingaporeAddress } from 'helpers/getSingaporeAddress'
import { useLanguage } from 'app/components/providers/languageProvider'
import ShowSalary from 'app/components/ShowSalary'

const JobCard = (props: any) => {
  const {
    job_title,
    salary_range_value,
    recruiter_full_name,
    recruiter_job_title,
    company_logo,
    company_name,
    company_size,
    id,
    job_url,
    job_type_id,
    job_location_id,
    xp_lvl_id,
    degree_id,
    is_urgent,
    recruiter_is_online: recruiterIs_Online,
    recruiter_last_active_at: recruiterLastActiveAt,
    work_arrangement,
    work_arrangement_id,
    is_all_location,
    originDictionary
  } = props
  const dic = useLanguage()
  const config = useSharedData('CONFIG')
  const sessionid = getCookie('sessionid')
  const source = sessionid
    ? viewJobSourceTracker.hotJobForLogin
    : viewJobSourceTracker.hotJobForUnLogin
  const labels = [
    getValueById(config, job_type_id, 'job_type_id'),

    getValueById(config, xp_lvl_id, 'xp_lvl_id'),
    getValueById(config, degree_id, 'degree_id')
  ].filter((a) => a)
  const router = useRouter()

  const work_arrangementName = typeof work_arrangement === 'string' ? work_arrangement : work_arrangement?.name
  console.log('dic:', dic)
  return (
    <div className={styles.jobCardMoblie}>
      <div
        id={'job_card_container_' + id}
        className={styles.container}
        onClick={(e) => {
          e.stopPropagation()
          setJobViewCookiesTracker(setCookie, {
            source: source,
            reco_from: props?.reco_from || null,
            query_id: props?.query_id || null
          })
          router.push(job_url, { scroll: true })
        }}
      >
        <div className={`${styles.titleContainer} ${styles.titleContainerUrgent}`}>
          {is_urgent ? <span className={styles.urgent}>{originDictionary?.search?.urgent}</span> : null}{' '}
          {work_arrangement_id == 3 ? (
            <span className={styles.urgent + ' ' + styles.remote}>{originDictionary?.search?.remote}</span>
          ) : null}
        </div>
        <div key={job_title + id} className={styles.titleContainer} title={`${job_title}`}>
          <h2 className={styles.title}>{`${job_title}`}</h2>
          <div className={styles.salary}>
            {/* {salary_range_value} */}
            <ShowSalary
              jobs={props}
              dictionary={dic}
            />
          </div>
        </div>
        <div className={styles.companyInfo}>
          <span className={styles.companyName}>{company_name}</span>
          {company_size ? (
            <>
              <span className={styles.companyInfoSpread}></span>
              <span className={styles.companySize}>{company_size}</span>
            </>
          ) : null}
        </div>
        <div className={styles.labelContainer}>
          {labels.map((label) => (
            <div key={label} className={styles.label}>
              {label}
            </div>
          ))}
        </div>

        {/* line */}
        <div className={styles.recruiterLine}></div>

        <div className={styles.recruiterContainer}>
          <div className={styles.info}>
            <div
              className={`${common_styles.avator}  ${transStateNew(recruiterLastActiveAt, null, recruiterIs_Online).state === 1
                ? ''
                : common_styles.avator2
                }`}
            >
              <Image src={company_logo} height={24} width={24} alt={''} />
            </div>
            <div
              className={styles.hrTitle}
              title={`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
            >
              {`${[recruiter_full_name, recruiter_job_title].filter((a) => a).join(' · ')}`}
            </div>
          </div>
          <div className={styles.fullName}>
            {getSingaporeAddress(is_all_location, dic.home.allRegions, getValueById(config, job_location_id, 'location_id'))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
