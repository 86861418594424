'use client'
import React, { useRef, useEffect } from 'react'
import Lottie from 'lottie-web'
import styles from 'app/index.module.scss'
import { remoteHomeBanner, remoteHomeBannerH5 } from 'images'
import { isMobile } from 'react-device-detect'
const HomeBanner = () => {
  const container = useRef()

  // useEffect(() => {
  //   Lottie.loadAnimation({
  //     container: container.current,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     animationData: require('images/homebanner.json')
  //   })

  //   return () => {
  //     Lottie.destroy()
  //   }
  // }, [])

  return (
    <div
      className={styles.searchBackground}
      ref={container}
      style={{
        background: `url(${isMobile ? remoteHomeBannerH5 : remoteHomeBanner}) no-repeat `,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    />
  )
}

export default HomeBanner
