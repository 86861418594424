'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { MoneyConvert, } from './util'
import { Drawer, Dropdown } from 'antd'
import { getMoneyRateList } from './service'
import { isMobile } from 'react-device-detect'

import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from './index.module.scss'
import { CloseIcon, DropDownIcon, ExchangeIcon } from './Icons'



const fetchMoneyList = () => getMoneyRateList().then(res => {
  const data = res?.data?.data || [];
  const listWithIndex = allSupportCurrencyList.map((v, index) => ({ label: v, index }))
  const result = data.filter(item => {
    const index = allSupportCurrencyList.indexOf(item.currency_code)
    if (index !== -1) {
      item.index = listWithIndex[index].index
    }
    return index !== -1
  });

  return result.sort((v1, v2) => v1.index - v2.index)
})

const baseConvert = (value, splitUnit, minUnit, maxUnit) => {
  value = Number(value);
  const unit = splitUnit;

  if (value < unit) {
    return {
      // round need?
      value: Number(value).toFixed(1),
      unit: minUnit
    }
  }

  const newValue = Number((value / unit).toFixed(1))

  return {
    value: newValue,
    unit: maxUnit
  }
}

const convert2K = (value: string | number) => {
  return baseConvert(value, 1000, '', 'K')
}

const convert2Million = (value: number) => {
  return baseConvert(value, 1000000, '', 'juta')
}

const convert2Japan = (value: number) => {
  value = Number(value);
  const unit = 10000
  return baseConvert(value, unit, '円', '万円')
  // if (value < unit) {
  // }

  // return {
  //   value: (value / unit).toFixed(1),
  //   unit: '万円'
  // }
}
const defaultConvert = (value: number) => {
  return {
    value: Number(Number(value).toFixed(1)),
    unit: ''
  }
}

const salaryConvert = {
  "INR": convert2K,
  "PHP": convert2K,
  "CNY": convert2K,
  "IDR": convert2Million,
  'JPY': convert2Japan,
  'USD': convert2K
}

const allSupportCurrencyList = ["USD", "EUR", "GBP", "JPY", "PHP", 'SGD', 'CNY', 'INR', "MYR", 'IDR', 'THB']

const ExchangeDrawer = ({ memoList, setValue, open, onCloseHandler, value, convertToLabel }) => {

  return <>
    <span
      style={{ display: 'flex', alignItems: 'center' }}
    ><DropDownIcon /></span>
    <Drawer
      height={'75vh'}
      className={styles.mobileExchangeDrawer}
      title={<div className={styles.mobileExchangeDrawerHeader}>
        <span>
          {convertToLabel}
          {/* Convert to */}
        </span>
        <span onClick={onCloseHandler}>
          <CloseIcon />
        </span>
      </div>}
      placement='bottom'
      width={'100vw'}
      closable={false}
      open={open}
      onClose={onCloseHandler}
      getContainer={() => document.body}
    >
      {memoList.map(v => {
        return <div
          key={v.id}
          className={styles.mobileExchangeDrawerListItem}
          data-checked={v.displaySalary === value}
          onClick={(e) => {
            setValue(v.displaySalary)
            onCloseHandler(e)
          }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ width: 24, height: 16, marginRight: 6 }} src={v.flag} /> {v.code}
          </span>
          <span className={styles.mobileExchangeDrawerListItemSalary}>
            {v.displaySalary}
          </span>
        </div>
      })}
    </Drawer>
  </>
}


export const MoneyExchange = ({
  originalValue, from, to, currency, label, isRemote
}) => {
  const canBeConverted = isRemote && from && to && originalValue && originalValue.indexOf('Login') == -1 && originalValue.indexOf('Below') == -1 && originalValue.indexOf('Not') == -1 && originalValue.indexOf('Un') == -1
  const langDic = useSharedData('DICTIONARY') || {}

  const [rate, setRate] = useState([])
  const [value, setValue] = useState(originalValue)
  const [open, setOpen] = useState(false)

  const memoList = useMemo(() => {
    const memoList = rate.map((v: any) => {
      const flag = `${process.env.S3_BUCKET_URL}/currency/flags/${`${v.currency_code}`.toLocaleUpperCase()}.png`

      return {
        id: v.currency_id,
        symbol: v.display_symbol,
        rate: v.to_usd_exchange,
        fullName: v.currency_name,
        code: v.currency_code,
        updateTime: v.exchange_update_time,
        flag
      }
    })
    const sourceCurrency = memoList.find(rate => {
      return rate.code === currency
    })

    return memoList.map(v => {
      const convertFun = value => MoneyConvert({ options: memoList })(value || 0, sourceCurrency.id, v.id);
      const fromValue = convertFun(`${from}`)
      const toValue = convertFun(`${to}`)?.value
      const { symbol, code } = fromValue.targetOpt
      const unitConvert = salaryConvert[code] || defaultConvert
      const fromObj = unitConvert(fromValue.value)
      const toObj = unitConvert(toValue)

      return { ...v, displaySalary: `${symbol} ${fromObj.value}${fromObj.unit} - ${toObj.value}${toObj.unit}` }

    })
  }, [rate, from, to])

  useEffect(() => {
    if (!canBeConverted) {
      return
    }
    fetchMoneyList().then(data => {
      setRate(data)
    })

  }, [canBeConverted])

  const option = memoList.map(v => {

    return {
      label: <div
        data-checked={value === v.displaySalary}
        // style={{ display: 'flex', gap: 20, justifyContent: 'space-between', padding: '11px 8px' }}
        className={styles.exchangeMoneyListWrapper}
        onClick={() => {
          setValue(v.displaySalary)
        }}>

        <span style={{
          display: 'flex', alignItems: 'center',
          //  color: '#121212'
        }}
        >
          <img src={v.flag} width={24} height={16} style={{ marginRight: 6 }} />  {v.code}
        </span>
        <span className={styles.listSalary}>
          {v.displaySalary}
        </span>
      </div>,
      key: v.id
    }
  })
  const convertTo = langDic?.moneyExchange?.convertTo
  return <div>
    {canBeConverted && !isMobile && <Dropdown
      menu={{ items: option }}
      placement="bottomLeft"
      overlayStyle={{ maxHeight: 280 }}
      onOpenChange={setOpen}
      dropdownRender={originalNode => {
        return <div className={styles.moneyDropdownWrapper}>
          <div className={styles.header}>
            {convertTo}
            {/* Convert to */}
          </div>
          {originalNode}
        </div>
      }}
    >
      <div className={styles.exchangeIconDownWrapperOnRemoteJob} data-hover={open}>
        <ExchangeIcon />
      </div>
    </Dropdown>}

    <span style={{ display: 'inline-flex', alignItems: 'center' }} onClick={canBeConverted && isMobile ? () => setOpen(true) : null}>
      {value}
      {(
        <span
          style={{
            color: '#2378e5',
            fontSize: 14,
            letterSpacing: '0.12px',
            fontWeight: '400'
          }}
        >
          [{label}]
        </span>
      )}
      {isMobile && canBeConverted &&
        <ExchangeDrawer memoList={memoList}
          setValue={setValue}
          value={value}
          open={open}
          convertToLabel={convertTo}
          onCloseHandler={(event) => {
            event?.stopPropagation?.()
            setOpen(false)
          }}
        />}
    </span>
  </div >
}

export default MoneyExchange