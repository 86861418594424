import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { EmblaCarouselType } from 'embla-carousel'

import styles from './index.module.scss'

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean
  nextBtnDisabled: boolean
  onPrevButtonClick: () => void
  onNextButtonClick: () => void
}

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect: any = useCallback(() => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

type PropType = ComponentPropsWithRef<'button'>

const LeftIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M14.0896 3.57806C13.7642 3.25263 13.2365 3.25263 12.9111 3.57806L6.24442 10.2447C5.91899 10.5702 5.91899 11.0978 6.24442 11.4232L12.9111 18.0899C13.2365 18.4153 13.7642 18.4153 14.0896 18.0899C14.415 17.7645 14.415 17.2368 14.0896 16.9114L8.01219 10.834L14.0896 4.75657C14.415 4.43114 14.415 3.9035 14.0896 3.57806Z" fill="#515151" />
</svg>

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <span
      className={styles['embla__button']}
      {...restProps}
    >
      <LeftIcon />
    </span>
  )
}

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <span
      className={styles['embla__button']}
      data-isright={true}
      {...restProps}
    >
      <LeftIcon />
    </span>
  )
}
