export const AvatarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="none"
    {...props}
  >
    <path
      stroke="#FFC53D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="m20.14 31.311 3.96-2.798-3.96-10.218-3.962 10.218 3.961 2.798Z"
    />
    <path
      stroke="#2378E5"
      strokeWidth={3.333}
      d="M35.445 34.392c0-8.453-6.853-15.306-15.306-15.306S4.833 25.939 4.833 34.392"
    />
    <path
      stroke="#2378E5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3.333}
      d="M20.14 18.182a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
  </svg>
)

export const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#FF9500"
      d="M8.14 13.834a5.333 5.333 0 1 1 0-10.667 5.333 5.333 0 0 1 0 10.667Zm0 1.333a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333Zm.667-10.356v5.333H7.473V4.81h1.334Zm-.001 7.69v-1.334H7.473v1.334h1.333Z"
    />
  </svg>
)

export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <rect width={20} height={20} x={0.14} fill="#353535" rx={10} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M5.608 14.177h9.066a.795.795 0 0 0 .795-.796V7.813a.795.795 0 0 0-.795-.796h-1.591l-.796-1.193H7.994L7.2 7.017H5.608a.795.795 0 0 0-.795.796v5.568a.796.796 0 0 0 .795.796Zm3.29-3.835a1.243 1.243 0 1 1 2.485 0 1.243 1.243 0 0 1-2.485 0Zm1.242-2.337a2.337 2.337 0 1 0 0 4.674 2.337 2.337 0 0 0 0-4.674Z"
      clipRule="evenodd"
    />
  </svg>
)

export const HelperIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#B8B8B8"
      d="m5.808 6.124-.558-.02-.558-.02v-.012a1.529 1.529 0 0 1 .009-.099 2.922 2.922 0 0 1 .282-.994c.137-.279.35-.58.677-.811.333-.236.754-.377 1.265-.377 1.04 0 1.73.57 1.97 1.335a2.086 2.086 0 0 1-.541 2.104h-.001c-.437.415-.688.65-.83.781v.738H6.405v-.986c0-.168.076-.327.205-.433l.01-.008.12-.109c.132-.122.38-.354.843-.792a.97.97 0 0 0 .245-.962c-.087-.278-.33-.552-.904-.552-.296 0-.488.078-.62.172a1.035 1.035 0 0 0-.322.395 1.808 1.808 0 0 0-.175.647v.006-.003ZM7.584 10.5V9.332H6.417v1.166h1.167Z"
    />
    <path
      fill="#B8B8B8"
      d="M7 12.833A5.833 5.833 0 1 0 7 1.166a5.833 5.833 0 0 0 0 11.667Zm0-1.167a4.667 4.667 0 1 1 0-9.333 4.667 4.667 0 0 1 0 9.333Z"
    />
  </svg>
)
