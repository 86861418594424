'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import styled from '@emotion/styled'

import Modal from '@mui/material/Modal'

import GoogleMap from 'components/GoogleMap/GoogleMap'
import { IconButton, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styles from '../../../page.module.scss'
import { addEventTag } from 'helpers/utilities'
import { sendViewCompany } from './Wrapper'
import { useSearchParams } from 'next/navigation'

const MobileStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 20px 18px 20px;
  background: #ffffff;
`

const MobileLeftStyled = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 70px);
`

const MobileMainStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
`

const MobileNameStyled = styled.div`
  width: 100%;
  color: #121212;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const MobileInfoStyled = styled.div`
  width: 100%;
  color: #515151;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const MobileMapStyled = styled.div`
  padding: 0 20px 20px;
  background-color: #ffffff;

  .mapWrapper {
    border-radius: 8px;
    overflow: hidden;
  }
`

const MobileImageStyled = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid#E5E6EB;
  background: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

const CompanyMobile = (props: any) => {
  const { data, lang, isMobile, languages, workingLocation } = props
  const companySection = languages as any
  const companyUrl = data?.company_url
  const companyId = data?.id
  const searchParams = useSearchParams()
  const from = searchParams.get('from')
  const lng = workingLocation.longitude
  const lat = workingLocation.latitude
  const [open, setOpen] = useState(false)

  const handleMapLayer = () => {
    setOpen(true)
  }

  const handleGoDetail = () => {
    sendViewCompany('/' + lang + companyUrl, companyId, isMobile)
    if (from === 'sg-h5') {
      addEventTag('sgh5_job_detail_company')
    }
  }
  // const industry = data?.industry
  // const companySize = data?.company_size
  return (
    <>
      <MobileStyled onClick={() => handleGoDetail()}>
        <MobileLeftStyled>
          <MobileImageStyled>
            <Image src={props.logo} width={40} height={40} alt={props?.name ?? ''} />
          </MobileImageStyled>
          <MobileMainStyled>
            <MobileNameStyled>{props?.name}</MobileNameStyled>
            <MobileInfoStyled>
              {data?.company_tags?.join(' | ')}
              {/* {industry}{industry ? <>&nbsp;|&nbsp;</> : null}{companySize} {companySection.employees} */}
            </MobileInfoStyled>
          </MobileMainStyled>
        </MobileLeftStyled>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='21'
          viewBox='0 0 20 21'
          fill='none'
        >
          <path
            d='M7.39209 16.4687L12.6079 10.9883L7.39209 5.47011'
            stroke='#7D7D7D'
            strokeWidth='1.36364'
            strokeLinecap='round'
          />
        </svg>
      </MobileStyled>
      {lat && lng ? (
        <MobileMapStyled onClick={handleMapLayer}>
          <GoogleMap
            lat={Number(lat)}
            lng={Number(lng)}
            gestureHandling='none'
            zoomControl={false}
            fullscreenControl={false}
            streetViewControl={false}
            clickable={false}
          />
        </MobileMapStyled>
      ) : null}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        keepMounted
      // hideBackdrop={true}
      >
        <div className={styles.workLocal}>
          <div className={styles.workLocal_head}>
            <span className={styles.workLocal_head_title}>{lang?.workLocation}</span>

            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon sx={{ color: '#BCBCBC' }} />
            </IconButton>
          </div>

          <div className={styles.workLocal_map}>
            <div className={styles.workLocal_map_wrapper}>
              <div className={styles.workLocal_map_wrapper_content} id='googleMapSoaWAErd4'>
                <GoogleMap
                  id='googleMapSoaWAErd4'
                  lat={Number(lat)}
                  lng={Number(lng)}
                  gestureHandling='auto'
                  zoomControl={true}
                  fullscreenControl={true}
                  streetViewControl={true}
                  clickable={true}
                  infoWindow={workingLocation?.address}
                />
              </div>
            </div>
          </div>

          <div className={styles.workLocal_footer}>
            <Button
              variant='contained'
              sx={{
                width: '153px',
                height: '44px',
                background: '#136FD3',
                borderRadius: '10px',
                float: 'right',
                marginRight: '20px',
                textTransform: 'capitalize'
              }}
              onClick={() => setOpen(false)}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CompanyMobile
