type stepProps = {
  total: number,
  current: number
}

export const Step = ({ total, current }: stepProps) => {
  const steps = new Array(total).fill(1)
  return <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ display: 'flex', gap: 0, flex: 1, flexDirection: 'row', borderRadius: 3, overflow: 'hidden', }}>
      {steps.map((v, i) => {
        return <div style={{ height: 6, flex: 1, backgroundColor: i < current ? '#2378E5' : '#E4E4E4' }} />
      })}
    </div>
    <SimplePagination total={total} current={current} />
  </div>
}
export const SimplePagination = ({ total, current }: stepProps) => {
  return <span style={{ fontSize: 14, fontWeight: 400, color: '#7D7D7D', whiteSpace: 'pre', marginLeft: 10 }}>
    <span style={{ color: '#3A90FF' }}>
      {Math.min(current, total)}
    </span>/{total}
  </span>
}