'use client'
import React, { useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'next/navigation'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { getCookie } from 'helpers/cookies'
import { LinkContext } from 'app/components/providers/linkProvider'
import { getLang } from 'helpers/country'
import MaintainNotification from 'components/MaintainNotification'
import { useMainResumeContext, withMainResumeProvider } from './context'

import { CommonHeader } from '../Common/Header'
import { CommonFooter } from '../Common/Footer'
import BaseProfile from '../BaseProfile'
import AttachedResume from '../AttachedResume'
import CompleteProfile from '../CompleteProfile'
import DesiredLayout from '../DesiredForm'

import styles from '../../common.module.scss'

import Loading from 'app/components/loading'


export const MainLayout = (props: {
  lang: any
  config: any
}) => {
  const sessionid = getCookie('sessionid')
  const { push } = useContext(LinkContext)
  const searchParams = useSearchParams()
  const step = searchParams.get('step')
  const { getUserInfo } = useMainResumeContext()
  const [loading, setLoading] = React.useState(true)

  const langKey = getLang()
  if (!sessionid) {
    push(`/${langKey}/get-started`)
  }

  const showDesired = useMemo(() => {
    if (!step) return true
    return ![2, 3, 4].includes(Number(step))
  }, [step])

  useEffect(() => {
    if (sessionid) {
      setLoading(true)
      getUserInfo().finally(() => setLoading(false))
    }
  }, [])

  return (
    <CacheProvider value={createCache({ key: 'complete-profile', prepend: true })}>
      <div className={styles.main}>
        <CommonHeader lang={props.lang} langKey={langKey} />
        <main className={styles.container}>
          <MaintainNotification lang={props.lang?.header} langKey={langKey} />
          {
            loading ? <Loading /> :
              (
                <>
                  {showDesired && <DesiredLayout {...props} />}
                  {Number(step) == 2 && <BaseProfile {...props} />}
                  {Number(step) == 3 && <AttachedResume {...props} />}
                  {Number(step) == 4 && <CompleteProfile {...props} />}
                </>
              )
          }
        </main>
        <CommonFooter lang={props.lang} />
      </div >
    </CacheProvider>
  )
}

export default withMainResumeProvider(MainLayout)
