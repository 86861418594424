'use client'
import React, { useMemo, useState } from 'react'
import { DatePicker, Input, Radio, Space, message } from 'antd';
import { DatePickerWithToday } from '@bossjob/ui'
import classNames from 'classnames';
import dayjs from 'dayjs';

import { removeEmptyOrNullValues } from 'helpers/formatter';
import { addUserEducationService } from 'store/services/users/addUserEducation';
import { updateUserEducationService } from 'store/services/users/updateUserEducation';

import SimpleSteps from '../Step/Simple';
import { useAttachedResumeContext } from '../AttachedResume/context';
import SchoolPopover from './SchoolPopover';
import LoadingButton from '../Common/LoadingButton';

import styles from './index.module.scss';
import { addEventTag, isMobileDevice } from 'helpers/utilities';
import { isChatProcess } from '../utils';

const { TextArea } = Input;

const EducationExperience = (props: {
  lang: any
  config: any
  onCancel: () => void
  onOk: (value: any) => void
  schoolList: any[]
  userDetail: any
  getUserInfo: () => void
}) => {
  const { onCancel, onOk, config, schoolList, userDetail = {}, getUserInfo, lang = {} } = props;
  const [loading, setLoading] = useState(false)
  const { educations = [] } = userDetail
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const chatProcess = isChatProcess()
  const isMobile = isMobileDevice()

  const degrees = useMemo(() => {
    return config?.degrees?.filter((e) => e.id !== 5)
  }, [config])

  const { eduData, setEduData } = useAttachedResumeContext()

  const disabledSubmit = useMemo(() => {
    // console.log('eduData', eduData)
    const checkDate = !eduData.startDate || (!eduData.endDate && !eduData.isToday)

    const checkDescription = eduData.description && eduData.description?.length < 10
    if (checkDescription) return true

    return !eduData?.degree || !eduData?.schoolName || !eduData?.fieldOfStudy || checkDate
  }, [eduData])

  const handleEndTime = (date, dateString, isToday) => {
    setEduData({ ...eduData, endDate: date, isToday: isToday })
  };

  const handleStartTime = (date, dateString) => {
    setEduData({ ...eduData, startDate: date, endDate: null })
  }

  const handleOk = () => {
    console.log('handle ok', eduData)

    const educationData = {
      school: eduData.schoolName,
      is_currently_studying: eduData.isToday,
      study_period_from: `${eduData.startDate?.format('YYYY-MM')}-01`,
      study_period_to: eduData.isToday
        ? null
        : `${eduData.endDate?.format('YYYY-MM')}-01`,
      field_of_study: eduData.fieldOfStudy,
      degree_id: eduData.degree,
      description: eduData.description
    }

    onSubmit(educationData)
  };

  const handleCancel = () => {
    onCancel();
  };

  const onSubmit = (educationData) => {
    setLoading(true)
    addEventTag(chatProcess ? 'new_user_chat_resume_education_fill_next' : 'new_user_complete_resume_education_fill_next')
    if (educations?.length) {
      const id = educations[0].id
      const educationPayload = {
        educationId: id,
        educationData: removeEmptyOrNullValues(educationData)
      }
      updateUserEducationService(educationPayload)
        .then((res) => {
          if (res.data) {
            getUserInfo && getUserInfo()
            onOk(false)
          }
        })
        .finally(() => setLoading(false))
    } else {
      const educationPayload = {
        educationData: removeEmptyOrNullValues(educationData)
      }
      addUserEducationService(educationPayload)
        .then((res) => {
          if (res.data) {
            getUserInfo && getUserInfo()
            onOk(false)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const disabledDate = (current) => {
    return current && current < dayjs(eduData.startDate).subtract(1, 'day').endOf('day');
  };

  const disabledDateStartDate = (current) => {
    return current && current > dayjs();
  }

  const checkDescription = useMemo(() => {
    if (!eduData.description) return false
    if (eduData.description?.length < 10) return true
    if (eduData.description?.length > 4000) return true
    return false
  }, [eduData.description])

  return (
    <div className={styles.eduExp}>
      <div className={styles.eduExp_container}>
        {isMobile ? <EducationIconH5 className={styles.eduExp_icon} /> : <EducationIcon className={styles.eduExp_icon} />}

        <div className={styles.eduExp_title}>
          <p className={styles.eduExp_subtitle}>{profile.eduExp?.title}</p>
          <SimpleSteps className={styles.eduExp_steps} currentStep={1} totalSteps={2} />
        </div>

        <div className={styles.eduExp_content}>
          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.degree}</p>
            <div className={styles.eduExp_radio}>
              <Radio.Group
                value={eduData.degree}
                onChange={(e) => setEduData({ ...eduData, degree: e.target.value })}
              >
                <Space direction="vertical">
                  {
                    degrees?.map((item, index) => {
                      return <Radio value={item.id} key={index}>{item.value}</Radio>
                    })
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>


          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.schoolName}</p>
            <div className={styles.eduExp_input}>
              <SchoolPopover
                schoolList={schoolList}
                onChange={(value) => setEduData({ ...eduData, schoolName: value })}
                value={eduData.schoolName}
                placeholder={profile.eduExp?.placeholder}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.fieldOfStudy}</p>
            <div className={styles.eduExp_input}>
              <Input
                style={{ width: '100%', height: 42 }}
                placeholder={profile.eduExp?.placeholder}
                value={eduData.fieldOfStudy}
                onChange={(e) => setEduData({ ...eduData, fieldOfStudy: e.target.value })}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.studyPeriod}</p>
            <div className={styles.eduExp_date}>
              <DatePicker
                style={{ width: '50%', height: 42 }}
                placeholder={profile.eduExp?.startDate}
                picker="month"
                onChange={handleStartTime}
                value={eduData.startDate}
                inputReadOnly={true}
                disabledDate={disabledDateStartDate}
              />
              <DatePickerWithToday
                style={{ width: '50%', height: 42 }}
                allowClear={true}
                todayText={profile?.today}
                picker="month"
                value={eduData.endDate}
                isToday={eduData.isToday}
                disabledDate={disabledDate}
                placeholder={profile.eduExp?.endDate}
                onChange={handleEndTime}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.descLabel} <span className={styles.eduExp_optional}>({profile?.optional})</span></p>
            <div className={styles.eduExp_input}>
              <TextArea
                rows={3}
                minLength={10}
                maxLength={4000}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={profile.eduExp?.description}
                value={eduData.description}
                status={checkDescription ? 'error' : undefined}
                onChange={(e) => setEduData({ ...eduData, description: e.target.value })}
              />
            </div>
            {checkDescription && <p className={styles.eduExp_error}>{profile.eduExp?.descriptionError}</p>}
          </div>
        </div>

        <div className={styles.eduExp_footer}>
          <button
            className={classNames(styles.eduExp_back, styles.eduExp_button)}
            onClick={handleCancel}
          >{profile.back}</button>
          <LoadingButton
            className={classNames(styles.eduExp_continue, styles.eduExp_button)}
            loading={loading}
            disabledSubmit={disabledSubmit || loading}
            onSubmit={handleOk}
            text={profile.continue}
          />
        </div>

      </div>
    </div>
  )
}

export default EducationExperience

const EducationIconH5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#121212"
      d="M18.981 3.333a6.667 6.667 0 0 0-5.962 0L.737 9.473a1.333 1.333 0 0 0 0 2.386l3.93 1.964v10.182c0 1.698 1.082 3.251 2.766 3.75 2.094.618 5.762 1.578 8.567 1.578 2.805 0 6.473-.96 8.567-1.579 1.684-.498 2.766-2.05 2.766-3.749V13.823l3.93-1.964a1.333 1.333 0 0 0 0-2.386l-12.282-6.14Zm-4.77 2.385a4 4 0 0 1 3.578 0l9.896 4.948-9.896 4.948a4 4 0 0 1-3.578 0l-9.896-4.948 9.896-4.948Zm10.456 9.439v8.848c0 .575-.36 1.045-.856 1.192-2.102.622-5.435 1.469-7.811 1.469-2.376 0-5.709-.848-7.811-1.47-.496-.146-.856-.616-.856-1.19v-8.85L13.02 18a6.667 6.667 0 0 0 5.962 0l5.686-2.842Z"
    />
  </svg>
)

const EducationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={37}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#121212"
        d="M21.633 4.25a7.5 7.5 0 0 0-6.708 0L1.108 11.16a1.5 1.5 0 0 0 0 2.682l4.421 2.21v11.455c0 1.91 1.218 3.658 3.112 4.218 2.356.696 6.483 1.776 9.638 1.776 3.156 0 7.283-1.08 9.638-1.776 1.895-.56 3.112-2.307 3.112-4.218V16.053l4.421-2.21a1.5 1.5 0 0 0 0-2.684L21.633 4.251Zm-5.366 2.684a4.5 4.5 0 0 1 4.025 0l11.133 5.567-11.133 5.566a4.5 4.5 0 0 1-4.025 0L5.133 12.502l11.134-5.567Zm11.762 10.619v9.954c0 .647-.404 1.176-.962 1.34-2.366.7-6.115 1.654-8.788 1.654s-6.422-.954-8.787-1.653c-.558-.165-.963-.694-.963-1.34v-9.955l6.396 3.198a7.5 7.5 0 0 0 6.708 0l6.396-3.198Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.28.5h36v36h-36z" />
      </clipPath>
    </defs>
  </svg>
)
