"use client"

import { setCookie } from 'helpers/cookies'
import { getDeviceUuid } from 'helpers/guest'

import {
  getDeviceNameTracker,
  setCompanyViewCookiesTracker,
  viewCompanySourceTracker
} from 'components/ReportTracker/util'

import styles from '../../../../page.module.scss'

export const sendViewCompany = async (url, company_id, isMobile) => {
  const device_udid = await getDeviceUuid()
  const params = {
    id: company_id,
    payload: {
      source: viewCompanySourceTracker.jobDetail,
      device: getDeviceNameTracker(isMobile),
      device_udid
    }
  }
  setCompanyViewCookiesTracker(setCookie, params)
  window.location.href = url
}

const InfoWrapper = ({ children, lang, companyUrl, company_id, isMobile }) => {

  return <div
    className={styles.company_info}
    onClick={() => sendViewCompany('/' + lang + companyUrl, company_id, isMobile)}
  >
    {children}
  </div>
}

const BtnWrapper = ({ children, lang, companyUrl, isRemote, company_id, isMobile }) => {

  return <span
    className={styles.company_url}
    onClick={() => sendViewCompany('/' + lang + companyUrl + (isRemote === 3 ? '/remoteJobs' : '/jobs'), company_id, isMobile)}
  >{children}</span>
}

export default InfoWrapper

export {
  BtnWrapper,
  InfoWrapper
}