import React from 'react';
import styled from '@emotion/styled';
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { ReactComponent as CloudUploadIcon } from '../../images/svg/cloud-upload.svg';

import { maxFileSize, useUploadResumeContext } from './context';
import UploadResumeWrap from './Wrap';

const { Dragger } = Upload;

const UploadMain = (props: {
  lang: any
  userDetail: any
}) => {
  const { lang = {}, userDetail } = props
  const { uploadResumeFile, fileData } = useUploadResumeContext()
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.pdf, .doc, .docx',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      // console.log('beforeUpload', file)
      if (file.size > maxFileSize) {
        message.error(profile?.uploadResumeModal?.errorTip)
        return false
      }
      // return false;
    },
    customRequest: (options) => {
      // console.log('customRequest', options)
      const resumeId = userDetail?.resume?.id || fileData?.data?.id
      // console.log('resumeId', { userDetail, fileData, resumeId })
      uploadResumeFile({ ...options, resumeId: resumeId })
    },
    // onChange(info) {
    //   const { status } = info.file;
    //   console.log('info', status, info)
    // },
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  };

  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className="modal-content">
          <Dragger {...uploadProps}>
            <div className="main-file-info">
              <CloudUploadIcon />
              <span className='main-btn-upload'>{profile?.uploadResumeModal?.uploadBtn}</span>
              <div className='main-upload-desc'>{profile?.uploadResumeModal?.uploadDesc}</div>
            </div>
          </Dragger>
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  );
};

const ContentWrap = styled.div`
  .modal-content {
    text-align: center;
  }

  .main-file-info {
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #FCFCFC;
    .main-btn-upload {
      display: flex;
      margin-top: 12px;
      padding: 10px 28px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      background: #2378E5;
      color: white;
    }
    .main-upload-desc {
      font-size: 14px;
      margin-top: 12px;
      color: #7D7D7D;
    }
  }

  .progress-file-info {
    display: flex;
    margin-top: 32px;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed #E4E4E4;
    background: #FCFCFC;
  }

  .progress-file-progress {
    width: 50%;
    margin: 24px 0;
  }

  .progress-file-message{
    color:#7D7D7D;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;


export default UploadMain;
