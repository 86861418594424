import React from 'react';
import styled from '@emotion/styled';
import { Progress } from 'antd';

import UploadResumeWrap from './Wrap';
import { useUploadResumeContext } from './context';

const UploadMain = (props: {
  lang: any
}) => {
  const { lang = {} } = props
  const profile = lang.profile || {}
  const { progress } = useUploadResumeContext()
  // console.log('progress', progress)

  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className="modal-content">
          <div className="progress-file-info">
            <FileIcon className="progress-file-icon" />
            <Progress className='progress-file-progress' size={'small'} type="line" percent={progress} showInfo={false} />
            <p className="progress-file-message">{profile?.uploadResumeModal?.uploadingTip}</p>
          </div>
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  );
};

const ContentWrap = styled.div`
  .modal-content {
    text-align: center;
  }

  .progress-file-info {
    display: flex;
    margin-top: 32px;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed #E4E4E4;
    background: #FCFCFC;
  }

  .progress-file-progress {
    width: 50%;
    margin: 24px 0;
  }

  .progress-file-message{
    color:#7D7D7D;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;


export default UploadMain;

const FileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={46}
    fill="none"
    {...props}
  >
    <path
      fill="#EFF6FF"
      stroke="#147BFF"
      strokeWidth={2}
      d="M4.329 4.554a1.4 1.4 0 0 1 1.4-1.4H27.93a1.4 1.4 0 0 1 1.023.445l8.597 9.202c.242.26.377.601.377.956V40.18a1.4 1.4 0 0 1-1.4 1.4h-30.8a1.4 1.4 0 0 1-1.4-1.4V4.554Z"
    />
    <path
      stroke="#147BFF"
      strokeWidth={2}
      d="M10.249 27.582h17.395M10.249 19.557h11.519M28.125 2.951v10.145a.8.8 0 0 0 .8.8h8.868"
    />
  </svg>
)