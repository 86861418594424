"use client"
import CompanyMobile from '../Mobile'
import styled from '@emotion/styled'

const MobileLineStyled = styled.div`
  width: 100%;
  height: 1px;
  padding: 0 16px;
  background: #ffffff;

  span {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
  }
`

const MobileWrapper = (props) => {
  return <div>
    <MobileLineStyled>
      <span></span>
    </MobileLineStyled>
    <CompanyMobile {...props} />
  </div>
}

export default MobileWrapper