import React from 'react'
// import { isMobile } from 'react-device-detect'

import Link from 'components/Link'
import styles from '../Header.module.scss'
import { getCountryKey } from 'helpers/country'

interface IProps {
  langKey: string,
  pageType?: string,
  isMobile?: boolean
}
export const LogoIcon = ({ height = 32, width = 114 }: { height?: number, width?: number }) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 114 32" fill="none">
    <path d="M69.2315 32C67.8327 32 66.8891 31.7709 66.1732 31.5089L67.0194 27.9421C67.5003 28.1115 68.0059 28.2 68.5156 28.2041C70.0763 28.2041 70.533 27.1574 70.533 25.0969V7.25447H75.3152V24.9706C75.3152 28.8955 73.8176 32 69.2315 32Z" fill="#2378E5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M86.5958 25.5152C92.2982 25.5152 95.7446 21.3201 95.7446 16.4377C95.7446 11.5553 92.2954 7.42192 86.5958 7.42192C80.8961 7.42192 77.5073 11.558 77.5073 16.4377C77.5073 21.3174 80.9537 25.5152 86.5958 25.5152ZM86.5958 21.445C83.7747 21.445 82.2072 19.1293 82.2072 16.4363C82.2072 13.7434 83.7734 11.4277 86.5958 11.4277C89.4758 11.4277 91.0447 13.7448 91.0447 16.4363C91.0447 19.1279 89.4758 21.445 86.5958 21.445Z" fill="#2378E5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M97.6153 0H102.099V7.86239H105.317C106.473 7.86239 107.618 8.09019 108.687 8.5328C109.755 8.97541 110.725 9.62415 111.543 10.442C112.361 11.2598 113.009 12.2307 113.452 13.2993C113.894 14.3678 114.122 15.5131 114.122 16.6697C114.122 19.0055 113.194 21.2457 111.543 22.8973C109.892 24.549 107.652 25.4769 105.317 25.4769H97.6153V0ZM102.097 20.2159V21.5809H104.656L104.655 21.5781C105.309 21.5783 105.957 21.4495 106.562 21.1991C107.166 20.9487 107.716 20.5816 108.178 20.1188C108.641 19.6559 109.008 19.1064 109.258 18.5017C109.509 17.8969 109.637 17.2488 109.637 16.5942C109.637 15.2724 109.112 14.0047 108.178 13.07C107.244 12.1354 105.976 11.6103 104.655 11.6103H102.099V20.2159H102.097Z" fill="#2378E5" />
    <path d="M27.1173 25.5748C32.7234 25.5748 36.105 21.4514 36.105 16.655C36.105 11.8586 32.7189 7.79584 27.1173 7.79584C21.5157 7.79584 18.1924 11.8564 18.1924 16.655C18.1924 21.4536 21.5785 25.5748 27.1173 25.5748ZM27.1173 21.5748C24.3479 21.5748 22.8096 19.3 22.8096 16.655C22.8096 14.0101 24.3479 11.733 27.1173 11.733C29.9495 11.733 31.4878 14.0101 31.4878 16.655C31.4878 19.3 29.9495 21.5725 27.1173 21.5725V21.5748ZM44.7718 25.5748C49.4496 25.5748 52.1585 23.2977 52.1585 20.1614C52.1585 13.3325 42.0674 15.4234 42.0674 12.9646C42.0674 12.0403 43.1124 11.3023 44.7135 11.3023C46.8057 11.3023 48.7163 12.2266 49.7613 13.272L51.5552 10.1312C49.6034 8.59956 47.1896 7.77618 44.709 7.79584C40.2241 7.79584 37.7575 10.2636 37.7575 13.1486C37.7575 19.7913 47.7924 17.5165 47.7924 20.2826C47.7924 21.3303 46.8685 22.0773 44.9602 22.0773C42.868 22.0773 40.343 20.9085 39.1119 19.7397L37.1991 22.9186C39.0469 24.5899 41.8791 25.5748 44.7718 25.5748ZM60.7311 25.5748C65.4088 25.5748 68.1177 23.2977 68.1177 20.1614C68.1177 13.3325 58.0267 15.4234 58.0267 12.9646C58.0267 12.0403 59.0739 11.3023 60.675 11.3023C62.7672 11.3023 64.6755 12.2266 65.7205 13.272L67.5145 10.1312C65.5664 8.60196 63.1579 7.77798 60.6818 7.7936C56.1856 7.7936 54.1223 10.2613 54.1223 13.1463C54.1223 19.789 63.7629 17.5142 63.7629 20.2803C63.7629 21.328 62.839 22.0751 60.9307 22.0751C58.8385 22.0751 56.3135 20.9063 55.0823 19.7374L53.1606 22.9186C54.9456 24.5899 57.7755 25.5748 60.7311 25.5748Z" fill="#2378E5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H4.50699V7.90461H7.74282C8.90519 7.90461 10.0562 8.13365 11.1301 8.57866C12.204 9.02367 13.1797 9.67592 14.0016 10.4982C14.8236 11.3204 15.4755 12.2966 15.9204 13.371C16.3652 14.4453 16.5941 15.5968 16.5941 16.7596C16.5941 19.1081 15.6616 21.3604 14.0016 23.0211C12.3417 24.6817 10.0903 25.6146 7.74282 25.6146H0V0ZM4.50561 20.3248V21.6975H7.07828L7.07691 21.6947C7.73473 21.6949 8.38614 21.5654 8.99392 21.3136C9.6017 21.0619 10.1539 20.6928 10.6191 20.2274C11.0842 19.7621 11.4532 19.2096 11.7048 18.6016C11.9565 17.9935 12.0859 17.3419 12.0857 16.6838C12.0857 15.3548 11.558 14.0802 10.6187 13.1405C9.67935 12.2008 8.40534 11.6728 7.07691 11.6728H4.50699V20.3248H4.50561Z" fill="#2378E5" />
    <path d="M70.1279 2.8537C70.1279 4.45672 71.3025 5.64295 72.9213 5.64295C73.2906 5.65193 73.6578 5.58591 74.0008 5.44891C74.3438 5.31192 74.6553 5.10679 74.9165 4.84598C75.1777 4.58516 75.3831 4.27409 75.5203 3.93163C75.6575 3.58916 75.7236 3.22244 75.7146 2.8537C75.7146 1.17989 74.5106 0.0644531 72.9213 0.0644531C71.3025 0.0644531 70.1279 1.17989 70.1279 2.8537Z" fill="#FFC53D" />
  </svg>
}

const Logo = (props: IProps) => {
  const { langKey, isMobile, pageType } = props
  const imageMap = {
    sg: 'Bossjob Singapore',
    ph: 'Bossjob Philippines',
    com: 'Bossjob Global & Remote',
    id: 'Bossjob Indonesia',
    jp: 'Bossjob 日本',
    my: 'Bossjob Malaysia',
    hk: 'Bossjob 香港',
    tw: 'Bossjob 台湾',
    tr: 'Bossjob Turkiye'
  }
  const countryKey = getCountryKey()
  const imageTitle = imageMap[countryKey]
  let logoWidth = 124
  let logoHeight = 32
  if (isMobile) {
    logoWidth = 80
    logoHeight = 21
  }
  if (isMobile && pageType === 'h5') {
    logoWidth = 73
    logoHeight = 19
  }

  return (
    <div className={styles.headerLogo}>
      <Link title='Home' to={'/' + langKey}>
        <div className={styles.headerLogoTitle} title={imageTitle}>
          <LogoIcon
            width={logoWidth}
            height={logoHeight}
          />
        </div>
      </Link>
    </div>
  )
}

export default Logo
