/* eslint-disable prefer-promise-reject-errors */
'use client'
import React, { createContext, useState, useCallback, useEffect, useMemo, useContext } from 'react'
import { ReaderTPromise as M } from 'app/models/abstractModels/monads'
import { registInterpreter } from 'app/models/abstractModels/util'
import { locationScript } from 'app/models/abstractModels/updateLocation'
import { getLocation } from 'store/services/location'
import { flatMap } from 'lodash-es'
import { getCookie } from 'helpers/cookies'
import { useRouter } from 'next/navigation'
import { getCountryKey } from 'helpers/country'
import { getDefaultLocation } from '../../../helpers/country'
import { ConfigContext } from './configProvider'
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'
interface IProps {
    location: any;
    setLocation: React.Dispatch<React.SetStateAction<any>>;
}


export const LocationContext = createContext<IProps>({
    location: '',
    setLocation: (value) => value
})

const Provider = LocationContext.Provider

// eslint-disable-next-line react/prop-types
const LocationProvider = ({ children }: any) => {
    const { location_lists: locations } = useContext(ConfigContext)
    const router = useRouter()
    const flatLocations = useMemo(() => {
        if (!locations?.length) {
            return []
        }
        return flatMap(locations, p => p.locations)
    }, [locations])
    // const defaultLocation = getCookie('location')?.[0] ?? getDefaultLocation(country)
    // useEffect(() => {
    //     if (flatLocations.length && location?.id === defaultLocation?.id) {
    //         const languageLocation = flatLocations.find(item => item?.id === location?.id)
    //         setLocation(languageLocation)
    //     }
    // }, [flatLocations])
    // const [location, setLocation] = useState(defaultLocation)

    let loc = getCookie('location')?.[0]// ?? getDefaultLocation(country)
    const [location, setLocation] = useState(loc)

    const defaultLocation = useMemo(() => {
        let copyValue = loc
        if (flatLocations?.length && location?.id === copyValue?.id) {
            copyValue = flatLocations.find(item => item?.id === location?.id)
        }
        return copyValue
    }, [flatLocations])
    const intepreter = useCallback(command => command.cata({
        queryLatLon: () => M(() => new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords
                resolve({ latitude, longitude })
            },
                error => {
                    reject(error)
                }
            )
        })),

        fetchLocation: position => M.do(() => {
            return getLocation(position).then(result => {
                const city = result?.data?.data?.city
                const country = result?.data?.data?.country
                if (!city) {
                    return Promise.reject('not find location')
                } else {
                    return { city, country }
                }
            })
        }),
        updateLocation: location => M.do(() => {
            const newLocation = flatLocations.find(item => item.value === location.city)
            if (newLocation && newLocation.id !== location?.id) {
                setLocation(newLocation)
            }
        }),
    }), [location, flatLocations, router])
    const locationIp = registInterpreter(intepreter)
    const [locationUpdated, setLocationupdated] = useState(false)
    useEffect(() => {
        if (flatLocations?.length && !locationUpdated && !getCookie('location')?.[0]) {
            locationIp(locationScript())
                .run()
                .catch(e => {
                    console.log('location error :', e)
                    setLocation(defaultLocation)
                })
            setLocationupdated(true)
        }
    }, [flatLocations, locationUpdated])

    useEffect(() => {
        publishSharedData('LOCATION_CONTEXT', { location: location, setLocation })
    }, [location, setLocation])
    console.log({ location, locations })
    return <Provider value={{ location: location, setLocation }}>{children}</Provider>
}
export default LocationProvider