/* eslint-disable react/no-unknown-property */
'use client'
import React, { useState, useCallback, useEffect, useMemo, useRef, useContext } from 'react'
import { flushSync } from 'react-dom'
import { flatMap } from 'lodash-es'
import JobSearchBar from '../../../../../../components/commons/location/search'
import styles from '../../index.module.scss'
import MaterialButton from 'components/MaterialButton'
import Single from 'app/components/commons/select/single'
import Multiple from 'app/components/commons/select/multiple'
import { useSuggest } from './hooks'
import theme from 'app/components/commons/theme'
import { ThemeProvider } from '@mui/material/styles'
import JobFunction from 'app/components/commons/jobFunction'
// import { useRouter } from 'next/navigation'
import { toPairs } from 'ramda'
import { Button } from 'app/components/MUIs'
import JobSearchFilters from 'app/components/commons/JobSearchFilters'
import { getCookie } from 'helpers/cookies'
import { useDispatch } from 'react-redux'
import { fetchConfigSuccess } from 'store/actions/config/fetchConfig'
import { useFirstRender } from 'helpers/useFirstRender'
import { filter } from 'ramda'
import useUserAgent from 'helpers/useUserAgent'
import { LoadingContext } from 'app/components/providers/loadingProvider'
import { HistoryIcons } from 'images'
import Image from 'next/image'
import classNames from 'classnames'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
// import { languageContext } from 'app/components/providers/languageProvider'
import { MoreFilterIcon } from 'images'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SvgResetIcon } from '../../svg'
// import OverrideMuiSelectSingle from '../OverrideMuiSelectSingle'
import useRemoteSearchSelect from '../OverrideMuiSelectSingle/useRemoteSearchSelect'
import SalarySelect from 'app/components/commons/salarySelect'
// import { Cascader, Popover } from 'antd'
import { CountrySelectorForPC } from './CountrySelectorForPC'
import searchFormStyles from './index.module.scss'
import { usePathname } from 'next/navigation'
const SearchArea = (props: any) => {
  const { config, searchValues, lang, search, remoteFindJobs } = props
  const {
    countryList,
    jobLanguages,
    timezoneLists,
    world_region_lists,

    country,
    // language,
    timezone,

    setCountry,
    // setLanguage,
    setTimezone,

    searchCountryList,
    // searchLanguageList,
    searchTimezoneList,
    removeEmptyValues
  } = useRemoteSearchSelect(config, searchValues)

  const sortOptions = [
    { label: search?.newest, value: '1' },
    { label: search?.relevance, value: '2' },
    { label: search?.highestSalary, value: '3' }
  ]
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchConfigSuccess(config))
  }, [])
  const sessionid = getCookie('sessionid')
  const userCookie = getCookie('user')
  const [page, setPage] = useState(searchValues.page ?? '1')
  const { push } = useContext(LoadingContext)
  const locations = flatMap(config.location_lists, (item) => item.locations)
  const [location, setLocation] = useState(
    locations.filter((location) => searchValues?.location?.includes(location.seo_value))
  )
  const [isFixed, setIsfixed] = useState(false)

  useEffect(() => {
    const listener = () => {
      const scrollTop = document.documentElement.scrollTop
      setIsfixed(scrollTop > 58)
    }
    window.addEventListener('scroll', listener, true)
    return window.removeEventListener('scroll', listener)
  }, [])

  // const [salaries, setSelaries] = useState(searchValues?.salary ?? [])
  const [language, setLanguage] = useState(searchValues.language_ids ?? '')
  const [salaries, setSalaries] = useState({
    salary: searchValues?.salary ?? [],
    salaryType: searchValues?.salaryType ?? []
  })

  const salaryOptions =
    config?.salary_range_filters?.map?.((item) => ({
      value: item?.['seo-value'],
      label: item.value
    })) ?? []

  const [jobFunctionValue, jobFunctionChange] = useState({
    functionTitles: searchValues?.functionTitles ?? [],
    jobFunctions: searchValues?.jobFunctions ?? [],
    mainFunctions: searchValues?.mainFunctions ?? []
  })

  const [showMore, setShowMore] = useState(false)
  const [sort, setSort] = useState(searchValues?.sort?.[0] ?? '2')

  const [moreData, setMoreData] = useState(
    filter((a) => a !== null && a !== undefined && a != '')({
      workExperience: searchValues?.workExperience ?? null,
      qualification: searchValues?.qualification ?? null,
      verifiedCompany: searchValues?.verifiedCompany ?? null,
      companySizes: searchValues?.companySizes ?? null,
      financingStages: searchValues?.financingStages ?? null,
      industry: searchValues?.industry ?? null
    })
  )
  const [jobTypes, setJobtypes] = useState(searchValues?.jobType ?? [])
  const jobTypeList =
    config?.job_types?.map?.((item) => ({
      value: item?.['seo-value'],
      label: item.value
    })) ?? []
  const [searchValue, setSearchValue] = useState(searchValues.query)
  const [queryFields, setQueryFields] = useState(searchValues.queryFields)
  const [suggestionList, handleSuggestionSearch, addSearchHistory, searchLoading] =
    useSuggest() as any[]

  const filterParams = useMemo(() => {
    let searchVal
    if (searchValue && Array.isArray(searchValue)) {
      searchVal = searchValue?.join(' ')
    } else {
      searchVal = searchValue?.trim?.()
    }

    return filter((a) => a !== null && a !== undefined && a != '')({
      query: searchVal,
      queryFields,
      //salary: salaries,
      location: location?.map((a) => a['seo_value']),
      jobType: jobTypes,
      remote_country_ids: searchCountryList,
      language_ids: language,
      timezone_ids: searchTimezoneList,
      sort: sort,
      page: page,
      ...salaries,
      ...jobFunctionValue,
      ...moreData
    })
  }, [
    searchValue,
    salaries,
    jobTypes,
    moreData,
    location,
    sort,
    jobFunctionValue,
    queryFields,
    searchCountryList,
    language,
    searchTimezoneList
  ])

  const result = useMemo(() => {
    return {
      params: removeEmptyValues(filterParams),
      searchQuery: 'job-search'
    }
  }, [filterParams])

  const firstRender = useFirstRender()
  const reload = useCallback(() => {
    if (firstRender) {
      return
    }
    // @ts-ignore
    const url = new URLSearchParams(toPairs(result.params)).toString()
    push('/remote-jobs-hiring/' + result.searchQuery + '?' + url)
  }, [result, push])

  const reloadRef = useRef(reload)

  useEffect(() => {
    reloadRef.current = reload
  }, [reload])

  useEffect(reload, [
    location,
    salaries,
    jobTypes,
    moreData,
    sort,
    jobFunctionValue,
    queryFields,
    searchCountryList,
    language,
    searchTimezoneList
  ])

  const moreCount = useMemo(() => {
    return Object.values(moreData).reduce((a1, a2: any) => a1 + (a2?.length ?? 0), 0)
  }, [moreData])
  const userAgent = useUserAgent()
  const styleleSelect = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
  const spanStyle = {
    paddingLeft: '10px',
    fontSize: '15px'
  }
  const pathname = usePathname()
  const isRemoteJob = pathname.includes('/remote-jobs-hiring/')

  return (
    <div className={styles.searchWrap}>
      <ThemeProvider theme={theme}>
        <div
          className={classNames({
            [styles.searchForm]: true
            // [styles.isFixed]: isFixed
          })}
        >
          <div className={styles.searchForm_headerBg}></div>
          <div className={styles.searchAreaContent}>
            {/* search */}
            {/* TODO */}
            <div className={styles.searchArea}>
              <div className={styles.searchAreaLeft}>
                {/* <LocationMultiSelector
                  className={styles.location}
                  locationList={config.location_lists}
                  value={location}
                  // isClear={true}
                  label={search.location}
                  // defaultValue={location}
                  lang={search}
                  onChange={setLocation}
                  sx={{
                    '> .MuiFormControl-root': {
                      borderRadius: '8px',
                      height: '60px',
                      marginTop: '4px',
                      overflow: 'hidden',
                      '> .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        height: '60px',
                        overflow: 'hidden',
                        marginTop: '4px'
                      }
                    }
                  }}
                /> */}
                <div className={styles.searchAreaBox}>
                  <JobSearchBar
                    id='search'
                    placeholder={search.title}
                    variant='outlined'
                    size='small'
                    className={styles.search}
                    value={searchValue}
                    maxLength={255}
                    isLoading={searchLoading}
                    searchFn={handleSuggestionSearch as any}
                    updateSearchValue={setSearchValue}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        flushSync(() => {
                          setSearchValue((e.target as HTMLInputElement).value)
                        })
                        addSearchHistory((e.target as HTMLInputElement).value)
                        setQueryFields('')
                        reloadRef.current()
                      }
                    }}
                    renderOption={(props, option) => {
                      const { type, is_history: isHistory, value, logo_url: logoUrl } = option || {}
                      return type === 'company' ? (
                        <li {...props} style={styleleSelect}>
                          <Image src={logoUrl} alt={value} width='22' height='22' />
                          <span style={spanStyle}>{value}</span>
                        </li>
                      ) : isHistory ? (
                        <li {...props} style={{ ...styleleSelect, color: '#136fd3' }}>
                          <AccessTimeIcon />
                          <span style={spanStyle}>{value}</span>
                        </li>
                      ) : (
                        <li {...props} style={styleleSelect}>
                          <Image src={HistoryIcons} alt='history icons' width='17' height='17' />
                          <span style={spanStyle}>{value || option}</span>
                        </li>
                      )
                    }}
                    options={suggestionList}
                    onSelect={(value: any) => {
                      const newValue = value?.value || value || ''
                      setQueryFields(value?.type || '')
                      flushSync(() => {
                        setSearchValue(newValue)
                      })
                      addSearchHistory(newValue)
                      reloadRef.current()
                    }}
                  />
                  <MaterialButton
                    className={styles.searchButton}
                    variant='contained'
                    capitalize
                    onClick={() => {
                      flushSync(() => {
                        setSearchValue(searchValue)
                      })
                      addSearchHistory(searchValue)
                      reloadRef.current()
                      setQueryFields('')
                    }}
                  >
                    {search?.findNow}
                  </MaterialButton>
                </div>
              </div>
            </div>
            {/* filter */}
            <div className={styles.filters}>
              <div className={styles.filters_content}>
                <div className={styles.filtersLeft}>
                  <Single
                    options={sortOptions}
                    value={sort}
                    onSelect={setSort}
                    className={classNames([styles.filterItems, styles.relevance])}
                    label='Sort by'
                    IconComponent={() => (
                      <ExpandMoreIcon className={styles.filtersLeft_inputIcon} />
                    )}
                  />
                  <CountrySelectorForPC
                    className={styles.filterItems}
                    value={country}
                    onChange={setCountry}
                    world_region_lists={world_region_lists}
                    text={remoteFindJobs.openCountry}
                  />
                  {/* <Multiple
                    label={search.type}
                    value={jobTypes}
                    options={jobTypeList}
                    className={classNames([styles.filterItems, styles.searchJobType])}
                    onSelect={setJobtypes}
                    defaultValue={jobTypes}
                    IconComponent={() => (
                      <ExpandMoreIcon className={styles.filtersLeft_inputIcon} />
                    )}
                    notPosition={true}
                  /> */}
                  <div className={searchFormStyles.jobFunctionWrapper} style={{ width: 160, }}>
                    <JobFunction
                      id='jobFunction'
                      label={search.function}
                      value={jobFunctionValue}
                      className={[styles.filterItems, styles.jobFunction]}
                      onChange={jobFunctionChange}
                    />
                  </div>

                  {!isRemoteJob && <SalarySelect
                    id='SalarySelect'
                    salaryTypeLists={config.salary_type_lists}
                    label={search.salary}
                    value={salaries}
                    className={classNames([styles.filterItems, styles.jobSalary])}
                    onChange={setSalaries}
                  />}

                  <Multiple
                    label={remoteFindJobs.language}
                    value={language}
                    options={jobLanguages?.length > 0 ? jobLanguages?.map(e => {
                      const item = {
                        ...e,
                        label: e.name,
                        value: e.id
                      }
                      return item
                    }) : []}
                    className={classNames([styles.filterItems, styles.filterLanguage])}
                    onSelect={setLanguage}
                    IconComponent={() => (
                      <ExpandMoreIcon className={styles.filtersLeft_inputIcon} />
                    )}
                  />

                  {/* <OverrideMuiSelectSingle
                    selectList={timezoneLists}
                    label={remoteFindJobs.timezone}
                    onSelect={setTimezone}
                    selectValue={timezone}
                    formControlClassName={styles.filterItems}
                  /> */}

                  <Button
                    className={classNames([
                      styles.moreButton,
                      moreCount ? styles.moreButtonCount : ''
                    ])}
                    variant='outlined'
                    onClick={() => {
                      setShowMore(true)
                    }}
                    startIcon={<Image src={MoreFilterIcon} width={24} height={24} alt='filter' />}
                  >
                    {remoteFindJobs.more} {moreCount ? `(${moreCount})` : ''}{' '}
                  </Button>
                </div>
                <div className={styles.filtersRight}>
                  <Button
                    className={styles.clearButton}
                    variant='text'
                    onClick={() => {
                      setSearchValue('')
                      setLocation([])
                      setSearchValue('')
                      setSort('1')
                      jobFunctionChange({
                        jobFunctions: [],
                        mainFunctions: [],
                        functionTitles: []
                      })
                      setJobtypes([])
                      setSalaries({} as any)
                      setMoreData({} as any)
                      setCountry([])
                      setLanguage([])
                      setTimezone('')
                      setPage('1')
                    }}
                    startIcon={<SvgResetIcon />}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>

      {showMore && (
        <JobSearchFilters
          urlDefaultValues={moreData}
          isShowFilter={showMore}
          onResetFilter={() => {
            setMoreData({} as any)
          }}
          keyword={searchValues.query}
          config={config}
          handleShowFilter={() => {
            setShowMore(false)
          }}
          moreFilterReset={false}
          isShowingEmailAlert={sessionid && !userCookie?.is_email_verify}
          setClientDefaultValues={(data) => {
            setMoreData(data)
          }}
        />
      )}
    </div>
  )
}
export default SearchArea
