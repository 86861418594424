import { useState, useEffect } from "react"

export const useDebouncedValue = <T>(inputValue: T) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(inputValue)

  useEffect(() => {
    const delay = 500 // 设置延迟时间（毫秒）
    const timerId = setTimeout(() => {
      setDebouncedValue(inputValue)
    }, delay)

    return () => {
      clearTimeout(timerId) // 清除计时器
    }
  }, [inputValue])

  return debouncedValue
}

