'use client'
import { useSelector } from "react-redux"
import { publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { useEffect } from "react"

const JobInProviders = () => {
  const isPostingReport = useSelector((store: any) => store.reports.postReport.fetching)
  const postReportResponse = useSelector((store: any) => store.reports.postReport.response)
  useEffect(() => {
    return publishSharedData('POST_REPORT_FETCHING', isPostingReport)
  }, [isPostingReport])
  useEffect(() => {
    return publishSharedData('POST_REPORT_RESPONSE', postReportResponse)
  }, [postReportResponse])
  return null
}

export default JobInProviders