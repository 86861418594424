export const CloseIcon = ()=>{
  return  <svg
  width='18'
  height='18'
  viewBox='0 0 18 18'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
    d='M14.0625 3.9375L3.9375 14.0625'
    stroke='#707070'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
  <path
    d='M14.0625 14.0625L3.9375 3.9375'
    stroke='#707070'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
</svg>
}

export const HeartIcon = ({isSaved})=>{
  return <svg
  width='18'
  height='16'
  viewBox='0 0 18 16'
  fill={isSaved ? '#136FD3' : 'none'}
  xmlns='http://www.w3.org/2000/svg'
>
  <path
    d='M9 14.875C9 14.875 1.1875 10.5 1.1875 5.18751C1.1875 4.24836 1.51289 3.33821 2.1083 2.61193C2.70371 1.88564 3.53236 1.38808 4.45328 1.2039C5.37419 1.01971 6.33047 1.16029 7.15943 1.6017C7.98838 2.04311 8.63879 2.7581 9 3.62501V3.62501C9.36121 2.7581 10.0116 2.04311 10.8406 1.6017C11.6695 1.16029 12.6258 1.01971 13.5467 1.2039C14.4676 1.38808 15.2963 1.88564 15.8917 2.61193C16.4871 3.33821 16.8125 4.24836 16.8125 5.18751C16.8125 10.5 9 14.875 9 14.875Z'
    stroke='#136FD3'
    strokeWidth='1.5'
    strokeLinecap='round'
    strokeLinejoin='round'
  />
</svg>
}