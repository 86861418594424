import React, { useRef, useContext } from 'react';
import styled from '@emotion/styled';
import { message } from 'antd';
import { usePathname } from 'next/navigation';
import { LinkContext } from 'app/components/providers/linkProvider';
import { ReactComponent as FileIcon } from '../../images/svg/file.svg';
import { ReactComponent as CheckCircleIcon } from '../../images/svg/check-circle.svg';

import { getUrlSearchString } from '../utils';
import { maxFileSize, useUploadResumeContext } from './context';
import UploadResumeWrap from './Wrap';

const UploadSuccess = (props: {
  lang: any,
  userDetail: any
  getUserInfo: () => Promise<any>
  onOk: (data: any) => void
}) => {
  const { getUserInfo, userDetail, lang = {} } = props
  const profile = lang.profile || {}
  const inputRef = useRef(null);
  const { fileData, uploadResumeFile } = useUploadResumeContext()
  const { push } = useContext(LinkContext)
  const pathname = usePathname()

  const onChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > maxFileSize) {
      message.error(profile?.uploadResumeModal?.errorTip)
      return false
    }
    const resumeId = userDetail?.resume?.id || fileData?.data?.id
    // console.log('resumeId', { userDetail, fileData, resumeId })
    uploadResumeFile({ file, resumeId: resumeId })
  };

  const handleUpload = () => {
    inputRef.current.click();
  };

  const handSubmit = () => {
    const searchWords = getUrlSearchString(4)
    getUserInfo && getUserInfo()
    push(`${pathname}${searchWords}`)
  }
  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className="modal-content">
          <div className="file-info">
            <FileIcon />
            <span className='file-name'>{fileData?.data?.name || ''}</span>
            <div className="upload-success">
              <CheckCircleIcon />
              <span>{profile?.uploadResumeModal?.uploadSuccess}</span>
            </div>
          </div>
          <div className="actions">
            <input type="file" accept='.pdf, .doc, .docx' ref={inputRef} onChange={onChange} style={{ display: 'none' }} hidden />
            <span className="re-upload" onClick={handleUpload}>{profile?.uploadResumeModal?.reUploadBtn}</span>
            <span className="confirm" onClick={handSubmit}>{profile?.confirm}</span>
          </div>
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  );
};

const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`,
}

const ContentWrap = styled.div`
  .modal-content {
    text-align: center;
  }

  .file-info {
    display: flex;
    margin-top: 32px;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed #E4E4E4;
    background: #FCFCFC;
    .file-name {
      margin-top: 4px;
    }
    .upload-success {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 28px;
      color: #121212;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.15px;
    }
  }

  .file-info img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .upload-success {
    color: green;
    font-weight: bold;
    margin-left: 10px;
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    gap: 16px;
  }

  .re-upload, .confirm {
    display: flex;
    padding: 10px 44px;
    justify-content: center;
    align-items: center;
    border-radius: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    cursor: pointer;

    ${media.sm} {
      padding: 10px 0;
    }
  }

  .re-upload {
    border: 1px solid #E4E4E4;
    color: #515151;
    flex: 2;
  }

  .confirm {
    background: #2378E5;
    color: white;
    flex: 3;
  }

`;


export default UploadSuccess;