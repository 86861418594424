'use client'
import React, { useState } from 'react'
import { Drawer, Dropdown } from 'antd'
import { isMobile } from 'react-device-detect'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import styles from './index.module.scss'
import { CloseIcon, DropDownIcon, ExchangeIcon } from './Icons'

const ExchangeDrawer = ({ list, setValue, open, onCloseHandler, value, convertToLabel }) => {

  return <>
    <span
      style={{ display: 'flex', alignItems: 'center' }}
    ><DropDownIcon /></span>
    <Drawer
      height={'75vh'}
      className={styles.mobileExchangeDrawer}
      title={<div className={styles.mobileExchangeDrawerHeader}>
        <span>
          {convertToLabel}
          {/* Convert to */}
        </span>
        <span onClick={onCloseHandler}>
          <CloseIcon />
        </span>
      </div>}
      placement='bottom'
      width={'100vw'}
      closable={false}
      open={open}
      onClose={onCloseHandler}
      getContainer={() => document.body}
    >
      {list.map(v => {
        return <div
          key={v.id}
          className={styles.mobileExchangeDrawerListItem}
          data-checked={v.text === value}
          onClick={(e) => {
            setValue(v.text)
            onCloseHandler(e)
          }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ width: 24, height: 16, marginRight: 6 }} src={v.currency_icon} /> {v.currency_code}
          </span>
          <span className={styles.mobileExchangeDrawerListItemSalary}>
            {v.text}
          </span>
        </div>
      })}
    </Drawer>
  </>
}


export const MoneyExchange = ({
  originalValue,  label, list
}) => {
  const langDic = useSharedData('DICTIONARY') || {}

  const [value, setValue] = useState(originalValue)
  const [open, setOpen] = useState(false)

  const option = list.map(v => {

    return {
      label: <div
        data-checked={value === v.text}
        className={styles.exchangeMoneyListWrapper}
        onClick={() => {
          setValue(v.text)
        }}>

        <span style={{
          display: 'flex', alignItems: 'center',
        }}
        >
          <img src={v.currency_icon} width={24} height={16} style={{ marginRight: 6 }} />  {v.currency_code}
        </span>
        <span className={styles.listSalary}>
          {v.text}
          
        </span>
      </div>,
      key: v.id
    }
  })
  const convertTo = langDic?.moneyExchange?.convertTo
  return <div>
    {!isMobile && <Dropdown
      menu={{ items: option }}
      placement="bottomLeft"
      overlayStyle={{ maxHeight: 280 }}
      onOpenChange={setOpen}
      dropdownRender={originalNode => {
        return <div className={styles.moneyDropdownWrapper}>
          <div className={styles.header}>
            {convertTo}
            {/* Convert to */}
          </div>
          {originalNode}
        </div>
      }}
    >
      <div className={styles.exchangeIconDownWrapperOnRemoteJob} data-hover={open}>
        <ExchangeIcon />
      </div>
    </Dropdown>}

    <span style={{ display: 'inline-flex', alignItems: 'center' }} onClick={isMobile ? () => setOpen(true) : null}>
      {value}
      {(
        <span
          style={{
            color: '#2378e5',
            fontSize: 14,
            letterSpacing: '0.12px',
            fontWeight: '400'
          }}
        >
          [{label}]
        </span>
      )}
      {isMobile &&
        <ExchangeDrawer list={list}
          setValue={setValue}
          value={value}
          open={open}
          convertToLabel={convertTo}
          onCloseHandler={(event) => {
            event?.stopPropagation?.()
            setOpen(false)
          }}
        />}
    </span>
  </div >
}

export default MoneyExchange