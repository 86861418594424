'use client'
import React, { useState, useEffect } from 'react'
import styles from '../../index.module.scss'
// import JobCard from '../jobCard'

import MobileCard from '../jobCard/index.mobile'
import { uniq } from 'lodash-es'
import { fetchQueryOnlineStatus } from 'store/services/jobs/fetchJobsCommunicated'
import Link from 'components/Link'
import dynamic from 'next/dynamic'
const JobCard = dynamic(() => import('../jobCard'), { ssr: false })
const HotJobTable = (props) => {
  const [jobs, setJobs] = useState(props.jobs)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (props.jobs?.length > 0) {
      getRecruiterIsOnline(props.jobs)
    }
  }, [props])
  const { query, header } = props
  useEffect(() => {
    Promise.all([
      import('../jobCard'),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  const getRecruiterIsOnline = (oldData) => {
    const Ids = uniq(oldData.map((e) => e.recruiter_id))
    fetchQueryOnlineStatus({
      ids: Ids
    })
      .then((res) => {
        const data = res.data.data
        oldData.map((e) => {
          data.map((k) => {
            if (e.recruiter_id == k.user_id) {
              e.recruiter_is_online = k.is_online
              e.recruiter_last_active_at = k.last_online_time
            }
          })
        })
        setJobs([...oldData])
      })
      .catch((e) => console.log(e))
  }

  if (!jobs?.length) {
    return null
  }
  const href = `/hot-jobs-recommend${query ? `?query=${query}` : ''}`
  const style = loading ? { display: 'none' } : {}
  return (
    <>
      {Boolean(!loading && jobs.length) && header}
      <div className={styles.tableContainer} style={style}>
        {jobs?.map((job) => {
          return (
            <div className={styles.jobContainer} key={job?.id}>
              <JobCard {...job} cardType='hotjobs' originDictionary={props?.originDictionary} dictionary={props.dictionary} />
            </div>
          )
        })}
        <Link href={href} className={styles.moreButton}>{props?.lang?.seeMore}</Link>
      </div>
      <div className={styles.mobileContainer} style={style}>
        {jobs?.map((job) => {
          return (
            <div className={styles.jobContainer} key={job?.id}>
              <MobileCard {...job} cardType='hotjobs' dictionary={props.dictionary} />
            </div>
          )
        })}

        <div className={styles.loadMore}>
          <Link href={href} className={styles.viewMore}>{props?.lang?.seeMore}</Link>
        </div>
      </div>
    </>
  )
}

export default HotJobTable
