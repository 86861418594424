'use client'
import { Stack } from 'app/components/MUIs'
import MaterialButton from 'components/MaterialButton'
import styles from '../../../page.module.scss'
import { useLazyConnectLogin } from 'helpers/lazyConnnectLogin'

type propsType = {
  signUp: { [key: string]: string }
}

const SignUp = ({ signUp }: propsType) => {
  const { showLoginModal } = useLazyConnectLogin()
  const handleSendEmailTOP = () => {
    sessionStorage.setItem('redirectPage', window?.location?.pathname)
    showLoginModal()
    return
  }
  return (
    <section className={styles.signUp}>
      <h3>{signUp.title}</h3>
      <div>
        <Stack spacing={2}>
          <MaterialButton
            variant='contained'
            sx={{
              height: '44px',
              lineHeight: '44px',
              background: '#2378E5',
              borderRadius: '8px',
              marginTop: '8px',
              textTransform: 'capitalize !important',
              fontSize: '16px',
              fontWeight: 400,
              boxShadow: 'none',
              '&:hover': {
                background: "#1B66C9",
                color: "#ffffff",
                boxShadow: "none"
              }
            }}
            isLoading={false}
            // disabled={! /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(email)}
            onClick={handleSendEmailTOP}
          >
            {signUp.btn}
          </MaterialButton>
        </Stack>
      </div>

      <p className={styles.signUp_userProtocol}>
        {signUp.note}{' '}
        <a href='https://blog.bossjob.ph/terms-and-conditions/' target='_blank' rel='noreferrer'>
          {signUp.term}
        </a>{' '}
        &
        <a href='https://blog.bossjob.ph/terms-and-conditions/' target='_blank' rel='noreferrer'>
          {signUp.policy}
        </a>
      </p>
    </section>
  )
}

export default SignUp
