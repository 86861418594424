'use client'
import React, { useEffect, useState } from 'react'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { DefaultAvatar } from 'images'
import styles from './Hamburger.module.scss'
import classNames from 'classnames'
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'
import vip_user_icon_url from './vip_user_icon.png'
interface HamburgerProps {
  disabled?: boolean
  lang?: any
  userInfo?: any
  isLogin: boolean,
  pageType?: string
}
const Hamburger = ({ disabled, lang, userInfo, isLogin }: HamburgerProps) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [openState, setOpenState] = useState(false)
  const pathname = usePathname()
  const handleShowMenu = () => {
    // if (!openState) {
    //   // opening menu, disable scrolling of body
    //   document.body.style.position = 'fixed'
    //   document.body.style.width = '100%'
    // }

    // if (openState) {
    //   // closing menu, enable scrolling of body
    //   const scrollY = document.body.style.top
    //   document.body.style.position = ''
    //   document.body.style.top = ''
    //   // retrieve previous scroll position
    //   window.scrollTo(0, parseInt(scrollY || '0') * -1)
    // }

    setOpenState(!openState)
  }
  useEffect(() => {
    postNotification('DISPATCH', toggleMenu(openState))
  }, [openState])
  const handleToGetStarted = () => {
    router.push('/get-started')
    if (searchParams.get('from') === 'sg-h5') {
      window?.gtag?.('event', 'sgh5_job_detail_get_started')
    }

    setOpenState(false)
  }

  return (
    <div className={styles.hamburgerWrapper}>
      {!isLogin && (
        <div className={styles.hamburgerWrapper_getStarted} onClick={handleToGetStarted}
          {
          ...(
            pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_get_started'
            })
          }
        >
          {lang.getStarted}
        </div>
      )}

      <div
        className={classNames([styles.mobile_menu_off, openState ? styles.mobile_menu_open : null])}
        onClick={disabled ? null : handleShowMenu}
      >
        <div id={styles.hamburgerMenu} className={openState ? styles.active : null}>
          {isLogin && !openState ? (
            !userInfo?.vip?.is_vip ? (
              <img
                src={userInfo?.avatar || DefaultAvatar}
                className={styles.profileAvatar}
                alt='avatar'
                style={{ width: '27px', height: '27px' }}
              />
            ) : (
              <div className={styles.vipAvatar}>
                <img
                  src={userInfo?.avatar || DefaultAvatar}
                  className={styles.profileAvatar}
                  alt='avatar'
                />
                <img
                  src={vip_user_icon_url.src}
                  width={17}
                  height={7}
                  alt=''
                  style={{ position: 'absolute', bottom: '-2px', right: 0 }}
                />
              </div>
            )
          ) : (
            <>
              <span />
              <span />
              <span />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hamburger
