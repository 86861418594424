import { PureModal } from "components/Modal/PrueModal"


import styles from './component.module.scss'
import { Button } from "@mui/material"

type SyncInfoProps = {
  show: boolean, info: Record<string, any>,
  onClose: (show: boolean) => void,
  onOk: () => void,
  lang: any
}

export const SyncInfo = (
  { show, info, onClose, onOk, lang }: SyncInfoProps
) => {
  const { work, skills, edu } = info

  return <PureModal
    showModal={show}
    handleModal={onClose}
    showClose={false}
    className={styles.pureModalContent}
  >
    <div className={styles.pureModal}>
      <div className={styles.title}>
        {/* Information synchronization confirmation */}
        {lang.profile.syncInfoModal.title}
      </div>
      <div>
        <div className={styles.syncDesc}>
          {/* We will re-sync the following content to your profile: */}
          {lang?.profile.syncInfoModal.reSync}
        </div>
        <div className={styles.syncArea}>
          {work && <div key={'work'}>
            {/* work experience */}
            {lang.profile.workExperience}
          </div>}
          {edu && <div key={'work'}>
            {/* education */}
            {lang.profile.education}
          </div>}
          {skills && <div key={'work'}>
            {/* skill */}
            {lang.profile.skill}
          </div>}
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          onClick={() => {
            onClose(false)
          }}>
          {/* Cancel */}
          {lang.comm.cancel}
        </Button>
        <Button onClick={() => {
          onOk()
        }}>
          {/* Confirm */}
          {lang.comm.confirm}
        </Button>
      </div>
    </div>
  </PureModal >
}