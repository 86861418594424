import { useEffect ,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import { updateUserPreferencesRequest } from 'store/actions/users/updateUserPreferences'
import { deleteUserPreferencesService } from 'store/services/users/addUserPreferences'
type EditJobPreferencesDeleteModalProps = {
  modalName: string
  showModal: boolean
  config: any
  userDetail: any
  handleModal: Function
  preference: any
  lang: Record<string, any>
  onDeleteCallback: () => void,
}

const EditJobPreferencesDeleteModal = ({
  modalName,
  showModal,
  userDetail,
  handleModal,
  preference,
  lang,
  onDeleteCallback,
}: EditJobPreferencesDeleteModalProps) => {
  const {
    manageProfile: {
      tab: {
        preference: { deleteModal }
      }
    }
  } = lang
  const dispatch = useDispatch()
 
  // const isUpdating = useSelector((store: any) => store.users.updateUserPreferences.fetching)

  const [loading,setLoading] = useState(false)
  const onSubmit = async () => {
    setLoading(true)
    const payload = {
      preferences: {
        action: 'delete',
        preferenceId: preference.id,
      }
    }
    deleteUserPreferencesService(payload.preferences).then(res=>{
      onDeleteCallback?.()
    }).finally(()=>setLoading(false))
    // dispatch(updateUserPreferencesRequest(payload))
    // onDeleteCallback?.()
  }

  useEffect(() => {
    handleCloseModal()
  }, [userDetail])
  const handleCloseModal = () => {
    handleModal(modalName, false)
  }

  return (
    <Modal
      showModal={showModal}
      handleModal={handleModal}
      headerTitle={deleteModal.title}
      firstButtonText={deleteModal.btn1}
      secondButtonText={deleteModal.btn2}
      isSecondButtonLoading={loading}
      firstButtonIsClose
      handleFirstButton={handleModal}
      handleSecondButton={onSubmit}
      fullScreen
    >
      {deleteModal.tips}
    </Modal>
  )
}

export default EditJobPreferencesDeleteModal
