/* Vendors */
import moment from 'moment'
import React from 'react'
/* Components */
import { Avatar } from '@mui/material'

/* Helpers */
import useWindowDimensions from 'helpers/useWindowDimensions'
/* Images */
import {
  DefaultAvatar
} from '../../images'
import Image from 'next/image'

/* Styles */
import styles from './UserProfileOverview.module.scss'
import { formatTemplateString } from 'helpers/formatter'
import { MouseOverPopover } from '../../app/components/popover/MouseOverPopover'
import { useManageProfileData } from 'app/[lang]/manage-profile/DataProvider'
import { VipActivity } from 'components/vipActivity'
import edit_url from './edit.svg'
import vip_user_icon_url from './vip_user_icon.png'
import icon_vip_expires_url from './icon_vip_expires.png'
import location_url from './location1.svg'
import birthday_url from './birthday.svg'
import exp_url from './exp.svg'
import email_url from './email.svg'
import tel_url from './tel.svg'
type UserProfileOverviewProps = {
  name: string
  location?: string
  email: string
  contactNumber?: string
  avatarUrl?: string
  description?: string
  birthdate?: string
  expLevel?: string
  lang?: object
  address?: string
  working_since?: string
  location_id?: number
  referral_code?: string
  vip?: {
    is_vip: 0 | 1
    ended_at: string
  }
  handleEditClick: () => void
  advertisingLink: any
  newGetStarted: any
  isFreshGraduate: boolean
}

const getAge = (birthDate) => {
  const now = moment(new Date())
  const then = moment(birthDate).format('YYYY-MM-DD')
  const age = now.diff(moment(then), 'years')
  return age
}

const UserProfileOverview = ({
  name,
  email,
  contactNumber,
  avatarUrl,
  birthdate,
  lang,
  location_id,
  address,
  vip,
  working_since,
  advertisingLink,
  newGetStarted,
  handleEditClick,
  isFreshGraduate
}: UserProfileOverviewProps) => {
  const { width } = useWindowDimensions()
  let age
  if (birthdate) {
    age = getAge(birthdate)
  }
  const { config } = useManageProfileData()
  const { location_lists } = config

  const formattedLocationList = location_lists.map((item) => item.locations).flat()

  const matchedLocation = formattedLocationList.find((loc) => {
    return loc?.id == location_id
  })
  const getYearString = (age: number) => {
    if (age > 1) {
      return formatTemplateString((lang as any).profile.year_other, { age })
    } else {
      return formatTemplateString((lang as any).profile.year_one, { age })
    }
  }

  return (
    <>
      <div className={styles.userOverview}>
        <div className={styles.userOverviewEditIcon} onClick={() => handleEditClick()}>
          <Image src={edit_url} width={24} height={24} alt={'edit_icon'} />
        </div>
        <div className={styles.userOverviewAvatar}>
          {!vip.is_vip ? (
            <Avatar
              sx={{ width: '110px', height: '110px', margin: 0 }}
              src={avatarUrl || DefaultAvatar}
            />
          ) : (
            <div className={styles.vipAvatar}>
              <Avatar
                sx={{ width: '110px', height: '110px', margin: 0, border: '3px solid #FFC248' }}
                src={avatarUrl || DefaultAvatar}
              />
              <Image
                src={vip_user_icon_url}
                width={52}
                height={21}
                alt=''
                style={{ position: 'absolute', bottom: 0, right: 0 }}
              />
            </div>
          )}
        </div>
        {vip.is_vip ? (
          <div className={styles.userOverviewNameLayout}>
            <MouseOverPopover
              className={`${styles.userOverviewName} ${styles.userOverviewNameLayoutVip}`}
              value={name || '-'}
            ></MouseOverPopover>
            <p
              style={{ fontSize: '14px', marginTop: '8px', textAlign: 'center', color: '#515151' }}
            >
              <Image
                src={icon_vip_expires_url}
                width={49}
                height={17}
                alt=''
              />
              <span style={{ marginLeft: '10px' }}>Expire date {vip?.ended_at}</span>
            </p>
          </div>
        ) : (
          <div className={styles.userOverviewNameLayout}>
            <MouseOverPopover
              className={styles.userOverviewName}
              value={name || '-'}
            ></MouseOverPopover>
          </div>
        )}

        <div className={styles.userOverviewInfo}>
          <div className={styles.userOverviewInfoDetail}>
            <Image
              src={location_url}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
              alt={'location'}
            />
            <MouseOverPopover
              className={styles.profileText}
              value={matchedLocation?.value || '-'}
            ></MouseOverPopover>
            {/* <Text textStyle='lg'>{location}</Text> */}
          </div>
          <div className={styles.userOverviewInfoDetail}>
            <Image
              src={location_url}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
              alt={'address'}
            />
            <MouseOverPopover
              className={styles.profileText}
              value={address || '-'}
            ></MouseOverPopover>
          </div>

          <div className={styles.userOverviewInfoDetail}>
            <Image
              src={birthday_url}
              width={24}
              height={24}
              alt={'age'}
              style={{ marginRight: '6px' }}
            />
            <MouseOverPopover
              className={styles.profileText}
              value={age ? getYearString(age) : '-'}
            ></MouseOverPopover>
          </div>

          {/* work since */}

          {
            !isFreshGraduate && <div className={styles.userOverviewInfoDetail}>
              <Image
                src={exp_url}
                width={24}
                height={24}
                style={{ marginRight: '6px' }}
                alt={'location'}
              />
              <MouseOverPopover
                className={styles.profileText}
                value={working_since ? String(working_since)?.split('-').slice(0, 2).join('-') : '-'}
              ></MouseOverPopover>
            </div>
          }

          <div className={styles.userOverviewInfoDetail}>
            <Image
              src={email_url}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
              alt={'email'}
            />
            <MouseOverPopover
              className={styles.profileText}
              value={email || '-'}
            ></MouseOverPopover>
          </div>

          <div className={styles.userOverviewInfoDetail}>
            <Image
              src={tel_url}
              width={24}
              height={24}
              style={{ marginRight: '6px' }}
              alt={'tel'}
            />
            <MouseOverPopover
              className={styles.profileText}
              value={contactNumber || '-'}
            ></MouseOverPopover>
          </div>
        </div>
      </div>

      <VipActivity lang={{ newGetStarted, advertisingLink }} />
    </>
  )
}

export default UserProfileOverview
