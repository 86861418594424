import React from "react"
export const ExchangeIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M3 4.6668C3 4.6668 5.5 0.833445 9.83333 2.33345C14.1667 3.83347 14 8.1668 14 8.1668" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 11.3333C13 11.3333 11 15 6.5 13.8333C2 12.6667 2 8 2 8" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 2.66602V7.99935" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 8V13.3333" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.63466 4.11523H7.49047V4.92111C6.449 5.09354 5.76941 5.74473 5.76941 6.66704V6.67587C5.76941 7.59601 6.3501 8.14042 7.49047 8.39309V9.98056C7.11285 9.85853 6.92139 9.61384 6.86359 9.30101L6.85476 9.25248H5.67676L5.68117 9.31866C5.74429 10.2497 6.43887 10.8492 7.49047 11.0129V11.7959H8.63466V11.0223C9.74502 10.868 10.5035 10.2305 10.5035 9.20836V9.19953C10.5035 8.24213 9.89464 7.73916 8.6681 7.47885L8.63466 7.47208V5.94672C8.97334 6.06322 9.18114 6.29941 9.23284 6.64057L9.24166 6.68028H10.4197L10.4108 6.60527C10.3304 5.67665 9.67497 5.07402 8.63466 4.91753V4.11523ZM8.63466 10.0173V8.65022C9.11725 8.80128 9.27254 9.00471 9.27254 9.32307V9.33189C9.27254 9.65917 9.07474 9.91151 8.63466 10.0173ZM7.49047 5.95635V7.19844C7.14154 7.05467 7.00036 6.85139 7.00036 6.5788V6.56998C7.00036 6.3066 7.16495 6.07762 7.49047 5.95635Z" fill="currentColor" />
  </svg>
}

export const DropDownIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M9.69137 12.3536L6.4666 8.80632C6.22328 8.53867 6.41318 8.10938 6.77491 8.10938H13.2244C13.5862 8.10938 13.7761 8.53867 13.5328 8.80632L10.308 12.3536C10.1427 12.5354 9.85669 12.5354 9.69137 12.3536Z" fill="#2378E5" />
  </svg>
}

export const CloseIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M6.5 6.5L17.5 17.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.5 17.5L17.5 6.5" stroke="#121212" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}